import { v1 as uuidv1 } from 'uuid'

export function joinWithAnd(array, andString) {
  if (array.length === 1) return array[0]
  if (array.length === 2) return array.join(' ' + andString + ' ')
  return array.slice(0, -1).join(', ') + ' ' + andString + ' ' + array[array.length - 1]
}
/**
 * 
 * @param {any} filters 
 * @param {{brand?:string,family?:string,gender?:string}} keys 
 * @returns 
 */
export function filtersToString(filters,keys = {}) {
  if (!filters) return ''
  // let gender = filters.find((f)=>f==='forMen')?'M':filters.find((f)=>f==='forWomen')?'F':(filters.find((f)=>f==='forWomen&forMen')?'U':null)
  // let brands = filters.filter((f)=>f.id==='brand.id').map((f)=>f.value)
  // //brand_ids, family_ids, price_range
  // return (gender?'&gender='+gender:'')+(brands.length?'&brand_ids='+brands.join(','):'')
  let gender = filters.filter((f) => f.keyPath === 'gender')
  let brands = filters.filter((f) => f.keyPath === 'brand.id')
  let family = filters.filter((f) => f.keyPath === 'family.id')
  let result = []
  if (gender.length) result.push(`${keys.gender??'included.gender'}=` + gender.map((d) => d.value.split('')).reduce((array,elem)=>array.concat(elem.filter(e => array.indexOf(e)<0)),[]))
  if (brands.length) result.push(`${keys.brand??'included.brands'}=` + brands.map((d) => d.value))
  if (family.length) result.push(`${keys.family??'included.families'}=`+ family.map((d) => d.value))
  return '&' + result.join('&')
}

export function filtersToFiltersEventObject(filters) {
  return filters.reduce((acc, curr) => {
    switch (curr.keyPath) {
      case 'gender':
        return {
          ...acc,
          gender: curr.value
        }
      case 'brand.id':
        return {
          ...acc,
          brands: [...acc.brands, curr.value]
        }
      case 'family.id':
        return {
          ...acc,
          families: [...acc.families, curr.value]
        }
    }
  }, {brands: [], families: []})
}

export function getNestedValue(ob, path) {
  let parts = path.split('.')
  return parts.reduce((child, part) => {
    return child && child[part]
  }, ob)
}

export function applyFilters(list, filters) {
  if (!filters) return list
  return list.filter((p) => {
    let ands = filters.reduce((ands, filter) => {
      if(filter.validation){
        ands[filter.keyPath] = filter.validation(filter.value,getNestedValue(p, filter.keyPath))
        return ands
      } 
      ands[filter.keyPath] = ands[filter.keyPath] || false
      ands[filter.keyPath] = ands[filter.keyPath] || getNestedValue(p, filter.keyPath) == filter.value
      return ands
    }, {})
    return Object.values(ands).reduce((res, and) => res && and, true)
  })
}

const levenshtein = require('js-levenshtein');

export function matchWords(a, b) {
  const newB = b.toLowerCase()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "") //Remove accents - NFD => Forma de Normalización de Descomposición Canónica.
  const newA = a.toLowerCase()
    .normalize("NFD").replace(/[\u0300-\u036f]/g, "") //Remove accents
  const splitedB = newB.split(' ').filter(e => e!=='')
  const splitedA = newA.split(' ').filter(e => e!=='')
  
  if (newA.toLowerCase().indexOf(newB.toLowerCase()) >= 0) return true
  
  const result = splitedB.every(e => splitedA.some(t => t.indexOf(e)>-1 || levenshtein(e,t)<2))
  if(a.length >= 5 && b.length >= 5  && result) return true
  //else if (a.length >= 5 && b.length >= 5 && levenshtein(a, b) - Math.abs(b.length - a.length) < 2) return true
  return false
}

export function getUserIdLocalStorage() {
  var userid = localStorage.getItem('tempId')
  if (userid === null) {
    userid = uuidv1()
    localStorage.setItem('tempId', userid)
  }
  return userid
}
export function removeUserIdLocalStorage(){
  localStorage.removeItem('tempId')
}