// import d3 from '../../utils/d3Importer'
import { forceSimulation as d3_forceSimulation } from 'd3-force'
import {
  forceManyBody as d3_forceManyBody,
  forceX as d3_forceX,
  forceY as d3_forceY,
  forceRadial as d3_forceRadial
} from 'd3-force'
import * as d3array from 'd3-array'
import * as d3scale from 'd3-scale'
import * as d3zoom from 'd3-zoom'
import forceCollideByType from './forceCollideByType'
import {select as d3_select} from 'd3'
import { forceBorderColision } from '../../utils/d3Forces/forceBorderColision'
import {forceBorderCircle} from '../../utils/d3Forces/forceBorderCircle'
import CanvasUtils from '../../utils/canvasUtils'
/**
 * @typedef {{id:string | number,ghost?:boolean, deep:number, type: string. id: number,colorBorder:string, colorOuter: string, image: string, perfumes: number,parent: string, x:number,y:number,r:number}} CircleNode 
 * @typedef {import('../../services/Api').TopFamilies} TopFamilies
 */
const TRANSFORM_TIME = 2000
const RADIUS = 6
const FONT_0 = 15
const FONT_0_MOBILE = 25

const STROKE_0 = 5//15
const STROKE_1 = 3
/** porcentaje del radio */
const STROKE_2 = .1

const SIZE_MAIN_TEXT = 30

const MAX_ZOOM_PEFUME = 20
export default ()=>({
  width:500,
  height:500,
  selected: [],
  rootDOM: d3_select('body').append('custom:sketch'),
  d3Nodes:d3_select('body').append('custom:sketch').selectAll('.none'),
  selectedDOM:[],
  lastSelected: [],
  mobile: false,
  /** si la familia ha sido clicada y la simulacion ya ha comenzado con unos tick */
  familyIsClicked: {},
  getTransformFromD3Selection(nodes) {
    const self = this
    var array = []
    nodes.each(function (n) {
      var r = d3_select(this).attr('r')
      array.push({x:n.x,y:n.y,k:self.getScaleFromRadius(r),deep:n.deep})
    })
    return array.sort((a,b)=>a.deep-b.deep)
      .reduce((transform, node) => transform.translate(-node.x, -node.y), d3zoom.zoomIdentity.scale(array[array.length - 1].k))
  },
  /** es una funcion para que, en el caso de tener una familia seleccionada, determinar la posicion del nodo clickado */
  transform: function () {
    if (this.selectedDOM.nodes().length > 0)
      return this.getTransformFromD3Selection(this.selectedDOM)
      // return this.selected
      //   .map(s => this.getNode(s.id))
      //   .filter(n => n)
      //   .reduce((transform, node) => transform.translate(-node.x, -node.y), d3zoom.zoomIdentity.scale(this.getScaleFromNode(this.selected[this.selected.length - 1])))
    return d3zoom.zoomIdentity
  },
  transformSelected: function () {
    if(this.lastSelected.length>0)
      return this.lastSelected//.map(s => this.getNode(s.id))
        .reduce((transform, node) => transform.translate(-node.x, -node.y), d3zoom.zoomIdentity.scale(this.getScaleFromNode(this.lastSelected[this.lastSelected.length - 1])))
    return d3zoom.zoomIdentity
  },
  zoom:null,
  init:false,
  canvas:null,
  zooming:false,
  /**@type {Map<string,CircleNode[]>} */
  mapNodes: new Map(),
  /**@type {Map<string,CircleNode[]>} */
  allGroupedNodes: new Map(),
  d3NodeMap: new Map(),
  scaleFont:d3scale.scaleLinear(),
  lines:{},
  nodes:[],
  get proportionSize(){
    return Math.min(this.width,this.height)/720
  },
  getNode(id) { return this.mapNodes.get(id) ? this.mapNodes.get(id)[0] : undefined },
  getProportionalSize(number){
    return number*this.proportionSize
  },
  /** nodes only in simulation @type {Map<string,CircleNode[]>} */
  get groupedNodes(){
    return d3array.group(this.canvas.node().simulation.nodes(),d => d.parent)
  },
  // /** all nodes @type {Map<string,CircleNode[]>} */
  // get allGroupedNodes(){
  //   return d3array.group(this.nodes,d => d.parent)
  // },
  get FONT_0(){
    return this.mobile ? FONT_0_MOBILE : FONT_0
  },
  getScaleFromNode(node){
    return this.getScaleFromRadius(node.r)
  },
  getScaleFromRadius(r){
    return Math.min(this.width/2-this.getProportionalSize(50),this.height/2-this.getProportionalSize(50))/r
  },
  setSize(width,height){
    this.width = width
    this.height = height
    if(this.init)
      this.setForces()
  },
  getFamiliesNodes(node){
    const mainNodes = Object.keys(this.lines)
    .reduce((arr,key)=>arr.concat(this.allGroupedNodes.get(key).filter(e => e.id!==node.id)),[])
    /** @type {CircleNode[]} */
    return mainNodes

  },

  setSelected(values) {
    this.lastSelected = [...this.selected]
    this.selectedDOM = this.getD3NodesByIds(values.map(e => e.id))
    if (values.length > 0 && values.length === this.selected.length && !values.some(newS => this.selected.every(s => s.id !== newS.id))) {
      console.debug('same selected, not change')
      this.selected = [...values]
      return 
    }
    if (!this.init) { console.warn('INIT is false!!!!!!');  return }
    // Save lastTransform because transform depends of selected values (PATCH!)
    // const lastTransform = this.transform
    const lastIsEmpty = this.selected.length===0
    this.selected = [...values]
    if(values.length>0){
      const resetPositionNewNodes = lastIsEmpty
      
      const copy = this.getFamiliesNodes(values[0],resetPositionNewNodes)
      const getRecursive = (children) => {
        if(children) return children.reduce((array,child) => array.concat(getRecursive(this.allGroupedNodes.get(child.id))),children)
        else return []
      }
      var newNodes = getRecursive([values[0]])
      // Remove the ghost nodes becuase it is fixed to center and we need put the selected node in center
      //if(values.length===2) newNodes = newNodes.filter(n => !(n.deep===1 && n.ghost))
      // const nodes = newNodes.filter(n => n.id!==values[0].id)
      // this.canvas.node().simulation.nodes(nodes)
      // this.setD3Nodes(nodes)
    
      // Do
      newNodes = newNodes.concat(copy)
      this.canvas.node().simulation.nodes(newNodes)
      this.setD3Nodes(newNodes)
      this.selectedDOM = this.getD3NodesByIds(values.map(e => e.id))
      
      if (!this.familyIsClicked[values[0].id]){
        this.canvas.node().simulation.tick(20)
        this.familyIsClicked[values[0].id] = true
      }
    }
    else {
      const mainNodes = Object.keys(this.lines).reduce((array,key)=>array.concat(this.allGroupedNodes.get(key)),[])
      this.canvas.node().simulation.nodes(mainNodes)
      this.setD3Nodes(mainNodes)
      this.selectedDOM = this.getD3NodesByIds(values.map(e => e.id))
    }
    this.setForces()
    this.applySelectedTransform()
    // //if(!this.init) return
    // var transform = d3zoom.zoomIdentity
    // if(values.length>0) {
    //   transform = values.reduce((transform,node)=>transform.translate(-node.x,-node.y),d3zoom.zoomIdentity.scale(this.getScaleFromNode(values[values.length-1])))
    // }
    // // this.transform = lastTransform
    // this.applyTransform(transform)
  },
  setD3Nodes(nodes) {
    var d3Nodes = this.rootDOM.selectAll('.circles').data(nodes,n => n.id)
    var d3NodesEnter = d3Nodes.enter().append('custom:circle')
      .attr('class',d => 'circles ' + 'parent-'+d.parent + ' id-'+d.id)
      .attr('r',0)
      .attr('global-opacity',0)
      .attr('opacity',1)
      d3NodesEnter
        .transition()
        .duration(1500)
        .attr('global-opacity',1)

    d3Nodes.exit().transition().duration(1500)
      .attr('opacity', 0)
      .attr('global-opacity',0)
      .attr("r", 0).remove()
    
    // console.table([['Exit', exit.nodes().length],
    // ['Enter', d3NodesEnter.nodes().length],
    //   ['Update', d3Nodes.nodes().length]])
    
    d3Nodes = d3Nodes.merge(d3NodesEnter)
    d3Nodes.transition().duration(1500)
      .attr('r',d => d.r)
      .attr('opacity', d => this.selected.some(sel => sel.id === d.id) ? 0 : 1)
      .attr('global-opacity',1)
    this.d3NodeMap = new Map()
    
    const recursive = (nodes)=> {
      
      nodes.each(n => {
        var select = this.rootDOM.selectAll('.parent-' + n.id)
        this.d3NodeMap.set(n.id, select)
        if(n.deep!==2)
          recursive(select)
      })
    }
    Object.keys(this.lines).forEach(key => {
      const select = this.rootDOM.selectAll('.parent-' + key)
      this.d3NodeMap.set(key,select)
      recursive(select)
    })
    this.d3Nodes = d3Nodes
  },
  getD3NodesByIds(ids) {
    if(ids.length>0)
      return this.rootDOM.selectAll(ids.map(e => '.id-' + e).join(','))
    return this.rootDOM.selectAll('.none')
  },
  getD3Nodes(parent){
    return this.d3NodeMap.get(parent)
  },
  getNodesToDraw() {
    if (this.selected[0]) {
      const copy = this.getFamiliesNodes(this.selected[0])
      const getRecursive = (children) => {
        if(children) return children.reduce((array,child) => array.concat(getRecursive(this.allGroupedNodes.get(child.id))),children)
        else return []
      }
      var newNodes = getRecursive([this.selected[0]])
      newNodes = newNodes.concat(copy)
      return newNodes
    }
    const mainLines = Object.keys(this.lines)
    mainLines.forEach(key => this.mapNodes.set(key,[{r:400}]))
    var nodes = mainLines.reduce((array, key) => array.concat(this.allGroupedNodes.get(key)), []).filter(e => e)
    return nodes
  },
  /**
   * 
   * @param {HTMLCanvasElement} canvas
   * @param {{nodes:CircleNode[]}} param1 
   */
  draw(canvas,{
    nodes,
    lines={main:{y:-100}},
    onClick = ()=>{},
    onHover = ()=>{},
    mobile=false,
  } = {}) {
    // set state
    this.nodes = nodes
    this.mobile = mobile
    this.canvas = d3_select(canvas)
    this.lines = lines
    this.allGroupedNodes = d3array.group(this.nodes,d => d.parent)
    // chapu!?
    if(!nodes.length) return 

    /** Map of nodes @type {Map<string,CircleNode[]>} */
    const mapNodes = d3array.group(nodes, d => d.id)
    this.mapNodes = mapNodes
    const getNode = id => mapNodes.get(id) ? mapNodes.get(id)[0] : undefined
    // group of main nodes
    const mainLines = Object.keys(lines)
    mainLines.forEach(key => mapNodes.set(key,[{r:400}]))
    const nodesToDraw = this.getNodesToDraw()
    this.setD3Nodes(nodesToDraw)
    
    // Create simulation
    const simulation = canvas.simulation || d3_forceSimulation()
    canvas.simulation = simulation

    this.forceX = d3_forceX()
    this.forceY = d3_forceY()
    this.forceRadial = d3_forceRadial(d => d.deep===1 ? 2/3 : getNode(d.parent)?.r*2/3).strength(d => !d.ghost && !lines[d.type] ? 1: 0)
    this.setForces()
    const getExtraMargin = d => d.deep == 0
      ? this.getProportionalSize(RADIUS + this.FONT_0)
      : d.deep === 1
        ? this.getProportionalSize(STROKE_1) / this.getScaleFromNode(getNode(d.parent))
        : this.getProportionalSize(.5)
    
    this.forceCollide = forceCollideByType(d => (d.deep === 2 && !d.ghost ? Math.random() * 2 : 1) * d.r + getExtraMargin(d), 'parent')//.strength(0.2)
    canvas.simulation.nodes(nodesToDraw)
    .force('x', this.forceX)
    .force('y', this.forceY)
    .force('border',forceBorderColision([-this.width/2,this.width/2],[-this.height/2,this.height/2],d => d.r + this.getProportionalSize(30 + this.FONT_0)))
    .force('charge', d3_forceManyBody().strength(d => d.ghost ? d.deep===1 ? -2 : -.5: 0.05))
    .force('border-radial',forceBorderCircle(d => d.deep===0 ? 0 : -d.r+getNode(d.parent)?.r-1)
      .strict(d => d.deep===2))
    .force('radial',this.forceRadial)
    .force(
      'collision',
      this.forceCollide
    )
    canvas.simulation.alpha(0.5).velocityDecay(0.5)
    if (this.init){
      canvas.simulation.alpha(1).velocityDecay(0.95)
    }
    canvas.simulation.restart()
    
    // Zoom
    this.zoom = d3zoom
    .zoom()
    .scaleExtent([0.5, 32])
    .translateExtent([
      [-this.width/2, -this.height/2],
      [this.width/2, this.height/2],
    ])
    .extent([
      [-this.width/2, -this.height/2],
      [this.width/2, this.height/2],
    ])
    .on('zoom', (params)=>this.zoomed(params))

    canvas.simulation.on('tick', this.ticked(false))
    //this.setSelected(this.selected)
    //this.applySelectedTransform()
    if (!this.init) {
      const findChild = (event,transform,nodes)=>{
        if(!nodes.length) return null
        const node = nodes[nodes.length-1]
        const childNodes = (this.groupedNodes.get(node.id)|| [])
        const child = childNodes.find(child => {
          if(child.ghost) return false
          const position = nodes.concat([child]).reduce((t,n) => t.translate(n.x,n.y),transform)
          return Math.hypot(position.x-event.offsetX,position.y-event.offsetY)<child.r*transform.k
        })
        return child
      }
      canvas.addEventListener("mousemove",(event) => {
        if(this.mobile) return 
        if(this.selected.length===0) return
        var transform = this.transform()
        transform = d3zoom.zoomIdentity
        .translate(this.width/2,this.height/2)
        .translate(transform.x,transform.y)
        .scale(transform.k)
        const mainNodes = mainLines.reduce((array,key)=>array.concat(this.groupedNodes.get(key)),[])
        const node = mainNodes.filter(node => node).find(node => {
          return Math.hypot(transform.x+(node.x*transform.k-event.offsetX),transform.y+(node.y*transform.k-event.offsetY))<node.r*transform.k
        })
        if(!node) return onHover(null)
        const child = findChild(event,transform,[node])
        if(!child) return onHover(null)
        
        const perfume = findChild(event,transform,[node,child])
        if(!perfume) return onHover(null)
        const position = [node,child,perfume].reduce((t,n) => t.translate(n.x,n.y),transform)
        onHover(perfume,position)
        
        
      })
      // Mouse Click
      canvas.addEventListener("mouseup", event => {
        if(this.zooming) return
        var transform = this.transform()
        transform = d3zoom.zoomIdentity
        .translate(this.width/2,this.height/2)
        .translate(transform.x,transform.y)
        .scale(transform.k)
        const mainNodes = mainLines.reduce((array,key)=>array.concat(this.groupedNodes.get(key)),[])
        const node = mainNodes.filter(node => node).find(node => {
          return Math.hypot(transform.x+(node.x*transform.k-event.offsetX),transform.y+(node.y*transform.k-event.offsetY))<node.r*transform.k
        })
        const findChildAndApplyTransform = (node)=>{
          const child = findChild(event,transform,[node])
          if(child){
            console.debug('CLICK TO CHILD',child)
            const nodesInside = (this.allGroupedNodes.get(child.id) || []).filter(n => !n.ghost)
            if(!nodesInside || nodesInside.length===0) {
              console.debug(`node ${child.name} doesnt have perfumes inside`)
              return child
            }
            const childIndex = this.selected.findIndex(e => e.id===child.id)
            if(childIndex>-1){
              const copy = [...this.selected]
              copy.splice(childIndex,1)
              onClick(copy)
              this.applyTransform(d3zoom.zoomIdentity.scale(this.getScaleFromNode(node)))
            }else{
              const copy = [...this.selected]
              if(this.selected.length>=2) copy.splice(1,1)
              onClick([...copy, child])
              this.applyTransform(this.selected.reduce((transform,node)=>transform.translate(-node.x,-node.y),d3zoom.zoomIdentity.scale(this.getScaleFromNode(this.selected[this.selected.length-1]))))
            }
            return child
          }
          return null
        }
        if(node){ 
          console.debug('CLICK TO',node)
          const index = this.selected.findIndex(e => e.id===node.id)
          if(index===-1){
            onClick([node])
            
          }
          // already selected
          else{
            if(findChildAndApplyTransform(node))return 
            else if(this.selected.length>=2) {
              onClick([node])
            }
            else {
              const copy = [...this.selected]
              copy.splice(index,1)
              onClick(copy)
            }
          }
        } else {
          onClick(null)
        }
      })
      this.init = true
      simulation.tick(50)
    }
  },
  setForces(params = {}) {
    const sameParent = d => this.selected[this.selected.length-1].parent===d.parent
    const isLayer = (i,d,self=false) => this.selected[i] && this.selected[i].parent === d.parent && (self || this.selected[i].id !== d.id)
    
    const strangthX = params.strangthX || (d => {
      if(d.ghost && d.deep===1) return 2
      if(d.ghost && d.deep===2) return 2
      if(isLayer(1,d)) return 0.01
      if(isLayer(1,d,true)) return .2
      // if(isLayer(0,d)) return 0
      // if(isLayer(0,d,true) ) return 0.5
      else return this.lines[d.type] ? 0.01 : 0
      //return this.lines[d.type] ? this.selected.length>0 && this.selected[this.selected.length-1].type === d.type ? 0.3: 0 : 0
    })
    const strangthY = params.strangthY || (d => {
      if(d.ghost && d.deep===1) return 2
      if(d.ghost && d.deep===2) return 2
      if(isLayer(1,d)) return 0.1
      if(isLayer(1,d,true)) return .2
      // if(isLayer(0,d)) return 0
      // if(isLayer(0,d,true) ) return 1
      return this.lines[d.type] ? 0.5 : 0
    })
    const getYposition = params.getYposition || (d => {
      return this.lines[d.type] ? this.lines[d.type].y : 0
      
    })
    const getXposition = params.getXposition || 0
    const strengthRadial = d => this.lines[d.type] ? 0: this.selected.length===2 && sameParent(d) ? 0: .1
    this.forceRadial.strength(strengthRadial)
    this.forceX.x(getXposition).strength(strangthX)
    this.forceY.y(getYposition).strength(strangthY)
  },
  applySelectedTransform() {
    var transform = d3zoom.zoomIdentity
    if(this.selected.length>0) {
      transform = this.selected.reduce((transform,node)=>transform.translate(-node.x,-node.y),d3zoom.zoomIdentity.scale(this.getScaleFromNode(this.selected[this.selected.length-1])))
    }
    this.applyTransform(transform)
  },
  applyTransform(transform){
    // Zoom transition
    this.canvas
      .call(this.zoom.transform, this.transformSelected())    
      .transition()
      .duration(TRANSFORM_TIME)
      .call(this.zoom.transform, transform)
      .on("start",()=>{
        this.zooming = true
        this.canvas.node().simulation.stop()
      })
      .on('end',()=>{
        this.zooming = false
        this.canvas.node().simulation.alpha(.2).restart()
      })
  },
  /**
   * main function for draw in canvas
   * @param {boolean} isZoom in case of "request animation frame" come from d3-zoom
   */
  ticked(isZoom = false, t) {
    // const context = CanvasUtils.setupCanvas(this.canvas.node())
    const context = this.canvas.node().getContext('2d')
    return () => {
      if(!isZoom && this.zooming ) return
      const translate = t || this.transform()
      const transform = d3zoom.zoomIdentity.translate(translate.x, translate.y).translate(this.width / 2, this.height / 2).scale(translate.k)
      context.clearRect(0,0,this.width,this.height)
      Object.keys(this.lines).map(key => ({key,startedNodes:this.getD3Nodes(key)})).map(({startedNodes,key}) => {
        if(!startedNodes) return
        const filteredNodes = startedNodes
          // WHY THIS!?
          // .filter(node => this.canvas.node().simulation.nodes().find(n => n.id===node.id))
          // .map(node => ({...node,id:this.selected.find(e => e.id===node.id)?.id}))
        
        this.applyTranslate(context, transform.x, transform.y, transform.k, () => {
          if (this.selected.length === 0) {
            CanvasUtils.drawLine(context,-this.width/2,this.lines[key].position,this.width/2,this.lines[key].position) 
            context.font = `normal ${SIZE_MAIN_TEXT}px SangBleu`
            context.fillText(this.lines[key].title, -this.width/2 +30, this.lines[key].position+SIZE_MAIN_TEXT);
          }
          this.recursiveDraw(context,filteredNodes,this.groupedNodes,transform)
        })
      })
    }
  },
  /**
   * save the transform in state for apply it in ticked function
   * @param {{transform:import('d3-zoom').ZoomTransform}} param0 
   */
  zoomed({ transform }) {
    return this.ticked(true,transform)()

  },
  getName(d){
    if(d.deep===2 && this.selected.length===2){
      const invert = this.selected[0].isSecondary
      const names = [this.selected[0].name,d.name]
      return !d.ghost ? d.parent!==this.selected[1].parent ? '' : names.join('/') : invert ? names.reverse().join('/') :names.join('/') 
    }
    return d.name ? d.name : ''
  },
  /**
   * 
   * @param {CanvasRenderingContext2D} context 
   * @param {CircleNode[]} nodes 
   * @param {Map<string,CircleNode[]>} mapNodes 
   * @param {number} it iteration: 0 for main circles. 1 for its children ...
   */
  recursiveDraw(context,nodes,mapNodes,transform,it=0){
    //const transform = this.transform()
    // const kFamily = this.selectedDOM.nodes()[0] ? this.getScaleFromRadius(Number(this.selectedDOM.nodes()[0].getAttribute('r'))) : 1
    // const kFamilyFinal = this.selected[0] ? this.getScaleFromRadius(this.selected[0].r) : 1
    const k = transform.k
    const transformPerfume = k>=MAX_ZOOM_PEFUME ? MAX_ZOOM_PEFUME/k : 1
    const self = this

    if(nodes)
      nodes.each(function (node) {
        const d3Select = d3_select(this)
        const r = Number(d3Select.attr('r'))
        const opacityDOM = Number(d3Select.attr('opacity'))
        const glbalOpacityDOM = Number(d3Select.attr('global-opacity'))
        const stroke = it===0 
        ? self.getProportionalSize(STROKE_0+self.FONT_0)/transform.k 
        : it===1 
        ? self.getProportionalSize(STROKE_1)/k 
            : r *transformPerfume * self.getProportionalSize(STROKE_2)
        
        if (r <= 0) return 
        if(!node.ghost) 
          CanvasUtils.drawCircle(context, node.x, node.y, node.deep!==2 ? r : r*transformPerfume, node.colorBorder,it==2 ? node.colorInner : null,stroke)

        var children = self.getD3Nodes(node.id)
        if(it==0 || it===1) 
          CanvasUtils.drawGradient(context,node.x,node.y,r,node.colors)

        if(children && node.id!==node.parent && self.selected.length>0)
          self.applyTranslate(context,node.x,node.y,1,()=>{
            self.recursiveDraw(context,children,mapNodes,transform,it+1)
          })
        if(node.deep===0 && node.image) {
          var opacity = Math.min(glbalOpacityDOM,opacityDOM)
          CanvasUtils.drawImage(context,node.image,node.x,node.y,r,opacity)
          CanvasUtils.drawGradient(context,node.x,node.y,r,['white','white'],Math.min(0.1,opacity))
          CanvasUtils.getCircularText(context,node.x,node.y,node.name,r,0,'center',false,true,'Lelo',self.getProportionalSize(self.FONT_0)/transform.k +'px',0,opacity,node.fontColor)
          CanvasUtils.getCircularText(context,node.x,node.y,node.perfumes?.toString(),r,180,'center',false,false,'Lelo',self.getProportionalSize(self.FONT_0)/transform.k +'px',0,opacity,node.fontColor)
          // CanvasUtils.drawText(context,self.getName(node),node.x,node.y,node.textSize/transform.k,true,opacity)
          // CanvasUtils.drawText(context,node.perfumes?.toString(),node.x,node.y+node.textSize/transform.k,node.textSize/transform.k,true,opacity)
        }
        else if(self.getName(node) && node.ghost){
          CanvasUtils.drawText(context,self.getName(node),node.x,node.y,node.textSize/k,true,glbalOpacityDOM)
          CanvasUtils.drawText(context,node.perfumes?.toString(),node.x,node.y+node.textSize/k,node.textSize/k,true,glbalOpacityDOM)
        }

      })
  },
  /**
   *  Remember apply ctx.restore before
   * @param {CanvasRenderingContext2D} ctx 
   * @param {number} x n
   * @param {number} y 
   * @param {(ctx:CanvasRenderingContext2D)=>void} cb callback
   */
  applyTranslate(ctx,x,y,scale,cb){
    ctx.save()
    ctx.translate(x,y)
    ctx.scale(scale,scale)
    cb(ctx)
    ctx.restore()
  }
})