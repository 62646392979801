<template>
  <div class="recommendations">
    <div class="background" @click="doClose"></div>
    <div class="scroll-wrapper" ref="scrollWrapper">
      <div class="content">
        <!-- <div class="title">{{t('your_recos')}}</div> -->
        <ProfileDesc></ProfileDesc>
        <SimilarPerfumes
          :reference="reference"
          type="by_profile"
          :selectId="selected ? null: selectedReco"
          @select="handleSelectPerfume"
        ></SimilarPerfumes>

        <div class="close-btn" @click="doClose"></div>

        <transition name="fade">
          <div class="selected-content-wrapper" v-if="selectedReco">
            <div class="selected-content">
              <PerfumeFullView
                :data="selected"
                @close="handleCloseFullView"
                :showRecoReason="true"
              ></PerfumeFullView>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
// import PerfumeCard from '@/components/PerfumeCard'
import PerfumeFullView from '@/components/PerfumeFullView'
import SimilarPerfumes from '@/components/SimilarPerfumes'
import ProfileDesc from '@/components/Profile/ProfileDesc'
import {fetchFavorites} from '@/services/Api'
export default {
  name: 'Recommendations',
  components: {
    SimilarPerfumes,
    PerfumeFullView,
    ProfileDesc
    // PerfumeCard
  },
  props:{
    family:Number
  },
  data(){
    return {
      selectedReco: null,
      selected:null
    }
  },
  routerLinkedData: ['selectedReco'],
  /*lazyData:{
    selectedReco: {
      type: 'perfume'
    }
  },*/
  asyncComputed:{
    favorites:{
      get(){
        return fetchFavorites()
      },
      default:[]
    }
  },
  computed:{
    reference(){
      if(this.family!==null)
       return this.favorites.filter(p => p.family.id === this.family)
      return this.favorites
    }
  },
  
  // WTF!!! porque no va este nextTick+emmit y SI que va en PerfumeAndSimilars!?!??!?!?!
  methods:{
    handleSelectPerfume(perfume){
      if(perfume){
        this.selectedReco = perfume.id
        this.selected = perfume
      }else
        this.handleCloseFullView()
        
      
    },
    handleCloseFullView(){
      this.selectedReco = null
      //this.selected = null
    },
    doClose(){
      this.selectedReco = null
      this.selected = null
      this.$nextTick(()=>this.$emit('close'))
    }
  },
  watch:{
    selectedReco (){
      if(this.selectedReco) this.$refs.scrollWrapper.scrollTop = 0
    }
  }
}
</script>
<style scoped lang="stylus">
.recommendations
  height: 100%
  width: 100%

  .background
    background-color: #FFFBF2 // #ddd
    // background-image: url("~@/assets/images/perfume-card-bg.jpg")
    // background-position: center center
    background-repeat: no-repeat
    background-size: cover
    height: 100%
    position: absolute
    width: 100%
    z-index: 0

  .scroll-wrapper
    height: 100%
    left: 0
    overflow: auto
    position: absolute
    top: 0
    width: 100%

    .content
      background: #fff
      display: flex
      flex-direction: column
      margin: auto
      min-height: 100% // para cuando esta cargando
      padding: vh(40px) vw(80px)
      // padding-bottom: 0 // queda mucho mejor el scroll
      position: relative

      .desktop &
        m-max-width-container()

      .mobile &
        .profile-desc
          margin-top: vw(30px)

      .title
        m-font-size(28, 30)
        margin-bottom: vh(40px)
        text-align: center

      .similar-perfumes
        display: flex
        flex: 1 1 100%
        flex-direction: column

        >>> .list-filters
          border-top: 1px solid rgba(#000, 0.15)

          .mobile &
            padding: vw(10px)

        >>> .similars-list
          flex: 1 1 0

          .perfume-card
            height: 30vh

            .mobile &
              height: 30vh

      .close-btn
        cursor: pointer
        m-icon("close", 20)
        position: absolute
        right: vw(20px)
        top: vh(20px)

        .mobile &
          right: vw(17px)

    .selected-content-wrapper
      height: 100%
      left: 0
      position: absolute
      top: 0
      width: 100%
      z-index: 2

      .selected-content
        background: #fff

        .desktop &,
        .tablet &
          m-max-width-container()

      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity 0.4s

      &.fade-enter,
      &.fade-leave-to
        opacity: 0
</style>