<template>
  <div class="profile-desc">
    <div class="main">
      <span v-if="families.length>1">{{t('you_love_perfumes_of_the_plural')}}&nbsp;</span>
      <span v-else>{{t('you_love_perfumes_of_the')}}&nbsp;</span>

      <span class="fam-group" v-for="(fam, i) in families" :key="fam.id">
        <template v-if="families.length>1 && i===families.length-1">&nbsp;{{t('and')}}&nbsp;</template>
        <span :class="['fam-word',{selected:selection===fam}]" @click="onFamClick(fam)">{{fam.name}}</span>
        <span v-if="i<families.length-2">,&nbsp;</span>
      </span>
      <span v-if="families.length>1">&nbsp;{{t('desc_families')}}</span>
      <span v-else>&nbsp;{{t('desc_family')}}</span>
    </div>
    <div class="sub" v-if="subFamilies.length>0">
      <span v-if="subFamilies.length>1">{{t('you_also_love_plural')}}&nbsp;</span>
      <span v-else>{{t('you_also_love')}}&nbsp;</span>
      <span class="sub-fam-group" v-for="(subfam, i) in subFamilies" :key="subfam.id">
        <template v-if="subFamilies.length>1 && i===subFamilies.length-1">&nbsp;{{t('and')}}&nbsp;</template>
        <span :class="['sub-fam-word']">{{subfam.name}}</span>
        <span v-if="i<subFamilies.length-2">,&nbsp;</span>
      </span>
      <span v-if="subFamilies.length>1">&nbsp;{{t('desc_sub_families')}}</span>
      <span v-else>&nbsp;{{t('desc_sub_family')}}</span>
    </div>
  </div>
</template>

<script>
// import {joinWithAnd} from '@/utils'

export default {
  name: 'ProfileDesc',
  components:{
  },
  model: {
    prop: 'selection',
    event: 'change'
  },
  props: ['selection'],
  computed: {
    // familiesString () {
    //   let fams = this.$store.getters.profileFamiliesWithSubs
    //   return joinWithAnd(fams.map((f)=>f.family.name),'and')
    // },
    families () {
      let fams = this.$store.getters.profileFamiliesWithSubs
      return fams.map((f)=>{return f.family})
    },
    subFamilies () {
      let fams = this.$store.getters.profileFamiliesWithSubs
      let subs = fams.map((f)=>f.subFamilies.map((s)=>s.subFamily)).flat() // aqui llistes totes les subfamilies de qualsevol familia en la mateixa array
      subs = subs.filter((s,i)=>subs.findIndex((s)=>(s.id===subs[i].id))===i) // elimines subfam repes
      subs = subs.filter((s)=>fams.findIndex((f)=>f.family.id===s.id)<0) // elimines les subfams q es diuen igual q una fam?
      subs = subs.slice(0,3) // nomes pilles 3 subfam
      return subs
    }
  },
  methods:{
    capitalize(word){
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    },
    onFamClick(fam){
      if(fam !== this.selection) this.$emit('change',fam)
      else this.$emit('change',null)
    }
  }
}
</script>

<style scoped lang="stylus">
.profile-desc
  display: flex
  flex-direction: column
  text-align: center

  .main,
  .sub
    display: inline
    margin-bottom: vh(20px)

    // white-space nowrap
    .fam-group,
    .sub-fam-group
      display: inline-block
      white-space: pre

      .fam-word,
      .sub-fam-word
        cursor: pointer
        display: inline-block
        white-space: pre
        m-first-letter-case()

        &.selected
          font-weight: bold
          text-decoration: underline

  .main
    m-first-letter-case()
    m-font-size(24, 32)

  .sub
    m-first-letter-case()
    m-font("Lelo", "extra-light")
    m-font-size(14, 20)
</style>