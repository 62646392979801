<template>
    <label class="container">
        <span v-html="label" class="label" :class="{marked:value}"/>
        <input type="checkbox" :checked="value" @input="$emit('input',$event.target.checked)"  />
        <span :class="{checkmark:true,marked:value}"/>
    </label>
</template>
<script>
export default {
    props:{
        label:String,
        value:Boolean
    }
}
</script>
<style lang="stylus" scoped>
.container 
    display: block;
    position: relative;
    padding-left: vw(35px);
    margin-bottom: vh(12px);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items flex-start
    
    m-font("Lelo", "light")
       
    input
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    
        
.checkmark        
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    left: 0;
    height: vw(17px);
    width: vw(17px);
    background-color: #eee;

    &.marked    
        position: absolute;
        top: 50%;
        transform: translate(0,-50%);
        left: 0;
        height: vw(17px);
        width: vw(17px);
        background-color: #eee;
        &:after
            content: "";
            position: absolute;
            display block;
            left: vw(6px);
            bottom: vh(4px);
            width: vw(7px);
            height: vw(11px);
            border: solid grey;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            .mobile &
                bottom: vh(9px);
                left: vw(5px);
.label
    &.marked
        font-weight bold    
        
</style>