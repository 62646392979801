<template>
  <div class="lang-toggle">
    <ButtonWithFeedback class="lang-btn" :class="currentLang==='en'?'selected':''" @click="setLang('en')">
      <div class="icon"></div>
      <div class="label">ENGLISH</div>
    </ButtonWithFeedback>
    <ButtonWithFeedback class="lang-btn" :class="currentLang==='fr'?'selected':''" @click="setLang('fr')">
      <div class="icon"></div>
      <div class="label">FRANÇAIS</div>
    </ButtonWithFeedback>
    <ButtonWithFeedback class="lang-btn" :class="currentLang==='es'?'selected':''" @click="setLang('es')">
      <div class="icon"></div>
      <div class="label">ESPAÑOL</div>
    </ButtonWithFeedback>
  </div>
</template>

<script>
import ButtonWithFeedback from '@/components/ButtonWithFeedback'
export default {
  name: 'LangToggle',
  components:{
    ButtonWithFeedback
  },
  computed: {
    currentLang(){
      return this.$store.state.i18n.lang
    }
  },
  methods:{
    setLang(lang){
      this.$store.dispatch('setLanguage',lang)
      this.eventTracker.emitEvent('LANGUAGE_CHANGED', { lang })
      document.documentElement.setAttribute('lang', lang)
      this.$emit('change')
    },
  }
}
</script>

<style scoped lang="stylus">
.lang-toggle
  // align-items: center
  // align-items: flex-start
  // display: flex
  // flex-direction: column

  // m-letter-spacing(50)
  .lang-btn
    align-items: center
    display: flex

    &:hover
      .label
        opacity: 1

    &.selected
      cursor: default

      .icon
        m-icon("radio-input-checked", 12)

        .mobile &
          m-icon("radio-input-checked", 16)

      .label
        opacity: 1

    .icon
      m-icon("radio-input", 12)
      margin-right: vw(5px)

      .mobile &
        m-icon("radio-input", 16)
        margin-right: vw(10px)

    .label
      opacity: 0.3
      color black
</style>