export default {

//Header_and_login,
login: "login",
logout: "logout",
explore_by: "explore by",
explore_by_generic: "select one",
explore_by_name_long: "brand or name",
explore_by_name: "brand or name",
explore_by_ingredients: "raw materials",
explore_by_concepts: "ideas",
explore_by_families: "scent families",
create_profile: "create profile",
view_profile: "profile",
about: "about us",
logging_in: "logging in",
create_your_profile: "Create your olfactive profile",
oops_nothing_yet: "OOPS! THERE IS NOTHING HERE YET…",
creating_account: "creating account",
cookies_and_policies: "Terms and conditions",
how_it_works_header: "How does it work?",
contact: "Contact us",
notification_profile_update: "Your favorites have been updated. Check for new recomendations on your ",
profile: "profile",
user: "user",
language: "language",
legal: "legal",
accept_terms:"By creating the account, I agree to the $terms_and_conds of use.",
accept_policy:"I want to receive communications from WikiParfum and I accept the $privacy_policy",

//Home,
find_any_fragrance: "find any fragrance in the world.",
title_by_name: "explore by brands or name",
desc_by_name: "find your fragrance searching by similar perfumes",
title_by_ings: "explore by raw materials",
desc_by_ings: "Cinnamon or Musk? find the closest fragrance to these ingredients",
title_by_concepts: "explore by ideas",
desc_by_concepts: "find your fragrance by thinking on your favorite color or place",
title_by_families: "explore by families",
desc_by_families: "find your fragrance by thinking on ...",

//Explore_by_name,
search_brand_or_name: "Search brand or name",

//PerfumeFullView,
expert_reviewed: "expert reviewed",
ingredients: "ingredients",
description: "description",
perfumist: "perfumer",
concepts: "concepts",
olfactive_family: "olfactive family",
main_family: "family",
sub_family: "subfamily",
add_to_favs: "add to profile",
remove_from_favs: "remove from profile",
me_family: "Fragrances of the World",

//PerfumeAndSimilars,
similars: "Perfumes with similar ingredients",
filter_by_family: "families",
filter_by_brand: "brands",
filter_by_gender: "gender",
for_him: "For him",
for_her: "For her",
unisex: "Unisex",
all: "All",
all_female: "All",
similars_explanation: "Similarity determined by the important ingredients and their olfactive family",

//by_ingredient,
search_ingredients: "Search ingredients",
show_similar: "find perfumes with similar ingredients",
selected_ingredients: "selected ingredients",
clear_all: "clear all",
show_me_results: "show me results",
toggle_popular_ing: "Most popular",
popular_ingredients: "Popular Ingredients",
best_ing_matches: "Best matches for selection",

//by_concepts,
toggle_popular_ideas: "Most popular",
related_concepts: "related concepts",
trash: "trash?",
view_concepts: "view concepts",
close_concepts: "close concepts",
you_are_looking_for: "your are looking for...",
concepts_list: "Concepts list",
no_results_for_this_combination: "No perfumes for this combination.",
select_at_least_one_concept: "Select at least one concept",
view_perfumes: "View perfumes",
back:"Go Back",
close:"Close",
filters:"Filters",
selected_concepts: "Selected concepts:",
selected_families: "Selected families:",
find_concept: "Find concepts...",
smell_of: "Smell of",
touch_of: "With a touch of",


//PREPROFILE,
add_or_wizard: "you can add directly or take the test if you want",
add_to_profile: "add to profile",
take_the_wizard: "take the test",
back_or_to_profile: "continue exploring or go to your profile",
go_back: "continue exploring",
enter_profile: "view my profile",
login_title: "log in to access your profile",
missing_fields: "required fields are missing!",
wrong_password_check: "Passwords do not match!",
create_account: "create account",
user_already_exists: "There's already an account with this email",
email_address: "Email address",
password: "Password",
name: "Name",
create_password: "Create password",
confirm_password: "Confirm password",
sign_up: "Sign up",
dont_have_account: "Don't have an account yet?",
signup_title: "create an account to save your profile",
already_have_account: "Already have an account?",
bad_login: "Wrong email or password",
remember_me: "Remember me",

//PROFILE,
wizard_myself: "something for myself",
wizard_other: "a gift for someone else",
wizard_what_are_you_looking: "what are you looking for?",
wizard_something_gender: "are you looking for something...",
wizard_masculine: "masculine",
wizard_femenine: "femenine",
wizard_unisex: "both",
your_profile: "your olfactive profile",
you_love_perfumes_of_the: "you love perfumes of the ",
you_love_perfumes_of_the_plural: "you love perfumes of the",
and: "and",
desc_family: "family",
desc_families: "families",
you_also_love_plural: "you also love nuances of the",
you_also_love: "you also love nuances of the",
desc_sub_family: "subfamily",
desc_sub_families: "subfamilies",

//remove_confirmation,
are_you_sure_to_remove: "are you sure that you want to remove from profile?",
this_will_remove: "if you remove from profile you will need to add it again",
save: "save",

//actions,
select: "select",
remove: "remove",
cancel: "cancel",
get_recomendations: "get recommendations",
your_favorite_ings: "your favorite ingredients",
click_to_view: "click on the wheel to view your favorites in each family",

//SHARE,
share_to: "share...",
facebook: "Facebook",
twitter: "Twitter",
whatsapp: "Whatsapp",
email: "eMail",
share_title: "Look what I've found on WikiParfum!",
share_desc: "Look what I've found on WikiParfum!",
share_quote: "Look what I've found on WikiParfum!",

//LOADER,
loading_related: "loading ingredients that combine",
loading_perfumes: "loading perfumes",
loading_concepts: "loading concepts",

//////////////HOW_IT_WORKS/////////////,
what_is_our_purpose: "What is<br>our purpose?",
make_the_choosing: "MAKE THE CHOOSING OF A FRAGRANCE A FUN AND PERSONAL EXPERIENCE",
bring_the_craft: "BRING THE CRAFTMANSHIP OF PERFUMERY CLOSER TO PEOPLE",
the_starting_point_was: "The starting point was the thirst for knowledge and expert advice in the world of perfumery that many people expressed. \"I love fragrances but it is complicated to choose and know what I like\".<br>We felt we could bring perfumers know how alive in an educational and simple manner.",
how_does_it_work: "How does it work",
the_quadrification_shows: "The “QUADRIFICATION” shows the dominant ingredients in a perfume. The larger the image is, the more dominant it is in the perfume. In this example, “Tonka Bean” and “Cocoa” are the dominant notes, followed by “Jasmine”, “Tuberose” and “Almond”.",
q_and_a: "Q&A",
are_there_more_ingredients: "Are there more ingredients in a perfume? Yes, many more! However we focus on those that give the main character to a scent and those that we hope you can learn to detect if you train your sense of smell.",
are_all_ingredients_the_same: "Are all ingredients the same? Not at all. There is a big difference in qualities, especially in natural ingredients. Like a grey coat, it can be made with basic wool or the ﬁnest cashmere. We do not capture the difference in quality and concentration of a fragrance.",
expret_reviewed_means: "“Expert Reviewed” means one of our perfumers evaluators has reviewed the scent proﬁle and olfactive family classiﬁcation. We review the perfumes systematically in order to ensure the classiﬁcation and description is accurate, based on objective and perfumery criteria.",
this_field_shows_the_scent: "This ﬁeld shows the scent classiﬁcation by Fragrances of the World by Michael Edwards. There are several ways to classify perfumes and there is not a universal agreement on it. However, we ﬁnd it important to credit Fragrances of the World, because it is a major source of data for WikiParfum and because we want to credit his pioneering work on developing a common language for perfumes.",
an_independent_perfumistic: "A <br>perfumistic point<br>of view",
the_recomendations_are_100: "The recommendations are based on an accurate analysis of each scent olfactory profile, established by a powerful algorithm.",
scents_are_recomended: "Scents are recommended to you according to how perfumes match to your preferences, olfactory proﬁle or ingredients you select.",
scents_are_recomended_second_part: "The recommendations are not equivalent to your submissions and preferences.",
hundredpercent_perfumistic_point_of_view: "100% Perfumistic point of view",
perfumes_are_the_heart: "Perfumers are the heart of both the logic of recommendation and the visualization of each individual perfume. How do we do it? Each perfume is first calibrated by a perfumery expert. Then, we rigorously review the most popular scents to check accuracy of recommendations. In many cases we use technology (like a chromatograph) to contrast the experts opinion.",
partnerships: "Partnerships",
we_have_collaborated: "We have collaborated with the team from Fragrances of the World, the largest independent guide to fragrance classiﬁcation. First published in 1984 by Michael Edwards, it is the most complete and qualitative perfume encyclopedia that exists today. Michael and his team evaluate every single entry and classify it. This is why you see the Fragrances of the World's classiﬁcation in all products that he provides information.",
is_the_visualization: "Is the visualization<br>really objective to the way it<br>smells?",
as_much_as_it_can_be: "As much as it can be because it is objective, based on the scent olfactory profile, ingredients and olfactory families. We depict the most dominant ingredients in a scent. Those a perfumer can identify “easily” when smelling a perfume (even if the product contains many more of course). However, perfumery is not an exact science. Ingredients vary in quality and concentration and therefore in the way they impact a perfume and its perception, although the visualization might be similar. The quality and concentration aspect is not visualized in WikiParfum.",

//POLICIES,
what_are_cookies: "What are cookies",
types_of_cookies: "Types of cookies",
privacy_reference_center: "PRIVACY REFERENCE CENTER",
accept_cookies: "Allow Cookies",
cookies_policy: "Cookies Policy",
cookies_desc: "We use cookies to make your experience on this website better. By browsing this website, it is understood that you agree to the use of these cookies.",
learn_more: "Learn more",
cookies_accept: "Accept",
privacy_policy: "Privacy policy",
terms_and_conds: "Terms and Conditions",

//OTHERS,
apply: "apply",
search: "search",
contact_us_message_title: "message",
contact_us: "contact us",
contact_error: "There was a connection error. Please try again later.",
contact_success: "Message sent! Thanks for contacting us.",
contact_send: "send",
how_it_works: "How it works",
no_results: "We could not find any perfumes with the selected parameters.",
no_results_search: "Sorry, we could not find what you are looking for.",

//FORGOT PASSWORD,
forgot_password: "Forgot your password?",
reset_password: "Submit",
forgotten_password: "Forgotten password?",
forgotten_password_sub_title: "We'll send you a link to reset your password!",
check_your_email: "Check your email",
send_link: "We just sent a link to reset your password",
havent_receive: "You haven't received any mail?",
change_password: "Change your password",
show_password: "Show password",
confirm_new_password: "Confirm new password",
new_password: "New password",

//INGREDIENT CHAPTER,
ingredient_type: "Type",
ingredient_parts: "Part(s) used",
ingredient_extraction: "Extraction method",
ingredient_first: "Iconic perfume",
history: "History",
effect: "Production & Effect",
more_info: "More info",
same_ingredient: "Fragrances with this ingredient",
match_ingredient: "Best matches for this ingredient",
explore_by_ingredient: "Explore by this ingredient",
origin: "Origin",
iconic_fragrance:"Iconic fragrance",
production:"Production",

//FAMILY CHAPTER,
iconic_fragrances: "Iconic fragrances in this family",
Ingredient_family: "Ingredient in this family",
show_more: "Show more",

//SOCIAL LOGIN,
continue_with: "Continue with",

//ALERTS
turn_your_device:"Please turn your device",
discontinued:"Discontinued"
}