<template>
  <div class="home">
    <div class="full-screen-background"></div>
    <div class="centered-content">
      <h1 class="title">{{t('find_any_fragrance')}}</h1>
      <div class="buttons">
        <router-link to="explore/by-name" class="btn-container">
          <ButtonWithFeedback class="btn btn--secondary btn--ghost">
            <h2 class="button-title">{{t('title_by_name')}}</h2>
            <!-- #152 <div class="button-desc">{{t('desc_by_name')}}</div>-->
          </ButtonWithFeedback>
        </router-link>
        <router-link to="explore/by-ingredients" class="btn-container">
          <ButtonWithFeedback class="btn btn--secondary btn--ghost">
            <h2 class="button-title">{{t('title_by_ings')}}</h2>
            <!--<div class="button-desc">{{t('desc_by_ings')}}</div>-->
          </ButtonWithFeedback>
        </router-link>
        <router-link to="explore/by-concepts" class="btn-container">
          <ButtonWithFeedback class="btn btn--secondary btn--ghost">
            <div class="button-title">{{t('title_by_concepts')}}</div>
            <!-- <div class="button-desc">{{t('desc_by_concepts')}}</div> -->
          </ButtonWithFeedback>
        </router-link>
        <router-link to="explore/by-families" class="btn-container">
          <ButtonWithFeedback class="btn btn--secondary btn--ghost">
            <div class="button-title">{{t('title_by_families')}}</div>
            <!-- <div class="button-desc">{{t('desc_by_families')}}</div> -->
          </ButtonWithFeedback>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ButtonWithFeedback from "@/components/ButtonWithFeedback";
export default {
  name: "home",
  metaInfo: {
    title: "Find your perfect fragrance - Reinvented",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Wikiparfum is the encyclopedia for perfume lovers. A fragrance search engine based on olfactory preferences, that help users to visualize the perfume notes.",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Wikiparfum - Find your perfect fragrance - Reinvented",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content:
          "Wikiparfum is the encyclopedia for perfume lovers. A fragrance search engine based on olfactory preferences, that help users to visualize the perfume notes.",
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: "Wikiparfum - Find your perfect fragrance - Reinvented",
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content:
          "Wikiparfum is the encyclopedia for perfume lovers. A fragrance search engine based on olfactory preferences, that help users to visualize the perfume notes.",
      },
    ],
  },
  components: {
    ButtonWithFeedback,
  },
  mounted() {
    this.eventTracker.emitEvent("VIEW_OPENED", { type: "LANDING_PAGE" });
  },
};
</script>

<style scoped lang='stylus'>
.home
  .full-screen-background
    // background-color: #CABFB6
    // background-image: url("~@/assets/images/V2.jpg")
    background-color: #9A9A9D
    background-image: url("~@/assets/images/V3_desktop.jpg")
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    height: 100%
    left: 0
    position: fixed
    top: 0
    width: 100%

    .mobile &
      background-image: url("~@/assets/images/V3_mobile.jpg")

  .centered-content
    display: flex
    flex-direction: column
    left: 50%
    margin-top: vh(-60px)
    padding: 0 5vw
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)
    width: 100%

    .mobile &
      top: "calc(50% + %s)" % $header-height

    .title
      color: #fff
      m-first-letter-case(false)
      m-font-size(42, 50)
      font-weight: normal
      margin-bottom: vh(60px)
      text-align: center

      .mobile &
        m-font-size(36, 40)

    .buttons
      align-items: center
      display: flex
      height: vh(175px)
      justify-content: center

      .mobile &
        flex-direction: column
        height: auto

    .btn-container
      flex: 1 1 0%
      height: 100%
      max-width: 33vw // porque he quitado el by_concepts y quedaba mal...

      &:not(:last-child)
        margin-right: vw(20px)

      .mobile &
        max-width: 100%
        width: 100%

        &:not(:last-child)
          margin-bottom: vh(20px)
          margin-right: 0

    .btn
      background-color: rgba(#000, 0.75)
      border-color: rgba(#fff, 0.25)
      flex-direction: column
      height: 100%
      padding: 2.5em 1.5em

      .mobile &
        padding: 1.5em 0.5em

      &:hover
        background-color: rgba(#000, 0.45)
        color: #fff

      &:active
        background-color: rgba(#000, 0.45)

    .button-title
      m-font("Lelo", "light")
      m-font-size(18)
      m-letter-spacing(50)
      // margin-bottom: vh(20px) //desde que quitaron la description sobraba esto...
      text-transform: uppercase

      .mobile &
        margin-bottom: 0
        margin-top: 0

    .button-desc
      m-first-letter-case()
      m-font("Lelo", "light")
      m-font-size(18)
      m-letter-spacing(50)

      .mobile &
        display: none
</style>
