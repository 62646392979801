export default {
  //RECOS:

it: 'il',
also: 'également',
$pronoun_self: 'vous',
$pronoun_other: 'il/elle',
$pronoun_other_F: 'elle',
$pronoun_other_M: 'il',
$possessive_singular_self: 'votre',
$possessive_refl_singular_self: 'mon',
$possessive_plural_self: 'vos',
$possessive_refl_plural_self: 'mes',
$possessive_singular_other: 'son',
$possessive_singular_prep_self: 'vôtre',
$possessive_singular_prep_other: 'sien',
$possessive_singular_liste_self: 'votre',
$possessive_singular_liste_other: 'sa',
$possessive_plural_other: 'ses',
$possessive_refl_singular_other: 'son',
$possessive_refl_plural_other: 'ses',

$you_self: 'vous',
$you_other_F: 'elle',
$you_other_M: 'il', 
$you_prep_self: 'vous',
$you_prep_other_F: 'elle',
$you_prep_other_M: 'lui',
$do_you_have_self: 'avez vous',
$do_you_have_other: 'a-t-il/elle',
$do_you_have_other_F: 'a-t-il/elle',
$do_you_have_other_M: 'a-t-il/elle',
$that_you_love_self: "que vous aimez",
$that_you_love_other: "qu'ill/elle aime",
$that_you_love_other_F: "qu'elle aime",
$that_you_love_other_M: "qu'ill aime",

$you_love_self: 'vous aimez',
$you_love_other_F: 'elle aime',
$you_love_other_M: 'il aime',
$you_adore_self: 'vous adorez',
$you_adore_other_F: 'elle adore',
$you_adore_other_M: 'il adore',
  
$apreciate_self: 'appréciez',
$apreciate_other_F: 'apprécie',
$apreciate_other_M: 'apprécie',
$you_have_self: 'vous avez',
$you_have_other_F: 'elle a',
$you_have_other_M: 'il a',

reco_desc_title: 'Pourquoi recommendons-nous ce parfum?',
possible_reco_title: 'Peut-être que $you_love ce parfum parce que',
  expert_reco: "Recommandation des experts",


recommended_by_hero: '$you_adore l\'ingrédient principal: <b>%HERO%</b>',
recommended_by_fam: "il appartient à la famille <b>%FAMILY%</b>, l'une de $possessive_plural préférées",
recommended_by_other: "il est proche de $possessive_singular profil olfactif",
recommended_by_ings: 'il contient certains de $possessive_plural ingrédients favoris: <b>%INGS%</b>',
}