
var timeout = null

export default {
  state: {
    pendingView: null
  },
  mutations: {
    setPending (state, value){
      if(value===true){
        if(timeout) clearTimeout(timeout)
        timeout = setTimeout(()=>{
          state.pendingView = false
        },6000)
      }
      state.pendingView = value
    },
  },
  actions: {
    setViewed(context){
      context.commit('setPending', false)
    },
  }
}