<template>
  <div
    itemscope
    itemtype="http://schema.org/Product"
    class="perfume-full-view"
    :class="{minified, onlyquad}"
  >
    <div class="floating-buttons">
      <!-- <div v-if="!isMobile" class="share-button" @click="showShare=data"></div> -->
      <ShareComponent v-if="!isMobile" :data="data"></ShareComponent>
      <div v-if="!isMobile && showClose" class="separator"></div>
      <div v-if="showClose" class="close-button" @click="$emit('close')"></div>
    </div>
    <div v-if="isMobile && minified || tabletFormat" class="header is-mobile-and-minified">
      <div class="image" :style="{backgroundImage: `url(${data.imageUrl})`}"></div>
      <div class="name-and-brand" :class="{showClose}">
        <div
          itemprop="name"
          class="name"
          :class="nameSizeClass(data.name)"
        >{{perfumeCapitalize(data.name)}}</div>
        <div class="brand-and-tag">
          <div itemprop="brand" class="brand" :class="{separator:data.subBrand}">{{data.brand.name}}</div>
          <div v-if="data.subBrand" class="sub-brand">{{data.subBrand.name}}</div>
        </div>

        <FamiliesWithIcon
          itemprop="additionalProperty"
          v-if="minified || tabletFormat"
          :family="data.family"
          :subfamily="data.secondaryFamily"
        ></FamiliesWithIcon>
      </div>
    </div>
    <div v-else-if="isMobile && onlyquad" class="header is-mobile-and-onlyquad">
      <div class="quad-header">
        <div class="name-and-brand">
          <div
            itemprop="name"
            class="name"
            :class="nameSizeClass(data.name)"
          >{{perfumeCapitalize(data.name)}}</div>
          <div class="brand-and-fam">
            <div itemprop="brand" class="brand" :class="{separator:data.subBrand}">{{data.brand.name}}</div>
            <div v-if="data.subBrand" class="sub-brand separator">{{data.subBrand.name}}</div>
            <FamiliesWithIcon
              itemprop="additionalProperty"
              :family="data.family"
              :subfamily="data.secondaryFamily"
            ></FamiliesWithIcon>
          </div>
        </div>
      </div>
      <div class="quad-content">
        <div class="left">
          <div class="image" :style="{backgroundImage: `url(${data.imageUrl})`}"></div>
          <AddToFavsButton :data="data"></AddToFavsButton>
          <!-- <div class="share-button" @click="showShare=data"></div> -->
          <ShareComponent :data="data"></ShareComponent>
        </div>
        <QuadPack :proportions="adjustedProportions" @select="ingSelected" :debug-lines="true" :perfume-families="perfumeFamilies"></QuadPack>
      </div>
    </div>
    <div v-else-if="isMobile " class="header is-mobile">
      <div class="name-and-brand">
        <div
          itemprop="name"
          class="name"
          :class="nameSizeClass(data.name)"
        >{{perfumeCapitalize(data.name)}}</div>
        <div class="brand-and-fam">
          <div itemprop="brand" class="brand">{{data.brand.name}}</div>
          <div v-if="data.subBrand" class="sub-brand separator">{{data.subBrand.name}}</div>
          <FamiliesWithIcon
            itemprop="additionalProperty"
            :family="data.family"
            :subfamily="data.secondaryFamily"
          ></FamiliesWithIcon>
        </div>
      </div>
    </div>

    <div v-else class="header" ref="subsection0" :style="forcedHeigh(0)">
      <div class="name-and-brand">
        <div
          itemprop="name"
          class="name"
          :class="nameSizeClass(data.name)"
        >{{perfumeCapitalize(data.name)}}</div>
        <div class="brand-and-tag">
          <div itemprop="brand" class="brand" :class="{separator:data.subBrand}">{{data.brand.name}}</div>
          <div v-if="data.subBrand" class="sub-brand">{{data.subBrand.name}}</div>
        </div>
      </div>
    </div>
    <div
      v-if="!minified && !onlyquad"
      class="subsection main"
      ref="subsection1"
      :style="forcedHeigh(1)"
    >
      <div class="left" v-if="!tabletFormat">
        <!-- <div class="family-with-icon">{{data.family.name}}</div> -->
        <FamiliesWithIcon
          itemprop="additionalProperty"
          v-if="!isMobile"
          :family="data.family"
          :subfamily="data.secondaryFamily"
        ></FamiliesWithIcon>
        <div class="image-wrapper">
          <div class="image" :style="{backgroundImage: `url(${data.imageUrl})`}"></div>
        </div>
        <AddToFavsButton :data="data"></AddToFavsButton>
        <!-- <div v-if="isMobile" class="share-button" @click="showShare=data"></div> -->
        <ShareComponent v-if="isMobile" :data="data"></ShareComponent>
      </div>
      <div class="right">
        <div v-if="data.expertReviewed" class="expert-reviewed">
          <div class="icon"></div>
          <div class="text">{{t("expert_reviewed")}}</div>
        </div>
        <QuadPack :proportions="adjustedProportions" :debug-lines="true" @select="ingSelected" :perfume-families="perfumeFamilies"></QuadPack>
      </div>
    </div>

    <div
      v-if="!minified && !onlyquad"
      class="subsection ingredients"
      ref="subsection2"
      :style="forcedHeigh(2)"
      itemprop="additionalProperty"
      itemscope
      itemtype="http://schema.org/PropertyValue"
    >
      <div itemprop="name" class="subsection-title">{{t('ingredients')}}</div>
      <div class="subsection-content ingredients-list">
        <template v-for="ingredient in data.ingredientProportions">
          <router-link
            v-if="ingredient.ingredient.isIngredientChapter"
            itemprop="value"
            :key="ingredient.id"
            class="ingredient-item"
            :to="`/chapter/ingredient?data=${ingredient.ingredient.id}`"
          >{{ingredient.ingredient.name}}</router-link>
            <div
            v-else
            itemprop="value"
            :key="ingredient.id"
            class="ingredient-item"
          >{{ingredient.ingredient.name}}</div>
        </template>
      </div>
    </div>

    <!-- De momento nada... -->
    <div v-if="showRecoReason && !minified && !onlyquad" class="subsection reco-reason">
      <div class="subsection-title">{{t('reco_desc_title')}}</div>
      <div class="subsection-content">
        <RecoDesc :data="data"></RecoDesc>
      </div>
    </div>

    <div
      v-if="!minified && !onlyquad"
      class="subsection description"
      ref="subsection3"
      :style="forcedHeigh(3)"
    >
      <div class="subsection-title">{{t('description')}}</div>
      <div itemprop="description" class="subsection-content">{{data.description}}</div>
    </div>
    <div
      v-if="!minified && !onlyquad"
      class="subsection family"
      ref="subsection4"
      :style="forcedHeigh(4)"
    >
      <FamilyDonut :family="data.family" :subfamily="data.secondaryFamily"></FamilyDonut>
      <div class="family-desc">
        <div class="subsection-title">{{t('olfactive_family')}}</div>
        <div class="subsection-content">
          <div
            itemprop="additionalProperty"
            itemscope
            itemtype="http://schema.org/PropertyValue"
            class="fam-group"
          >
            <div itemprop="name" class="title uppercase">{{t('main_family')}}</div>
            <div itemprop="value" class="value">{{data.family.name}}</div>
          </div>
          <div
            itemprop="additionalProperty"
            itemscope
            itemtype="http://schema.org/PropertyValue"
            class="fam-group"
            v-if="data.secondaryFamily"
          >
            <div itemprop="name" class="title uppercase">{{t('sub_family')}}</div>
            <div itemprop="value" class="value">{{data.secondaryFamily.name}}</div>
          </div>
          <div
            itemprop="additionalProperty"
            itemscope
            itemtype="http://schema.org/PropertyValue"
            class="fam-group"
            v-if="data.meFamilyName"
          >
            <div itemprop="name" class="title">{{t('me_family')}}</div>
            <div itemprop="value" class="value">{{data.meFamilyName}}</div>
          </div>
        </div>
      </div>
    </div>

    <div
      itemprop="additionalProperty"
      itemscope
      itemtype="http://schema.org/PropertyValue"
      v-if="!minified && !onlyquad"
      class="subsection perfumist"
      :class="(canHide(5) && !data.perfumer)?'hidden':''"
      ref="subsection5"
      :style="forcedHeigh(5)"
    >
      <template v-if="data.perfumer">
        <div itemprop="name" class="subsection-title">{{t('perfumist')}}</div>
        <div itemprop="value" class="subsection-content">{{data.perfumer}}</div>
      </template>
    </div>
    <!-- REMOVED CONCEPTS UNTIL WE GET THE DATA...  -->
    <!-- <div
      v-if="!minified && !onlyquad"
      class="subsection concepts"
      ref="subsection6"
      :style="forcedHeigh(6)"
    >
      <div class="subsection-title">{{t('concepts')}}</div>
      <div class="subsection-content">
        <div class="concepts-list">
          <ConceptTag color="white" :data="{label: 'fake-concept'}" :showRemove="false"></ConceptTag>
          <ConceptTag color="white" :data="{label: 'fake-concept-2'}" :showRemove="false"></ConceptTag>
          <ConceptTag color="white" :data="{label: 'other-fake'}" :showRemove="false"></ConceptTag>
        </div>
      </div>
    </div>-->

    <div class="similar-button-wrapper" v-if="canShowSimilar">
      <SquareButton @click="$emit('show-similar')">{{t('show_similar')}}</SquareButton>
    </div>
  </div>
</template>

<script>
// import QuadPack from '@/components/QuadPack/QuadPack'
import { QuadPack as QuadPack } from "@bestiario/puig-quad-pack";
// import {SillageQuadPack as QuadPack} from '@bestiario/puig-quad-pack'
// import { AudioQuadPack as QuadPack } from "@bestiario/puig-quad-pack";
import "@bestiario/puig-quad-pack/dist/puig-quad-pack.css";
import FamiliesWithIcon from "@/components/FamiliesWithIcon";
import AddToFavsButton from "@/components/AddToFavsButton";
import SquareButton from "@/components/SquareButton";
import FamilyDonut from "@/components/FamilyDonut";
import NameSizeClasses from "@/vue-plugins/NameSizeClasses";
import ShareComponent from "@/components/ShareComponent";
import RecoDesc from "@/components/Profile/RecoDesc";
// import ConceptTag from '@/components/ConceptTag'
export default {
  name: "PerfumeFullView",
  components: {
    QuadPack,
    FamiliesWithIcon,
    AddToFavsButton,
    SquareButton,
    FamilyDonut,
    RecoDesc,
    ShareComponent,

    // ConceptTag
  },
  mixins: [NameSizeClasses],
  props: {
    data: Object,
    canShowSimilar: {
      type: Boolean,
      default: false,
    },
    forcedHeights: Array,
    minified: {
      type: Boolean,
      default: false,
    },
    onlyquad: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    tabletFormat: {
      type: Boolean,
      default: false,
    },
    showRecoReason: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ownHeights: null,
      // showShare: null
    };
  },
  computed: {
    adjustedProportions() {
      let ingProps = JSON.parse(
        JSON.stringify(this.data.ingredientProportions)
      );
      if (this.heroIngredient) {
        let candidate = ingProps.find(
          (p) => p.ingredient.id === this.heroIngredient.id
        );
        if (candidate) candidate.proportion *= 2;
      }
      return ingProps;
    },
    perfumeFamilies() {
      return this.data.family
        ? [this.data.family, this.data.secondaryFamily]
        : [];
    },
  },
  // routerLinkedData:['showShare'],
  methods: {
    ingSelected(ing) {
      if (ing.isIngredientChapter)
        this.$router.push(`/chapter/ingredient?data=${ing.id}`);
    },
    forcedHeigh(index) {
      // if(this.forcedHeights) return {height: this.forcedHeights[index]+'px'}
      // // else if(this.ownHeights) return {height: this.ownHeights[index]+'px'}
      // else return {height: 'auto'}
      let forcedHeight = this.forcedHeights && this.forcedHeights[index];
      return { height: forcedHeight ? forcedHeight + "px" : "auto" };
    },
    canHide(index) {
      if (this.forcedHeights && this.forcedHeights[index]) return false;
      else return true;
    },
    getHeights() {
      // LIMPIARLO PERO PARECE QEU "IS THE WAY", quitar->medir->reponer
      let idxs = [0, 1, 2, 3, 4, 5, 6];
      let currentsStyles = [];
      idxs.forEach((i) => {
        if (this.$refs["subsection" + i]) {
          currentsStyles[i] = this.$refs["subsection" + i].style.height;
          this.$refs["subsection" + i].style.height = "auto";
        }
      });

      this.ownHeights = idxs.map((i) => {
        return (
          this.$refs["subsection" + i] &&
          this.$refs["subsection" + i].offsetHeight
        );
      });

      idxs.forEach((i) => {
        if (currentsStyles[i])
          this.$refs["subsection" + i].style.height = currentsStyles[i];
      });
      if (!this.data.perfumer) this.ownHeights[5] = null;
      return this.ownHeights;
    },
  },
  mounted() {
    this.eventTracker.emitEvent("VIEW_OPENED", {
      type: "PERFUME_VIEWED",
      data: { id: this.data.id },
    });
    this.$nextTick(() => {
      this.$emit("update");
    });
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.$emit("update");
      });
    },
  },
};
</script>

<style scoped lang="stylus">
$similar-button-wrapper-height = vh(100px)

.perfume-full-view
  background-color: #fff
  margin: auto
  padding: vw(20px)
  padding-bottom: 10vh // por el boton flotante - no se si debe ir "siempre o no"
  pointer-events: all
  position: relative
  width: 100%
  m-max-width-container()

  .mobile &
    padding: vw(10px)
    padding-bottom: $similar-button-wrapper-height

  &.minified
    padding-bottom: 0

  &.onlyquad
    height: 100%
    padding-bottom: vh(10px)

  .floating-buttons
    align-items: center
    display: flex
    position: absolute
    right: vw(20px)
    top: vh(20px)

    .tablet &
      top: vh(10px)

    .mobile &
      right: vw(12px)

    .separator
      background-color: rgba(#000, 0.15)
      height: vh(25px)
      margin: 0 vw(15px)
      width: 1px

  // los saco de "floating-buttons" para cuando el share esta debajo del favs button
  .close-button
    cursor: pointer
    m-icon("close", 15)

    @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
      m-icon("close", 30)

    .mobile &
      m-icon("close", 20)
      margin-top: vh(20px)

  // .share-button
  // cursor: pointer
  // m-icon("share", 15)
  // // DESHABILITAMOS los share buttons hasta nueva orden!!!!
  // // visibility hidden

  // .mobile &
  // m-icon("share", 20)
  .header
    border-bottom: 1px solid rgba(#000, 0.15)
    padding: 0 vw(70px) vh(20px) // top right bottom
    text-align: center

    &.is-mobile
      border-bottom: none

    &.is-mobile,
    &.is-mobile-and-onlyquad
      display: flex
      margin: 0
      overflow: hidden
      padding: 0

      .name-and-brand
        align-items: flex-start
        display: flex
        flex-direction: column
        margin-bottom: vh(10px)
        overflow: hidden
        width: 100%

        // .name
        // margin-bottom: 0
        .brand-and-fam
          align-items: center
          display: flex
          overflow: hidden
          width: 100%

          .name
            margin-right: vw(80px)
            text-align: left

          .brand
            margin-right: vw(10px)
            max-width: 50%
            padding-right: vw(10px)
            position: relative

            &:after
              background-color: rgba(#000, 0.15)
              content: ""
              height: 100%
              position: absolute
              right: 0
              top: 0
              width: 1px

          >>> .family-and-icon
            max-width: 50%
            overflow: hidden

            // margin-top: vw(5px)
            .icon
              margin-right: vw(4px)

            .label
              display: block

    &.is-mobile-and-minified
      display: flex
      margin: 0
      padding: 0
      padding-bottom: vh(20px)

      .image
        flex: 0 0 vw(70px) // ALGO ASI...
        margin: 0

      .name-and-brand
        align-items: flex-start
        overflow: hidden

        &.showClose
          padding-right: vw(90px)

        .name
          m-ellipsis(100%)

        .brand
          margin-bottom: vh(5px)

        >>> .family-and-icon
          margin-top: vw(5px)

          .icon
            margin-right: vh(4px)

    &.is-mobile-and-onlyquad
      display: flex
      flex-direction: column
      height: 100%
      padding-bottom: vh(20px)
      width: 100%

      .similar-perfumes-wrapper &
        border-bottom: none
        padding-bottom: 0

        .name
          margin-right: vw(40px)

      .quad-header
        display: flex
        margin-bottom: vh(10px)

        .name-and-brand
          align-items: flex-start
          display: flex
          flex-direction: column

          .name
            text-align: left
            m-ellipsis(100%)

          .brand-and-fam
            display: flex

      .quad-content
        display: flex
        flex: 1 1 0%

        .mobile &
          flex-direction: column

        .left
          align-items: center
          display: flex
          flex: 0 0 25%
          flex-direction: column
          justify-content: space-evenly

          .mobile &
            flex: 0 0 35%
            flex-direction: row

          .image-wrapper
            height: 100%
            padding: vh(40px) vw(40px)
            width: 100%

            .image
              height: 36vh
              margin: vw(20px)
              width: 100%

          >>> .add-to-favs-button
            margin: vh(10px) auto

            .mobile &
              margin: vw(10px)

            .icon
              m-icon("add", 20)
              margin-bottom: 0

              &.faved
                m-icon("remove", 20)

            .label
              display: none

          >>> .share-component
            .mobile &
              margin: vw(10px)

            .share-panel
              bottom: "calc(100% + %s)" % vh(12px)
              left: vh(-6px)
              right: inherit
              top: inherit

              .mobile &
                left: auto
                right: "calc(-100% + %s)" % vw(12px)

        .quad-pack
          flex: 1 1 0%

    .image
      background-position: center
      background-repeat: no-repeat
      background-size: contain
      flex: 1 1 0%
      margin: vh(20px) 0
      width: 90%

      .mobile &
        height: 90%
        margin: 0

    .name-and-brand
      align-items: center
      display: flex
      flex-direction: column
      height: 100%
      justify-content: center

      .mobile &
        overflow: hidden
        padding: vw(10px)

      .name
        m-font-size(24, 32)
        margin-bottom: vh(5px)

        .mobile &
          m-font-size(24, 32) // no esta bien, porque si el nombre es largo "se sale..."
          padding-right: vw(40px)
          text-align: left

      .brand-and-tag
        align-items: center
        display: flex

      .brand
        m-ellipsis(100%)
        m-font("Lelo", "light")
        m-font-size(14, 17)
        m-letter-spacing(16)
        text-transform: uppercase

        .mobile &
          m-font-size(12, 15)

      .separator
        margin-right: vw(10px)
        padding-right: vw(10px)
        position: relative

        &:after
          background-color: rgba(0, 0, 0, 0.15)
          content: ""
          height: 100%
          position: absolute
          right: 0
          top: 0
          width: 1px

      .sub-brand
        m-ellipsis(100%)
        m-font("Lelo", "light")
        m-font-size(11, 16)
        margin-top: 0.1em
        text-transform: uppercase

        .mobile &
          m-font-size(11, 15)

  .similar-button-wrapper
    bottom: vw(10px)
    display: flex
    justify-content: center
    left: 10%
    position: fixed
    width: 80%
    z-index: 1

    .square-button
      margin-bottom: vh(10px)
      z-index: 1

      .mobile &
        height: $similar-button-wrapper-height
        margin: 0
        // chapuzilla para qeu "FINd PERFUMES WITH SIMILAR INGREDIENTS" salga 1-liner
        padding: 0
        width: 100%

  .subsection
    border-bottom: 1px solid rgba(#000, 0.15)
    margin: vh(20px) 0
    padding-bottom: vh(20px)
    padding-left: vw(15px)
    padding-right: vw(15px)
    transition: height 0.4s

    .mobile &
      margin: vh(50px) 0
      padding-bottom: vh(50px)

    &.hidden
      border: none
      height: auto
      margin: 0
      padding: 0

    &:not(.main)
      m-font("Lelo", "light")

    &.main
      display: flex
      margin-bottom: vh(20px)
      margin-top: 0
      overflow: hidden
      padding: 0

      .mobile &
        display: block

        .left,
        .right
          border: none
          max-width: none

        .left
          position: relative

          .image
            flex: 0 0 36vh !important
            height: 36vh !important

          .share-component
            bottom: 0
            bottom: vh(10px)
            margin: vh(20px)
            margin: 0
            position: absolute
            right: 0

            >>> .share-panel
              bottom: "calc(100% + %s)" % vh(18px)
              left: inherit
              right: vh(4px)
              top: inherit

        .right
          .expert-reviewed
            border-top: 1px solid rgba(#000, 0.15)
            justify-content: flex-start
            margin-bottom: vh(20px)
            margin-top: vh(20px)
            padding-top: vh(20px)

      .left,
      .right
        padding: vh(10px) vw(20px)

        .mobile &
          padding: vh(10px) 0

      // NO ME GUSTA NADA AQUI,
      // .half-size &
      // .left
      // transition flex 1.4s
      // flex: 0 0 auto

      // .right
      // transition flex 1.4s
      // flex: 1 1 0
      .left
        border-right: 1px solid rgba(#000, 0.15)
        display: flex
        flex: 1 1 0%
        flex-direction: column

        .family-and-icon
          >>>.label
            white-space: normal

        .image-wrapper
          height: 100%
          padding: 2vw
          width: 100%

          .image
            background-position: center
            background-repeat: no-repeat
            background-size: contain
            flex: 1 1 vh(100px)
            height: 100%
            margin: vh(10px) 0
            width: 100%

      .right
        display: flex
        flex: 1 1 20% // auto
        flex-direction: column
        justify-content: center // flex-end // #224
        max-width: 50vh

        .expert-reviewed
          align-items: center
          display: flex
          justify-content: flex-end
          margin-bottom: vh(20px)

          .icon
            m-icon("expert-reviewed", 18)
            margin-right: vw(5px)

          .text
            m-first-letter-case()
            m-font("Lelo", "medium")
            m-font-size(11, 13)

    .subsection-title
      font-weight: $fw-medium
      m-first-letter-case()
      m-font-size(11, 13)
      margin-bottom: vh(20px)

      .mobile &
        m-font-size(15, 17)

    .subsection-content
      m-font-size(14, 24)
      m-letter-spacing(25)

  .subsection
    &.main
      .mobile &
        border: none

    &.ingredients
      .ingredients-list
        display: flex
        flex-wrap: wrap
        margin-left: vw(-10px)
        margin-right: vw(-10px)

        .ingredient-item
          margin: vh(5px) vw(10px)

          &:not(:last-child)
            position: relative

            &:after
              background: rgba(#000, 0.15)
              content: ""
              height: 100%
              position: absolute
              right: vw(-10px)
              top: 0
              width: 1px

    &.family
      align-items: center
      display: flex
      flex-direction: row

      .family-donut
        flex: 0 0 vw(100px)
        height: vw(100px)
        margin-right: vw(20px)
        width: vw(100px)

      .family-desc
        .subsection-content
          display: flex
          flex-wrap: wrap

          .fam-group
            margin: vh(5px) 0

            &:not(:last-child)
              margin-right: vw(40px)

            .title
              m-font-size(11, 13)
              margin-bottom: vh(5px)
              opacity: 0.5

            .uppercase
              m-first-letter-case()

    &.concepts
      .concepts-list
        display: flex
        flex-wrap: wrap
        margin: vh(-5px) vw(-5px)

        .concept-tag
          margin: vh(5px) vw(5px)
</style>