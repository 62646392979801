<template>
  <div class="share-component" v-click-outside="()=> open=false">
    <div class="share-button" @click="shareButtonClickHandler()"></div>
    <div v-if="open" class="share-panel">
      <!-- <div class="title">{{t('share_to')}}</div> -->
      <div class="close-button" @click="open = false"></div>
        <social-sharing :url="`https://www.wikiparfum.fr/explore/by-name?selection=${data.id}`"
                      :title="t('share_title')"
                      :description="t('share_desc')"
                      :quote="t('share_quote')"
                      hashtags="wikiparfum"
                      :twitter-user="null"
                      inline-template
                      @open="open=false">
          <div class="actions">
            <network network="facebook" class="action-button">
              <div class="icon facebook"></div>
              <div class="label">{{t('facebook')}}</div>
            </network>
            <network network="twitter" class="action-button">
              <div class="icon twitter"></div>
              <div class="label">{{t('twitter')}}</div>
            </network>
            <network network="whatsapp" class="action-button">
              <div class="icon whatsapp"></div>
              <div class="label">{{t('whatsapp')}}</div>
            </network>
            <network network="email" class="action-button">
              <div class="icon email"></div>
              <div class="label">{{t('email')}}</div>
            </network>
          </div>
        </social-sharing>
        
    </div>
  </div>
</template>

<script>
// import ButtonWithFeedback from '@/components/ButtonWithFeedback'
import ClickOutside from 'vue-click-outside'
export default {
  name: "SharePanel",
  props:['data'],
  directives: {
    ClickOutside
  },
  components:{
    // ButtonWithFeedback
  },
  data (){
    return {
      open: false
    }
  },
  methods: {
    shareButtonClickHandler() {
      this.open = !this.open
      if (this.open) {
        this.eventTracker.emitEvent('ACTION', { type: 'PERFUME_SHARED', data: { id: this.data.id } })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.share-component
  position: relative
  user-select: none

  .share-button
    cursor: pointer
    m-icon("share", 15)

    @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
      m-icon("share", 30)

    .mobile &
      m-icon("share", 20)

  .share-panel
    background-color: #fff
    box-shadow: 0 1px 5px 1px rgba(#000, 0.1)
    display: flex
    padding: vw(15px) vw(20px) vw(10px) vw(10px)
    position: absolute
    right: "calc(-100% + %s)" % vw(12px)
    top: "calc(100% + %s)" % vh(10px)
    width: auto
    z-index: 2

    >>> .actions
      display: flex
      width: 100%

      .mobile &
        margin: 0

      .action-button
        align-items: center
        display: flex
        flex: 1 1 0%
        flex-direction: column
        margin: 0 vh(10px)

        .icon
          background: #000
          border-radius: vw(30px)

          &.facebook
            m-icon("facebook", 30)

          &.email
            m-icon("email", 30)

          &.whatsapp
            m-icon("whatsapp", 30)

          &.twitter
            m-icon("twitter", 30)

        .label
          m-font("Lelo", "medium")
          m-font-size(10, 12)
          margin: vw(4px)

    .close-button
      m-icon("close", 12)
      cursor: pointer
      position: absolute
      right: vw(6px)
      top: vw(6px)
</style>