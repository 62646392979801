
import { addToProfile, removeFromProfile, logout, getProfile } from '@/services/Api'
import LocalStorageContainer from '../utils/LocalStorageContainer'
const FAVORITES = 'favorites'
var favorites = null  
const favoritesString = localStorage.getItem(FAVORITES)
if(favoritesString){
  try {
    favorites = JSON.parse(favoritesString)
  }
  catch(e){
    console.error(e)
    console.log(localStorage)
    localStorage.removeItem(FAVORITES)
  }
}

export default {
  state: {
    favorites: favorites ? favorites :[],
    meta: null,
    // pendingView: false,
    gender: null,
    gift: null
  },
  mutations: {
    addFav(state, data) {
      state.favorites.push(data)
      LocalStorageContainer.favorites = state.favorites
    },
    removeFav(state, data) {
      state.favorites.splice(state.favorites.indexOf(data), 1);
      LocalStorageContainer.favorites = state.favorites
    },
    setMeta(state, data) {
      state.meta = data
    },
    // setPending (state, value){
    //   state.pendingView = value
    // },
    setGender(state, value) {
      state.gender = value
    },
    setGift(state, value) {
      state.gift = value
    },
    resetSession(state) {
      state.favorites = []
      state.meta = null
      state.gender = null
      state.gift = null
      localStorage.removeItem(FAVORITES)
    },
    setFav(state,favorites){
      state.favorites = favorites
    },
    setSession(state, newSessionData) {
      state.favorites = newSessionData.favorites
      state.meta = newSessionData.meta
      LocalStorageContainer.favorites = newSessionData.favorites
    }
  },
  actions: {
    toggleFav(context, data) {
      let candidate = context.state.favorites.find((item) => {
        return item === data.id
      })
      let ret
      if (!candidate) {
        ret = addToProfile(data.id)
        context.commit('addFav', data.id)
        data.eventTracker.emitEvent('ADD_TO_PROFILE', {
          id: data.id,
          type: 'PERFUME',
          resultingProfile: {
            perfumes: context.state.favorites
          }
        })
      } else {
        ret = removeFromProfile(data.id)
        context.commit('removeFav', data.id)
        data.eventTracker.emitEvent('REMOVE_FROM_PROFILE', {
          id: data.id,
          type: 'PERFUME',
          resultingProfile: {
            perfumes: context.state.favorites
          }
        })
      }
      context.commit('setPending', true)
      return ret
    },
    setMeta(context, data) {
      context.commit('setMeta', data)
    },
    // setViewed(context){
    //   context.commit('setPending', false)
    // },
    setGender(context, value) {
      context.commit('setGender', value)
    },
    setGift(context, value) {
      context.commit('setGift', value)
    },
    initSession(context, eventTracker) {
      return getProfile().then((response) => {
        const userId = response.meta.userId;
        console.log({userId})
        eventTracker.emitEvent('LOGIN', { user: userId })
        context.dispatch('setSession', response)
      }).catch((err) => {
        console.warn(err)
        // console.log("no SESSION_ID, or soemthing...<dzfazs<df")
      })
    },
    resetSession(context) {
      logout()
      context.commit('resetSession')
    },
    setSession_old(context, value) {
      context.commit('setSession', value)
    }
  }
}