import axios from 'axios'
import store from '../store'
import { getUserIdLocalStorage, filtersToString } from '../utils'
import localStorageContainer from '../utils/LocalStorageContainer'
const CancelToken = axios.CancelToken
let cancels = {}

function cancelRequest (id) {
  if (cancels[id]) cancels[id]()
  cancels[id] = null
}
let instance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
let instanceNoHostId = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

let cancelableInstance = axios.create({
  baseURL: '',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})
// AXIOS INSTANCE FOR ENPOINT WITHOUT HOST ID
instanceNoHostId.interceptors.request.use(config => {
  config.params = Object.assign(config.params || {}, {
    lang: store.state.i18n.lang
  })
  return config
})

instance.interceptors.request.use(config => {
  // config.params = Object.assign(axiosParms || {},{tempid: localStorage.getItem('nocibe-tempid')})
  config.params = Object.assign(config.params || {}, {
    // userId: store.getState().user.nocibeId,
    lang: store.state.i18n.lang
    // gift: store.getState().session['for-who']==='self'?0:1
  })
  // NO FUNCINABAN BIEN LOS EP, pero deberia ponerse el hostId que toca!!!!
  if (config.params.hostId === undefined)
    config.params = Object.assign(config.params || {}, {
      hostId: process.env.VUE_APP_HOST_ID
    })
  return config
})
cancelableInstance.interceptors.request.use(config => {
  /// CANCELABLE
  config.params = Object.assign(config.params || {}, {
    lang: store.state.i18n.lang
  })
  let reqId = config.url.split('?')[0]
  cancelRequest(reqId)
  if (config.params.hostId === undefined)
    config.params = Object.assign(config.params || {}, {
      hostId: process.env.VUE_APP_HOST_ID
    })

  config.cancelToken = new CancelToken(c => (cancels[reqId] = c))
  return config
})
cancelableInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (axios.isCancel(error)) console.log('Request canceled', error.message)
    else throw error
  }
)
export function getAllBrands () {
  let lang = 'en'
  let path = `/brands/`
  let cached = store.getters['brands/get'](lang, path)
  if (cached) return Promise.resolve(cached)
  return instance.get(process.env.VUE_APP_APIURL + path).then(response => {
    // store.dispatch('cacheIngredients',response.data)
    store.dispatch('brands/save', { lang, path, objects: response.data })
    return response.data
  })
}
export function getIngredient (id) {
  let lang = store.getters.lang
  let cached = store.getters['ingredients/getById'](lang, id)
  if (cached) return Promise.resolve(cached)
  return instance
    .get(process.env.VUE_APP_APIURL + `/ingredients/${id}`)
    .then(response => {
      store.dispatch('ingredients/saveById', { lang, object: response.data })
      return response.data
    })
}
/**
 *
 * @param {Number | String} id
 */
export function getMetaIngredientById (id) {
  return new Promise(resolve =>
    instance
      .get(process.env.VUE_APP_APIURL + `/ingredients/${id}/info/`)
      .then(response => {
        return resolve(response.data)
      })
  )
}
export function getAllIngredients () {
  let lang = store.getters.lang
  let cached = store.getters.getCachedIngredients()
  if (cached) return Promise.resolve(cached)
  return instance
    .get(process.env.VUE_APP_APIURL + `/ingredients/`)
    .then(response => {
      store.dispatch('ingredients/save', {
        lang,
        path: '/ingredients/',
        objects: response.data
      })
      return response.data
    })
}
/**
 *
 * @param {Array} array
 */
function fetchArrayPerfumes (array) {
  let lang = store.getters.lang
  let cached = array
    .map(id => store.getters['perfumes/getById'](lang, id))
    .filter(e => e !== null)
  if (cached.length === array.length) return Promise.resolve(cached)
  return Promise.all(
    array.map(id =>
      instance
        .get(process.env.VUE_APP_APIURL + `/perfumes/${id}`)
        .then(response => {
          return response.data
          // return new Promise(function(resolve) {
          //   setTimeout(()=>{
          //     resolve(response.data)
          //   },1000)
          // });
        })
    )
  ).then(responses => {
    store.dispatch('perfumes/save', {
      lang,
      path: array.join(','),
      objects: responses
    })
    return responses
  })
}

export function fetchFavorites () {
  return fetchArrayPerfumes(store.state.session.favorites)
}

export function getPerfume (id) {
  let lang = store.getters.lang
  let cached = store.getters['perfumes/getById'](lang, id)
  if (cached)
    return new Promise(function (resolve) {
      resolve(cached)
    })
  return instance
    .get(process.env.VUE_APP_APIURL + `/perfumes/${id}`)
    .then(response => {
      store.dispatch('perfumes/saveById', { lang, object: response.data })
      return response.data
      // return new Promise(function(resolve) {
      //   setTimeout(()=>{
      //     resolve(response.data)
      //   },1000)
      // });
    })
}
export function searchByName (query) {
  let lang = store.getters.lang
  let path = `/v7/search?q=${query}`
  // let fullPath = `http://localhost:3001/api/search?q=${query}&strategy=wikiparfum`
  let cached = store.getters['perfumes/get'](lang, path)
  if (cached) return Promise.resolve(cached)
  return cancelableInstance
    .get(process.env.VUE_APP_APIURL + path)
    // .get(fullPath)
    .then(response => {
      store.dispatch('perfumes/save', {
        lang,
        path,
        objects: response.data.data
      })
      return response.data.data
    })
}
/**
 *
 * @param {Array, Object} perfume Object with id or Array of Object with id
 * @param {*} filters
 */
export function getSimilarPerfumes (perfume, filters) {
  let lang = store.getters.lang
  const perfumes = Array.isArray(perfume) ? perfume : [perfume]
  let filterParams = filtersToString(filters)
  if (perfumes.length === 0) return Promise.resolve([])
  let path = `/v7/reco?perfumes=${perfumes
    .map(p => p.id)
    .join(',')}&limit=12${filterParams}`
  // let fullPath = `http://localhost:3001/api/reco?strategy=wikiparfum&perfumes=${perfumes
  //   .map(p => p.id)
  //   .join(',')}&limit=12${filterParams}`
  // return cancelableInstance.get(process.env.VUE_APP_APIURL+`/perfume_recommendations/by_perfume/?ref_perfume=${perfume.id}${filterParams}`)
  let cached = store.getters['perfumes/get'](lang, path)
  if (cached) return Promise.resolve(cached)
  return cancelableInstance
    .get(process.env.VUE_APP_APIURL + path)
    // .get(fullPath)
    .then(response => {
      // store.dispatch('cachePerfumes',response.data)
      // return response.data
      store.dispatch('perfumes/save', {
        lang,
        path,
        objects: response.data.data
      })
      return response.data.data
    })
}

export function getSimilarByIngredients (ingredients, filters) {
  let filterParams = filtersToString(filters, {
    family: 'family_ids',
    brand: 'brand_ids'
  })
  let path = `/perfume_search/by_ingredients/?${filterParams}`
  return cancelableInstance
    .post(
      process.env.VUE_APP_APIURL + path,
      ingredients.map(i => {
        return { ingredientId: i.id, proportion: 10 }
      })
    )
    .then(response => {
      return response.data
    })
}

// NEEDED FOR TEH CONCEPTS MODULES, hay que revisarlo, lo he hecho muy a saco sin "cache" ni "langs" ni nada...
export function getFamilies () {
  // let lang = store.getters.lang
  // let cached = store.getters.getCachedFamilies()
  // if (cached)
  //   return Promise.resolve(cached)
  return instanceNoHostId
    .get(process.env.VUE_APP_APIURL + `/families/`)
    .then(response => {
      // store.dispatch('families/save', {lang, path:'/families/', objects:response.data})
      return response.data
    })
}
export function getConcepts () {
  return instance
    .get(process.env.VUE_APP_APIURL + `/concepts/`, {
      params: { addOnly: 'categories' }
    })
    .then(response => {
      // store.dispatch('families/save', {lang, path:'/families/', objects:response.data})
      return response.data
    })
}

export function getConceptSimilarities () {
  let path = process.env.VUE_APP_APIURL + `/file/precomputed?fileName=conceptsDistance`
  // let path = ` http://localhost:3001/api/static?fileName=conceptsDistance&strategy=wikiparfum`
  return instance
    .get(path,{
      withCredentials:false,
      headers:{
        apikey: process.env.VUE_APP_APIKEY,
        safetyCode: process.env.VUE_APP_SAFETY_CODE,
      }
    })
    .then(response => {
      // store.dispatch('families/save', {lang, path:'/families/', objects:response.data})
      return response.data
    })
}

export function getConceptPerfumes () {
  let path = process.env.VUE_APP_APIURL + `/file/precomputed?fileName=conceptsRecos`
  // let path = ` http://localhost:3001/api/static?fileName=conceptsRecos&strategy=wikiparfum`
  return instance
    .get(path,
      {
        withCredentials:false,
        headers:{
          apikey: process.env.VUE_APP_APIKEY,
          safetyCode: process.env.VUE_APP_SAFETY_CODE,
        }
      }
    )
    .then(response => {
      // store.dispatch('families/save', {lang, path:'/families/', objects:response.data})
      return response.data
    })
}

//TOTALY FAKE!!!
export function getSimilarByText (text, filters) {
  let lang = store.getters.lang
  let filterParams = filtersToString(filters)
  let path = `/perfume_recommendations/by_perfume/?ref_perfume=123${filterParams}`
  let cached = store.getters['perfumes/get'](lang, path)
  if (cached) return Promise.resolve(cached)

  return instance.get(process.env.VUE_APP_APIURL + path).then(response => {
    store.dispatch('perfumes/save', { lang, path, objects: response.data })
    return response.data
  })
}
/**
 * Get array of perfumes with parameter ids
 * @param {string[]} ids
 * @returns
 */
export function getPerfumesByIds (ids) {
  let lang = store.getters.lang
  let path = `/perfumes/by_ids?lang=${lang}&perfumeIds=${ids.join(
    ','
  )}&allPresent=false&sortByEntryOrder=true`
  let cached = store.getters['perfumes/get'](lang, path)
  if (cached) return Promise.resolve(cached)

  return instance.get(process.env.VUE_APP_APIURL + path).then(response => {
    store.dispatch('perfumes/save', { lang, path, objects: response.data })
    return response.data
  })
}
export function getRestrictedIngredients (references) {
  if (!Array.isArray(references)) references = [references]
  let lang = store.getters.lang
  let path = `/ingredients/getPossibleCombinations?ingredients=${references
    .map(d => d.id)
    .join(',')}`
  let cached = store.getters['ingredients/get'](lang, path)
  if (cached) return Promise.resolve(cached)
  return cancelableInstance
    .get(process.env.VUE_APP_APIURL + path)

    .then(response => {
      let objects = response.data.map(e => e.ingredient)
      store.dispatch('ingredients/save', { lang, path, objects })
      return objects
    })
}

// ::PROFILE RELATED::

export function addToProfile (id) {
  return instance
    .post(process.env.VUE_APP_APIURL + '/favorites/perfume/add', [
      { perfumeId: id, rating: 5 }
    ])
    .then(() => {
      // console.log('Should refresh user data')
    })
}

export function removeFromProfile (id) {
  return instance
    .delete(process.env.VUE_APP_APIURL + '/favorites/perfume/remove', {
      data: [id]
    })
    .then(() => {
      // console.log('Should refresh user data')
    })
}

function translateProfile (responseData) {
  return {
    favorites: responseData.favoritePerfumes
      ? responseData.favoritePerfumes.map(f => f.perfume)
      : [],
    meta: {
      username: responseData.email,
      userId: responseData.userId
    }
  }
}

export function getProfile () {
  return instance
    .get(process.env.VUE_APP_APIURL + `/user/profile`)
    .then(response => {
      const data = translateProfile(response.data)
      const favs = localStorageContainer.favorites
      if (data.favorites && data.favorites.lenght === 0 && favs)
        return Promise.all(
          store.state.session.favorites.map(p => {
            return addToProfile(p)
          })
        ).then(() => data)
      return data
    })
}

export function login ({ user, password }, options = { skipReset: false }) {
  let qs = new URLSearchParams()
  qs.append('user', user)
  qs.append('password', password)
  return instance
    .post(process.env.VUE_APP_APIURL + `/user/login`, qs.toString(), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    })
    .then(response => {
      let sessionData = translateProfile(response.data)
      if (!options.skipReset) {
        return store.dispatch('setSession', sessionData)
      } else {
        return store.dispatch('setMeta', sessionData.meta)
      }
    })
}

export function signup ({
  email,
  password,
  name,
  surname,
  privacyPolicyAccepted
}) {
  // console.log("want to signup, then should add",store.state.session.favorites)
  let userId = getUserIdLocalStorage()
  // let currentPerfumes = store.state.session.favorites.map((p)=>p)
  let qs = new URLSearchParams()
  qs.append('email', email)
  qs.append('password', password)
  qs.append('name', name)
  qs.append('surname', surname)
  qs.append('userId', userId)
  qs.append('privacyPolicyAccepted', privacyPolicyAccepted)
  qs.append('hostId', 2)
  return instance
    .post(process.env.VUE_APP_APIURL + `/user/signup`, qs.toString(), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    })
    .then(() => {
      return login(
        { user: email, password: password },
        { skipReset: true }
      ).then(() => {
        return Promise.all(
          store.state.session.favorites.map(p => {
            return addToProfile(p)
          })
        )
      })
    })
}

export function logout () {
  return instance.post(process.env.VUE_APP_APIURL + `/user/logout`)
}

export function sendContactMessage (data) {
  return instance.post(process.env.VUE_APP_APIURL + `/user/contactEmail`, data)
}

export function resetPassword (user) {
  let qs = new URLSearchParams()
  qs.append('user', user)
  return instance.post(
    process.env.VUE_APP_APIURL + `/user/recoverPwd`,
    qs.toString(),
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  )
}
export function setResetPassword (user, pwd, token) {
  let qs = new URLSearchParams()
  qs.append('user', user)
  qs.append('password', pwd)
  qs.append('token', token)

  return instance.post(
    process.env.VUE_APP_APIURL + `/user/restorePwd`,
    qs.toString(),
    {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    }
  )
}
/**
 *
 * @param {string} provider
 */
function socialLogin (provider) {
  //let userId = getUserIdLocalStorage()
  window.location.href = process.env.VUE_APP_APIURL + '/auth/' + provider
}

export function loginGoogle () {
  return socialLogin('google')
}
export function loginFacebook () {
  return socialLogin('facebook')
}
/** @typedef {import('../types/TopFamilies').TopFamilies} TopFamilies */
/**
 *
 * @param {{lang:string,hostId:number,includeFamilyTopPerfumes:boolean,includeSubFamilyTopPerfumes:boolean}} param0
 * @return {Promise<TopFamilies[]>}
 */
export function getFamiliesTopList ({
  lang = 'en',
  hostId = undefined,
  includeFamilyTopPerfumes = false,
  includeSubFamiliesTopPerfumes = false,
  genders = []
} = {}) {
  const qs = new URLSearchParams()
  if (lang) qs.set('lang', lang)
  if (hostId) qs.set('hostId', hostId)
  if (includeFamilyTopPerfumes)
    qs.set('includeFamilyTopPerfumes', includeFamilyTopPerfumes)
  if (includeSubFamiliesTopPerfumes)
    qs.set('includeSubFamiliesTopPerfumes', includeSubFamiliesTopPerfumes)
  return instance
    .get(
      process.env.VUE_APP_APIURL +
        '/families/top/list?' +
        qs.toString() +
        `&gender=${genders.map(e => e.split('').join(',')).join(',')}`
    )
    .then(res => res.data)
}
/**
 * @param {{lang:string,hostId:number,includeFamilyTopPerfumes:boolean,includeSubFamilyTopPerfumes:boolean,genders=[]}} param0
 * @return {Promise<TopFamilies>}
 */
export function getFamiliesById (
  id,
  {
    isPrimary = undefined,
    lang = 'en',
    hostId = undefined,
    includeFamilyTopPerfumes = false,
    includeSubFamiliesTopPerfumes = false,
    genders = []
  } = {}
) {
  const qs = new URLSearchParams()
  if (lang) qs.set('lang', lang)
  if (hostId) qs.set('hostId', hostId)
  if (includeFamilyTopPerfumes)
    qs.set('includeFamilyTopPerfumes', includeFamilyTopPerfumes)
  if (includeSubFamiliesTopPerfumes)
    qs.set('includeSubFamiliesTopPerfumes', includeSubFamiliesTopPerfumes)
  if (isPrimary !== undefined) qs.set('isPrimary', isPrimary)

  return instance
    .get(
      process.env.VUE_APP_APIURL +
        `/families/top/${id}?${qs.toString()}&gender=${genders
          .map(e => e.split('').join(','))
          .join(',')}`
    )
    .then(res => res.data)
}

export default instance
