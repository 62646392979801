<template>
<div class="bubble-animation">
  <canvas ref="canvas" :width="size.width" :height="size.height" />
  <cloud-perfume-tooltip v-if="tooltipData" :meta-data="tooltipData" :style="{top: tooltipData.y+'px', left: tooltipData.x+'px'}" />
</div>
</template>
<script>
import Bubble from './d3BubbleAnimation'
import Size from '../Concepts/mixins/Size'
import CloudPerfumeTooltip from '../Concepts/CloudPerfumeTooltip.vue'
import { eventTracker } from '../../services/tracker'
export default {
  components: { CloudPerfumeTooltip },
  mixins: [Size],
  props: ['nodes', 'lines', 'selected'],
  /** @type {ReturnType<Bubble>} */
  bubbleAnimation:null,
  data(){
    return {
      tooltipData:null,
    }
  },
  created() {
    this.bubbleAnimation = Bubble()
  },
  mounted() {
    this.internalNodesMap = this.internalNodesMap || new Map()
    this.currentNodesMap = this.currentNodesMap || new Map()

    this.setNodes()
    if(!this.size.width|| !this.size.height) return 
    this.setSelected()
    this.draw()
  },
  computed: {

  },
  watch:{
    
    nodes() {
      this.setNodes()
      this.setSelected()
      this.draw()
      // Comment this line if you dont want apply zoom transition when nodes change
      //this.bubbleAnimation.applySelectedTransform()
    },
    selected() {
      this.setSelected()
    },
    size(){
      this.bubbleAnimation.setSize(this.size.width, this.size.height)
      //this.setSelected()
      this.setNodes()
      this.draw()
      this.setSelected()
    }
  },
  methods: {
      getPopulatedSelected() {
      const nodes = this.currentNodesMap ? Array.from(this.currentNodesMap.values()) : []
      if (!this.selected) return [];
      return nodes
        .filter((n) => n.deep < 2 && this.selected.some((select) => n.id == select))
        .map((e) => {
          e.label = e.name;
          return e;
        });
    },
    setNodes() {
      this.internalNodesMap = this.internalNodesMap || new Map()
      this.currentNodesMap = this.currentNodesMap ||  new Map()
      const map = new Map()
      this.nodes.forEach(n => {
        const lastNode = this.internalNodesMap.get(n.id)
        if(lastNode){
          n.x = lastNode.x
          n.y = lastNode.y
          n.image = lastNode.image
        }
        this.internalNodesMap.set(n.id, n)
        map.set(n.id,n)
      })
      if (this.nodes.length>0 && this.selected.some(id => !map.get(id))) {
        console.debug('set nodes\t','emit change selected')
        this.$emit('click',this.getPopulatedSelected().filter(e => map.get(e.id)))
      }
      this.currentNodesMap = map
    },
    draw() {
      const nodes = this.currentNodesMap ? Array.from(this.currentNodesMap.values()) : []
      this.bubbleAnimation.setSize(this.size.width, this.size.height)
      this.bubbleAnimation.draw(this.$refs.canvas,{nodes:nodes,lines:this.lines,onClick:this.handleClick,onHover:this.handleHover,mobile:this.isMobile})
    },
    handleClick(families) {
      console.log(families)
      const newSel = families
      const oldSel = this.getPopulatedSelected()
      const addedConcepts = newSel?.filter(c => !oldSel?.map(c => c._id).includes(c._id)) ?? []
      const removedConcepts = oldSel?.filter(c => !newSel?.map(c => c._id).includes(c._id)) ?? []
      addedConcepts.forEach(c => {
        c && eventTracker.emitEvent('ACTION', { type: !c.isSubfamily ? 'SELECT_FAMILY' : 'SELECT_SUBFAMILY', data: { id: parseInt(c._id) } })
        })
      removedConcepts.forEach(c => {
        c && eventTracker.emitEvent('ACTION', { type: !c.isSubfamily ? 'UNSELECT_FAMILY' : "UNSELECT_SUBFAMILY", data: { id: parseInt(c._id) } })
      })
      this.$emit('click',families)
    },
    handleHover(perfume,position){
      
      
      if(perfume && position ){
        if(this.id!==perfume.id){
          this.id = perfume.id
          this.tooltipData = {...perfume,id:perfume._id,x:position.x,y:position.y}
        }
      }
      else {
        this.tooltipData = null
        this.id = null
        }
    },
    setSelected() {
      this.bubbleAnimation.setSelected(this.getPopulatedSelected())
    },
  },
}
</script>
<style lang="stylus" scoped>
.bubble-animation
  width 100%
  height: 100%
  position relative
</style>