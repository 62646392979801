<template>
  <div class="empty-profile">
    <div class="title">{{t("oops_nothing_yet")}}</div>
    <div class="title big">{{t("create_your_profile")}}.</div>
    <div class="button-wrapper">
      <router-link to="explore/by-name" class="btn-container">
        <ButtonWithFeedback class="btn">
          <div class="button-title">{{t('title_by_name')}}</div>
        </ButtonWithFeedback>
      </router-link>
      <router-link to="explore/by-ingredients" class="btn-container">
        <ButtonWithFeedback class="btn">
          <div class="button-title">{{t('title_by_ings')}}</div>
        </ButtonWithFeedback>
      </router-link>
    </div>
    <div class="already" v-if="!this.$store.state.session.meta">
      or
      <router-link to="/login">{{t("login")}}</router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ButtonWithFeedback from '@/components/ButtonWithFeedback'
// import SquareButton from '@/components/SquareButton'
export default {
  name: 'EmptyProfile',
  components: {
    ButtonWithFeedback,
    // SquareButton
  }
}
</script>

<style scoped lang='stylus'>
.empty-profile
  align-items: center
  display: flex
  flex-direction: column
  height: 100%
  justify-content: center
  left: 0
  position: absolute
  top: 0
  width: 100%

  .mobile &
    padding: vw(40px)

  .title
    m-font("Lelo", "light")
    m-font-size(13, 16)
    m-letter-spacing(50)
    margin-bottom: vh(10px)

    .mobile &
      margin-bottom: vh(20px)
      text-align: center

    &.big
      m-first-letter-case()
      m-font("SangBleu", "regular")
      m-font-size(42, 50)
      margin-bottom: vh(40px)

      .mobile &
        m-font-size(30, 40)

  input
    border: none
    border-bottom: 1px solid rgba(#000, 0.15)
    m-font("Lelo", "light")
    m-font-size(15, 18)
    margin-bottom: vh(30px)
    outline: none
    padding: vh(10px) 0
    width: vw(300px)

    .mobile &
      padding: vh(20px) 0
      width: 100%

  .btn
    width: vw(300px)

    .mobile &
      width: 100%

  .button-wrapper
    align-items: center
    display: flex
    margin-bottom: vh(40px)

    .mobile &
      flex-direction: column
      width: 100%

    .btn-container
      &:not(:last-child)
        margin-right: vw(20px)

        .mobile &
          margin-bottom: vh(20px)
          margin-right: 0

      .mobile &
        width: 100%

      .btn
        padding: 1.5em 2.5em
        width: vw(250px)

        .mobile &
          padding: 2em 4em
          width: 100%

  .already
    m-font("Lelo", "light")
    m-font-size(15, 18)

    > a
      display: inline-block
      m-first-letter-case()
      text-decoration: underline
</style>
