import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ExploreByName from '../views/ExploreByName'
import ExploreByIngredients from '../views/ExploreByIngredients'
import ExploreByConcepts from '../views/ExploreByConcepts'
import IngredientChapter from '../views/IngredientChapter'
//import FamilyChapter from '../views/FamilyChapter'
import Profile from '../views/Profile'
import Login from '../views/Login'
import Signup from '../views/Signup'
import HowItWorks from '../views/HowItWorks'
import Contact from '../views/Contact'
import ChangePassword from '../views/ChangePassword'
import qs from 'qs'
import ExploreByFamilies from '../views/ExploreByFamilies.vue'
Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/explore/by-name',
    name: 'explore-by-name',
    component: ExploreByName
  },
  {
    path: '/explore/by-ingredients',
    name: 'explore-by-ingredients',
    component: ExploreByIngredients
  },
  {
    path: '/explore/by-concepts',
    name: 'explore-by-concepts',
    component: ExploreByConcepts
  },
  {
    path: '/explore/by-families',
    name: 'explore-by-families',
    component: ExploreByFamilies
  },
  {
    path: '/chapter/ingredient',
    name: 'raw ingredient',
    component: IngredientChapter
  },
  /*{
    path: '/chapter/family',
    name: 'family-chapter',
    component: FamilyChapter
  },*/
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/howto',
    name: 'how it works',
    component: HowItWorks
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    var result = qs.stringify(query, { arrayFormat: 'brackets', encode: false });
    return result ? ('?' + result) : '';
  }
})

//DEBERIA IR DENTRO dEL "routeWatcher" pero no se como "acceder a router" desde ahi... algo tipo "Vue.use(routeWatcher)"....
router.pushQuery = (query) => {
  let newQuery = { ...router.history.current.query, ...query }
  Object.keys(newQuery).forEach((key) => {
    if (newQuery[key] === null) delete newQuery[key]
    // else newQuery[key] = JSON.stringify(newQuery[key])
  })
  return router.push({ query: newQuery, state: { from: 'linked-data' } }).catch(() => { })
}

router.beforeEach((to, from, next) => {
  console.log(to.query)
  if (to.query.brandAccess !== undefined) {
    console.log('SHOULD SET BRAND LOCK', to.query.brandAccess)
    if (to.query.brandAccess === '') localStorage.removeItem("brandAccess")
    else {
      localStorage.setItem("brandAccess", JSON.stringify(to.query.brandAccess.split(',').map((id) => Number(id))))
      router.app.eventTracker.emitEvent('BRAND_ACCESS', { brandAccess: to.query.brandAccess.split(',').map((id) => Number(id)) })
    }
    delete to.query.brandAccess
    next({ name: to.name, query: to.query, replace: true })
  } else {
    next()
  }
})

export default router
