<template>
  <div class="share-popup">
    <div class="background" @click="$emit('close')"></div>
    <div class="content">
      <div class="mini-view">
        <div  itemscope itemtype="http://schema.org/Product" class="left">
          <div class="image" :style="{backgroundImage: `url(${data.imageUrl})`}"></div>
          <div itemprop="name" class="name">{{data.name}}</div>
          <div itemprop="brand" class="brand">{{data.brand.name}}</div>
          <FamiliesWithIcon itemprop="additionalProperty" :family="data.family" :subfamily="data.secondaryFamily"></FamiliesWithIcon>
        </div>
        <div class="right">
          <QuadPack :proportions="data.ingredientProportions" :heroIngredient="data.heroIngredient" @select="ingSelected"></QuadPack>
        </div>
      </div>
      <div class="share-box">
        <div class="title">{{t('share_to')}}</div>
        <div class="actions">
          <SquareButton :ghost="true" @click="doShare('facebook')">{{t('facebook')}}</SquareButton>
          <SquareButton :ghost="true" @click="doShare('twitter')">{{t('twitter')}}</SquareButton>
          <SquareButton :ghost="true" @click="doShare('instagram')">{{t('instagram')}}</SquareButton>
          <SquareButton :ghost="true" @click="doShare('mail')">{{t('mail')}}</SquareButton>
        </div>
      </div>
      <div class="close-btn" @click="$emit('close')"></div>
    </div>
  </div>
</template>

<script>
import SquareButton from "@/components/SquareButton";
// import QuadPack from "@/components/QuadPack/QuadPack";
import { QuadPack as QuadPack } from "@bestiario/puig-quad-pack";
import FamiliesWithIcon from "@/components/FamiliesWithIcon";

export default {
  name: "SharePopup",
  components: {
    SquareButton,
    QuadPack,
    FamiliesWithIcon,
  },
  props: ["data"],
  computed: {},
  methods: {
    ingSelected(ing) {
      if (ing.isIngredientChapter)
        this.$router.push(`/chapter/ingredient?data=${ing.id}`);
    },
    doRemove() {
      //  console.log("REmove me from store")
      //  this.$store.dispatch('toggleFav',this.data)
      //  this.$emit('close')
    },
  },
};
</script>
<style scoped lang="stylus">
.share-popup
  height: 100%
  width: 100%

  .background
    background-color: #fff
    height: 100%
    position: absolute
    width: 100%
    z-index: 0

  .content
    align-items: center
    background-color: #fff
    display: flex
    flex-direction: column
    height: 100%
    justify-content: center
    left: 50%
    padding: vh(40px) vw(80px)
    position: absolute
    top: 0
    transform: translate(-50%, 0)
    width: 60vw

    .mobile &
      height: 100%
      left: 0
      padding: 0
      top: 0
      transform: none
      width: 100%

    .mini-view
      display: flex
      flex: 1 1 0%
      overflow: hidden
      width: 100%

    .left,
    .right
      .mobile &
        padding: vh(10px) vw(10px)

    .left
      align-items: flex-start
      display: flex
      flex: 0 0 40%
      flex-direction: column
      padding: vh(20px) vw(20px)

      .name
        margin-bottom: vh(5px)

      .brand
        margin-bottom: vh(10px)

      .family-and-icon
        >>>.label
          white-space: normal

      .image
        background-position: center
        background-repeat: no-repeat
        background-size: contain
        flex: 1 1 0%
        margin: vh(20px) 0
        width: 100%

    .right
      flex: 0 0 60%

      .mobile &
        align-items: center
        display: flex
        justify-content: center

        .quad-pack
          flex: 1 1 0%

  .share-box
    align-items: center
    border-top: 1px solid rgba(#000, 0.15)
    display: flex
    flex-direction: column
    margin-top: vh(30px)
    padding: vh(30px) vw(10px) vw(10px)
    width: 100%

    .title
      m-font("Lelo", "medium")
      m-font-size(20, 24)
      margin-bottom: vh(30px)
      text-transform: uppercase

    .actions
      display: flex
      width: 100%

      .mobile &
        flex-wrap: wrap
        margin: 0

      .btn
        flex: 1 1 0%
        padding: 1.5em 2.5em

        .mobile &
          margin: vh(5px) vw(5px)

  .close-btn
    cursor: pointer
    m-icon("close", 20)
    position: absolute
    right: 0
    top: vh(40px)

    .mobile &
      right: vw(15px)
      top: vh(15px)
</style>