<template>
  <div class="tuned-toggle-btn">
    <div class="text" @click="_value=!_value">
      <slot v-if="!_value || !$slots.active" name="default"></slot>
      <slot v-else name="active"></slot>
    </div>
    <toggle-button v-model="_value" :sync="true" />
  </div>
</template>

<script>
// import Vue from 'vue'
import {ToggleButton} from 'vue-js-toggle-button'


export default {
  name: 'MyToggleButton',
  components: {
    ToggleButton
  },
  props: [ 'value' ],
  model: {
    prop: 'value',
    event: 'change'
  },
  computed: {
    _value: {
      get() { return this.value},
      set(value) { this.$emit('change', value) }
    }
  }
}
</script>

<style scoped lang="stylus">
.tuned-toggle-btn
  align-items: center
  cursor: pointer
  display: flex
  
  .text
    m-font("Lelo", "light")
    m-font-size(13, 16)
    m-letter-spacing(80)
    margin-right: vw(5px)
    text-transform: uppercase

  >>> .vue-js-switch
    $size = 16px
    $move-x = "calc(%s)" % vw($size)
    align-items: center
    display: flex
    width: vw($size * 2)

    &.toggled
      .v-switch-button
        background-color: $malachite
        transform: translate3d($move-x, -50%, 0) !important

    .v-switch-core
      background-color: #000 !important
      height: vh(1px) !important

    .v-switch-button
      background-color: #000
      border: vw(2px) solid #fff
      height: vw($size) !important
      margin: 0 !important
      top: 50%
      transform: translate3d(0, -50%, 0) !important
      width: @height
</style>