export default {
  //RECOS:


it: 'eso',
also: 'tambien',
$pronoun_self: 'tu',
$possessive_singular_self: 'tu',
$possessive_refl_singular_self: 'mi',
$possessive_plural_self: 'tus',
$possessive_refl_plural_self: 'mis',
$possessive_singular_prep_self: 'vuestro',
$possessive_singular_liste_self: 'vuestros',

$you_self: 'tu',
$you_prep_self: 'tu',
$do_you_have_self: 'tienes',
$that_you_love_self: "que tu quieres",

$you_love_self: 'te guste',
$you_adore_self: 'te encanta',
  
$apreciate_self: 'like',
$you_have_self: 'you have',

reco_desc_title: 'Porque recomendamos este perfume?',
possible_reco_title: 'quizás $you_love este perfume porque',

recommended_by_hero: '$you_adore su ingrediente principal: <b>%HERO%</b>',
recommended_by_fam: 'pertenece a la família <b>%FAMILY%</b>, una de $possessive_plural preferidas',
recommended_by_other: "esta cerca de $possessive_singular perfil olfativo",
recommended_by_ings: 'tiene alguno de $possessive_plural ingredientes favoritos: <b>%INGS%</b>',
}