<template>
  <div v-if="ready && !landscape" :class="['app',deviceClass]">
    <Header :minimal="($route.name==='home')" :transparent="($route.name==='home')" />
    <div class="section">
      <transition-group name="fade" tag="div" class="t-wrapper">
        <router-view :key="$route.name" />
      </transition-group>
      <transition name="fade">
        <div  v-if="showShare" class="section-overlay" :class="{showShare}">
          <SharePopup :data="showShare" @close="showShare=null"></SharePopup>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div :class="{showLegal}" class="legal-overlay">
        <LegalPopup @close="showLegal=null" />
      </div>
    </transition>
  </div>
  <div class="landscape-wrapper" v-else-if="landscape">
    <div class="landscape"> 
      <div class="icon"/>
      <div class="message">{{t("turn_your_device")}}</div>
    </div>
    
  </div>
</template>

<script>
// import {getProfile} from '@/services/Api'
import Header from '@/components/Header.vue'
import SharePopup from '@/components/SharePopup'
import LegalPopup from '@/components/LegalPopup'
import device from '@/utils/device';
export default {
  name: 'app',
  metaInfo: {
    titleTemplate: 'Wikiparfum - %s',
    meta:[{
      vmid:"og:type",
      name:"og:type",
      content:"website"
      }]
  },
  components: {
    Header,
    SharePopup,
    LegalPopup
  },
  mounted () {
    const self = this
    this.eventTracker.emitEvent('NEW_SESSION')
    this.$store.dispatch('initSession', this.eventTracker).then(()=> {
      this.ready=true
    })
    this.eventTracker.emitEvent('REFFERRER', { url: document.referrer, target: window.location.href })
    //check landscape with window.orientation. 
    this.landscape = this.checkLandscape(window.orientation) //TODO: window.orientation is deprecated
    //orientation change
    //window.addEventListener("orientationchange", (event) => {
    //  this.checkLandscape(event.target.screen.orientation.angle)
    //});
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if(isSafari){
      window.addEventListener('resize', function(){
         if(this.innerWidth > this.innerHeight){
              self.checkLandscape(90)
          } else {
              self.checkLandscape(0)
          }
      })
    }
    else {
      window.onorientationchange = (event) => {
        this.checkLandscape(event.target.screen.orientation.angle)
      }
    }
    
    document.documentElement.setAttribute('lang', this.$store.state.i18n.lang)
  },
  data (){
    return {
      showShare: null,
      showLegal: null,
      showFooter: !localStorage.getItem('accepted_cookies'),
      ready: false,
      landscape: false // We cant use this.isMobile in data 
    }
  },
  computed:{
    deviceClass (){
      return device
    }
  },
  methods:{
    checkLandscape(angle){
      const bool = this.isMobile && Math.abs(angle%180)===90
      this.landscape = bool
      return bool
    },
    acceptCookies () {
      localStorage.setItem('accepted_cookies',true)
      this.showFooter = !localStorage.getItem('accepted_cookies')
    }
  },
  lazyData:{
    showShare: {type: 'perfume'}
  },
  routerLinkedData: ['showShare','showLegal']
}
</script>
<style lang="stylus">
body
  margin: 0

a
  color: inherit
  text-decoration: none

a:hover
  color: inherit
  cursor: pointer
  text-decoration: none
</style>
<style scoped lang="stylus">
.landscape-wrapper
  height: 100%

  .landscape
    align-items: center
    display: flex
    flex-direction: column
    height: 100%
    justify-content: center

    .icon
      m-icon("turn-your-device", 50)

    .message
      m-font("Lelo", "medium")

.app
  display: flex
  flex-direction: column
  height: 100%
  overflow: hidden
  width: 100%

  .section
    flex: 1 1 0%
    height: 100%
    left: 0
    overflow: hidden
    position: relative
    top: 0
    width: 100%
    z-index: 0

    .t-wrapper
      display: flex
      flex: 1 1 0%
      height: 100%
      overflow: hidden
      position: relative
      position: absolute
      width: 100%
      z-index: 0

      > div
        flex: 1 1 0%
        height: 100%
        overflow: hidden
        position: absolute
        width: 100%

        &.fade-enter-active,
        &.fade-leave-active
          transition: opacity 0.4s

        &.fade-enter,
        &.fade-leave-to
          opacity: 0

    .section-overlay
      // background-color: #000
      height: 100%
      position: absolute
      width: 100%
      z-index: 1

      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity 0.4s

      &.fade-enter,
      &.fade-leave-to
        opacity: 0

  .legal-overlay
    display: none
    height: 100%
    position: absolute
    width: 100%
    z-index: 2

    &.showLegal
      display: inline

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.4s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0
</style>
