<template>
  <ButtonWithFeedback class="btn btn--primary square-button" :class="{'btn--ghost': ghost, 'disabled': disabled}" @click="handleClick">
    <slot v-if="!this.label"></slot>
    <div>{{label}}</div>
  </ButtonWithFeedback>
</template>

<script>
import ButtonWithFeedback from '@/components/ButtonWithFeedback.vue'
export default {
  name: 'SquareButton',
  components:{
    ButtonWithFeedback
  },
  props: {
    label: String,
    ghost: {type: Boolean, deafult: false},
    disabled: {type: Boolean, deafult: false},
  },
  methods:{
    handleClick(){
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="stylus">
.button-with-feedback
  background: #444
  border: solid 1px #000
  color: #fff
  padding: vh(10px) vw(25px)
  transition: background 0.1s
</style>