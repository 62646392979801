<template>
  <div class="result-explore-by-concepts">
    <div class="result-header">
      <div class="back" @click="$emit('back', null)">{{t("back")}}</div>
      <div class="title">
        <div class="word">{{ concepts.map((c) => c.label).join("/") }}</div>
      </div>
      <!--chapuzilla, para que se centre el title, aunque no hay concept-venn-->
      <div class="concept-venn"></div>
    </div>
    <div class="result-body">
      <template
        v-if="
          $asyncComputed.fetchedPerfumes &&
          $asyncComputed.fetchedPerfumes.success
        "
      >
        <div class="filters">
          <list-filters
            v-if="filtersSetting && filtersSetting.length !== 0"
            :listData="fetchedPerfumes"
            :filterDefs="filtersSetting"
            v-model="filterSelection"
            :labelAsSelection="isMobile"
            :defaultSelected="genders"
            applyText="search"
          ></list-filters>
        </div>
        <template v-if="filteredPerfumes.length > 0">
          <div class="perfumes-wrapper">
            <div class="perfumes-container">
              <perfume-card
                v-for="perfume in filteredPerfumes"
                :key="perfume.id"
                :data="perfume"
                @click="handlePerfumeClick(perfume)"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="no-results">
            {{ t("no_results_for_this_combination") }}
          </div>
        </template>
      </template>
      <LoadingOverlay v-else text="loading_perfumes" />
    </div>
  </div>
</template>
<script>
import { GENDER_ENUM } from "../utils/enums";
import { getAllDifferentValuesFromObjects } from "../utils/getFieldObjectUtils";
import { applyFilters } from "../utils";
import PerfumeCard from "@/components/PerfumeCard.vue";
import ListFilters from "./ListFilters.vue";
import { getPerfumesByIds } from "@/services/Api";
import LoadingOverlay from "@/components/LoadingOverlay";
import { eventTracker } from "../services/tracker";
export default {
  components: {
    PerfumeCard,
    ListFilters,
    LoadingOverlay,
  },
  props: {
    perfumes: { type: Array, required: true },
    concepts: { type: Array, required: true },
    genders:{type: Array}
  },
  data() {
    return {
      filterSelection:null
    };
  },
  asyncComputed: {
    fetchedPerfumes() {
      if (this.perfumes && this.perfumes.length > 0) {
        return getPerfumesByIds(this.perfumes.map((p) => p.id));
      }
      return [];
    },
  },
  mounted() {
    console.log(this.concepts)
  },
  watch: {
    filterSelection(){
      console.debug('change filter selection', this.filterSelection)
      const genders = this.filterSelection.filter(d => d.keyPath === 'gender')
      if (genders.length>0) {
        this.$emit('gender',genders.map(g => g.value))
      }
      else {
        this.$emit('gender',[])
      }
    },
    filteredPerfumes(newFP, oldFP) {
      if (!newFP) return;
      if (oldFP) {
        const newFPIds = newFP.map((p) => p.id);
        const oldFPIds = oldFP.map((p) => p.id);
        const addedIds = newFPIds.filter((id) => !oldFPIds.includes(id));
        const removedIds = oldFPIds.filter((id) => !newFPIds.includes(id));
        if (addedIds.length !== 0 || removedIds.length !== 0) {
          eventTracker.emitEvent("SEARCH_PERFUMES", {
            type: "FAMILIES",
            data: {
              families: this.$props.concepts.map((c) => parseInt(c._id)),
            },
            filter: this.filterSelection || {},
            topResults: newFPIds,
          });
        }
      }
    },
  },
  computed: {
    filteredPerfumes() {
      return applyFilters(this.fetchedPerfumes || [], this.filterSelection);
    },
    filtersSetting() {
      return [
        {
          id: "gender",
          filterLabel: "filter_by_gender",
          type: "options",
          fixedOptions: [
            { value: GENDER_ENUM.FEMALE, customLabel: "for_her" },
            { value: GENDER_ENUM.MALE, customLabel: "for_him" },
            { value: GENDER_ENUM.UNISEX, customLabel: "unisex" },
          ],
          keyPath: "gender",
          validation: (value, object) => value.indexOf(object) >= 0,
        },
        {
          id: "brand",
          filterLabel: "filter_by_brand",
          type: "options",
          fixedOptions: getAllDifferentValuesFromObjects(
            this.fetchedPerfumes,
            "brand.name"
          ).map((value) => ({ value, label: value })),
          keyPath: "brand.name",
          female: true,
        },
        // {
        //   id: "family",
        //   filterLabel: "filter_by_family",
        //   type: "options",
        //   fixedOptions: getAllDifferentValuesFromObjects(
        //     this.fetchedPerfumes,
        //     "family.name"
        //   ).map((value) => ({ value, label: value })),
        //   keyPath: "family.name",
        // },
      ];
    },
  },
  methods: {
    handlePerfumeClick(perfume) {
      this.$emit('select', perfume)
    }
  },
};
</script>
<style scoped lang="stylus">
.result-explore-by-concepts
  display: flex
  flex-direction: column
  width: 100%

  .result-header
    align-items: center
    display: flex
    padding-left: 5vw
    padding-right: 5vw

    .back
      flex: 0 0 vw(140px)
      text-align: center

    .title
      display: flex
      flex: 1 1 0%
      justify-content: center
      text-align: center

      .mobile &
        flex-wrap: wrap-reverse

      .word
        m-font-size(56px)
        cursor: pointer
        margin: vw(20px)

        .mobile &
          m-font-size(25, 30)
          margin: 0

        &.excluded
          opacity: 0.15

    .concept-venn
      flex: 0 0 vw(140px)
      height: vw(140px)

    .mobile &
      flex-direction: column-reverse

      .title
        m-font-size(26px)

      .back
        left: vw(10px)
        position: absolute
        top: vw(20px)

      .concept-venn
        flex: 0 0 vw(60px)
        height: vw(175px)
        width: vw(200px)

  .result-body
    display: flex
    flex: 1 1 0%
    flex-direction: column
    overflow: auto
    position: relative

    .filters
      m-max-width-container(padding: 5%)
      margin-bottom: vw(20px)
      width: 100%

      .list-filters
        border-bottom: 1px solid rgba(#000, 0.15)
        padding-bottom: vw(20px)
        padding-top: vw(20px)

    .perfumes-wrapper
      overflow-y: auto

    .no-results
      left: 50%
      position: absolute
      text-align: center
      top: 50%
      transform: translate(-50%, -50%)
      m-font-size(24)

    .perfumes-container
      m-max-width-container(padding: 5%)
      display: flex
      flex-wrap: wrap
      margin-bottom: vw(20px)

      .perfume-card
        $margin = vw(10px)
        $percentage = percentage((1 / 4))
        $width = "calc(%s - (%s * 2))" % ($percentage $margin)
        flex: 0 0 $width
        height: vw(200px)
        margin: $margin

        .mobile &
          $margin = vw(5px)
          $percentage = percentage((1 / 2))
          $width = "calc(%s - (%s * 2))" % ($percentage $margin)
          flex: 0 0 $width
          margin: $margin
</style>
