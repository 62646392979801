export default {
  //RECOS:

it: 'it',
also: 'also',
$pronoun_self: 'you',
$pronoun_other: 'he/she',
$pronoun_other_F: 'she',
$pronoun_other_M: 'he',
$possessive_singular_self: 'your',
$possessive_refl_singular_self: 'my',
$possessive_plural_self: 'your',
$possessive_refl_plural_self: 'my',
$possessive_singular_other: 'their',
$possessive_singular_prep_self: 'your',
$possessive_singular_prep_other: 'their',
$possessive_singular_liste_self: 'your',
$possessive_singular_liste_other: 'their',
$possessive_plural_other: 'their',
$possessive_refl_singular_other: 'their',
$possessive_refl_plural_other: 'their',

$you_self: 'you',
$you_other_F: 'she',
$you_other_M: 'he', 
$you_prep_self: 'you',
$you_prep_other_F: 'her',
$you_prep_other_M: 'him',
$do_you_have_self: 'you have',
$do_you_have_other: 'he/she has',
$do_you_have_other_F: 'she has',
$do_you_have_other_M: 'he has',
$that_you_love_self: "that you love",
$that_you_love_other: "that he/she loves",
$that_you_love_other_F: "that she loves",
$that_you_love_other_M: "that he loves",

$you_love_self: 'you love',
$you_love_other_F: 'she loves',
$you_love_other_M: 'he loves',
$you_adore_self: 'you love',
$you_adore_other_F: 'she loves',
$you_adore_other_M: 'he loves',
  
$apreciate_self: 'like',
$apreciate_other_F: 'likes',
$apreciate_other_M: 'likes',
$you_have_self: 'you have',
$you_have_other_F: 'she has',
$you_have_other_M: 'he has',

reco_desc_title: 'Why do we recommend this perfume?',
possible_reco_title: 'Maybe $you_love this perfume because',

recommended_by_hero: '$you_adore its main ingredient: <b>%HERO%</b>',
recommended_by_fam: 'it belongs to the <b>%FAMILY%</b> family, one of $possessive_plural favorite',
recommended_by_other: "it is close to $possessive_singular olfactive profile",
recommended_by_ings: 'it has some of $possessive_plural favorite ingredients: <b>%INGS%</b>',
}