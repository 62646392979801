<template>
  <div class="sign-up">
    <div class="sign-up-container">
      <div class="title">{{t('signup_title')}}</div>
      <input v-model="form.email" :placeholder="t('email_address')+' *'" />
      <input v-model="form.name" :placeholder="t('name')+' *'" />
      <input v-model="form.password" type="password" :placeholder="t('create_password')+' *'" />
      <input v-model="form.passwordCheck" type="password" :placeholder="t('confirm_password')+' *'" />
      <div class="warning" v-if="state.missingFields">{{t('missing_fields')}}</div>
      <div class="warning" v-else-if="state.wrongPassword">{{t('wrong_password_check')}}</div>
      <div class="warning error" v-else-if="state.userExists">{{t('user_already_exists')}}</div>
      <div class="checkbox-wrapper">
        <Checkbox class="checkbox" :label='acceptPolicyTitle' v-model='form.privacyPolicyAccepted'/>
        <hr/>
        <Checkbox class="checkbox" :label='acceptTermTitle' v-model='form.termsAccepted'/>
        <hr/>
      </div>
      <div class="button-wrapper">
        <SquareButton
          :disabled="state.loading  || !form.termsAccepted"
          @click="submit"
        >{{state.loading?t('creating_account'):t('create_account')}}</SquareButton>
        
      </div>
      <div class="social-icon">
        <SocialButton  class="icon " icon='google'  @click="googleLogin"> {{t("continue_with")}} Google </SocialButton>
        <SocialButton class="icon " icon='facebook'  @click="facebookLogin">{{t("continue_with")}} Facebook</SocialButton>
      </div>
      <div class="already">
        {{t("already_have_account")}}
        <router-link to="/login">{{t("login")}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import SquareButton from '@/components/SquareButton'
import {signup,loginGoogle,loginFacebook} from '@/services/Api'
import SocialButton from '@/components/SocialButton/SocialButton'
import Checkbox from '@/components/Checkbox'
export default {
  name: "Signup",
  metaInfo: {
    title: 'Sign up',
    meta:[{
      vmid: 'description', name: 'description',
      content: "Welcome to Wikiparfum. Sign up to discover our encyclopedia for perfume lovers, find fragrances according to your olfactive preferences and build your own olfactive profile."
    },{
      vmid: 'og:title', name: 'og:title',
      content:"Wikiparfum - Sign up"

    },{
      vmid: 'og:description', name: 'og:description',
      content: "Welcome to Wikiparfum. Sign up to discover our encyclopedia for perfume lovers, find fragrances according to your olfactive preferences and build your own olfactive profile."
    },{
      vmid: 'twitter:title', name: 'twitter:title',
      content:"Wikiparfum - Sign up"

    },{
      vmid: 'twitter:description', name: 'twitter:description',
      content: "Welcome to Wikiparfum. Sign up to discover our encyclopedia for perfume lovers, find fragrances according to your olfactive preferences and build your own olfactive profile."
    }]
  },
  components:{
    SquareButton,
    SocialButton,
    Checkbox
  },
  data(){
    return {
      state:{
        missingFields: false,
        wrongPassword: false,
        userExists: false,
        loading: false
      },
      form: {
        email: null,
        name: null,
        password: null,
        passwordCheck: null,
        privacyPolicyAccepted:false,
        termsAccepted:false
      }
    }
  },
  computed:{
    acceptTermTitle(){
      return this.tRecursive("accept_terms",term => `<a href='?showLegal=true' target="_blank">${term}</a>`)
    },
    acceptPolicyTitle(){
      return this.tRecursive("accept_policy",policy => `<a href="?showLegal=true" target="_blank">${policy}</a>`)
    },

  },
  methods:{
    googleLogin(){
      loginGoogle()
    },
    facebookLogin(){
      loginFacebook()
    },
    submit () {
      //VErY WrONG to store the password as plain text...
      this.state.missingFields = false
      this.state.wrongPassword = false
      this.state.userExists = false
      if(!this.form.password || !this.form.name || !this.form.email){
        this.state.missingFields = true
      }
      else if(!this.form.passwordCheck || this.form.passwordCheck != this.form.password){
        this.state.wrongPassword = true
      } else {
        this.state.loading = true
        signup(this.form).then(()=>{
          this.state.missingFields = false
          this.state.wrongPassword = false
          this.eventTracker.emitEvent('REGISTRATION', {
            date:Date.now(),
            is_temp:/temp/.test(this.form.name) || /temp/.test(this.form.email)
          })
          this.$router.push({ path: '/profile'})
        }).catch(()=>{
          this.state.userExists = true
          this.state.loading = false
        })

      }
    },
  }
}
</script>

<style scoped lang='stylus'>
$width=vw(400px)
.sign-up
  //position: relative !important
  overflow-y auto !important
  .sign-up-container
    justify-content center
    align-items center
    flex-direction column
    display flex
    padding: vw(40px)

  .title
    m-first-letter-case()
    m-font("Lelo", "light")
    m-font-size(22, 26)
    margin-bottom: vh(40px)

    .mobile &
      text-align: center

  input
    display block
    border: none
    border-bottom: 1px solid rgba(#000, 0.15)
    m-font("Lelo", "light")
    m-font-size(15, 18)
    margin-bottom: vh(20px)
    outline: none
    padding: vh(10px) 0
    width: $width

    .mobile &
      padding: vh(20px) 0
      width: 100%
  .checkbox-wrapper
    width $width
    color rgba(#000, 0.5)
    m-font-size(15, 18)
    .mobile &
      padding: vh(20px) 0
      width: 100%
    > hr
      background-color rgba(#000, 0.15)
      border-width 0px
      height 1px
    .checkbox
      >>> a
        text-decoration-line underline
  .btn
    width: $width

    .mobile &
      width: 100%
  .social-icon
    width: $width
    margin-bottom vh(10px)
    //display flex
    .mobile &
      padding: vh(20px) 0
      width: 100%
    .icon 
      width 100%
      margin-top vh(20px)
      align-items left
    .right
      margin-left vw(5px)
    .left
      margin-right vw(5px)
  
  .button-wrapper
    align-items: center
    display: flex
    margin-bottom: vh(40px)
    .mobile &
      width: 100%

    .btn
      width: $width

      .mobile &
        width: 100%

  .warning
    color: #f00
    m-first-letter-case()
    m-font("Lelo", "light")
    margin: vw(10px)

    &.error
      color: #f00

  .already
    m-font("Lelo", "light")
    m-font-size(15, 18)

    > a
      display: inline-block
      m-  first-letter-case()
      text-decoration: underline
</style>