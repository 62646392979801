<template>
  <input
    :value="value"
    :class="{error}"
    @input="$emit('input',$event.target.value)"
    :placeholder="placeholder"
    :required="required"
    :type="type"
  />
</template>
    
  
<script>
export default {
  name: "Input",
  props: ["value", "type", "placeholder", "required", "error"]
};
</script>

<style lang="stylus" scoped>
input {
  border: none;
  border-bottom: 1px solid rgba(#000, 0.15);
  m-font('Lelo', 'light');
  m-font-size(15, 18);
  outline: none;
  padding: vh(10px) 0;
  width: vw(300px);

  .mobile & {
    padding: vh(20px) 0;
    width: 100%;
  }
}

.error {
  border-bottom: 1px solid red;
}
</style>