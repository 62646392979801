import fr from './lang/fr.js'
import en from './lang/en.js'
import es from './lang/es.js'
import recos_fr from './lang/recos.fr.js'
import recos_en from './lang/recos.en.js'
import recos_es from './lang/recos.es.js'
import store from '@/store'

let storeInstance = store
let allStrings = {
  en: { ...en, ...recos_en },
  fr: { ...fr, ...recos_fr },
  es: { ...es, ...recos_es },
}
function flatT(key) {
  return allStrings[storeInstance.state.i18n.lang][key]
}
function replaceArticles(str) {
  let keys, propperKey, propperKeyNoG, g, w
  if (!str || str.indexOf('$') < 0 || str.indexOf('[') === 0) return str
  else keys = str.match(/\$[a-zA-Z_]*/g)
  // g = store.getState().session.gender
  // if(g==='U') g = 'M'
  g = 'M' //Remember this is diferents that GENDER_ENUM.MALE. This is in order to find the key in lang
  // w = store.getState().session['for-who']
  w = 'self'
  return keys.reduce((result, key) => {
    propperKey = w === 'self' ? key + '_self' : key + '_other_' + g
    propperKeyNoG = w === 'self' ? key + '_self' : key + '_other'
    return result.replace(key, flatT(propperKey) || flatT(propperKeyNoG) || '[' + propperKey + ']')
  }, str)
}
export function t(key) {
  // return allStrings[storeInstance.state.i18n.lang][key]|| '['+key+']'
  // lo anterior no funciona pq el string buit es un falsy
  let aux = replaceArticles(allStrings[storeInstance.state.i18n.lang][key])
  return aux != undefined ? aux : '[' + key + ']'
}
/**
 * 
 * @param {string} key 
 * @param {(str:string)=>string} setStringCallback 
 */
export function tRecursive(key, setStringCallback = (s) => s) {
  const str = flatT(key)
  if(!str) return `[${key}]`
  const keys = str.match(/\$[a-zA-Z_]*/g)
  if(keys)
    return keys.reduce((result, key) =>
      result.replace(key, setStringCallback(tRecursive(key.slice(1)) || '[' + key + ']'))
      , str)
  return str
}
export default {
  methods: {
    t,
    tRecursive
  }
}
