<template>
  <div class="explore-by-name">
    <div class="explore-header" :class="{floating: query===null}">
      <BetterInput
        @change="(value)=>query=value"
        @focus="selection=null; selectedSimilar=null; showSimilar=false"
        :value="query"
        class="search-input"
        :placeholder="t('search_brand_or_name')"
        ref="searchInput"
      />
      <template v-if="!isMobile">
        <ListFilters
          v-if="results && !selection"
          :listData="results"
          :filterDefs="filterDefs"
          v-model="filterSelection"
          :labelAsSelection="isMobile"
        ></ListFilters>
      </template>
    </div>
    <div class="content-wrapper">
      <template v-if="isMobile">
        <ListFilters
          v-if="results"
          :listData="results"
          :filterDefs="filterDefs"
          v-model="filterSelection"
          :labelAsSelection="isMobile"
        ></ListFilters>
      </template>
      <div class="scroll-wrapper">
        <LoadingOverlay v-if="loading" text="loading_perfumes" />
        <div class="no-results" v-if="!loading && filteredResults.length===0">{{t("no_results_search")}}</div>
        <div  class="perfume-list">
          <PerfumeListItem
            v-for="perfume in filteredResults"
            :key="perfume.id"
            :data="perfume"
            @click="selection=perfume.id"
          >
            <template v-slot:action>
              <div class="arrow"></div>
            </template>
          </PerfumeListItem>
        </div>
      </div>
      <transition name="fade">
        <div v-if="selection" class="overlay">
          <PerfumeAndSimilars :data="selection" @close="selection=null"></PerfumeAndSimilars>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>

import {searchByName} from '@/services/Api.js'
import {applyFilters} from '@/utils'
import PerfumeListItem from '@/components/PerfumeListItem'
import ListFilters from '@/components/ListFilters'
import PerfumeAndSimilars from '@/components/PerfumeAndSimilars'
import BetterInput from '@/components/BetterInput'
import LoadingOverlay from '@/components/LoadingOverlay'
import {GENDER_ENUM} from '../utils/enums'
import { filtersToFiltersEventObject } from '../utils'

export default {
  name: 'ExploreByName',
  metaInfo(){
    return {
      title: this.title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.description
      },{
      vmid: 'og:title', name: 'og:title',
      content:`Wikiparfum - ${this.title}`
    },{
      vmid: 'og:description', name: 'og:description',
      content: this.description
    },{
      vmid: 'twitter:title', name: 'twitter:title',
      content:`Wikiparfum - ${this.title}`

    },{
      vmid: 'twitter:description', name: 'twitter:description',
      content: this.description
    }]
    }
  },
  components: {
    PerfumeListItem,
    ListFilters,
    PerfumeAndSimilars,
    BetterInput,
    LoadingOverlay
  }, 
  mounted () {
    this.$nextTick(()=>{
      if(!this.selection) this.$refs.searchInput.$el.focus()
    })
    this.eventTracker.emitEvent('VIEW_OPENED', { type: 'EXPLORE_BY_PERFUMES' })
  },
  data (){
    return {
      query: null,
      selection: null,
      filterSelection: null,
      showSimilar: false,
      selectedSimilar: null,
      loading: false,
      filterDefs: [
        {
          id: 'family',
          filterLabel: 'filter_by_family',
          type: 'options',
          keyPath: 'family.id',
          labelPath: 'family.name',
          female:true
        },
        {
          id: 'gender',
          filterLabel: 'filter_by_gender',
          type: 'options',
          fixedOptions:[{value:GENDER_ENUM.FEMALE,customLabel:'for_her'},{value:GENDER_ENUM.MALE,customLabel:'for_him'},{value:GENDER_ENUM.UNISEX,customLabel:'unisex'}],
          keyPath: 'gender',
          validation:(value,object) => value.indexOf(object)>=0
        }
      ]
    }
  },
  lazyData: {
    selection: {type: 'perfume'}
  },
  routerLinkedData: ['selection','selectedSimilar','showSimilar', 'query'],
  // routerLinkedData:{
  //   selection:{
  //     getter () {
  //       return this.selection?this.selection.id:null
  //     },
  //     setter (id){
  //       // if(this.results) this.selection = this.results.find((p)=>p.id == id)
  //       this.selection = id
  //     }
  //   }
  // }
  computed:{
    title(){
      return this.selection && this.selection.name ? ` Information for the fragrance ${this.selection.name}` : 'Find your perfume'
    },
    description(){
      return this.selection && this.selection.ingredientProportions ? 
      `Information for ${this.selection.name}. Discover more details about the fragrance such as olfactive and sub-olfactive family, ingredients (${this.selection.ingredientProportions.slice(0,5).map(e => e.ingredient.name).join(', ')}), description and perfumer.` 
      : 'Search for any perfume by brand name or perfume name. Wikiparfum will display a list of perfume matches and enable you to find perfumes with similar olfactive family and ingredients.'
    },
    filteredResults (){
      // return this.results && applyFilters(this.results,this.filterSelection) || []
      if(this.results) {
        this.eventTracker.emitEvent('SEARCH_PERFUMES', {
          type: "TEXT",
          data: {
            queryString: this.query,
          },
          filter: this.filterSelection ? filtersToFiltersEventObject(this.filterSelection) : {},
          topResults: this.results.map(({ id }) => id)
        })
      }
      return applyFilters(this.results,this.filterSelection) || []
    }
  },
  asyncComputed:{
    results () {
      if(this.$store.state.i18n.lang) Function.prototype // noop
      if(this.query){
        // const query = this.query //saved query string because, in async function, this.query can change
        this.loading = true
        return searchByName(this.query).then((response)=>{
          this.loading = false
          return response
        })
      } else {
        return null
      }
    }
  },
  methods:{
  }

}
</script>
<style scoped lang="stylus">
.explore-by-name
  background: #fff
  display: flex
  flex-direction: column
  overflow: hidden
  position: relative

  .explore-header
    align-items: center
    background-color: #fff
    border-bottom: 1px solid rgba(#000, 0.15)
    display: flex
    justify-content: space-between
    padding: vh(10px) 5vw
    position: relative
    width: 100%

    .mobile &
      align-items: flex-start
      flex-direction: column
      margin: 0
      padding: 0

      // un poco guarro, pero parece que es batante "especial" este caso...
      input
        margin: vw(5px) 0
        width: 100%

    &.floating
      border: solid 1px #ddd
      left: 50%
      padding: vh(10px) 0
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)
      width: 60%
      z-index: 1

      .mobile &
        width: 90%

    // MENUDO LIO, els list-filters canvien de lloc segons isMobile o no...
    .list-filters
      flex: 1 1 auto
      justify-content: space-evenly

    input
      outline: none

  .search-input
    appearance: none
    background-image: url("~@/assets/icons/icon-search.svg")
    background-position: vw(15px) center
    background-repeat: no-repeat
    background-size: vw(20px) vh(30px)
    border: none
    border-radius: 0
    m-font("Lelo", "medium")
    m-font-size(14, 20)
    m-letter-spacing(15)
    outline: none
    padding: vw(5px) vw(10px) vw(5px) vw(50px)
    width: 50%

  .content-wrapper
    flex: 1 1 0
    height: 100%
    overflow: hidden
    position: relative

    // MENUDO LIO, els list-filters canvien de lloc segons isMobile o no...
    .mobile &
      display: flex
      flex-direction: column

      .list-filters
        border-bottom: 1px solid rgba(#000, 0.15)
        padding: vw(10px) 0
        width: 100%

        >>> .filter
          flex: 0 0 50%
          overflow: hidden
          padding: 0 vw(10px)

          &:first-child
            padding-right: vw(20px)

          .dropdown-button
            width: 100%

    .scroll-wrapper
      height: 100%
      overflow-y: auto

      .mobile &
        flex: 1 1 0
        height: auto

      .perfume-list
        height: 100%
        m-max-width-container(padding: 5%)

        .mobile &
          height: auto
          padding: 0

    .overlay
      display: flex
      height: 100%
      left: 0
      overflow: hidden
      position: absolute
      top: 0
      width: 100%
      z-index: 11

      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity 0.4s

      &.fade-enter,
      &.fade-leave-to
        opacity: 0

  .no-results
    m-font("Lelo", "medium")
    m-font-size(14, 14)
    opacity: 0.9
    position: absolute
    text-align: center
    top: 50%
    width: 100%

  .loading-overlay
    z-index: 1

    >>> .content
      justify-content: center
      // margin-top: vh(120px)
</style>