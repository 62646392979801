
<template>
  <Card :open="open" @close="close">
    <template v-slot:title v-if="!finishForgotPassword">
      <div class="title">{{t('forgot_password')}}</div>
    </template>
    <template v-slot:title v-else>
      <div class="title">{{t("check_your_email")}}</div>
    </template>
      
    <template v-slot:container v-if="!finishForgotPassword" >    
        <div class="sub-title">{{t('forgotten_password_sub_title')}}</div>
        <div class="input-container">
        <Input
          class="input"
          :value="email"
          @input="$emit('update:email',$event)"
          :placeholder="t('email_address')"
        />
        <SquareButton :disabled="loading" @click="resetPassword">{{t('reset_password')}}</SquareButton>
        </div>
    </template>
    <template v-slot:container v-else>
      <div class="sub-title">{{t("send_link")}}</div>
      <div @click="resetPassword" class="sub-title-grey">{{t("havent_receive")}}</div>
  </template>
  </Card>
</template>

<script>
import Card from '@/components/Card'
import Input from "@/components/Login/Input";
import SquareButton from '@/components/SquareButton'
import {resetPassword} from '@/services/Api'
export default {
  name: "ForgotPassword",
  props: {
    email: {
      type: String
    },
    open:{
      type:Boolean,
      required:true
    }
  },
  components: {
    Card,
    Input,
    SquareButton
  },
  data() {
    return {
      finishForgotPassword: false,
      loading:false
    };
  },
  methods:{
    resetPassword(){
      this.loading = true
      resetPassword(this.email).then(()=>{
        this.loading = false
        this.finishForgotPassword = true
      })
    },
    close(){
      this.finishForgotPassword = false
      this.$emit('close')
    }
  }
};
</script>
<style lang="stylus" scoped>
.input {
  margin-bottom vh(50px)
}
.input-container {
  display flex
  flex-direction column
}
.title {
  text-align: center;
  m-font-size(32, 38);
}

.sub-title {
  text-align: center;
  m-font-size(20, 24);
  margin-bottom: vh(40px);
}

.sub-title-grey {
  margin-top: vh(100px);
  color: #7b7b7b;
  m-font-size(13, 15);
  margin-top: vh(60px);
  cursor: pointer;
  text-align: center;
}

</style>