<template>
  <div class="cloud-perfume-tooltip">
    <div class="wrapper">
      <div class="content">
        <div v-if="$asyncComputed.data.updating" class="loading">LOADING</div>
        <div v-else class="perfume-info">
          <div class="image" :style="{backgroundImage: `url(${data.imageUrl})`}" />
          <div class="brand">{{data.brand.name}}</div>
          <div class="name">{{ data.name }}</div>
          <families-with-icon :family="data.family" :subfamily="data.secondaryFamily"></families-with-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getPerfume} from '@/services/Api'
import FamiliesWithIcon from '../FamiliesWithIcon.vue'
export default {
  components: { FamiliesWithIcon },
  props: ['metaData'],
  asyncComputed: {
    data() {
      return getPerfume(this.metaData.id)
    },
  },
}
</script>

<style lang="stylus" scoped>
.cloud-perfume-tooltip
  position: absolute

  .wrapper
    position: absolute
    
    filter: drop-shadow(5px 5px 8px rgba(0,0,0,0.2));
    transform: translate(5px, calc(-100% + -5px))

    .content
      padding: 20px
      background: #fff
      border-radius vw(10px)

      .name
        margin: 10px

      .image
        width: vw(100px)
        height: vw(100px)
        background-position: center
        background-size: contain
        background-repeat: no-repeat
</style>