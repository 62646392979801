<template>
  <div class="contact">
    <div class="title">{{t('contact_us')}}</div>
    <input v-model="form.email" :disabled="state.success" :placeholder="t('email_address')+' *'" />
    <input v-model="form.name" :disabled="state.success" :placeholder="t('name')+' *'" />
    <div class="message-title">{{t("contact_us_message_title")}}</div>
    <textarea v-model="form.message" :disabled="state.success" class="message" rows="4" cols="50" />
    <div class="warning" v-if="state.missingFields">{{t('missing_fields')}}</div>
    <div class="warning" v-if="state.error">{{t('contact_error')}}</div>
    <div class="button-wrapper">
      <SquareButton
        v-if="!state.success"
        :disabled="state.loading"
        @click="submit"
      >{{t('contact_send')}}</SquareButton>
      <div v-else class="success-message" v-html="t('contact_success')"></div>
    </div>
  </div>
</template>
<script>
import SquareButton from '@/components/SquareButton'
import {sendContactMessage} from '@/services/Api'
export default {
  name: "ContactUs",
  metaInfo: {
    title: 'Contact us',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'A contact form where you can share your ideas and let us know what you think of Wikiparfum.'
    }]
  }, 
  components:{
    SquareButton
  },
  mounted(){
    this.eventTracker.emitEvent('VIEW_OPENED', { type: "CONTACT_US" })
  },
  data(){
    return {
      state:{
        missingFields: false,
        loading: false,
        success: false,
        error: false
      },
      form: {
        email: null,
        name: null,
        message: null
      }
    }
  },
  methods:{
    submit () {
      //VErY WrONG to store the password as plain text...
      this.state.missingFields = false
      if(!this.form.name || !this.form.email){
        this.state.missingFields = true
      } else {
        this.state.loading = true
        sendContactMessage(this.form).then(()=>{
          console.log("SUCCESS")
          this.state.loading = false
          this.state.success = true
        }).catch(()=>{
          this.state.loading = false
          this.state.error = true
        })
      }
    }
  }
}
</script>
<style scoped lang="stylus">
$width = vw(500px)

.contact
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center

  .mobile &
    padding: vw(40px)

  .title
    m-first-letter-case()
    m-font("Lelo", "light")
    m-font-size(22, 26)
    margin-bottom: vh(40px)

    .mobile &
      text-align: center

  input
    border: none
    border-bottom: 1px solid rgba(#000, 0.15)
    m-font("Lelo", "light")
    m-font-size(15, 18)
    margin-bottom: vh(30px)
    outline: none
    padding: vh(10px) 0
    width: $width

    .mobile &
      padding: vh(20px) 0
      width: 100%

  .message-title
    color: grey
    m-first-letter-case()
    m-font("Lelo", "light")
    m-font-size(15, 18)
    margin-bottom: vh(10px)
    text-align: left
    width: $width

    .mobile &
      margin-bottom: vh(20px)
      width: 100%

  .message
    border: 1px solid rgba(#000, 0.15)
    height: vh(150px)
    m-font("Lelo", "light")
    m-font-size(15, 18)
    margin-bottom: vh(30px)
    outline: none
    padding: vw(10px)
    resize: none
    width: $width

    .mobile &
      width: 100%

  .button-wrapper
    align-items: center
    display: flex
    margin-bottom: vh(40px)
    width: $width

    .mobile &
      width: 100%

  .btn
    width: $width

    .mobile &
      width: 100%

  .success-message
    m-font("Lelo", "light")
    m-font-size(20, 24)
    text-align: center
    width: $width

    .mobile &
      width: 100%

  .warning
    color: #f00
    m-first-letter-case()
    m-font("Lelo", "light")
    m-font-size(15, 18)
    margin-bottom: vh(20px)
    margin-top: vh(-20px)
    width: $width

    .mobile &
      width: 100%

    &.error
      color: #f00

  .already
    m-font("Lelo", "light")
    m-font-size(15, 18)

    > a
      display: inline-block
      m-first-letter-case()
      text-decoration: underline
</style>