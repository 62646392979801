<template>
    <div @click="$emit('click',$event)" class="social-button" :class="{[icon]:true}">
        <div class="icon" :class="{['icon-'+icon]:true}"></div>
        <span class="text"><slot/></span>
    </div>
</template>

<script>
export default {
    props:['icon']
}
</script>
<style lang="stylus" scoped>
.google
    background-color #dd4b39
    color white
.facebook
    background-color #2d4373
    border-color blue
    color white
    
.social-button
    border 1px solid rgba(0, 0, 0, 0)
    border-radius 5px
    display flex
    align-items center
    m-font("Lelo", "light")
    m-font-size(16, 24)
    padding vh(5px) vh(20px)
    text-align center
    &:hover
        cursor pointer
    .icon
        background-position center
        background-repeat: no-repeat
        width vw(30px)
        height vw(30px)
        //margin 0 auto
    .text
        margin auto
    .icon-google
        background-image url('~@/assets/icons/icon-google-plus.svg')
    .icon-twitter
        background-image url('~@/assets/icons/icon-twitter.svg')
    .icon-facebook
        background-image url('~@/assets/icons/icon-facebook-white.svg')
</style>