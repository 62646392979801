export function pickTextColorBasedOnBgColorSimple(color,simple=true, lightColor='#fff', darkColor='#000',) {
  var r = hexToR(color);
  var g = hexToG(color);
  var b = hexToB(color);
  if (simple) {
    return ((r * 0.299 + g * 0.587 + b * 0.114) > 186) ?
      '#000000' : '#ffffff';
  } // else complex formula
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((c) => {
    if (c <= 0.03928) {
      return c / 12.92;
    } else {
      return Math.pow((c + 0.055) / 1.055,2.4);
    }
  });

  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  //console.log(L);
  return (L > 0.179) ? darkColor : lightColor;
}
var cutHex= function(h) {
  return (h.charAt(0) == "#") ? h.substring(1, 7) : h
};
var hexToR= function(h) {
  return parseInt((cutHex(h)).substring(0, 2),16)
};
var hexToG= function(h) {
  return parseInt((cutHex(h)).substring(2, 4),16)
};
var hexToB= function(h) {
  return parseInt((cutHex(h)).substring(4, 6),16)
};