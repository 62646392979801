<template>
  <div class="add-to-favs-button" @click="onClick">
    <div :class="{icon: true, faved:isFav}"></div>
    <div class="label">{{isFav?t('remove_from_favs'):t('add_to_favs')}}</div>
  </div>
</template>

<script>
export default {
  name: 'AddToFavsButton',
  components:{
  },
  props: ['data'],
  computed: {
    isFav () {
      return this.$store.state.session.favorites.findIndex((f)=>f === this.data.id)>=0
    }
  },
  methods:{
    onClick () {
      
      // con preprofile hay que hacer esto
      // if(this.$store.state.session.meta) this.$store.dispatch('toggleFav',this.data)
      // else this.$router.push({ path: '/profile', query:{mode:'adding', currentFav:this.data.id}})
      
      // sin preprofile lo añadimos "tal qual"
      this.$store.dispatch('toggleFav', { ...this.data, eventTracker: this.eventTracker })
    }
  }
}
</script>

<style scoped lang="stylus">
.add-to-favs-button
  align-items: center
  cursor: pointer
  display: flex
  flex-direction: column
  justify-content: center

  .icon
    m-icon("add", 30)
    margin-bottom: vh(10px)

    &.faved
      m-icon("remove", 30)

  .label
    m-font("Lelo", "medium")
    m-font-size(10, 12)
    text-align: center
    text-transform: uppercase
</style>