<template>
  <div itemscope itemtype="http://schema.org/Product"  v-if="data" class="perfume-card" @click="$emit('click',data)">
    <div class="image left" :style="{backgroundImage: `url(${data.imageUrl})`}"></div>
    <div class="right">
        <FamiliesWithIcon itemprop="additionalProperty" :family="data.family" :subfamily="data.secondaryFamily"></FamiliesWithIcon>
        <div itemprop="name" class="name">{{data.name}}</div>
        <div itemprop="brand" class="brand">{{data.brand.name}}</div>
        <!--<SquareButton @click="()=> $emit('click')">{{t("show_similar")}}</SquareButton>-->
    </div>
  </div>
</template>

<script>
import FamiliesWithIcon from '@/components/FamiliesWithIcon'
//import SquareButton from '@/components/SquareButton'
export default {
  name: 'IconicPerfumeCard',
  components:{
    FamiliesWithIcon,
    //SquareButton
  },
  props: ['data'],
  methods:{
  }
}
</script>

<style scoped lang="stylus">
.perfume-card
  align-items: center
  background-color: #fff
  cursor: pointer
  display: flex
  overflow: hidden
  padding: vw(10px)
  .left 
    flex 1 1 30%
    margin-right vw(10px)
  .right 
    flex 1 1 70%
    margin-left vw(10px)
  .image
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    width: vw(100px)
    height vh(150px)
    .mobile &
      margin: vh(40px) 0

  .name
    m-ellipsis(100%)
    font-weight: 500
    m-ellipsis(100%)
    m-font-size(14, 20)
    margin vh(5px) 0
  .brand
    m-font("Lelo", "light")
    m-ellipsis(100%)
    m-font-size(11, 13)
    
  .family-and-icon
    width: 100%
    margin-bottom vh(10px)
  .btn
    margin-top vh(20px)
    padding vh(10px) 0
</style>