<template>
  <div class="how-container">
    <div class="how-content">
      <div class="background top-left bg_image_top" />
      <div class="background bottom-right bg_image_bottom" />

      <div class="how-section purpose">
        <div class="centered-content">
          <div class="title left" v-html="t('what_is_our_purpose')"></div>
          <div class="steps right">
            <div class="step-number">1</div>
            <div class="step-title" v-html="t('make_the_choosing')"></div>
            <div class="step-number">2</div>
            <div class="step-title" v-html="t('bring_the_craft')"></div>
            <div class="step-text" v-html="t('the_starting_point_was')"></div>
          </div>
        </div>
      </div>

      <div class="how-section image-and-text align-right">
        <div class="centered-content">
          <div class="left">
            <div class="title" v-html="t('how_does_it_work')"></div>
            <div class="text" v-html="t('the_quadrification_shows')"></div>
          </div>
          <div class="right">
            <div class="image" :class="'image_01'"></div>
          </div>
        </div>
      </div>

      <div class="how-section image-and-text align-left">
        <div class="centered-content">
          <div class="left">
            <div class="image" :class="'image_02'"></div>
          </div>
          <div class="right">
            <div class="sub-title" v-html="t('q_and_a')"></div>
            <div class="sub-text" v-html="t('are_there_more_ingredients')"></div>
            <div class="sub-text" v-html="t('are_all_ingredients_the_same')"></div>
          </div>
        </div>
      </div>

      <div class="how-section image-and-text">
        <div class="centered-content">
          <div class="left">
            <div class="text" v-html="t('expret_reviewed_means')"></div>
          </div>
          <div class="right">
            <div class="image" :class="'image_03'"></div>
          </div>
        </div>
      </div>

      <div class="how-section image-and-text">
        <div class="centered-content">
          <div class="left">
            <div class="image" :class="'image_04'"></div>
          </div>
          <div class="right">
            <div class="text" v-html="t('this_field_shows_the_scent')"></div>
          </div>
        </div>
      </div>

      <div class="how-section quads">
        <div class="centered-content">
          <div class="quad title-quad">
            <div class="title" v-html="t('an_independent_perfumistic')"></div>
          </div>
          <div class="quad text-quad">
            <div class="title" v-html="t('the_recomendations_are_100')"></div>
            <div class="content" v-html="t('scents_are_recomended')"></div>
            <div class="content" v-html="t('scents_are_recomended_second_part')"></div>
          </div>
          <div class="quad text-quad">
            <div class="title" v-html="t('hundredpercent_perfumistic_point_of_view')"></div>
            <div class="content" v-html="t('perfumes_are_the_heart')"></div>
          </div>

          <div class="quad text-quad">
            <div class="title" v-html="t('partnerships')"></div>
            <div class="content" v-html="t('we_have_collaborated')"></div>
          </div>
        </div>
      </div>

      <div class="how-section qa">
        <div class="centered-content">
          <div class="title" v-html="t('q_and_a')"></div>
          <!--<div class="row">
            <div class="row-title" v-html="t('what_about_the_ideas')"></div>
            <div class="row-content" v-html="t('yes_as_much_as_language')"></div>
          </div>-->
          <div class="row">
            <div class="row-title" v-html="t('is_the_visualization')"></div>
            <div class="row-content" v-html="t('as_much_as_it_can_be')"></div>
          </div>
          <!--<div class="row">
            <div class="row-title" v-html="t('do_users_have_a_say')"></div>
            <div class="row-content" v-html="t('we_gather_user_ratings')"></div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="close-btn" @click="$router.go(-1)"></div>
  </div>
</template>

<script>
// import bg_image_bottom from '@/assets/images/how-to/bg_image_bottom.png'
// import bg_image_top from '@/assets/images/how-to/bg_image_top.png'
// import image_01 from '@/assets/images/how-to/image_01.jpg'
// import image_02 from '@/assets/images/how-to/image_02.jpg'
// import image_03 from '@/assets/images/how-to/image_03.jpg'
// import image_04 from '@/assets/images/how-to/image_04.jpg'
export default {
  metaInfo: {
    title: 'How does it work',
    meta:[{
      vmid: 'description',
      name: 'description',
      content: 'A quick introduction into the world of fragrances and how Wikiparfum enables you to explorer this wonderful world of perfumes.'
    }]
  },
  mounted(){
    this.eventTracker.emitEvent('VIEW_OPENED', { type: "HELP" })
  }
}
</script>

<style scoped lang="stylus">
.how-container
  overflow-x: hidden !important
  overflow-y: auto !important
  position: relative

  .image_01
    background-image: url("~@/assets/images/how-to/image_01.png")

  .image_02
    background-image: url("~@/assets/images/how-to/image_02.png")

  .image_03
    background-image: url("~@/assets/images/how-to/image_03.png")

  .image_04
    background-image: url("~@/assets/images/how-to/image_04.png")

  .bg_image_bottom
    background-image: url("~@/assets/images/how-to/bg_image_bottom.png")

  .bg_image_top
    background-image: url("~@/assets/images/how-to/bg_image_top.png")

  .left
    padding-right: vw(20px)

  .right
    padding-left: vw(20px)

  .title
    m-font-size(38, 54)
    margin-bottom: vw(40px)

    .mobile &
      m-font-size(30, 40)
      margin-bottom: vw(20px)

  .step-title
    m-font-size(20, 30)
    m-letter-spacing(50)
    margin-bottom: vh(20px)
    text-transform: uppercase

    .mobile &
      margin-bottom: vh(40px)

  .sub-title
    m-font-size(14, 24)
    margin-bottom: vh(20px)

    &:after
      background-color: #000
      content: ""
      display: block
      height: 1px
      margin-top: vh(10px)
      width: vw(75px)

  .step-text,
  .sub-text,
  .text
    m-font-size(14, 24)

  .step-number
    m-font-size(16)
    margin-bottom: vh(10px)

    &:after
      background-color: #000
      content: ""
      display: inline-block
      height: 1px
      margin-left: vw(5px)
      width: vw(75px)

  .how-content
    min-height: 100%
    position: relative

    .mobile &
      overflow: hidden
      padding: vw(20px)

    .background
      background-color: #fff
      background-position: center center
      background-repeat: no-repeat
      background-size: contain
      position: absolute

      &.top-left
        height: 150vh
        left: -50%
        top: -11%
        width: 100%

        .mobile &
          top: -8%

      &.bottom-right
        background-position: center 25vh
        bottom: 0
        height: 170vh
        right: -50%
        width: 100%

        .mobile &
          bottom: -5%

    .how-section
      align-items: center
      display: flex
      justify-content: center
      margin: vh(20px) 0
      position: relative
      width: 100%

      .mobile &
        margin-bottom: 0
        margin-top: vh(80px)

      .centered-content
        align-items: center
        display: flex
        justify-content: center
        text-align: left
        text-shadow: 0 1px 20px #fff
        width: 80%

        .mobile &
          flex-direction: column
          width: 100% !important

        @media only screen and (min-width: 1440px)
          width: 60%

      .left
        .mobile &
          padding: 0
          width: 100%

      .right
        .mobile &
          padding: 0
          width: 100%

      &.purpose
        margin-top: vh(80px)
        margin-bottom: vh(80px)

        // .mobile &
        // margin-top: 0
        .title
          align-self: flex-start

          .mobile &
            margin-bottom: vh(80px)

        .left
          flex: 1 1 40%

        .right
          flex: 1 1 60%

      &.image-and-text
        &.align-left
          justify-content: flex-start

          .centered-content
            width: 80%

          .left
            padding-left: 10%

            .mobile &
              padding-left: 0

            @media only screen and (min-width: 1440px)
              padding-left: 20%

        &.align-right
          justify-content: flex-end

          .centered-content
            width: 80%

          .right
            padding-right: 10%

            .mobile &
              padding-right: 0

            @media only screen and (min-width: 1440px)
              padding-right: 20%

        .left,
        .right
          flex: 1 1 50%

          .image
            .mobile &
              background-position: center center

        .left
          .image
            background-position: left center

        .right
          .image
            background-position: right center

        .image
          background-position: center center
          background-repeat: no-repeat
          background-size: contain
          height: 40vh

          &.large
            height: 60vh

      &.quads
        margin-top: vh(80px)

        // .mobile &
        // margin-top: 0
        .centered-content
          align-items: normal
          flex-wrap: wrap

        .quad
          flex: 1 1 50%
          margin-bottom: vh(40px)

          &.title-quad
            .title
              m-font-size(38, 54)

          &.text-quad
            padding-left: vw(20px)
            padding-right: vw(40px)
            position: relative

            &:before
              background-color: rgba(#000, 0.25)
              content: ""
              display: block
              height: 100%
              left: 0
              position: absolute
              top: 0
              width: 1px

            .title
              m-font-size(22, 32)
              margin-bottom: vh(10px)

            .content
              m-font-size(14, 24)

      &.qa
        .centered-content
          align-items: normal
          flex-direction: column

        .row
          display: flex
          margin-bottom: vh(40px)

          .mobile &
            flex-direction: column

          .row-title
            m-font-size(20, 28)
            flex: 0 0 30%
            padding-right: vw(20px)

            &:before
              background-color: rgba(#000, 0.25)
              content: ""
              display: block
              height: 1px
              margin-bottom: vh(10px)
              width: vw(75px)

              .mobile &
                margin-bottom: vh(20px)

            .mobile &
              margin-bottom: vh(20px)
              width: 100%

          .row-content
            flex: 0 0 70%

  .close-btn
    cursor: pointer
    m-icon("close", 30)
    position: fixed
    right: vw(80px)
    top: "calc(%s + %s)" % ($header-height vh(30px))

.mobile
  .how-container
    .how-section
      &.purpose
        margin-top: vh(20px)

      &.image-and-text
        &.align-left,
        &.align-right
          justify-content: center

        .image
          margin-bottom: vh(30px)
          margin-top: vh(30px)

        .left,
        .right
          padding: 0
          width: 100%

      &.qa
        .row
          .row-title,
          .row-content
            flex: 0 0 50%

      .centered-content
        flex-direction: column
        width: 90% !important

  .close-btn
    m-icon("close", 20)
    right: vw(12px)
    top: $header-height
</style>