<template>
  <div class="concepts-filter">

    <div class="toggle" :class="{open}" @click="open=!open">
      <div class="icon"></div>
      <div class="text">{{open?t("close"):t("filters")}}</div>
    </div>
    <transition name="fade">
      <div class="options" v-if="open">
        <div class="options-content">
          <div v-for="category in categories" :key="category">
            <checkbox class="option" :value="isCategorySelected(category)" @input="()=>handleClickCategory(category)" :label="category" ></checkbox>
          </div>
        </div>
      </div>

    </transition>

  </div>
</template>

<script>
import Checkbox from '../Checkbox.vue'
export default {
  props:{
    concepts:{type:Array, required:true},
    selectedCategories:{type:Array, required:true}
    // selected:{type:Array, default:()=>[] },
  },
  model:{
    prop: 'selectedCategories',
    event: 'change'
  },
  components:{
    Checkbox
  },
  data(){
    return {
      open:true
    }
  },
  
  computed:{
    internalSelection:{
      get(){
        return this.selectedCategories
      },
      set(val){
        this.$emit('change',val)
      }
    },
    categories(){ 
      const duplicateCategories = this.concepts.reduce((acc, curr) => [...acc, ...curr.categories.map(c => c.name)], [])
      return duplicateCategories.filter((c, index) => duplicateCategories.indexOf(c) === index)
    },
    // toggleText(){
    //   if(this.internalSelection.length>0) return `${this.internalSelection.length} ${this.internalSelection.length===1 ? this.t('category'):this.t('categories')}`
    //   return this.t('all_cats')
    // },
    filteredConcepts(){
      const result = this.internalSelection.length===0 
        ? this.concepts 
        : this.concepts.filter(concept => this.internalSelection.includes(concept.categories))
      
      return this.search ? result.filter(concept => concept.label.toLowerCase().indexOf(this.search.toLowerCase())>-1) : result
    },
  },
  methods:{
    handleClickCategory(category){
      if(this.isCategorySelected(category)){
        this.internalSelection.splice(this.internalSelection.indexOf(category),1)
      } else {
        this.internalSelection.push(category)
      }
    },
    isCategorySelected(category){
      return this.internalSelection.includes(category)
    },
  },
  watch:{
    open(){
      this.internalSelection = []
    }
  }
}
</script>

<style lang="stylus" scoped>
.concepts-filter
  .toggle
    align-items: center
    background-color: #fff
    border: solid 1px #ddd
    border-radius: vw(20px)
    cursor: pointer
    display: flex
    display: flex
    height: vw(40px)
    padding: vw(10px) vw(20px)
    position: absolute
    text-transform: uppercase
    z-index: 2

    &.open
      .icon
        m-icon("close", 14)

    .icon
      m-icon("filters", 16)
      margin-right: vw(10px)

    .text
      m-font-size(13, 22)
      margin-top: 0.25em

  .options
    // pointer-events none
    background: #fff
    border: solid 1px #ddd
    border-radius: vw(20px)
    max-height: vh(300px)
    overflow: hidden
    padding: vw(15px)
    position: absolute
    top: vw(45px)
    width: vw(220px)
    z-index: 1

    .options-content
      .option
        padding-left: vw(25px)
        text-transform: uppercase
        white-space: nowrap

    &.fade-enter-active
      transition: all 0.4s

    &.fade-leave-active
      transition: all 0.4s
      // transition-delay: 0.3s

    &.fade-enter,
    &.fade-leave-to
      max-height: 0
      opacity: 0
      top: vw(20px)
      width: vw(100px)
      // pointer-events all
</style>