<template>
  <div class="ingredient-chapter">
    <div class="background"></div> 
    <div class="scroll-wrapper" ref="scrollWrapper">
      <div class="content">
        <div class="close-btn" @click="doClose"></div>
        <template v-if="loaded">
          <div class="subsection main">
            <div class="info">
              <FamiliesWithIcon :family="data.family" :subfamily="data.secondaryFamily"></FamiliesWithIcon>
              <div class="ing-title">{{data.name}}</div>
              <div class="ing-description">{{data.description}}</div>
              <div v-if="data.type || data.parts || data.extraction" class="props">
                <div v-if="data.type " class="prop">
                  <div class="title">{{t("ingredient_type")}}</div>
                  <div class="value">{{data.type}}</div>
                </div>
                <div v-if="data.parts" class="prop">
                  <div class="title">{{t("ingredient_parts")}}</div>
                  <div class="value">{{data.parts}} </div>
                </div>
                <div v-if="data.extraction " class="prop">
                  <div class="title">{{t("ingredient_extraction")}}</div>
                  <div class="value">{{data.extraction}}</div>
                </div> 
              </div>
            </div>
            
          </div>
          <!-- HISTORY -->
          <div v-if="data.history || data.imageUrlM || data.lightImageUrl" class="subsection history-subsection">
            <div class="image-wrapper">
                <img class="img" :src="data.lightImageUrlM || data.lightImageUrl || data.imageUrlM || data.imageUrl" :alt="data.name">
              </div>
            <div v-if="data.history"  class=" element history">
              <div class="title">{{t("history")}}</div>
              <div class="subsection-content" v-html="data.history">
              </div>
            </div>
          </div>
          <!-- IMAGE -->
           <div v-if="data.otherImageUrlL || data.otherImageUrl" class="subsection image">
            <img :src="data.otherImageUrlL || data.otherImageUrl" :alt="data.name" >
          </div>
          <!-- PRODUCTION -->
          <div v-if="data.production" class="subsection descs">
            <div class="element production">
              <div class="title">{{t("production")}}</div>
              <div class="subsection-content" v-html="data.production">
            </div>
            </div>
          </div>
          <!-- IMAGE -->
          <div v-if="(data.detailImageUrl1M || data.detailImageUrl1) || (data.detailImageUrl2M || data.detailImageUrl2)" class="subsection image">
            <img v-if="data.detailImageUrl1M || data.detailImageUrl1" :src="data.detailImageUrl1M || data.detailImageUrl1" :alt="data.name" >
            <img v-if="data.detailImageUrl2M || data.detailImageUrl2" :src="data.detailImageUrl2M || data.detailImageUrl2" :alt="data.name" >
          </div>
          <!-- ICONIC FRAGRANCE -->
          <div v-if="data.iconicPerfume && data.iconicFraganceText" class="subsection descs">
           <div  class=" element iconic-fragrance">
              
              <div class="subsection-content" >
                <div class="iconic-perfume">
                  <div v-if="data.iconicPerfume" class="element iconic">
                    <div class="title">{{t("ingredient_first")}}</div>
                    <IconicPerfumeCard v-if="!isMobile" @click ="goToPerfume(iconicPerfume)" :data="iconicPerfume"></IconicPerfumeCard>      
                    <div v-else-if="iconicPerfume">
                      <PerfumeCard class="perfume-card"  :data="iconicPerfume"></PerfumeCard>      
                      <!--<SquareButton @click ="goToPerfume(iconicPerfume)">{{t("show_similar")}}</SquareButton>-->
                    </div>
                  </div>
                </div>
                <div v-if="!isMobile" class="title">{{t("iconic_fragrance")}}</div> <!-- TODO: Improve this if isMobile -->
              
                <div v-if="isMobile" class="title">{{t("iconic_fragrance")}}</div> <!-- TODO: Improve this if isMobile -->
                <div class="subsection-sub-content" v-html="data.iconicFraganceText"></div>
              </div>
            </div>
            
          </div>
          <div class="separator"> </div>
          <!-- EFFECT AND MORE INFO -->
          
          <div v-if="data.effect || data.moreInfo" class="subsection descs">
            <div  class="effect-more">
              <div v-if="data.effect" class="effect">
                <div class="title">{{t("effect")}}</div>
                <div class="subsection-content" v-html="data.effect">
                </div>
              </div>
              <div v-if="data.moreInfo" class="more-info">
                <div class="title">{{t("did_you_know")}}</div>
                <div class="subsection-content" v-html="data.didYouKnow">
                </div>
              </div> 
            </div>
          </div>
          <!-- IMAGE -->
          <div v-if="(data.surroundingsImageUrl1M || data.surroundingsImageUrl1) || (data.surroundingsImageUrl2M || data.surroundingsImageUrl2)" class="subsection image">
            <img v-if="data.surroundingsImageUrl1M || data.surroundingsImageUrl1" :src="data.surroundingsImageUrl1M || data.surroundingsImageUrl1" :alt="data.name" >
            <img v-if="data.surroundingsImageUrl2M || data.surroundingsImageUrl2" :src="data.surroundingsImageUrl2M || data.surroundingsImageUrl2" :alt="data.name" >
          </div>
          <!-- MAP -->
          <div v-if="data.origin && data.origin.length>0" class="subsection map">
            <div class="map-origin">
              <div class="title">{{t("origin")}}</div>
              <ul>
                <li class="list-element"  v-for="(e,index) of mapPoints" :key="e.name" :class="getClassPoint(index,mapPoints.length)">
                  <!--<div class="position-icon"></div>-->
                  <div class="place">{{e.name}}</div>
                </li>
              </ul>
            </div>
            <div class="world-map">
              <WorldMap :points="mapPoints"></WorldMap>
              </div>
          </div>
          <!-- RECOMMENDED PERFUMES -->
          <div class="subsection perfumes">
            <div class="header">
              <div class="title">{{t("same_ingredient")}}</div>
            </div>
            <div class="perfumes-wrapper">
              <PerfumeCard class="perfume-card" v-for="perfume in relatedPerfumes" :key="perfume.perfume.id" :data="perfume.perfume" @click="goToPerfume"></PerfumeCard>
            </div>
            <SquareButton @click="goToMorePerfumes">{{t("show_more")}}</SquareButton>
            
          </div>
          <!--  SIMILAR INGREDIENTS -->
          <div class="subsection ingredients">
            <div class="header">
              <div class="title">{{t("match_ingredient")}}</div>
              <div class="flex-grow"></div>
              <div v-if='!isMobile' class="explore" @click="goToMoreIngredients">{{t("explore_by_ingredient")}} <div class="arrow"/></div>
            </div>
            <div class="perfumes-wrapper">
              <IngredientItem class="ingredient-card" v-for="ingredient in relatedIngredients" 
              :key="ingredient.id" 
              :data="ingredient"
              :class="{clicked:ingredient.isIngredientChapter}"
              @click="setNewTarget"></IngredientItem>
            </div>
            <SquareButton v-if='isMobile' @click="goToMoreIngredients"> {{t("explore_by_ingredient")}}</SquareButton>
          </div>
          
        </template>
      </div>
    </div>
    <transition name="fade">
      <div v-if="selectedPerfume" class="overlay">
        <PerfumeAndSimilars :data="selectedPerfume" @close="selectedPerfume=null"></PerfumeAndSimilars>
      </div>
    </transition>
  </div>
</template>

<script>
import {getPerfume,getSimilarByIngredients,getRestrictedIngredients} from '@/services/Api'
import PerfumeCard from '@/components/PerfumeCard'
import IconicPerfumeCard from '@/components/IconicPerfumeCard'
import IngredientItem from '@/components/IngredientItem'
import FamiliesWithIcon from '@/components/FamiliesWithIcon'
import SquareButton from '@/components/SquareButton'
import WorldMap from '@/components/WorldMap'
import PerfumeAndSimilars from '@/components/PerfumeAndSimilars'
export default {
  name: "IngredientChapter",
  props: [],
  metaInfo(){
    return {
      title: this.title,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.description
      },{
      vmid: 'og:title', name: 'og:title',
      content:`Wikiparfum - ${this.title}`
    },{
      vmid: 'og:description', name: 'og:description',
      content: this.description
    },{
      vmid: 'twitter:title', name: 'twitter:title',
      content:`Wikiparfum - ${this.title}`

    },{
      vmid: 'twitter:description', name: 'twitter:description',
      content: this.description
    }]
    }
  },
  components: {
    PerfumeCard,
    IngredientItem,
    WorldMap,
    FamiliesWithIcon,
    SquareButton,
    PerfumeAndSimilars,
    IconicPerfumeCard
  },
  routerLinkedData: ['data','selectedPerfume'],
  lazyData: {
    data: {
      type: 'ingredientMeta',
    },
    selectedPerfume: {type: 'perfume'}
  },
  asyncComputed:{
    iconicPerfume(){
      if(this.data.iconicPerfume)
        return getPerfume(this.data.iconicPerfume)
      return Promise.resolve(null)
    },
    relatedPerfumes(){
      if(this.data.id)
        return getSimilarByIngredients([this.data]).then((response)=>{
          return response.slice(0,8)
        })
      return Promise.resolve([])
    },
    relatedIngredients () {
      if(this.data.id)
        return getRestrictedIngredients(this.data).then((response)=>{
          return response.slice(0,8)
        })
      return Promise.resolve([])
    }
  },
  computed:{
    loaded(){
      return this.data.name
    },
    title(){
      return this.data && this.data.name ? ` Information for the fragrance ${this.data.name}` : 'Raw materials'
    },
    description(){
      return this.data && this.data.description ? this.data.description : ''
    },
    mapPoints(){
      /**
       * COORS is array of undefined,undefined
       */
      return this.data.origin.map((e,index) => ({...e,color:this.getMapColor(index,this.data.origin.length), coord:[e.long,e.lat]}))
    }
  },
  methods:{
    setNewTarget (target) {
      if(target.isIngredientChapter)
        this.$router.push(`/chapter/ingredient?data=${target.id}`)
      //this.data = target.id
    },
    goToPerfume (target) {
      // console.log("target",target)
      this.selectedPerfume = target.id
    },
    goToMoreIngredients () {
      this.$router.push(`/explore/by-ingredients?selection[]=${this.data.id}`)
    },
    goToMorePerfumes () {
      this.$router.push(`/explore/by-ingredients?selection[]=${this.data.id}&query=true`)
    },
    doClose () {
      this.$router.go(-1)
    },
    
    getClassPoint(index,total){
      if(index===0) return {green:true} //green
      if(index===total-1) return {red:true} //red
      else return {blue:true}
    },
    getMapColor(index,total){
      if(index===0) return 'green' //green
      if(index===total-1) return '#fb5555' //red
      else return 'blue'
    }
  },
  watch:{
    data (data) {
      if (data._loaded) {
        this.eventTracker.emitEvent('VIEW_OPENED', { type: 'INGREDIENT_VIEWED', data: { id: data.id } })
      }
      this.$refs.scrollWrapper.scrollTop = 0
    }
  }
}
</script>

<style scoped lang="stylus">
.warn
  color: red

.ingredient-chapter
  .title
    m-font-size(20, 22)

  .background
    background-color: #fff
    height: 100%
    position: absolute
    width: 100%
    z-index: 0

  .scroll-wrapper
    height: 100%
    overflow-y: auto
    position: relative

    .content
      position: relative
      m-max-width-container(padding: 5%)
      background: #fff
      height: 100%

      .separator
        border-bottom: 2px solid rgba(0, 0, 0, 0.15)
        max-width: vw(900px)
        width: 100%

      .subsection
        .element
          .title
            margin: vh(20px) 0

        &.main
          .info
            align-items: center
            display: flex
            flex: 1 1 0%
            flex-direction: column
            padding-top: vh(100px)

            .ing-description
              margin-top: vh(30px)
              max-width: vw(550px)
              text-align: center
              m-font-size(18, 28)

              .mobile &
                padding: 0 vw(20px)

            .ing-title
              m-font-size(70, 77)
              margin: vh(20px) 0

              .mobile &
                margin: vh(30px) 0
                m-font-size(50, 60)

            .family-and-icon
              >>> .label
                m-font-size(16, 16)

            .props
              display: flex
              justify-content: space-around
              margin: vh(50px) 0
              width: 100%

              .mobile &
                display: block

              .prop
                align-items: center
                display: flex
                flex: 1 1 33%
                flex-direction: column
                margin: auto 0

                .mobile &
                  margin: vh(20px) auto

                .title
                  m-font("Lelo", "light")
                  margin-bottom: vh(5px)
                  margin-top: vh(10px)
                  m-font-size(12, 16)
                  color: #909090
                  text-transform: uppercase

                .value
                  m-font("Lelo", "light")
                  m-font-size(16, 16)
                  text-align: center

        &.image
          > img
            padding: vw(10px)
            width: 50%

            &:only-child
              width: 100%

            .mobile &
              width: 100%

        &.history-subsection
          display: flex

          .mobile &
            flex-direction: column

          .image-wrapper
            margin: auto
            width: 50%

            .mobile &
              height: vh(600px)
              width: 100%

            .img
              background-position: center
              background-repeat: no-repeat
              background-size: contain
              max-width: vw(600px)
              width: 100%

              .mobile &
                height: 100%

          .history
            height: 100%
            max-width: vw(500px)
            padding: vw(20px)
            white-space: pre-line
            width: 50%
            m-font-size(14, 24)

            .mobile &
              width: 100%

            .title
              m-font("SangBleu", "medium")
              m-font-size(24, 26)

            .subsection-content
              m-font("Lelo", "light")
              m-font-size(16, 26)

            .mobile &
              border: none
              border-bottom: $border

        &.descs
          align-items: center
          flex: 1 1 0%
          flex-direction: column
          margin-bottom: vh(30px)
          width: 100%

          .title
            .mobile &
              margin-top: vh(100px)

            m-font("SangBleu", "medium")
            m-font-size(24, 26)
            text-align: left

          .subsection-content
            m-font("Lelo", "light")
            m-font-size(16, 26)

          .production
            flex: 1 1 0%
            margin: vh(20px) auto
            max-width: vw(600px)
            max-width: vw(600px)
            width: 100%

          .iconic-fragrance
            flex: 1 1 0%
            width: 100%

            .title
              .mobile &
                margin-top: vh(100px)

              m-font("SangBleu", "medium")
              m-font-size(24, 26)
              text-align: left

            .subsection-content
              m-font("Lelo", "light")
              m-font-size(16, 26)

            .iconic-perfume
              float: left
              margin: 0 vh(20px) vh(10px) 0

              .mobile &
                float: none

              .perfume-card
                .mobile &
                  align-items: center
                  border: none
                  height: vh(800px)
                  width: 100%

              .title
                m-font-size(16, 18)
                m-font-family("SangBleu", "light")
                text-align: left

          .effect-more
            align-items: center
            display: flex
            flex-direction: column
            justify-content: space-around
            margin: auto
            max-width: vw(600px)
            width: 100%

            .mobile &
              flex-direction: column

            .effect,
            .more-info
              display: inline-block
              flex: 1 1 0%
              height: 100%

              .mobile &
                margin: vh(50px) 0

              .title
                m-font("SangBleu", "medium")
                m-font-size(24, 26)
                margin: vh(20px) 0
                // text-align center

              .subsection-content
                m-font("Lelo", "light")
                m-font-size(16, 26)
                white-space: pre-line

              .value
                m-font-size(16, 16)

            .effect
              padding-right: vw(40px)

        &.map
          height: vh(600px)
          position: relative

          .mobile &
            flex-direction: column

          .title
            m-font("SangBleu", "medium")
            m-font-size(24, 26)
            text-align: center

            .mobile &
              margin-bottom: vh(50px)

          .map-origin
            bottom: 0
            flex: 1 1 30%
            left: 0
            padding: vh(20px) vw(15px)
            position: absolute

            .mobile &
              position: relative

            ul
              .mobile &
                display: flex
                flex-wrap: wrap

            li
              .mobile &
                width: 50%

            .title
              margin-bottom: vh(20px)

            .green
              &:before
                background: url("~@/assets/icons/icon-map-macker-green.svg") no-repeat left 10px

            .blue
              &:before
                background: url("~@/assets/icons/icon-map-macker-blue.svg") no-repeat left 10px

            .red
              &:before
                background: url("~@/assets/icons/icon-map-macker-red.svg") no-repeat left 10px

            .list-element
              position: relative

              &:before
                background-position: center
                content: ""
                height: 1em
                left: 0
                margin-left: -1.5em
                position: absolute
                top: 0
                width: 1em

              .position-icon
                display: inline-block
                m-icon("map-macker", 30)
                height: vh(15px)
                width: vh(15px)

              .place
                display: inline-block

          .world-map
            height: 100%
            width: 100%

            .mobile &
              height: vh(300px)

        &.perfumes,
        &.ingredients
          border-bottom: $border
          display: block
          padding: vh(50px) 0

          .header
            display: flex
            margin-bottom: vh(30px)

            .mobile &
              margin: vh(30px) 0
              text-align: center
              width: 100%

            .flex-grow
              flex-grow: 1

            .explore
              align-items: center
              cursor: pointer
              display: flex
              m-font("Lelo", "light")
              m-font-size(14, 16)

              .arrow
                m-icon("big-arrow", 10)
                margin-left: vw(10px)

          .btn
            margin: vh(20px) auto
            width: vw(300px)

          .perfumes-wrapper
            display: flex
            flex-wrap: wrap
            justify-content: center

            .ingredient-card
              margin: vh(10px) vw(10px)
              width: vw(190px)
              &.clicked
                cursor: pointer
              .mobile &
                margin: vh(10px) auto
                width: 50%

            .perfume-card
              height: vh(300px)
              margin: vh(10px) vw(10px)
              width: vw(190px)

              .mobile &
                height: vh(400px)
                margin: vh(10px) auto
                width: 47%

  .close-btn
    cursor: pointer
    m-icon("close", 20)
    position: absolute
    right: vw(20px)
    top: vw(20px)
    transform: translateY(-50%)

  .overlay
    height: 100%
    position: absolute
    top: 0
    width: 100%

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.4s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0
</style>