var autoGenerateName = 1
/**
 * 
 * @param {Array} langs 
 */
export default function (langs, name = '_$$_VUEX_CONTAINER_' + autoGenerateName, getId) {

    const SET_OBJECTS = 'SET_OBJECT_' + name
    const SET_REQUEST = 'SET_REQUEST_' + name
    return {
        namespaced: true,
        state: {
            objects: {
                ...langs.reduce((obj, lang) => {
                    obj[lang] = {}
                    return obj
                }, {})
            },
            requests: {}
        },
        getters: {
            get: state => (lang, path = '') => {
                if (state.requests[path] && state.requests[path].every(id => state.objects[lang][id] !== undefined))
                    return state.requests[path].map(id => state.objects[lang][id])
                else
                    return null
            },
            getById: state => (lang, id) => {
                let obj = state.objects[lang][id]
                return obj ? obj : null
            }
        },
        actions: {
            save({ commit, state }, {lang, path, objects}) {
                let noCache = objects.reduce((obj, data) => {
                    let id = getId ? getId(data) : data.id
                    if (!state.objects[lang][id])
                        obj[id] = data
                    return obj
                }, {})
                if (Object.keys(noCache).length > 0)
                    commit(SET_OBJECTS, { lang, objects: noCache })
                commit(SET_REQUEST, { objects, path })
            },
            saveById({ commit }, {lang, object}) {
                let objects = {
                    [object.id]: object
                }
                commit(SET_OBJECTS, {lang, objects})
            }
        },
        mutations: {
            [SET_OBJECTS](state, { lang, objects }) {
                state.objects[lang] = {
                    ...state.objects[lang],
                    ...objects
                }
            },
            [SET_REQUEST](state, { path, objects }) {
                state.requests[path] = objects.map(e => getId ? getId(e) : e.id)
            }
        }
    }

}