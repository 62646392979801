<template>
  <div class="gender-filter">

    <div class="toggle" :class="{open:open && isMobile,bold:!open && selected && selected.length>0}" @click="open=!open">
      <div class="icon"></div>
      <div v-if="isMobile" class="text">{{open?t("close"):t('filter_by_gender')}}</div>
      <div v-else class="text">{{t('filter_by_gender')}}</div>
    </div>
    <transition name="fade">
      <div class="options" v-if="!isMobile || open">
        <div class="options-content">
          <div v-for="gender in genders" :key="gender.id">
            <checkbox class="option" :value="isSelected(gender.id)" @input="()=>handleClick(gender.id)" :label="gender.label" ></checkbox>
          </div>
        </div>
      </div>

    </transition>

  </div>
</template>

<script>
import Checkbox from '../Checkbox.vue'
export default {
  props:{
    selected:{type:Array}
    // selected:{type:Array, default:()=>[] },
  },
  model:{
    prop: 'selected',
    event: 'change'
  },
  components:{
    Checkbox
  },
  data(){
    return {
      open:true
    }
  },
  mounted(){
    if(this.isMobile)
      this.open= false
  },
  computed:{
    internalSelection:{
      get(){
        return this.selected || []
      },
      set(val){
        this.$emit('change',val)
      }
    },
    genders(){ 
      return [{
        id:'MU',
        label:this.t("for_him")
      },{
        id:'FU',
        label:this.t("for_her")
      },{
        id:'U',
        label:this.t("unisex")
      }]
    },
  },
  methods:{
    handleClick(category){
      if(this.isSelected(category)){
        this.internalSelection.splice(this.internalSelection.indexOf(category),1)
      } else {
        this.internalSelection.push(category)
      }
    },
    isSelected(category){
      return this.internalSelection.includes(category)
    },
  },
  watch:{
    open(){
      if(!this.isMobile)
        this.internalSelection = []
    },
  }
}
</script>

<style lang="stylus" scoped>
.gender-filter

  .toggle
    align-items: center
    background-color: #fff
    border: solid 1px #ddd
    border-radius: vw(20px)
    cursor: pointer
    display: flex
    display: flex
    height: vw(40px)
    width: fit-content
    padding: vw(10px) vw(20px)
    text-transform: uppercase
    z-index: 2

    &.open
      .icon
        m-icon("close", 14)

    .icon
      m-icon("filters", 16)
      margin-right: vw(10px)
    &.bold
      .text
        font-weight: bold
    .text
      m-font-size(13, 22)
      margin-top: 0.25em

  .options
    // pointer-events none
    background: #fff
    border: solid 1px #ddd
    border-radius: vw(20px)
    max-height: vh(300px)
    overflow: hidden
    padding: vw(15px)
    top: vw(45px)
    width: vw(220px)
    right: 0
    z-index: 1
    margin-top: vh(2px)
    .isMobile &
      position: absolute
      margin-top: 0


    .options-content
      .option
        padding-left: vw(25px)
        text-transform: uppercase
        white-space: nowrap

    &.fade-enter-active
      transition: all 0.4s

    &.fade-leave-active
      transition: all 0.4s
      // transition-delay: 0.3s

    &.fade-enter,
    &.fade-leave-to
      max-height: 0
      opacity: 0
      top: vw(20px)
      width: vw(100px)
      // pointer-events all
</style>