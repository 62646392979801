<template>
    <transition name='fade'>
        <div v-if="open" class="model">
            <div  class="card">
                <div class="btn-close" @click="$emit('close')" />
                <div class="card-wrapper">
                    <slot name='title'/>
                    <div class='card-container'>
                        <slot name='container'/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    name:'Card',
    props:{
        open:{type:Boolean,required:true}
    }
}
</script>
<style lang="stylus" scoped>
.model
    position fixed
    display flex
    height: 100vh
    width: 100vw
    justify-content center
.card 
  background-color: #fff;
  position: absolute;
  height: 100%;
  display: flex;

  .card-wrapper 
    position: relative;
    margin: auto;
    padding: vh(10px) vw(20px);

    .card-container 
      background-color: #fefefe;
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: vh(10px) vw(120px);
    
  

  .mobile & 
    padding: vw(40px);
  
  .btn-close 
    position: absolute;
    top: vh(100px);
    right: vw(50px);
    cursor: pointer;
    m-icon('close', 20);

    .mobile & 
      top: vh(200px);
    
  
  .btn 
    margin-bottom: vh(40px);
    width: vw(300px);

    .mobile & 
      width: 100%;
    
  




.fade-enter-active, .fade-leave-active 
    transition: opacity .5s
 
.fade-enter, .fade-leave-to 
    opacity: 0
 
  


</style>