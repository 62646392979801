import Vue from 'vue'
import Vuex from 'vuex'
import Session from './session'
import i18n from './i18n'
//import dataCache from './dataCache'
import appState from './appState'
import i18nStoreRequest from './i18nStoreRequest'
//import createLogger from 'vuex/dist/logger'
// import VuexPersist from 'vuex-persist'
import LocalStorageContainer from '../utils/LocalStorageContainer'
Vue.use(Vuex)

// const vuexPersist = new VuexPersist({
//   key: 'wikiparfum',
//   storage: window.localStorage,
//   reducer: state => {
//     // eslint-disable-next-line
//     let {dataCache, appState, ...rest} = state;
//     return rest
//   }
// })

export default new Vuex.Store({
   //plugins: [vuexPersist.plugin],
  // plugins: [createLogger()], //low performance with logger
  modules: {
    session: Session,
    i18n: i18n ,
    perfumes: i18nStoreRequest(['es','en','fr'],'PERFUMES'),
    ingredients: i18nStoreRequest(['es','en','fr'],'INGREDIENTS'),
    brands: i18nStoreRequest(['en'],'BRANDS'),
    //dataCache: dataCache,
    appState: appState
  },
  getters:{
    getCachedIngredient:(state,getters) => (id) => {
      let lang = state.i18n.lang
      return getters['ingredients/getById'](lang,id)
    },
    getCachedIngredients:(state,getters) => () => {
      let lang = state.i18n.lang
      return getters['ingredients/get'](lang)
    },
    lang:(state) => state.i18n.lang,
    profileIngredients(state,getters) {
      // let all = state.favorites.map((p)=>p.ingredientProportions.map((i)=>i.ingredient)).flat()
      // all = all.filter((p,i)=>all.findIndex((pp)=>pp.id===p.id)===i)
      // return all
      let lang = getters.lang
      let dict = state.session.favorites.map(e =>getters['perfumes/getById'](lang,e) ).filter(e => e).reduce((accum, p) => {
        p.ingredientProportions.sort((a, b) => b.proportion - a.proportion).forEach((ing) => {
          let id = ing.ingredient.id
          if (!accum[id]) accum[id] = { value: 0, ing: ing.ingredient }
          accum[id].value += ing.proportion
        })
        return accum
      }, {})
      let list = Object.values(dict).sort((a, b) => b.value - a.value)
      return list.map((pp) => pp.ing)
    },
    profileMainIngredients(state,getters) {
      let lang = getters.lang
      let dict = state.session.favorites.map(e =>getters['perfumes/getById'](lang,e) ).filter(e => e).reduce((accum, p) => {
        p.ingredientProportions.sort((a, b) => b.proportion - a.proportion).slice(0,5).forEach((ing) => {
          let id = ing.ingredient.id
          if (!accum[id]) accum[id] = { value: 0, ing: ing.ingredient }
          accum[id].value += ing.proportion
        })
        return accum
      }, {})
      let list = Object.values(dict).sort((a, b) => b.value - a.value)
      return list.map((pp) => pp.ing)
    },
    profileFamiliesWithSubs(state,getters) {
      let lang = getters.lang
      let pairs = state.session.favorites.map(e =>getters['perfumes/getById'](lang,e) ).filter(e => e).map(p => { return { secondaryFamily: p.secondaryFamily, family: p.family, value: 1 } });
      let fams = Object.values(pairs.reduce((dict, { family, secondaryFamily }) => {
        dict[family.id] = dict[family.id] || { family: family, subFamilies: [] }
        dict[family.id].subFamilies.push(secondaryFamily)
        return dict
      }, {}))
      fams.forEach((f) => {
        f.subFamilies = Object.values(f.subFamilies.reduce((dict, subFamily) => {
          dict[subFamily.id] = dict[subFamily.id] || { subFamily: subFamily, count: 0 }
          dict[subFamily.id].count += 1
          return dict
        }, {}))
        f.id = f.family.id
      })
      return fams
    },
    favorites(state,getters){
      let lang = getters.lang
      return state.session.favorites.map(e => getters['perfumes/getById'](lang,e))
    }
  },
  actions:{
    setSession({commit,dispatch,getters},value){
      let lang = getters.lang
      let favorites = value.favorites.map(e => e.id)
      dispatch('perfumes/save',{lang,objects:value.favorites})
      commit('setFav',favorites)
      commit('setMeta',value.meta)
      LocalStorageContainer.favorites = favorites //it's necessary??
    }
  }
})