const prefix = 'lazy_data_'

const isProxy = function (obj){
  return (Array.isArray(obj) && obj[0]._isProxy) || (obj._isProxy)
}
//TODO: REPENSAR LOS isArray.. (hacen que no se entienda nada...)
var LazyData = {
  install: function install(Vue, pluginOptions={}) {

    let getDefault = function (key,attr) {
      let type = this.$options.lazyData[key].type
      return (pluginOptions.types[type].default && pluginOptions.types[type].default[attr]) || null
    }
    
    let wrapInProxy = function (key, obj) {
      let lazyHandler = {
        get: (obj, attr) => {
          if(attr==='_isProxy') return !obj._loaded
          return attr in obj ?
            obj[attr] :
            getDefault.call(this,key,attr)
        },
        set: (obj, attr) => {
          return obj.attr = attr
        }
      }
      return new Proxy(obj,lazyHandler)
    }
    // Vue.config.optionMergeStrategies.routerLinkedData = Vue.config.optionMergeStrategies.computed;
    
    Vue.mixin({
      data () {
        if(!this.$options.lazyData) return {}
        return {
          // Copy the "lazyData" object, but with nulls (removing the "types")
          lazyDatas_data: {...Object.keys(this.$options.lazyData || {}).reduce((res,key)=>{
            res[key]=null 
            return res
          },{})}
        }
      },
      beforeCreate (){
        if(!this.$options.lazyData) return
        
        // Borramos del data los que esten en lazyData, si los hay
        let original_data = this.$options.data.call(this)
        Object.keys(this.$options.lazyData).forEach((ld_key)=>{
          delete original_data[ld_key]
        })
        this.$options.data = ()=>{
          return original_data
        }
        //////////////////////////////////////
        
        
        // definimos un asyncComputed y un computed para cada lazyData
        this.$options.asyncComputed = this.$options.asyncComputed || {}

        Object.keys(this.$options.lazyData).forEach((key) => {
          this.$options.asyncComputed['asc_'+prefix + key] = {
            get: () => {
              if(this.$store.state.i18n.lang) Function.prototype // noop
              if(!this.lazyDatas_data[key]) return null 
              else if(isProxy(this.lazyDatas_data[key])){
                let type = this.$options.lazyData[key].type
                if(Array.isArray(this.lazyDatas_data[key])) return Promise.all(this.lazyDatas_data[key].map((v)=>pluginOptions.types[type].lazyGetter(v.id).then((response)=>{response._loaded=true; return response})))
                else return pluginOptions.types[type].lazyGetter(this.lazyDatas_data[key].id).then((response)=>{response._loaded=true; return response})
              } else {
                return this.lazyDatas_data[key]
              }
            },
            default () {
              if(this.lazyDatas_data)
                return this.lazyDatas_data[key]
              return null
            }
          
          }
          this.$options.computed[key] = {
            get(){
              // SOLO PUEDE DEPENDER DE UNO! (que ya lleva el default dentro)
              return this['asc_'+prefix + key]
            },
            set(value){
              if(!value) this.lazyDatas_data[key] = null
              else if (Array.isArray(value)){
                // if(value[0] !== null && typeof value[0] === 'object') {
                if(value[0] !== null && value[0].id) {
                  value._loaded = true
                  this.lazyDatas_data[key] = value
                } else {
                  this.lazyDatas_data[key] = value.map((v)=>{
                    let candidate = (this.lazyDatas_data[key] || []).find((ld)=>ld.id==v.id)
                    if(candidate && candidate._loaded) return candidate
                    else return wrapInProxy.call(this, key, {id: v})
                  })
                }
              } else {
                // if(typeof value === 'object'){
                if(value.id){
                  value._loaded = true
                  this.lazyDatas_data[key] = value
                }
                else{
                  if(this.lazyDatas_data[key] !== null && this.lazyDatas_data[key]._loaded) return
                  else this.lazyDatas_data[key] = wrapInProxy.call(this, key, {id: value})
                }
              }
            }
          }
        })
        
      }
    })
  }
}

export default LazyData