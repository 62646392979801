import { rollup as d3_rollup } from 'd3-array'
import { len } from './circleUtils'
function centroid(nodes) {
  let x = 0;
  let y = 0;
  let z = 0;
  for (const d of nodes) {
    let k = d.r ** 2;
    x += d.x * k;
    y += d.y * k;
    z += k;
  }
  return { x: x / z, y: y / z };
}

export function forceCluster() {
  let strength = 0.2;
  let nodes;

  let clusterId = (n) => {
    return n.family + n.groups.join('-')
  }
  function force(alpha) {
    let activeNodes = nodes.filter((n) => n.groups.length > 0 && !n.isNew)
    const centroids = d3_rollup(activeNodes, centroid, clusterId);
    const l = alpha * strength;
    for (const d of activeNodes) {
      // if (!d.isNew) {
      const { x: cx, y: cy } = centroids.get(clusterId(d));
      d.vx -= (d.x - cx) * l;
      d.vy -= (d.y - cy) * l;
      // }
    }
  }
  force.strength = function (_) {
    return arguments.length ? (strength = +_, force) : strength;
  };
  force.clusterId = function (_) {
    return arguments.length ? (clusterId = _, force) : clusterId
  }
  force.initialize = _ => nodes = _;

  return force;
}

function constant(x) {
  return function () {
    return x;
  };
}
// export function forceY(y) {
//   var strength = constant(0.1),
//     nodes,
//     strengths,
//     yz;

//   if (typeof y !== "function") y = constant(y == null ? 0 : +y);

//   function force(alpha) {
//     for (var i = 0, n = nodes.length, node; i < n; ++i) {
//       node = nodes[i], node.vy += (yz[i] - node.y) * strengths[i] * alpha;
//     }
//   }

//   function initialize() {
//     if (!nodes) return;
//     var i, n = nodes.length;
//     strengths = new Array(n);
//     yz = new Array(n);
//     for (i = 0; i < n; ++i) {
//       strengths[i] = isNaN(yz[i] = +y(nodes[i], i, nodes)) ? 0 : +strength(nodes[i], i, nodes);
//     }
//   }

//   force.initialize = function (_) {
//     nodes = _;
//     initialize();
//   };

//   force.strength = function (_) {
//     return arguments.length ? (strength = typeof _ === "function" ? _ : constant(+_), initialize(), force) : strength;
//   };

//   force.y = function (_) {
//     return arguments.length ? (y = typeof _ === "function" ? _ : constant(+_), initialize(), force) : y;
//   };

//   return force;
// }

// export function forceX(x) {
//   var strength = constant(0.1),
//     nodes,
//     strengths,
//     xz;

//   if (typeof x !== "function") x = constant(x == null ? 0 : +x);

//   function force(alpha) {
//     for (var i = 0, n = nodes.length, node; i < n; ++i) {
//       node = nodes[i], node.vx += (xz[i] - node.x) * strengths[i] * alpha;
//     }
//   }

//   function initialize() {
//     if (!nodes) return;
//     var i, n = nodes.length;
//     strengths = new Array(n);
//     xz = new Array(n);
//     for (i = 0; i < n; ++i) {
//       strengths[i] = isNaN(xz[i] = +x(nodes[i], i, nodes)) ? 0 : +strength(nodes[i], i, nodes);
//     }
//   }

//   force.initialize = function (_) {
//     nodes = _;
//     initialize();
//   };

//   force.strength = function (_) {
//     return arguments.length ? (strength = typeof _ === "function" ? _ : constant(+_), initialize(), force) : strength;
//   };

//   force.x = function (_) {
//     return arguments.length ? (x = typeof _ === "function" ? _ : constant(+_), initialize(), force) : x;
//   };

//   return force;
// }
import { distVec } from './circleUtils'
export function forceIncludeExclude() {
  let nodes, includeStrength = constant(0.4), excludeStrength = constant(0.4)


  function force(alpha) {
    // for (var i = 0, n = nodes.length, node; i < n; ++i) {
    nodes.forEach((node) => {
      // let homeV = node.center ? distVec(node.center, node) : null
      node.excludeFrom?.forEach((g) => {
        let d = distVec(g, node)
        let l = len(d)
        let r = g.r + node.r
        if (l < r) { //&& (!node.isNew || node.includeIn.length === 0)) 
          // if (!homeV || (Math.abs(angle(d, homeV)) < Math.PI / 2)) {
          node.vx += d.x * excludeStrength(node) * 1 * alpha;
          node.vy += d.y * excludeStrength(node) * 1 * alpha;
        }
        // } else {
        //   let inv = normalize(d, 2 / (l - r))
        //   node.vx += inv.x * excludeStrength(node) * 1 * alpha;
        //   node.vy += inv.y * excludeStrength(node) * 1 * alpha;
        // }
      })
      node.includeIn?.forEach((g) => {
        let d = distVec(g, node)
        let l = len(d)
        let r = g.r - node.r // + (node.includeIn.length > 0 ? 5 : 10)
        // if (l > g.r) {

        if (l > r) { //&& (!node.isNew || node.includeIn.length === 0)) 
          // if (!homeV || (Math.abs(angle(d, homeV)) < Math.PI / 2)) {
          node.vx -= d.x * excludeStrength(node) * 1 * alpha;
          node.vy -= d.y * excludeStrength(node) * 1 * alpha;
        }
        // } else {
        //   let inv = normalize(d, 2 / (l - r))
        //   node.vx -= inv.x * excludeStrength(node) * 1 * alpha;
        //   node.vy -= inv.y * excludeStrength(node) * 1 * alpha;
        // }
      })

      //MENOS DA UNA PIEDRA... pero no va del todo bien...
      // if (alpha < 0.02) {
      //   node.includeIn?.forEach((g) => {
      //     if (dist(g, node) > g.r) {
      //       // let d = normalize(distVec(g, node), g.r / 2)
      //       // if (!homeV || (Math.abs(angle(d, homeV)) < Math.PI / 2)) {
      //       node.x = g.x //+ d.x
      //       node.y = g.y //+ d.y
      //       // }
      //     }
      //   })
      // }

    })
  }

  // function initialize() {
  //   if (!nodes) return;
  //   var i, n = nodes.length;
  //   strengths = new Array(n);
  //   yz = new Array(n);
  //   for (i = 0; i < n; ++i) {
  //     strengths[i] = isNaN(yz[i] = +y(nodes[i], i, nodes)) ? 0 : +strength(nodes[i], i, nodes);
  //   }
  // }

  force.initialize = function (_) {
    nodes = _;
    // initialize();
  };

  force.includeStrength = function (_) {
    return arguments.length ? (includeStrength = typeof _ === "function" ? _ : constant(+_), force) : includeStrength;
  }

  force.excludeStrength = function (_) {
    return arguments.length ? (excludeStrength = typeof _ === "function" ? _ : constant(+_), force) : excludeStrength;
  }
  return force
}

import { quadtree as d3_quadtree } from 'd3-quadtree'
export function rectCollide() {
  var nodes, sizes, masses
  var size = constant([0, 0])
  var strength = 1
  var iterations = 1

  function force() {
    var node, size, mass, xi, yi
    var i = -1
    while (++i < iterations) { iterate() }

    function iterate() {
      var j = -1
      var tree = d3_quadtree(nodes, xCenter, yCenter).visitAfter(prepare)

      while (++j < nodes.length) {
        node = nodes[j]
        size = sizes[j]
        mass = masses[j]
        xi = xCenter(node)
        yi = yCenter(node)

        tree.visit(apply)
      }
    }

    function apply(quad, x0, y0, x1, y1) {
      var data = quad.data
      var xSize = (size[0] + quad.size[0]) / 2
      var ySize = (size[1] + quad.size[1]) / 2
      if (data) {
        if (data.index <= node.index) { return }

        var x = xi - xCenter(data)
        var y = yi - yCenter(data)
        var xd = Math.abs(x) - xSize
        var yd = Math.abs(y) - ySize

        if (xd < 0 && yd < 0) {
          var l = Math.sqrt(x * x + y * y)
          var m = masses[data.index] / (mass + masses[data.index])

          if (Math.abs(xd) < Math.abs(yd)) {
            node.vx -= (x *= xd / l * strength) * m
            data.vx += x * (1 - m)
          } else {
            node.vy -= (y *= yd / l * strength) * m
            data.vy += y * (1 - m)
          }
        }
      }

      return x0 > xi + xSize || y0 > yi + ySize ||
        x1 < xi - xSize || y1 < yi - ySize
    }

    function prepare(quad) {
      if (quad.data) {
        quad.size = sizes[quad.data.index]
      } else {
        quad.size = [0, 0]
        var i = -1
        while (++i < 4) {
          if (quad[i] && quad[i].size) {
            quad.size[0] = Math.max(quad.size[0], quad[i].size[0])
            quad.size[1] = Math.max(quad.size[1], quad[i].size[1])
          }
        }
      }
    }
  }

  function xCenter(d) { return d.x + d.vx + sizes[d.index][0] / 2 }
  function yCenter(d) { return d.y + d.vy + sizes[d.index][1] / 2 }

  force.initialize = function (_) {
    sizes = (nodes = _).map(size)
    masses = sizes.map(function (d) { return d[0] * d[1] })
  }

  force.size = function (_) {
    return (arguments.length
      ? (size = typeof _ === 'function' ? _ : constant(_), force.initialize(nodes || []), force)
      : size)
  }

  force.strength = function (_) {
    return (arguments.length ? (strength = +_, force) : strength)
  }

  force.iterations = function (_) {
    return (arguments.length ? (iterations = +_, force) : iterations)
  }

  return force
}

///////////////////////////////
function x(d) {
  return d.x + d.vx;
}

function y(d) {
  return d.y + d.vy;
}
function jiggle(random) {
  return (random() - 0.5) * 1e-6;
}

export function forceCollideOptional(radius) {
  var nodes,
    radii,
    random,
    strength = 1,
    iterations = 1;

  if (typeof radius !== "function") radius = constant(radius == null ? 1 : +radius);

  function force() {
    var i, n = nodes.length,
      tree,
      node,
      xi,
      yi,
      ri,
      ri2;

    for (var k = 0; k < iterations; ++k) {
      tree = d3_quadtree(nodes, x, y).visitAfter(prepare);
      for (i = 0; i < n; ++i) {
        node = nodes[i];
        ri = radii[node.index], ri2 = ri * ri;
        xi = node.x + node.vx;
        yi = node.y + node.vy;
        tree.visit(apply);
      }
    }

    function apply(quad, x0, y0, x1, y1) {
      var data = quad.data, rj = quad.r, r = ri + rj;
      // r = r * (2 - alpha)
      if (data) {
        if (data.index > node.index) {
          var x = xi - data.x - data.vx,
            y = yi - data.y - data.vy,
            l = x * x + y * y;
          if (l < r * r) {
            if (x === 0) x = jiggle(random), l += x * x;
            if (y === 0) y = jiggle(random), l += y * y;
            l = (r - (l = Math.sqrt(l))) / l * strength(data, node);
            node.vx += (x *= l) * (r = (rj *= rj) / (ri2 + rj));
            node.vy += (y *= l) * r;
            data.vx -= x * (r = 1 - r);
            data.vy -= y * r;
          }
        }
        return;
      }
      return x0 > xi + r || x1 < xi - r || y0 > yi + r || y1 < yi - r;
    }
  }

  function prepare(quad) {
    if (quad.data) return quad.r = radii[quad.data.index];
    for (var i = quad.r = 0; i < 4; ++i) {
      if (quad[i] && quad[i].r > quad.r) {
        quad.r = quad[i].r;
      }
    }
  }

  function initialize() {
    if (!nodes) return;
    var i, n = nodes.length, node;
    radii = new Array(n);
    for (i = 0; i < n; ++i) node = nodes[i], radii[node.index] = +radius(node, i, nodes);
  }

  force.initialize = function (_nodes, _random) {
    nodes = _nodes;
    random = _random;
    initialize();
  };

  force.iterations = function (_) {
    return arguments.length ? (iterations = +_, force) : iterations;
  };

  force.strength = function (_) {
    return arguments.length ? (strength = typeof _ === "function" ? _ : constant(+_), force) : strength;
  };

  force.radius = function (_) {
    return arguments.length ? (radius = typeof _ === "function" ? _ : constant(+_), initialize(), force) : radius;
  };

  return force;
}