<template>
  <div class="concept-list-dialog">
    <!-- <transition name="slide"> -->
      <transition name="fade">
        <div v-if="open" class="overlay" @click="open=false"></div>
      </transition>
      <div class="wrapper" :class="{open}">
        <div class="toggle-btn" @click="open=!open">
          <div class="icon"></div>
          <div class="label">{{t('concepts_list')}}</div>
        </div>
        <div class="concept-list-dialog-container">
          <div class="concept-navigator" :class="{'select-open':isSelectOpen}" >
            <div class="header-list">
              <div class="top">
                <div class="category-list">
                  <div class="category" :class="{active:isCategorySelected(category)}" v-for="category in categories" :key="category" @click="()=>handleClickCategory(category)">
                    {{getCategoryLabel(category)}}
                  </div>
                </div>
                <div class="search">
                  <input v-model="search" :placeholder="t('find_concept')"/>
                </div>
              </div>
            </div>
            <div class="body-list">
              <!-- <div class="selected">
                <div class="element" v-for="concept in internal_selection" :key="concept.id" :class="{selected:isSelected(concept)}" @click="()=>handleClick(concept)">
                  {{concept.label}}
                </div>
              </div> -->
              <div class="related-title" v-if="internal_selection.length>0">RELEATED TO {{internal_selection.map((c)=>c.label).join(' and ')}} ({{this.relatedConcepts.length}})</div>
              <div class="element" v-for="concept in relatedConcepts" :key="concept.id" :class="{selected:isSelected(concept)}" @click="()=>handleClick(concept)">
                {{concept.label}}
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import ClickOutside from 'vue-click-outside'

export default {
  components: { },
  props:{
    concepts:{type:Array, required:true},
    selection:{type:Array, default:()=>[] },
  },
  directives: {
    ClickOutside
  },
  model:{
    prop:'selection',
    event:'change'
  },
  data(){
    return {
      open: false,
      isSearch:false,
      search:'',
      isSelectOpen:false,
      selectedCategories:[]
    }
  },
  mounted(){
  },
  computed:{
    internal_selection:{
      get(){
        return this.selection || []
      },
      set(value){
        this.$emit('change',value)
      }
    },
    titleSelector(){

      if(this.selectedCategories.length>0) return `${this.selectedCategories.length} ${this.selectedCategories.length===1 ? this.t('category'):this.t('categories')}`
      return this.t('all_cats')
    },
    categories(){
      const duplicateCategories = this.concepts.reduce((acc, curr) => [...acc, ...curr.categories.map(c => c.name)], [])
      return ['ALL'].concat(duplicateCategories.filter((c, index) => duplicateCategories.indexOf(c) === index))
    },
    selectedIds(){
      return this.internal_selection.map(concept => concept.id)
    },
    filteredConcepts(){
      let ret = this.selectedCategories.length===0 
        ? this.concepts 
        : this.concepts.filter(concept =>
          (this.currentSelection && this.currentSelection.includes(concept)) ||
          this.selectedCategories.some(cat => concept.categories.map(c => c.name).includes(cat))
        )
      return ret
    },
    relatedConcepts(){
      let list = this.selectedIds.length>0 ? this.filteredConcepts.filter((c)=>c.overThreshold) : this.filteredConcepts
      list = list.filter((c)=>{
        return this.internal_selection.findIndex((cc)=>c.id===cc.id)<0
      })
      // list.sort((a,b)=>{
      //   if(this.internal_selection.findIndex((c)=>c.id===b.id)>=0) return 1
      //   else if (this.internal_selection.findIndex((c)=>c.id===a.id)>=0) return -1
      //   else return a.label.localeCompare(b.label)
      // })
      return list
    }
  },
  methods:{
    isCategorySelected(category){
      if(this.selectedCategories.length===0 && category ==='ALL') return true
      else return this.selectedCategories.includes(category)
    },
    getCategoryLabel(category){
      if(category==='ALL') return 'ALL CONCEPTS'
      else return category
    },
    isSelected(concept){
      return this.selectedIds.includes(concept.id)
    },
    handleClickCategory(category){
      if(this.isCategorySelected(category) || category==='ALL'){
        this.selectedCategories = []
      } else {
        this.selectedCategories = [category]
      }
    },
    handleClick(concept){
      
      const copy = [...this.internal_selection]
      if(this.isSelected(concept)){
        copy.splice(copy.findIndex(c => c.id === concept.id),1)
      } else if(copy.length<3) {
        copy.push(concept)
      }
      this.search = null
      this.$emit('change',copy)
      this.animateAndClose()
    },
    animateAndClose(){
      this.open = false
      setTimeout(()=>{
        this.$emit('close')
      },500)
    }
  }
}
</script>
<style scoped lang="stylus">
.concept-list-dialog
  height: 100%
  pointer-events: none
  position: absolute
  width: 100%
  z-index: 10

  .overlay
    background-color: rgba(#000, 0.5)
    height: 100%
    pointer-events: all
    position: absolute
    width: 100%

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.5s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0
      pointer-events: none

  .wrapper
    m-font("Lelo", "medium")
    height: "calc(100% - %s)" % vh(240px)
    position: absolute
    transform: "translate(0, calc(100% + %s))" % vh(240px)
    transition: transform 0.4s
    width: 100%

    &.open
      transform: translate(0, vh(240px))

    .toggle-btn
      align-items: center
      background-color: #fff
      border-top-left-radius: vw(8px)
      border-top-right-radius: vw(8px)
      box-shadow: 0 2px 8px rgba(#000, 0.15)
      height: vw(62px)
      left: 50%
      padding: vw(10px) vw(40px)
      pointer-events: all
      position: absolute
      top: vw(-62px)
      transform: translate(-50%, 0)

      .icon
        m-icon("arrow-up", 16)
        margin-bottom: vw(4px)
        margin-left: auto
        margin-right: auto

      .label
        m-font-size(13, 22)
        m-font("Lelo", "regular")
        text-transform: uppercase
        white-space: nowrap

    .concept-list-dialog-container
      height: 100%
      overflow: hidden
      pointer-events: all

      .concept-navigator
        background-color: #fff
        display: flex
        flex-direction: column
        height: 100%
        position: absolute
        right: 0
        top: 0
        width: 100%
        z-index: 10

        &.select-open
          &:after
            background-color: rgba(#fff, 0.5)
            content: ""
            height: 100%
            left: 0
            position: absolute
            top: 0
            width: 100%
            z-index: 1

        .header-list
          margin-bottom: vw(20px)
          position: relative

          .top
            .category-list
              align-items: center
              display: flex
              margin-bottom: vw(10px)
              overflow-x: auto
              padding: vw(15px) vw(10px)

              .category
                m-font-size(14, 20)
                text-transform: uppercase
                white-space: nowrap

                &:not(:last-child)
                  margin-right: vw(20px)

                &.active
                  font-weight: bold

            .search
              border-bottom: 1px solid rgba(#000, 0.5)
              margin-left: vw(30px)
              margin-right: vw(30px)

              input
                m-font("SangBleu", "light")
                m-font-size(22, 26)
                border: none
                padding: vw(10px)
                text-align: center
                width: 100%

        .body-list
          flex: 1 1 0%
          overflow-y: auto

          .related-title
            m-font("Lelo", "light")
            m-font-size(14, 20)
            color: #bbb
            padding: vw(10px)
            text-align: center
            text-transform: uppercase

          .element
            m-font("SangBleu", "regular")
            m-font-size(28, 30)
            margin-bottom: vw(20px)
            text-align: center

            &.selected
              font-weight: bold
</style>