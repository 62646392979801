<template>
  <input @input="onInputChange" v-on:keyup.enter="onEnter" @focus="$emit('focus')" :value="value"/>
</template>
<script>

var debounce = require('debounce');
export default {
  name: 'BetterInput',
  props: ['value'],
  components: {
  },
  methods: {
    onInputChange: debounce(function (e) {
      this.$emit('change', e.target.value)
    }, 300),
    onEnter (){
      this.$emit('submit')
    }
  }
}
</script>
<style>
</style>