export function dist(p1, p2) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
}
export function len(vec) {
  return Math.sqrt(vec.x * vec.x + vec.y * vec.y)
}
export function distVec(p1, p2) {
  return { x: p2.x - p1.x, y: p2.y - p1.y }
}
export function normalize(vec, norm) {
  let size = len(vec)
  return { x: vec.x * norm / size, y: vec.y * norm / size }
}
export function angle(p1, p2) {
  Math.atan2(p2.y - p1.y, p2.x - p1.x);
}

export function isInside(point, includeIn, excludeFrom) {
  return includeIn.every((circle) => Math.pow(point.x - circle.x, 2) + Math.pow(point.y - circle.y, 2) < Math.pow(circle.r, 2)) &&
    excludeFrom.every((circle) => Math.pow(point.x - circle.x, 2) + Math.pow(point.y - circle.y, 2) >= Math.pow(circle.r, 2))
}
export function confine(node, circles, padding = 7) {
  circles.forEach((c) => {
    let rad = c.r - padding
    let d = dist(node, c)
    if (d > rad && Math.abs(d - rad) < 20 && !node.isNew) {
      let norm = normalize(distVec(c, node), rad)
      node.x = c.x + norm.x
      node.y = c.y + norm.y
    }
  })
  return node
}

export function exfine(node, circles, padding = 7) {
  circles.forEach((c) => {
    let rad = node.groups.length > 0 ? c.r + padding : c.r + 2 * padding
    let d = dist(node, c)
    if (d < rad && Math.abs(d - rad) < 20 && !node.isNew) {
      let norm = normalize(distVec(c, node), rad)
      node.x = c.x + norm.x
      node.y = c.y + norm.y
    }
  })
  return node
}

// NAAAA, creo qeu no sirve...
// export function refine(node) {
//   if (node.center) {
//     let c = node.center
//     let rad = node.center.r || 50
//     let d = dist(node, c)
//     if (d > node.center.r) {
//       let norm = normalize(distVec(c, node), rad)
//       node.x = c.x + norm.x
//       node.y = c.y + norm.y
//     }
//   }
// }

// intersection points of 2 circles
function circleIntersections(c0, c1) {
  var x0 = c0.x;
  var y0 = c0.y;
  var r0 = c0.r;
  var x1 = c1.x;
  var y1 = c1.y;
  var r1 = c1.r;

  // calc circles' proximity
  var dx = x1 - x0;
  var dy = y1 - y0;
  var d = Math.sqrt((dy * dy) + (dx * dx));

  // return if circles do not intersect. 
  if (d >= (r0 + r1)) { return; }
  // return if one circle is contained in the other 
  if (d <= Math.abs(r0 - r1)) { return; }

  // calc the 2 intersection points
  var a = ((r0 * r0) - (r1 * r1) + (d * d)) / (2.0 * d);
  var x2 = x0 + (dx * a / d);
  var y2 = y0 + (dy * a / d);
  var h = Math.sqrt((r0 * r0) - (a * a));
  var rx = -dy * (h / d);
  var ry = dx * (h / d);
  var xi = x2 + rx;
  var xi_prime = x2 - rx;
  var yi = y2 + ry;
  var yi_prime = y2 - ry;

  return ([{ x: xi, y: yi }, { x: xi_prime, y: yi_prime }]);
}

function ptIsInCircle(pt, circle) {
  var dx = pt.x - circle.x;
  var dy = pt.y - circle.y;
  var r = circle.r + 1; // allow circle 1px expansion for rounding
  return (dx * dx + dy * dy <= r * r);
}
export function getCentroid(arr) {
  var centerX, centerY
  centerX = arr.reduce((sum, p) => sum + p.x, 0) / arr.length
  centerY = arr.reduce((sum, p) => sum + p.y, 0) / arr.length
  return { x: centerX, y: centerY };
}
export function centroid(circles) {

  if (circles.length === 1) return circles[0]

  if (circles.length === 2) {
    let intersection = circleIntersections(circles[0], circles[1]);
    // return {x: intersection[0].x + (intersection[1].x-intersection[0].x)/2, y: intersection[0].y + (intersection[1].y-intersection[0].y)/2}
    return getCentroid(intersection || [{ x: 0, y: 0 }])
  }

  var A = circles[0]
  var B = circles[1]
  var C = circles[2]

  var intersections = [];
  var AB = circleIntersections(A, B);
  var BC = circleIntersections(B, C);
  var CA = circleIntersections(C, A);
  if (AB) { intersections = intersections.concat(AB); }
  if (BC) { intersections = intersections.concat(BC); }
  if (CA) { intersections = intersections.concat(CA); }

  var triangle = [];
  for (var i = 0; i < intersections.length; i++) {
    var pt = intersections[i];
    if (ptIsInCircle(pt, A) && ptIsInCircle(pt, B) && ptIsInCircle(pt, C)) {
      triangle.push(pt);
    }
  }
  return getCentroid(triangle)
  // let centroid = circles.reduce(
  //   (sum, c) => {
  //     sum.x += c.x
  //     sum.y += c.y
  //     return sum
  //   },
  //   { x: 0, y: 0 }
  // )
  // centroid.x = centroid.x / circles.length
  // centroid.y = centroid.y / circles.length
  // return centroid
}

export function rotate(center, point, angle) {
  var radians = angle,
    cos = Math.cos(radians),
    sin = Math.sin(radians),
    nx = (cos * (point.x - center.x)) + (sin * (point.y - center.y)) + center.x,
    ny = (cos * (point.y - center.y)) - (sin * (point.x - center.x)) + center.y;
  return { x: nx, y: ny };
}

export function getBoundingBox(circles) {
  let bbox = circles.reduce((box, c) => {
    box.left = Math.min(box.left, c.x - c.r)
    box.right = Math.max(box.right, c.x + c.r)
    box.top = Math.min(box.top, c.y - c.r)
    box.bottom = Math.max(box.bottom, c.y + c.r)
    return box
  }, { top: Infinity, left: Infinity, right: -Infinity, bottom: -Infinity })
  bbox.width = bbox.right - bbox.left
  bbox.height = bbox.bottom - bbox.top
  return bbox
}


export function layoutLabels(circles) {

  let dr = 30
  //TODO moverlos un poco hacia "derecha los tops" y "izquierda los bottoms" creo
  let top = [...circles].sort((a, b) => a.y - b.y).map((c) => ({ circle: c, x: c.x, y: c.y - (c.r + dr) }))
  let bottom = [...circles].sort((a, b) => b.y - a.y).map((c) => ({ circle: c, x: c.x, y: c.y + (c.r + dr) }))
  if (circles.length === 1) {
    let c = circles[0]
    return [{ circle: c, x: c.x, y: c.y + (c.r + dr) }]
  } else if (circles.length === 2) {
    return [top[0], bottom[0]]
  } else if (circles.length === 3) {
    let third = circles.filter((c) => c !== top[0].circle && c !== bottom[0].circle)[0]
    if (Math.abs(third.x - top[0].circle.x) > Math.abs(third.x - bottom[0].circle.x)) {
      third = top.filter((c) => c.circle !== top[0].circle && c.circle !== bottom[0].circle)[0]
    } else {
      third = bottom.filter((c) => c.circle !== top[0].circle && c.circle !== bottom[0].circle)[0]
    }
    return [top[0], bottom[0], third]
  } else {
    return top
  }
  // return circles.map((c) => {
  //   return {
  //     circle: c,
  //     x: c.x,
  //     y: c.y
  //   }
  // })
}
import { circleCircleIntersection } from 'venn.js'

function getIntersectionPoints(circles) {
  var ret = [];
  for (var i = 0; i < circles.length; ++i) {
    for (var j = i + 1; j < circles.length; ++j) {
      var intersect = circleCircleIntersection(circles[i],
        circles[j]);
      for (var k = 0; k < intersect.length; ++k) {
        var p = intersect[k];
        p.parentIndex = [i, j];
        ret.push(p);
      }
    }
  }
  return ret;
}

//MUYYY manual, pero weno, si solo van a haber 3 circles como mucho y siempre estan "centrados"....
export function getIntersectionRegions_SIMPLE(circles) {
  if(circles.length === 0){
    return []
  } else if (circles.length === 1 ) {
    let r = circles[0].r
    return [{
      path: ['M',circles[0].x,circles[0].y,'m',-r,0,'a',r,r,0,1,0,2*r,0,'a',r,r,0,1,0,-2*r,0].join(' '),
      x: circles[0].x,
      y: circles[0].y,
      groups: [circles[0]]
    }]
  } else if (circles.length === 2) {
    let r = circles[0].r
    
    let ret = [{
      path: ['M',circles[0].x,circles[0].y,'m',-r,0,'a',r,r,0,1,0,2*r,0,'a',r,r,0,1,0,-2*r,0].join(' '),
      x: circles[0].x,
      y: circles[0].y,
      groups: [circles[0]]
    },
    {
      path: ['M',circles[1].x,circles[1].y,'m',-r,0,'a',r,r,0,1,0,2*r,0,'a',r,r,0,1,0,-2*r,0].join(' '),
      x: circles[1].x,
      y: circles[1].y,
      groups: [circles[1]]
    }]
    let path
    let points = getIntersectionPoints(circles)
    let centroid
    path = ['M', points[0].x, points[0].y]
    path.push('\nA', r, r, 0, 0, 0, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 0, 0, points[0].x, points[0].y)
    centroid = getCentroid(circles)
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[1]]
    })
    return ret
  } else if (circles.length === 3) {
    let points = getIntersectionPoints(circles)
    console.log(points)
    let ret = []
    let path
    let r = circles[0].r
    let centroid
    


    path = ['M', points[0].x, points[0].y]
    path.push('\nA', r, r, 0, 0, 0, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 0, 0, points[0].x, points[0].y)
    centroid = getCentroid([circles[0], circles[1]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[1]]
    })

    path = ['M', points[4].x, points[4].y]
    path.push('\nA', r, r, 0, 0, 0, points[5].x, points[5].y)
    path.push('\nA', r, r, 0, 0, 0, points[4].x, points[4].y)
    centroid = getCentroid([circles[1], circles[2]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[1], circles[2]]
    })

    path = ['M', points[3].x, points[3].y]
    path.push('\nA', r, r, 0, 0, 0, points[2].x, points[2].y)
    path.push('\nA', r, r, 0, 0, 0, points[3].x, points[3].y)
    centroid = getCentroid([circles[0], circles[2]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[2]]
    })

    path = ['M', points[1].x, points[1].y]
    path.push('\nA', r, r, 0, 0, 1, points[2].x, points[2].y)
    path.push('\nA', r, r, 0, 0, 1, points[5].x, points[5].y)
    path.push('\nA', r, r, 0, 0, 1, points[1].x, points[1].y)
    centroid = getCentroid([circles[0], circles[1], circles[2]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[1], circles[2]]
    })


    ret.push({
      path: ['M',circles[0].x,circles[0].y,'m',-r,0,'a',r,r,0,1,0,2*r,0,'a',r,r,0,1,0,-2*r,0].join(' '),
      x: circles[0].x,
      y: circles[0].y,
      groups: [circles[0]]
    })
    ret.push({
      path: ['M',circles[1].x,circles[1].y,'m',-r,0,'a',r,r,0,1,0,2*r,0,'a',r,r,0,1,0,-2*r,0].join(' '),
      x: circles[1].x,
      y: circles[1].y,
      groups: [circles[1]]
    })
    ret.push({
      path: ['M',circles[2].x,circles[2].y,'m',-r,0,'a',r,r,0,1,0,2*r,0,'a',r,r,0,1,0,-2*r,0].join(' '),
      x: circles[2].x,
      y: circles[2].y,
      groups: [circles[2]]
    })


    return ret
    // return [getIntersectionRegions([circles[0], circles[1]]), getIntersectionRegions([circles[1], circles[2]]), getIntersectionRegions([circles[0], circles[2]])].flat()
  }
}
export function getIntersectionRegions(circles) {
  if(circles.length === 0){
    return []
  } else if (circles.length === 1 ) {
    let r = circles[0].r
    return [{
      path: ['M',circles[0].x,circles[0].y,'m',-r,0,'a',r,r,0,1,0,2*r,0,'a',r,r,0,1,0,-2*r,0].join(' '),
      x: circles[0].x,
      y: circles[0].y,
      groups: [circles[0]]
    }]
  } else if (circles.length === 2) {
    let centroid
    let ret = []
    let points = getIntersectionPoints(circles)
    let r = circles[0].r
    let path
    path = ['M', points[0].x, points[0].y]
    path.push('\nA', r, r, 0, 0, 0, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 0, 0, points[0].x, points[0].y)
    centroid = getCentroid(circles)
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[1]]
    })

    path = ['M', points[0].x, points[0].y]
    path.push('\nA', r, r, 0, 0, 0, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 1, 1, points[0].x, points[0].y)
    centroid = getCentroid([circles[1]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[1]]
    })

    path = ['M', points[0].x, points[0].y]
    path.push('\nA', r, r, 0, 1, 1, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 0, 0, points[0].x, points[0].y)
    centroid = getCentroid([circles[0]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0]]
    })
    return ret
  } else if (circles.length === 3) {
    let points = getIntersectionPoints(circles)
    console.log(points)
    let ret = []
    let path
    let r = circles[0].r
    let centroid
    path = ['M', points[0].x, points[0].y]
    path.push('\nA', r, r, 0, 0, 1, points[5].x, points[5].y)
    path.push('\nA', r, r, 0, 0, 0, points[2].x, points[2].y)
    path.push('\nA', r, r, 0, 0, 1, points[0].x, points[0].y)
    centroid = getCentroid([circles[0], circles[1]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[1]]
    })
    path = ['M', points[4].x, points[4].y]
    path.push('\nA', r, r, 0, 0, 0, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 0, 1, points[2].x, points[2].y)
    path.push('\nA', r, r, 0, 0, 0, points[4].x, points[4].y)
    centroid = getCentroid([circles[1], circles[2]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[1], circles[2]]
    })

    path = ['M', points[3].x, points[3].y]
    path.push('\nA', r, r, 0, 0, 1, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 0, 0, points[5].x, points[5].y)
    path.push('\nA', r, r, 0, 0, 1, points[3].x, points[3].y)
    centroid = getCentroid([circles[0], circles[2]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[2]]
    })

    path = ['M', points[1].x, points[1].y]
    path.push('\nA', r, r, 0, 0, 1, points[2].x, points[2].y)
    path.push('\nA', r, r, 0, 0, 1, points[5].x, points[5].y)
    path.push('\nA', r, r, 0, 0, 1, points[1].x, points[1].y)
    centroid = getCentroid([circles[0], circles[1], circles[2]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0], circles[1], circles[2]]
    })


    path = ['M', points[0].x, points[0].y]
    path.push('\nA', r, r, 0, 1, 0, points[4].x, points[4].y)
    path.push('\nA', r, r, 0, 0, 1, points[2].x, points[2].y)
    path.push('\nA', r, r, 0, 0, 1, points[0].x, points[0].y)
    centroid = getCentroid([circles[1]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[1]]
    })

    path = ['M', points[3].x, points[3].y]
    path.push('\nA', r, r, 0, 1, 1, points[4].x, points[4].y)
    path.push('\nA', r, r, 0, 0, 0, points[1].x, points[1].y)
    path.push('\nA', r, r, 0, 0, 0, points[3].x, points[3].y)
    centroid = getCentroid([circles[2]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[2]]
    })

    path = ['M', points[3].x, points[3].y]
    path.push('\nA', r, r, 0, 1, 0, points[0].x, points[0].y)
    path.push('\nA', r, r, 0, 0, 1, points[5].x, points[5].y)
    path.push('\nA', r, r, 0, 0, 1, points[3].x, points[3].y)
    centroid = getCentroid([circles[0]])
    ret.push({
      path: path.join(" "),
      x: centroid.x,
      y: centroid.y,
      groups: [circles[0]]
    })


    return ret
    // return [getIntersectionRegions([circles[0], circles[1]]), getIntersectionRegions([circles[1], circles[2]]), getIntersectionRegions([circles[0], circles[2]])].flat()
  }
}

export function generateVennCircles(count,r,frame,options={}) {
  let circles
  if (count === 0){
    circles = []
  } else if (count === 1)
    circles = [
      {
        x: frame.x,
        y: frame.y,
        r: r,
      },
    ]
  else if (count === 2) {
    circles = [
      {
        x: frame.x - r * 0.7,
        y: frame.y,
        r: r,
      },
      {
        x: frame.x + r * 0.7,
        y: frame.y,
        r: r,
      },
    ]
  } else if (count === 3) {
    circles = [
      {
        x: frame.x - r * 0.7,
        y: frame.y + r * 0.5,
        r: r,
      },
      {
        x: frame.x + r * 0.7,
        y: frame.y + r * 0.5,
        r: r,
      },
      {
        x: frame.x,
        y: frame.y - r * 0.7,
        r: r,
      },
    ]
  }
  if(options.transposed && circles.length === 2) {
    circles.forEach((c)=>{
      let tmp = c.x
      c.x = c.y - frame.y + frame.x
      c.y = tmp - frame.x + frame.y
    })
  }
  return circles
}