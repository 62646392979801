<template>
  <div class="login">
    <div class="container">
      <div class="title">{{t('login_title')}}</div>
      <input class='email' v-model="form.user" :placeholder="t('email_address')" />
      <input v-model="form.password" type="password" @keydown.enter="submit" :placeholder="t('password')" />
      <div class="warning" v-if="state.badLogin">{{t("bad_login")}}</div>
      <div class="forgot" @click="showForgotPassword=true">{{t("forgot_password")}}</div>
      <Checkbox class="checkbox" :label='t("remember_me")' v-model='form.remember'/>
      <SquareButton
        :disabled="state.loading"
        @click="submit"
      >{{state.loading?t('logging_in'):t('login')}}</SquareButton>
      <div class="social-icon">
        <SocialButton  class="icon " icon='google'  @click="googleLogin"> {{t("continue_with")}} Google </SocialButton>
        <SocialButton class="icon " icon='facebook'  @click="facebookLogin">{{t("continue_with")}} Facebook</SocialButton>
      </div>
      <div class="already">
        {{t("dont_have_account")}}
        <router-link to="/signup">{{t("sign_up")}}</router-link>    
      </div>
    </div>
    <ForgotPassword :open="openForgotPassword"  :email.sync="form.user" @close="showForgotPassword=false"/>
    
  </div>
</template>

<script>
import SquareButton from '@/components/SquareButton'
import ForgotPassword from '@/components/Login/ForgotPassword'
import Checkbox from '@/components/Checkbox'
import {login,loginGoogle,loginFacebook} from '@/services/Api'
import SocialButton from '@/components/SocialButton/SocialButton'

const LOCAL_STORAGE_EMAIL = 'email'
const LOCAL_STORAGE_REMEMBER = 'remember'
export default {
  name: "Login",
  metaInfo: {
    title: 'Login',
    meta:[{
      vmid: 'description', name: 'description',
      content: "Welcome back to Wikiparfum. Login to discover our encyclopedia for perfume lovers, find fragrances according to your olfactive preferences and build your own olfactive profile."
    },{
      vmid: 'og:title', name: 'og:title',
      content:"Wikiparfum - Login"

    },{
      vmid: 'og:description', name: 'og:description',
      content: "Welcome back to Wikiparfum. Login to discover our encyclopedia for perfume lovers, find fragrances according to your olfactive preferences and build your own olfactive profile."
    },{
      vmid: 'twitter:title', name: 'twitter:title',
      content:"Wikiparfum - Login"

    },{
      vmid: 'twitter:description', name: 'twitter:description',
      content: "Welcome back to Wikiparfum. Login to discover our encyclopedia for perfume lovers, find fragrances according to your olfactive preferences and build your own olfactive profile."
    }]
  },
  components:{
    SquareButton,
    ForgotPassword,
    Checkbox,
    SocialButton,
  },
  data(){
    return {
      state:{
        badLogin: false,
        loading: false
      },
      form: {
        user: null,
        password: null,
        remember:false
      },
      params:{
        client_id:'656077974271-8dhfsiq4q1odmf6rh3t1sbsl8u0vlg0j'
      },
      renderParams: {
                    width: 250,
                    height: 50,
                    longtitle: true
                },
      showForgotPassword:false,
    }
  },
  mounted(){
    let email = window.localStorage.getItem(LOCAL_STORAGE_EMAIL)
    let remember = window.localStorage.getItem(LOCAL_STORAGE_REMEMBER)
    if(email) this.form.user = email
    if(remember) this.form.remember = Boolean(remember)
  },
  methods:{
    submit () {
      //VErY WrONG to store the password as plain text...
      this.state.loading = true
      this.state.badLogin = false
      if(this.form.remember){
        window.localStorage.setItem(LOCAL_STORAGE_EMAIL,this.form.user)
        window.localStorage.setItem(LOCAL_STORAGE_REMEMBER,true)
      }
      else {
        window.localStorage.removeItem(LOCAL_STORAGE_EMAIL)
        window.localStorage.removeItem(LOCAL_STORAGE_REMEMBER)
      }
      login(this.form).then(()=>{
        this.$router.push({ path: '/profile'})
        this.eventTracker.emitEvent('LOGIN', { user: this.form.user })
      }).catch(()=>{
        this.state.badLogin = true
        this.state.loading = false
      })
    },
    closeForgotPassword(){
        this.showForgotPassword=false
    },
    googleLogin(){
      loginGoogle()
    },
    facebookLogin(){
      loginFacebook()
    },
    signout(){
    }
  },
  computed:{
    openForgotPassword(){
      return this.showForgotPassword!==null ? this.showForgotPassword : false
    }
  },
  routerLinkedData:['showForgotPassword']
}

</script>

<style scoped lang='stylus'>
.login
  display: flex
  justify-content: center  
    
  background-color #fefefe
  .container
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center  
    background-color #ffffff
    width vw(1100px)
  .mobile &
    padding: vw(40px)

  .title
    m-first-letter-case()
    m-font("Lelo", "light")
    m-font-size(22, 26)
    margin-bottom: vh(20px)

    .mobile &
      text-align: center
      
  .warning
    m-first-letter-case()
    m-font("Lelo", "light")
    margin vw(10px)
    color #f00
    &.error
      color #f00
  input
    border: none
    border-bottom: 1px solid rgba(#000, 0.15)
    m-font("Lelo", "light")
    m-font-size(15, 18)
    outline: none
    padding: vh(10px) 0
    width: vw(300px)
    .mobile &
      padding: vh(20px) 0
      width: 100%
  .email
      margin-bottom: vh(30px)
    
  .forgot
    width: vw(300px)
    text-align right 
    margin-bottom: vh(40px)
    color #7b7b7b
    m-font-size(13, 15)
    margin-top vh(10px)
    cursor pointer
    m-font("Lelo", "light")

  .btn
    margin-bottom: vh(40px)
    width: vw(300px)

    .mobile &
      width: 100%

  .already
    m-font("Lelo", "light")
    m-font-size(15, 18)

    > a
      display: inline-block
      m-first-letter-case()
      text-decoration: underline      
  .checkbox
      width: vw(300px)
      margin-bottom vh(20px)
      align-items flex-start
      color #7b7b7b
  .social-icon
    width: vw(300px)
    margin-bottom vh(10px)
    //display flex
    .icon 
      width 100%
      margin-top vh(20px)
      align-items left
    .right
      margin-left vw(5px)
    .left
      margin-right vw(5px)
    


.fade-enter-active, .fade-leave-active 
  transition: opacity .5s;

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ 
  opacity: 0

  
</style>
