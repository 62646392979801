<template>
  <div class="perfume-and-similars">
    <div class="background" :class="'fam-'+data.family.id" v-resize:debounce="resized"></div>
    <div
      ref="scrollWrapper"
      :class="['scroll-wrapper',{'double-scroll':showSimilar && !selectedSimilar}]"
      @click="(e)=>(e.target === e.currentTarget)?onClose():null"
    >
      <div
        class="perfume-and-similars-content"
        :class="{'half-size':showSimilar,'comparing': selectedSimilar}"
      >
        <div class="full-view-wrapper" :class="{border:showSimilar || selectedSimilar}">
          <PerfumeFullView
            ref="leftFullView"
            :data="data"
            @close="onClose"
            @show-similar="onShowSimilar"
            :canShowSimilar="!showSimilar"
            :showClose="!showSimilar"
            :tabletFormat="isTablet && Boolean(showSimilar)"
            :forcedHeights="minHeights"
            :minified="isMobile && showSimilar && !selectedSimilar"
            :onlyquad="isMobile && selectedSimilar!=null"
            @update="onFullViewUpdated"
          ></PerfumeFullView>
        </div>
        <transition name="slide">
          <div class="similar-perfumes-wrapper" v-if="showSimilar" >
            <div class="similar-perfumes-content" >
              <div class="similar-perfumes-header">
                <div class="title">{{t("similars")}}</div>
                <div class="close-button" @click="showSimilar=false"></div>
              </div>
              <SimilarPerfumes
                v-if="!selectedSimilar"
                ref="similarPerfumes"
                :reference="data"
                type="by_perfume"
                @select="(subSelection)=>selectedSimilar = subSelection"
              ></SimilarPerfumes>
              <transition name="fade">
                <PerfumeFullView
                  ref="rightFullView"
                  
                  class="similar-full-view"
                  v-if="selectedSimilar"
                  :data="selectedSimilar"
                  :tabletFormat="isTablet && Boolean(showSimilar)"
                  @close="selectedSimilar=null"
                  :canShowSimilar="false"
                  :forcedHeights="minHeights"
                  :onlyquad="isMobile"
                  @update="onFullViewUpdated"
                ></PerfumeFullView>
              </transition>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import PerfumeFullView from '@/components/PerfumeFullView'
import SimilarPerfumes from '@/components/SimilarPerfumes'
import resize from 'vue-resize-directive'
export default {
  name: 'PerfumeAndSimilars',
  components: {
    PerfumeFullView,
    SimilarPerfumes
  },
  props:['data'],
  data (){
    return {
      showSimilar: false,
      selectedSimilar: null,
      selection: null,
      minHeights: null
    }
  },
  lazyData: {
    selectedSimilar: {type: 'perfume'}
  },
  routerLinkedData: ['showSimilar','selectedSimilar']
  // routerLinkedData:{
  //   showSimilar: true,
  //   selectedSimilar:{
  //     getter () {
  //       return this.selectedSimilar?this.selectedSimilar.id:null
  //     },
  //     setter (id){
  //       if(!id) this.selectedSimilar = null
  //       else this.selectedSimilar = id //this.$refs.similarPerfumes.getById(id)
  //     }
  //   }
  // }
  ,
  directives:{
    resize: resize
  },
  computed:{
    generalHeight(){
      console.log(this.minHeights ? this.minHeights.filter(e => Number.isInteger(e)).reduce((a,b)=>a+b) : 0)
      return this.minHeights ? this.minHeights.filter(e => Number.isInteger(e)).reduce((a,b)=>a+b) : 0
    }
  },
  methods:{
    onClose (){
      this.selectedSimilar = null
      this.showSimilar = null

      //BAstante guarrero... como el close destruye el vm hay que esperar a que se hay ejecutado el watcher de selectedSimilar
      //-> SOLO PORQUE ES "LazyData" sino ya funcionaria...
      this.$nextTick(()=>this.$emit('close'))

    },
    setMinHeights () {
      console.log("setting heights")
      if(!this.$refs.leftFullView || !this.$refs.rightFullView){
        this.minHeights=null
      } else {
        let lefts = this.$refs.leftFullView.getHeights()
        let rights = this.$refs.rightFullView.getHeights()
        this.minHeights = lefts.map((v,i)=>Math.max(v,rights[i]))
        
      }
    },
    resized(){
      // console.log("---> setMinHeights: on resized")
      setTimeout(this.setMinHeights,100)
    },
    onFullViewUpdated(){
      // console.log("---> setMinHeights: after fullview updated")
      this.setMinHeights()
    },
    onShowSimilar(){
      this.showSimilar = true
    }
  },
  watch:{
    selectedSimilar(){
      if(this.selectedSimilar){
        this.$refs.scrollWrapper.scrollTop = 0
      } else{
        this.minHeights = null
      }
    },
    showSimilar(){
      this.$refs.scrollWrapper.scrollTop = 0
    }
  }
}

</script>

<style scoped lang="stylus">
.perfume-and-similars
  .background
    // background-image: url("~@/assets/images/families/fam-1.jpg")
    background-color: #eeeeee // #237
    height: 100%
    position: absolute
    width: 100%

  .scroll-wrapper
    align-items: center
    // display: flex
    // flex-direction: column
    height: 100%
    overflow-y: auto
    position: absolute
    width: 100%

    .perfume-and-similars-content
      align-items: flex-start
      background-color: #fff
      display: flex
      flex: 1 1 0%
      height: unset
      transition: padding 0.4s ease-in-out

      .desktop &
        m-max-width-container()
        min-width: 60vw // Para cuando se carga... un poco arbitrario este 60vh pero creo que cuela

      .mobile &
        flex-direction: column
        height: 100%
        overflow: hidden
        width: 100%

        .full-view-wrapper
          flex: 0 0 auto
          overflow-y: auto

        .similar-perfumes-wrapper
          border-left: none
          flex: 1 1 0%
          min-height: 0
          overflow-y: auto
          width: 100%

      &.half-size
        m-max-width-container(max-width: 1280px, padding: 5%)
        transition: min-width 0.4s, max-width 0.4s, padding 0.4s
        width: 100%

        .mobile &
          min-width: auto
          padding: 0

        .full-view-wrapper
          + .perfume-full-view
            border-left: 1px solid rgba(#000, 0.15)

          .mobile &
            overflow: visible

        .mobile &
          min-width: 0
          padding: 0

      &.comparing
        .mobile &
          .full-view-wrapper
            flex: 0 0 50%
            overflow: hidden

            .perfume-full-view
              overflow: hidden

          .similar-perfumes-header
            display: none !important

          .similar-perfumes-wrapper
            flex: 0 0 50%

            .similar-perfumes-content
              height: 100%
              position: relative

          >>> .quad-content
            overflow: hidden

      .full-view-wrapper
        flex: 1 1 50%
        overflow: hidden // importante!

        &.border
          border-right: 1px solid rgba(#000, 0.15)

        .mobile &
          flex: 0 1 auto
          overflow-y: auto
          position: sticky
          top: 0
          width: 100%
          z-index: 1

      .similar-perfumes-wrapper
        // border-left: 1px solid rgba(#000, 0.15)
        flex: 1 1 50%
        min-height: 100%

        // overflow: hidden
        &.slide-enter-active,
        &.slide-leave-active
          transition: flex 0.4s, opacity 0.4s

        &.slide-enter,
        &.slide-leave-to
          flex: 0 0 0%
          opacity: 0

        .similar-perfumes-content
          background-color: #fff
          min-height: 100%
          padding: vw(20px)
          position: relative

          .mobile &
            display: block
            height: 100%
            padding: vw(10px)
            padding-top: 0

          .similar-perfumes
            flex: 1 1 0%

          .similar-perfumes-header
            align-items: center
            border-bottom: 1px solid rgba(#000, 0.15)
            display: flex
            justify-content: space-between
            m-font("Lelo", "light")
            padding-bottom: vw(10px)

            .mobile &
              border: none
              padding: 0
              position: fixed
              right: 0
              right: vw(12px)
              top: "calc(%s + %s)" % ($header-height-mobile vh(110px))
              z-index: 1

            .title
              m-first-letter-case()
              m-font-size(16, 18)

              .mobile &
                m-font-size(0)

            .close-button
              cursor: pointer
              m-icon("close", 15)

              @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
                m-icon("close", 30)

              .mobile &
                m-icon("close", 20)

          .similar-full-view
            height: 100%
            left: 0
            position: absolute
            top: 0
            width: 100%

            &.fade-enter-active,
            &.fade-leave-active
              transition: opacity 0.4s

            &.fade-enter,
            &.fade-leave-to
              opacity: 0

          // CHAPUZA... no servian los "default" con el "max-width / half-size / etc..."
          .desktop &,
          .tablet &
            >>> .list-filters
              .filter
                flex: 1 1 auto
                margin-right: vw(10px)

                .dropdown-button
                  width: 80%

          >>> .similars-list
            .perfume-card
              $margin-x = vw(10px)
              // si tiene que ser "menos de 4 por fila" hay que sobreescrivir en la vista correspondiente (como en PerfumeAndSimilars que hay un 3)
              $percentage = percentage((1 / 3))
              $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
              flex: 0 0 $width
              margin: $margin-x

              +m-breakpoint(md xmd)
                $percentage = percentage((1 / 2))
                $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
                flex: 0 0 $width !important
                margin: $margin-x !important
</style>