<template>
  <transition name="fade">
    <div class="loading-overlay" v-if="active">
      <div class="content">
        <div class="icon"></div>
        <div v-if="text" class="text">{{t(text)}}</div>
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  name: "LoadingOverlay",
  props: {
    text:{
      type: String,
      default: 'loading'
    }
  },
  data (){
    return {
      active: false
    }
  },
  mounted (){
    this.$nextTick(()=>this.active = true)
  }
}
</script>

<style scoped lang="stylus">
.loading-overlay
  background-color: rgba(#fff, 0.9)
  display: flex
  height: 100%
  justify-content: center
  left: 0
  position: absolute
  top: 0
  width: 100%
  z-index: 10

  // opacity 0
  &.fade-enter-active
    transition: opacity 0.3s

  &.fade-leave-active
    transition: opacity 0.3s
    transition-delay: 0.3s

  &.fade-enter,
  &.fade-leave-to
    opacity: 0

  .content
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center

    .icon
      background-image: url("~@/assets/icons/loader.gif")
      background-position: center center
      background-repeat: no-repeat
      background-size: contain
      height: 40px
      width: 40px

    .text
      m-first-letter-case()
      m-font-size(16, 20)
      text-align center
      margin vw(5px)
      max-width 60%
</style>