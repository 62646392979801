const FAVORITES = 'favorites'

const validateLocalStore = () => {
    const favoritesString = localStorage.getItem(FAVORITES)
    if (favoritesString)
        try {
            var favorites = JSON.parse(favoritesString)
            if (favorites.some(e => isNaN(Number(e))))
                localStorage.removeItem(FAVORITES)
        }
        catch (e) {
            console.error(e)
            console.log(localStorage)
            localStorage.removeItem(FAVORITES)
        }
}



class LocalStorageContainer {
    /**
     * @return {Array}
     */
    constructor() {
        validateLocalStore()
    }
    validateLocalStore() {
        return validateLocalStore()
    }
    get favorites() {
        const favoritesString = localStorage.getItem(FAVORITES)
        var favorites = []
        if (favoritesString) {
            try {
                favorites = JSON.parse(favoritesString)
            }
            catch (e) {
                console.error(e)
                console.log(localStorage)
                localStorage.removeItem(FAVORITES)
            }
        }
        return favorites
    }
    /**
     * @param {Array} fav
     */
    set favorites(fav) {
        localStorage.setItem(FAVORITES, JSON.stringify(fav))
    }
}

export default new LocalStorageContainer()