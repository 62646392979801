import {routes} from '../router'
export default {
    computed: {
        ...routes.reduce((obj,b)=>{
            return {
                ...obj,
                [b.name+'Path'](){
                    return b.path
                }
            }
        },{}),
    },
    methods: {
        /**
         * 
         * @param {String | Number} id 
         */
        perfumePath(id){
            return '/explore/by-name/?selection='+id
        }
    },
}