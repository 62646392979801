function constant(x) {
  return function() {
    return x;
  };
}
export function forceBorderCircle(radius,circle=[0,0],strength=-2,strict=false){
  if (typeof radius !== "function") radius = constant(radius == null ? 1 : +radius);
  if (typeof circle !== "function") circle = constant(circle == null ? [0,0] : circle);
  if (typeof strength !== "function") strength = constant(strength == null ? [0,0] : strength);
  if (typeof strict !== "function") strict = constant(strict == null ? [0,0] : strict);
  var nodes = []
  var arrayCircle = new Array(0)
  var arrayradius = new Array(0)
  var strengths = new Array(0)
  var arrayStrict = new Array(0)
  
 function force(alpha){
  nodes.forEach((node,i) => {
    if (arrayradius[i] && Math.hypot(node.x - arrayCircle[i][0],node.y - arrayCircle[i][1]) > arrayradius[i]) {
      const l = 1+ Math.hypot(node.x - arrayCircle[i][0],node.y - arrayCircle[i][1]) - arrayradius[i]
      const theta = Math.atan((node.y - arrayCircle[i][1]) / (node.x - arrayCircle[i][0]))
      if(arrayStrict[i]){
        node.x = arrayCircle[i][0] + arrayradius[i] * Math.cos(theta) * (node.x < arrayCircle[i][0] ? -1 : 1);
        node.y = arrayCircle[i][1] + arrayradius[i] * Math.sin(theta) * (node.x < arrayCircle[i][0] ? -1 : 1);
        return
      }
      const x = node.x - arrayCircle[i][0]
      const y = node.y - arrayCircle[i][1]
      // if(forceX)node.vx += (forceX-n.vx)*a*alpha/2
      // if(forceY)node.vy += (forceY-n.vy)*a*alpha/2
      var w = strengths[i] * alpha * l/arrayradius[i];
      node.vx += x * w;
      node.vy += y * w;
    }
  })
 }
 function initialize() {
  arrayCircle = new Array(nodes.length)
   arrayradius = new Array(nodes.length)
   nodes.forEach((n,i) => {
     arrayCircle[i] = circle(n,i,nodes)
     arrayradius[i] = radius(n,i,nodes)
     strengths[i] = strength(n,i,nodes)
     arrayStrict[i] = strict(n,i,nodes)
   })
}
 force.initialize = function(_nodes){
   nodes = _nodes
   initialize()
   
 }
 force.radius = function(_) {
  return arguments.length ? (radius = typeof _ === "function" ? _ : constant(+_), initialize(), force) : radius;
};
force.strength = function(_) {
  return arguments.length ? (strength = typeof _ === "function" ? _ : constant(+_), initialize(), force) : strength;
};
force.circle = function(_) {
  return arguments.length ? (circle = typeof _ === "function" ? _ : constant(_ || [0,0]), initialize(), force) : circle;
};
force.strict = function(_) {
  return arguments.length ? (strict = typeof _ === "function" ? _ : constant(_ || [0,0]), initialize(), force) : strict;
};
return force
}