<template>
  <div class="concept-tag" :class="{black: color==='black', white: color==='white'}">
    <div class="label" @click="$emit('click',data)">{{data.label}}</div>
    <ButtonWithFeedback v-if="showRemove" class="remove-btn" @click="$emit('remove')"></ButtonWithFeedback>
  </div>
</template>

<script>
import ButtonWithFeedback from '@/components/ButtonWithFeedback.vue'
export default {
  name: 'ConceptTag',
  components:{
    ButtonWithFeedback
  },
  props: {
    data: Object,
    color: {type:String, default: 'black'},
    showRemove: {type: Boolean, default: true}
  },
  
}
</script>

<style scoped lang="stylus">
.concept-tag
  align-items: center
  border-radius: vw(40px)
  display: flex
  padding: vh(8px) vw(16px)

  .label
    m-font("Lelo", "light")
    m-font-size(14, 18)
    text-transform: capitalize

  .remove-btn
    margin-left: vh(5px)

  &.black
    background-color: #000
    border: 1px solid rgba(#fff, 0.15)
    color: #fff

    .remove-btn
      m-icon("tag-close-white", 16)

  &.white
    background-color: #fff
    border: 1px solid #333
    color: #333

    .remove-btn
      m-icon("tag-close", 16)
</style>