<template>
  <div class="list-filters" v-click-outside="()=> openedFilter=null">
    <div class="filter" v-for="filter in filterDefs" :key="filter.id">
      <div v-if="!labelAsSelection" class="filter-label">{{t(filter.filterLabel)}}:</div>
      <div
        v-if="filter.type==='options'"
        class="dropdown-button"
        :class="{open: openedFilter===filter}"
        @click="openedFilter=openedFilter!=filter?filter:null"
      >
        <div class="text">{{currentDropdownLabel(filter)}}</div>
      </div>
      <template v-if="filter.type==='toggle'">TOGGLER</template>
    </div>
    <div class="drop-down-container" v-if="openedFilter">
      <div class="current-filters">
        <template v-if="currentValues && currentValues.length">
          <div class="tags">
            <div v-for="value in currentValues" :key="value.value" class="current-filter-tag">
              <div
                class="filter-tag-text"
              >{{value.customLabel ? t(value.customLabel) : value.label}}</div>
              <div class="remove-btn" @click.stop="onFilterRemoved(value)"></div>
            </div>
          </div>
          <div class="clear-button" @click="clearFilters">{{t('clear_all')}}</div>
        </template>
      </div>
      <div class="drop-down-content">
        <div class="search">
          <input class="search-input" v-model="optionSearch" ref="filterSearchInput" />
        </div>
        <div class="selected-filter-options">
          <div
            v-for="option in getFilterOptions(openedFilter)"
            :key="option.value"
            @click="onFilterChanged(openedFilter,option)"
            :class="['multi-option',(currentValues || []).find((v)=>v.keyPath === option.keyPath && v.value === option.value)?'selected':'']"
          >
            <div class="tick-box"></div>
            <div class="option-text">{{option.customLabel ? t(option.customLabel): option.label }}</div>
          </div>
        </div>
        <div class="footer">
          <div class="close-button btn btn--primary" @click.stop="onApply">{{t(applyText)}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import {getNestedValue, matchWords} from '@/utils'
import ClickOutside from 'vue-click-outside'

//DEBERIA / PODRIA tomar los "other filters" para tenerlo en cuenta en el count?
let getOptionValues = function(def,list){
  let options
  if(def.fixedOptions) {
    options = def.fixedOptions.map((fo)=>{
      return {
        value: fo.value,
        label: fo.label,
        customLabel: fo.customLabel, //si usamos filtros caseros que no vienen del backend, el "label" debe cambiar dependiendo del idioma
        keyPath: def.keyPath,
        count: def.validation ? 
          list.filter((p)=>def.validation(fo.value,getNestedValue(p,def.keyPath))).length
          : list.filter((p)=>getNestedValue(p,def.keyPath)===fo.value).length,
        validation:def.validation
      }
    })
  } else {
    let dict = {}
    list.forEach((p)=>{
      let key = getNestedValue(p,def.keyPath)
      let candidate = dict[key] || {
        value: getNestedValue(p,def.keyPath),
        keyPath: def.keyPath,
        label: getNestedValue(p,def.labelPath),
        count: 0
      }
      dict[key] = candidate
      candidate.count+=1
    })
    options = Object.values(dict)
  }
  return options
}
export default {
  name: 'ListFilters',
  components:{
    // Multiselect
  },
  model:{
    prop: 'values',
    event: 'change'
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      openedFilter: null,
      optionSearch: null,
      currentValues: this.values,
      // filterValues: this.filterDefs.reduce((dict,fd)=>{dict[fd.id]=null; return dict},{})
    }
  },
  props: {listData:Array, filterDefs:Array, values:Array, labelAsSelection: {default:false}, applyText:{default:'apply'},defaultSelected:Array},
  mounted(){
    let brandsDef = this.filterDefs ? this.filterDefs.find((d)=>d.keyPath==='brand.id') : []
    if( brandsDef && localStorage.getItem('brandAccess')){
      let brandAccess = JSON.parse(localStorage.getItem('brandAccess'))
      let forcedBrands = brandAccess.map((id)=>{
        return {
          value: id,
          label: brandsDef.fixedOptions.find((fo)=>fo.value===id).label,
          keyPath: "brand.id"
        }
      })
      this.$emit('change',forcedBrands)
    }
    if(this.defaultSelected && (!this.currentValues || this.currentValues.length===0)){
      const options = this.filterDefs
        .reduce((array,def) => array.concat(this.getFilterOptions(def).map(e => ({...e,def}))),[])
        .filter(e => this.defaultSelected.includes(e.value))
      options.forEach(e => this.onFilterChanged(e.def,e))
      this.onApply()
    }
  },
  methods:{
    getFilterOptions(filterDef){
      // return getOptionValues(filterDef,this.listData)
      if(this.optionSearch && this.optionSearch!=='') return getOptionValues(filterDef,this.listData).filter((o)=>matchWords(o.label,this.optionSearch))
      else return getOptionValues(filterDef,this.listData)
    },
    onFilterChanged(def,option){
      let newValues = this.currentValues?[...this.currentValues]:[]
      let candidate = newValues.find((nv)=>nv.keyPath === def.keyPath && nv.value === option.value)
      if(candidate) newValues = newValues.filter((nv)=>nv!==candidate)
      else newValues.push({...option})
      // this.$emit('change',newValues)
      this.currentValues = newValues
    },
    onFilterRemoved(value){
      console.log("should remove value",value)
      let newValues = this.currentValues?[...this.currentValues]:[]
      let candidate = newValues.find((nv)=>nv.value === value.value)
      if(candidate) newValues = newValues.filter((nv)=>nv!==candidate)
      // this.$emit('change',newValues)
      this.currentValues = newValues
    },
    clearFilters(){
      localStorage.removeItem('brandAccess')
      this.currentValues = null
      this.$emit('change',null)
    },
    currentDropdownLabel(def){
      if(this.labelAsSelection) {
        let candidates = (this.values || []).filter((v)=>v.keyPath === def.keyPath)
        // if(candidates.length) return this.t(def.filterLabel).toUpperCase() + ` (${candidates.length})`
        if(candidates.length) return candidates.map((c)=>c.customLabel ? this.t(c.customLabel): c.label).join(', ')
        else return this.t(def.filterLabel).toUpperCase()
        
      } else {
        let candidates = (this.values || []).filter((v)=>v.keyPath === def.keyPath)
        if(candidates.length) return candidates.length+' selected'
        else return def.female ? this.t("all_female"): this.t('all')
      }
    },
    onApply () {
      this.openedFilter=null
      this.$emit('change',this.currentValues)
    }

  },
  watch: {
    openedFilter(){
      if(this.openedFilter) {
        this.optionSearch = null
        this.$nextTick(()=> {   
          this.$refs.filterSearchInput.focus()
        })
      }
    },
    values(){
      this.currentValues = this.values
    }
    // filterValues : {
    //   deep: true,
    //   handler(){
    //     // let newValues
    //     // if(!this.values) newValues = []
    //     // else newValues = this.values.filter((v)=>v.keyPath !== def.keyPath)
    //     // if(value) newValues.push({value: value, keyPath: def.keyPath})
    //     // if(newValues.length===0) newValues = null
    //     this.$emit('change',Object.values(this.filterValues).filter((f)=>f))
    //   }

    // }
  }
}
</script>

<style scoped lang="stylus">
.list-filters
  align-items: center
  display: flex
  justify-content: space-between
  position: relative

  .filter
    align-items: center
    display: flex
    flex: 0 1 auto

    .mobile &
      align-items: flex-start
      flex-direction: column

    .filter-label
      m-font("Lelo", "light")
      m-font-size(10, 12)
      margin-right: vw(5px)
      text-transform: uppercase
      // width: vw(60px)

    .dropdown-button
      border: 1px solid
      m-font("Lelo", "light")
      m-font-size(11, 13)
      padding: vh(5px) vw(5px)
      position: relative
      width: vw(100px)

      .text
        m-ellipsis(90%) // paso de intentar fer el calc...

        .mobile &
          m-ellipsis(80%) // paso de intentar fer el calc...

      &:after
        content: ""
        m-icon("caret-down", 12)
        position: absolute
        right: vw(5px)
        top: 50%
        transform: translateY(-50%)
        transition: transform 0.2s

      &.open
        &:after
          transform: translateY(-50%) rotate(180deg)

      .mobile &
        border: none
        m-font-size(13, 13)

  .drop-down-container
    background-color: #fff
    border: 1px solid rgba(#000, 0.15)
    box-shadow: 0 5px 15px -5px rgba(#000, 0.5)
    // only needed for mobile, pero si sirve siempre MEJOR
    display: flex
    flex-direction: column
    max-height: 50vh
    min-width: vw(300px)
    padding: vw(10px)
    position: absolute
    top: "calc(100% + %s)" % vh(10px)
    width: 100%
    z-index: 1

    .mobile &
      // border-left: none
      // border-right: none
      // box-shadow: none
      height: vh(500px)
      left: 0
      min-width: 0
      top: 100%
      z-index: 99

    .current-filters
      align-items: flex-start
      display: flex
      flex: 0 0 auto // Fix for Safari and flex columns issue
      margin-bottom: vh(10px)

      .mobile &
        align-items: center

      &:empty
        display: none

      .tags
        display: flex
        flex: 1 1 0%
        flex-wrap: wrap
        margin-right: vh(20px)

        .mobile &
          flex-wrap: nowrap
          overflow-x: auto

        .current-filter-tag
          align-items: center
          border: 1px solid rgba(#000, 0.15)
          display: flex
          flex: 0 0 auto
          flex: 0 1 auto
          m-font("Lelo", "light")
          m-font-size(10, 12)
          margin-bottom: vh(5px)
          margin-right: vw(5px)
          padding: vh(5px) vw(5px)

          .mobile &
            padding: vw(8px)

          .filter-tag-text
            white-space: nowrap

          .remove-btn
            cursor: pointer
            m-icon("close", 8)
            margin-left: vw(5px)
            width: @height

      .clear-button
        cursor: pointer
        m-font("Lelo", "light")
        m-font-size(10, 12)
        opacity: 0.8

        .mobile &
          margin-top: -0.1em
          text-transform: uppercase

        &:hover
          opacity: 1

    .drop-down-content
      align-items: center
      display: flex
      flex-direction: column
      overflow: hidden

      .mobile &
        flex: 1 1 0%

      .search
        // align-items: center
        // display: flex
        margin-bottom: vh(10px)
        width: 100%

        .mobile &
          margin-bottom: vh(20px)

        .search-input
          appearance: none
          background-image: url("~@/assets/icons/icon-search.svg")
          background-position: 10px center
          background-repeat: no-repeat
          border: 1px solid rgba(#000, 0.15)
          border-radius: 0
          m-font("Lelo", "medium")
          m-font-size(14, 20)
          m-letter-spacing(15)
          outline: none
          padding: vh(5px) vw(10px) vh(5px) vw(40px)
          width: 100%

          .mobile &
            padding: vh(10px) vw(10px) vh(10px) vw(40px)

        .close-button
          margin: auto
          m-font-size(10, 12)
          padding: 1em

      .selected-filter-options
        margin-bottom: vh(20px)
        overflow-y: auto
        width: 100%

        .mobile &
          flex: 1 1 0%

        .multi-option
          align-items: center
          cursor: pointer
          display: flex
          margin-left: vw(10px)
          margin-right: vw(10px)

          .mobile &
            margin: 0

          &:not(:last-child)
            margin-bottom: vw(20px)

          &:hover
            .tick-box
              border-color: rgba(#000, 0.5)

            .option-text
              opacity: 1

          &.selected
            .tick-box
              border: none
              m-icon("checked", 20)

            .option-text
              opacity: 1

          .tick-box
            border: 1px solid rgba(#000, 0.15)
            height: vw(20px)
            margin-right: vw(10px)
            width: @height

          .option-text
            m-font("Lelo", "light")
            m-font-size(14, 18)
            opacity: 0.8

      .footer
        align-items: center
        display: flex
        flex: 0 0 auto // Fix for Safari and flex columns
        justify-content: flex-end
        width: 100%

      .close-button
        padding: 1em 2em

        .mobile &
          width: 100%
</style>