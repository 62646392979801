function constant(x) {
  return function() {
    return x;
  };
}
export function forceBorderColision(x,y,dist=100){
  var _nodes = []
  if (typeof dist !== "function") dist = constant(dist == null ? 1 : +dist);
  var forceWall = 2
  var radii = new Array(0);
  function force(alpha){
    //console.log('force',x,radii)
    _nodes.forEach((n,i) => {
      let dist = radii[i]
      if(n.x<x[0]+dist){
        const a = 1 - n.x/(x[0]+dist)
         n.vx += (forceWall-n.vx)*a*alpha/2
      }
      if(n.x>x[1]-dist){
        const a = 1 - n.x/(x[1] - dist)
        n.vx += (forceWall+n.vx)*a*alpha/2
      } 
      if(n.y<y[0]+ dist){
        const a = 1 - n.y/(y[0] + dist)
        n.vy += (forceWall-n.vy)*a*alpha/2
      } 
      if(n.y>y[1]-dist){
        const a = 1 - n.y/(x[1]-dist)
        n.vy += (forceWall+n.vy)*a*alpha/2
      }
      
      n.x = Math.max(x[0]+dist,Math.min(x[1]-dist,n.x))
      n.y = Math.max(y[0]+dist,Math.min(y[1]-dist,n.y))
    })
  }
  function initialize(nodes){
    _nodes = nodes
    var i, n = _nodes.length, node;
    radii = new Array(n);
    for (i = 0; i < n; ++i) node = nodes[i], radii[node.index] = +dist(node, i, nodes);
  }

  force.distance = function(_) {
    return arguments.length ? (dist = typeof _ === "function" ? _ : constant(+_), initialize(), force) : dist;
  };
  force.initialize = initialize
  return force
}