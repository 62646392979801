<template>
    <Card :open="true" @close='onRedirect'>
        <template v-slot:title>
            <div class="title">
                {{t("change_password")}}
            </div>
        </template>
        <template v-slot:container>
            <div class='flex'>
                <div  class="input show-password-container">
                    <Input                         
                        :type='type'
                        v-model="password"
                        :placeholder="t('new_password')"
                    />
                    <div @click="type==='password'?type='text':type='password'" class="show-password sub-title-grey">{{t("show_password")}}</div>
                </div>
                <Input 
                    class="input"
                    type='password'
                    v-model="repeatePassword"
                    :error="error"
                    :placeholder="t('confirm_password')" 
                    />
                    <SquareButton class="button" :disabled="loading" @click="submit">{{t('reset_password')}}</SquareButton>
        
                </div>
        </template>
    </Card>
</template>
<script>
import Card from '@/components/Card'
import Input from '@/components/Login/Input'
import SquareButton from '@/components/SquareButton'
import {setResetPassword} from '@/services/Api'
export default {
    components:{
        Card,
        Input,
        SquareButton
    },
    name:'ChangePassword',
    data(){
        return {
            type:'password',
            password:'',
            repeatePassword:'',
            loading:false,
            token:'',
            user:'',
            error:false
        }
    },
    routerLinkedData:['token','user'],
    methods:{
        submit(){
            if(this.password!==this.repeatePassword){
                this.error=true
                return
            }
                
            this.loading = true
            setResetPassword(this.user,this.password,this.token).then(()=>{
                this.loading = false
                this.$router.push({ name: 'login' })
            }).catch(err => console.warn(err))
            
        },
        onRedirect(){
            console.log('Redirect')
            this.$router.push({ name: 'login' });

        }
    }

}
</script>
<style lang="stylus" scoped>
.page {
    position fixed
    display flex
    justify-content center
}
.input {
    margin-bottom vh(30px)
}
.button {
    margin-top vh(30px)
}
.flex {
    display flex
    flex-direction column
}
.title {
    text-align: center;
    margin-bottom vh(30px)
    m-font-size(24, 28);
}

.sub-title {
text-align: center;
m-font-size(20, 24);
margin-bottom: vh(40px);
}

.sub-title-grey {
  margin-top: vh(100px);
  color: #7b7b7b;
  m-font-size(13, 15);
  margin-top: vh(60px);
  cursor: pointer;
  text-align: center;
}
.show-password-container {
    position relative
    .show-password {
        position absolute
        margin auto 0
        right 0
        top auto
        margin-top vh(3px)
    }
}
</style>
