export default {
  drawText(ctx,text,x,y,size,split=true,opacity){
    if(!text) return 
    ctx.save();
    if (opacity !== undefined)
      ctx.globalAlpha = opacity
    const texts = split ? text.split(' ') : [text]
    ctx.font = `normal ${size}px SangBleu`
    texts.forEach((text,i) => {
      const transX = text.length*size/4
      ctx.fillText(text, x-transX, y-(texts.length-i-1.3)*size);
    })

    ctx.restore()
  },
  /**
   * 
   * @param {CanvasRenderingContext2D} ctx 
   * @param {HTMLImageElement} image 
   * @param {number} x 
   * @param {number} y 
   * @param {number} r 
   */
   drawImage(ctx,image,x,y,r,opacity=1){
    ctx.save();
    ctx.globalAlpha = opacity
    ctx.translate(x,y)
    ctx.beginPath();
    ctx.arc(0, 0, r, 0, 2 * Math.PI, false);
    ctx.closePath();
    ctx.clip();
    ctx.drawImage(image, -r, -r,2*r,2*r*image.height/image.width );
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI, false);
    ctx.clip();
    ctx.closePath();
    ctx.restore()
    
    
  },
  
  drawGradient(context,x,y,r,color=[],opacity=.3){
    if(isNaN(x) || isNaN(y) || isNaN(r)) return 
    context.save()
    context.globalAlpha = opacity
    if(color.length===1) {
      console.warn('ONLY ONE COLOR!?')
      return
    }
    var gradient = context.createRadialGradient(x, y, 1, x, y, r)
    color.forEach((c,index) => gradient.addColorStop(index/(color.length-1), c))
    context.fillStyle = gradient
    context.beginPath();
    context.arc(x, y, r, 0, 2 * Math.PI, false);
    context.fill();
    context.closePath();
    context.restore()

  },
  /**
   * @param {CanvasRenderingContext2D} ctx 
   */
  drawLine(ctx, x, y,finx,finy) {
    ctx.save()
    ctx.globalAlpha = .2
    ctx.beginPath();
    ctx.lineWidth = 2
    ctx.strokeStyle = '#000'
    ctx.moveTo(x, y);
    ctx.lineTo(finx, finy);
    ctx.stroke();
    ctx.restore()
    
  },
  drawCircle(context,x,y,r=10,color="#000",fill=false,stroke=.1){
    context.save()
    context.beginPath();
    r = r+stroke*.5
    context.arc(x, y, r, 0, 2 * Math.PI, false);
    context.lineWidth = stroke;
    context.strokeStyle = fill ? 'white': color;
    if(!fill)
      context.stroke();
    if(fill){
      context.fillStyle = color;
      context.fill();
      context.beginPath();
      context.arc(x, y, r*7/15, 0, 2 * Math.PI, false);
      context.fillStyle = fill;
      context.stroke();
      context.fill();
    }
    context.restore()
  },
  /**
   * 
   * @param {CanvasRenderingContext2D} ctxRef 
   * @param {string} text The text to be displayed in circular fashion
   * @param {number} radius The radius of the circle around which the text will be displayed (inside or outside)
   * @param {number} startAngle In degrees, Where the text will be shown. 0 degrees if the top of the circle
   * @param {'center' | 'left' |'right'} align Positions text to left right or center of startAngle
   * @param {boolean} textInside true to show inside the diameter. False draws outside
   * @param {boolean} inwardFacing true for base of text facing inward. false for outward
   * @param {string} fName  name of font family. Make sure it is loaded
   * @param {string} fSize size of font family. Don't forget to include units
   * @param {number} kerning 0 for normal gap between letters. positive or negative number to expand/compact gap in pixels
   * @returns 
   */
   getCircularText(ctxRef,x,y, text, radius, startAngle, align, textInside, inwardFacing, fName, fSize, kerning=0,opacity=1,color='black') {
    var diameter = radius*2
    ctxRef.save()
    ctxRef.globalAlpha = opacity
    ctxRef.fillStyle = color;
    ctxRef.translate(x,y)
    ctxRef.font = fSize + ' ' + fName;
    //return this.drawTextAlongArc(ctxRef,text,x,y,radius,inwardFacing ? 2*Math.PI : -2*Math.PI)
    // declare and intialize canvas, reference, and useful variables
    align = align.toLowerCase();
    var clockwise = align == "right" ? 1 : -1; // draw clockwise for aligned right. Else Anticlockwise
    startAngle = startAngle * (Math.PI / 180); // convert to radians

    // calculate height of the font. Many ways to do this
    // you can replace with your own!
    // var div = document.createElement("div");
    // div.innerHTML = text;
    // div.style.position = 'absolute';
    // div.style.top = '-10000px';
    // div.style.left = '-10000px';
    // div.style.fontFamily = fName;
    // div.style.fontSize = fSize;
    // document.body.appendChild(div);
    // document.body.removeChild(div);
    var textHeight = Number(fSize.replace('px',''))

    // in cases where we are drawing outside diameter,
    // expand diameter to handle it
    if (!textInside) diameter += textHeight * 2;

    // super chapu temporal 
    var totalRotate= 0
    for(var key of text){
      var charWid3 = ctxRef.measureText(key).width; // half letter
      totalRotate+=(charWid3/2) / (diameter / 2 - textHeight) 
      totalRotate+=(charWid3/2 + kerning) / (diameter / 2 - textHeight) 
    }
    if(totalRotate>3*Math.PI/2) text = text.slice(0,Math.trunc(text.length/2))
    // Reverse letter order for align Left inward, align right outward 
    // and align center inward.
    if (((["left", "center"].indexOf(align) > -1) && inwardFacing) || (align == "right" && !inwardFacing)) text = text.split("").reverse().join(""); 

    // Setup letters and positioning
    //ctxRef.translate(diameter / 2, diameter / 2); // Move to center
    startAngle += (Math.PI * !inwardFacing); // Rotate 180 if outward
    ctxRef.textBaseline = 'middle'; // Ensure we draw in exact center
    ctxRef.textAlign = 'center'; // Ensure we draw in exact center

    // rotate 50% of total angle for center alignment
    if (align == "center") {
        for (var j = 0; j < text.length; j++) {
            var charWid = ctxRef.measureText(text[j]).width;
            startAngle += ((charWid + (j == text.length-1 ? 0 : kerning)) / (diameter / 2 - textHeight)) / 2 * -clockwise;
        }
    }
    // Phew... now rotate into final start position
    ctxRef.rotate(startAngle);
    // Now for the fun bit: draw, rotate, and repeat
    for (var i = 0; i < text.length; i++) {
        var charWid2 = ctxRef.measureText(text[i]).width; // half letter
        ctxRef.rotate((charWid2/2) / (diameter / 2 - textHeight) * clockwise);  // rotate half letter

        // draw char at "top" if inward facing or "bottom" if outward
        ctxRef.fillText(text[i], 0, (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2));

        ctxRef.rotate((charWid2/2 + kerning) / (diameter / 2 - textHeight) * clockwise); // rotate half letter
    }
    ctxRef.restore()
  },
  drawTextAlongArc(context, str, centerX, centerY, radius, angle) {
    var len = str.length,
      s;
    context.save();
    //context.translate(centerX, centerY);
    context.rotate(-1 * angle / 2);
    context.rotate(-1 * (angle / len) / 2);
    for (var n = 0; n < len; n++) {
      context.rotate(angle / len);
      context.save();
      context.translate(0, -1 * radius);
      s = str[n];
      context.fillText(s, 0, 0);
      context.restore();
    }
    context.restore();
  },
  setupCanvas(canvas) {
  // Get the device pixel ratio, falling back to 1.
  var dpr = window.devicePixelRatio || 1;
  // Get the size of the canvas in CSS pixels.
  var rect = canvas.getBoundingClientRect();
  // Give the canvas pixel dimensions of their CSS
  // size * the device pixel ratio.
  canvas.width = rect.width * dpr;
  canvas.height = rect.height * dpr;
  var ctx = canvas.getContext('2d');
  // Scale all drawing operations by the dpr, so you
  // don't have to worry about the difference.
    ctx.scale(dpr, dpr);
  ctx.save()
  return ctx;
}
}