<template>
  <div class="legal-popup">
    <div class="background" @click="$emit('close')"></div>
    <div class="content">
      <div class="header">
        <div
          class="tab"
          :class="currentTab==='cookies'?' current':''"
          @click="currentTab = 'cookies'"
        >{{t("cookies_policy")}}</div>
        <div
          class="tab"
          :class="currentTab==='privacy'?' current':''"
          @click="currentTab = 'privacy'"
        >{{t("privacy_policy")}}</div>
        <div
          class="tab"
          :class="currentTab==='terms'?' current':''"
          @click="currentTab = 'terms'"
        >{{t("terms_and_conds")}}</div>
        <ButtonWithFeedback class="close-btn" @click="$emit('close')"></ButtonWithFeedback>
      </div>
      <div class="sub-content" :key="currentTab" v-html="getContent()"></div>
    </div>
  </div>
</template>

<script>
import ButtonWithFeedback from '@/components/ButtonWithFeedback'
import privacy_HTML_en from '@/components/Legal/privacy_en.html'
import privacy_HTML_es from '@/components/Legal/privacy_es.html'
import privacy_HTML_fr from '@/components/Legal/privacy_fr.html'
import terms_HTML_en from '@/components/Legal/terms_and_conds_en.html'
import terms_HTML_fr from '@/components/Legal/terms_and_conds_fr.html'
import terms_HTML_es from '@/components/Legal/terms_and_conds_es.html'
import cookies_en from '@/components/Legal/cookies_en.html'
import cookies_fr from '@/components/Legal/cookies_fr.html'
import cookies_es from '@/components/Legal/cookies_es.html'
import {mapGetters} from 'vuex'
export default {
  name: 'SharePopup',
  components: {
    ButtonWithFeedback,
  },
  props: ['data'],
  
  data (){
    return {
      currentTab: 'cookies'
    }
  },
  computed:{
    ...mapGetters(['lang'])
  },
  methods:{
    getContent(){
      if(this.currentTab==='cookies') 
        switch(this.lang){
          case 'en': return cookies_en
          case 'fr': return cookies_fr
          case 'es': return cookies_es
          default: return cookies_en
        }
      else if(this.currentTab==='privacy') {
        switch(this.lang){
          case 'en': return privacy_HTML_en
          case 'fr': return privacy_HTML_fr
          case 'es': return privacy_HTML_es
          default: return privacy_HTML_en
        }
        
      }
      else if(this.currentTab==='terms') {
        switch(this.lang){
          case 'en': return terms_HTML_en
          case 'fr': return terms_HTML_fr
          case 'es': return terms_HTML_es
          default: return terms_HTML_en
        }
    }
  }
}
}
</script>
<style scoped lang="stylus">
.legal-popup
  m-font("Lelo", "light")
  height: 100%
  width: 100%

  .background
    background-color: rgba(0, 0, 0, 0.5)
    height: 100%
    position: absolute
    width: 100%
    z-index: 0

  .content
    align-items: center
    background-color: #fff
    display: flex
    flex-direction: column
    height: 90vh
    left: 50%
    max-width: 1000px
    overflow: hidden
    position: absolute
    top: 50%
    transform: translate(-50%, -50%)
    width: 90vw

    .mobile &
      height: 100%
      left: 0
      padding: 0
      top: 0
      transform: none
      width: 100%

    .header
      border-bottom: 1px solid rgba(#000, 0.15)
      display: flex
      justify-content: flex-start
      margin-bottom: vh(20px)
      padding: vw(20px)
      padding-bottom: vh(15px)
      position: relative
      width: 100%

      .tab
        cursor: pointer
        m-font-size(12, 14)
        m-letter-spacing(50)
        opacity: 0.75
        text-transform: uppercase

        .mobile &
          m-font-size(10, 12)

        &:not(:last-child)
          margin-right: vw(40px)

        &:hover
          opacity: 1

        &.current
          cursor: default
          font-weight: bold
          opacity: 1
          position: relative

          &:after
            background-color: #000
            bottom: vh(-15px)
            content: ""
            height: 2px
            left: 0
            position: absolute
            width: 100%

    .sub-content
      margin: vh(20px) vw(120px) vh(40px)
      overflow-y: auto
      padding-right: vw(10px)

      .mobile &
        padding-right: 0

      .mobile &
        flex: 1 1 0
        margin: vw(20px)

      >>> a
        text-decoration: underline

      >>> > .title
        m-font-size(38, 50)
        margin-bottom: vh(40px)

      >>> > .subtitle
        m-font-size(20, 24)
        font-weight: 200
        margin-bottom: vh(40px)
        margin-top: vh(-30px)

      >>> .section
        display: flex

        .mobile &
          flex-direction: column

        &:not(:last-child)
          margin-bottom: vh(40px)

        > .title
          font-weight: bold
          m-font-size(12, 16)
          margin-right: vw(10px)
          text-transform: uppercase
          width: 25%

          .mobile &
            margin-bottom: vw(10px)
            margin-right: 0
            width: 100%

        > .desc
          flex: 1 1 0
          m-font-size(12, 16)
          margin-left: vw(10px)

          .mobile &
            margin-left: 0

          ul
            margin-left: vw(20px)

          li
            list-style-type: initial
          table
            width: 100%
            text-align: left
            border-collapse: collapse
            tr
              th
                font-size: 15px
                font-weight: bold
                background-color: rgba(0,0,0,0.03)
                text-align: left
                border: 1px solid rgba(0,0,0,0.15);
                padding vw(10px)
              td
                border: 1px solid rgba(0,0,0,0.15);
                padding vw(10px)
            

  .close-btn
    cursor: pointer
    m-icon("close", 20)
    position: absolute
    right: vw(20px)
    top: 50%
    transform: translateY(-50%)
</style>