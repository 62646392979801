<template>
  <div class="ingredient-item" @click="$emit('click',data)">
    <div class="image" :style="style"></div>
    <div class="label">{{data.name}}</div>
    <div class="fam-label" v-if="showFamily">{{data.family && data.family.name}}</div>
  </div>
</template>

<script>
export default {
  name: 'IngredientItem',
  components:{
  },
  computed:{
    style(){
      if(this.data.imageUrl || this.data.lightImageUrl) 
        return {
          backgroundImage: `url(${this.data.imageUrl || this.data.lightImageUrl})`
        }
      else{
        return {
          backgroundColor:this.data.family ? this.data.family.color : 'white'
        }
      }
        
    }
  },
  props: ['data','showFamily'],
  methods:{
  }
}
</script>

<style scoped lang="stylus">
.ingredient-item
  // cursor: pointer
  display: flex
  flex-direction: column
  align-items center
  // overflow: hidden

  // &.unselected
  // opacity: 0.2
  .image
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    border-radius: 50%
    //flex: 1 1 0 // Que no lo ocupe todo y se mantenga cuadrado
    margin: 1px // Para asegurar que no se corta "ni un pixel" de la redonda
    margin-bottom: vh(15px)
    width: vw(80px)
    height : vw(80px)
    &:after
      content: ""
      display: block
      padding-bottom: 100%

  .label
    //m-ellipsis(100%) // para eliminar el white-space: nowrap
    m-font("Lelo", "light")
    m-font-size(14, 16)
    //m-letter-spacing(25)
    text-align: center

  .fam-label
    color: #777
    m-ellipsis(100%)
    m-font("Lelo", "light")
    m-font-size(12, 14)
    margin-top: vh(5px)
    text-align: center
</style>