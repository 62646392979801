function defaultGetter (key) {
  if(!this[key]) return null
  if(Array.isArray(this[key])) return this[key].map((x)=>x.id || x)
  else return this[key].id?this[key].id:this[key]
}
function defaultSetter (key,value) {
  this[key] = value
}

function cleanQuery (obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] != null && obj[key] != undefined) {
      newObj[key] = String(obj[key]); // copy value
    }
  });
  return newObj;
}

function forwardIsBack (newQuery) {
  if(!routeStack[routeStack.length-2] || router.history.current.name !== routeStack[routeStack.length-2].name) return false
  let forwardQuery = cleanQuery({...router.history.current.query,...newQuery})
  let backQuery = cleanQuery(routeStack[routeStack.length-2].query)
  let areEqual = JSON.stringify(forwardQuery) === JSON.stringify(backQuery)
  return areEqual
}

// store manually the stack (parece que el router.history no lo expone....)
let routeStack = []
import router from '../router'

router.afterEach((to)=>{
  // solo la apuntamos si no es un back (un poco feo...)
  if(routeStack.length<2 || routeStack[routeStack.length-2].fullPath!==to.fullPath) routeStack.push(to)
  else routeStack.pop()
})



let queryQueue = []

var RouterLinkedData = {
  install: function install(Vue) {
    // pluginOptions = pluginOptions || {};

    // Vue.config.optionMergeStrategies.routerLinkedData = Vue.config.optionMergeStrategies.computed;
    
    Vue.mixin({
      created () {
        this.self_prevent_route_watcher = false
        this.self_prevent_key_watcher = false

        if(!this.$options.routerLinkedData) return
        let routerLinkedData = Array.isArray(this.$options.routerLinkedData)?this.$options.routerLinkedData.reduce((o,k)=>{o[k]={};return o},{}) :this.$options.routerLinkedData
      
        this.$watch('$route',{
          handler: (to)=>{
            if(this.self_prevent_route_watcher) return
            this.self_prevent_key_watcher = true
            
            Object.keys(routerLinkedData).forEach((key)=>{
              let value = to.query[key]?to.query[key]:null
              routerLinkedData[key].setter?routerLinkedData[key].setter.call(this,value):defaultSetter.call(this,key,value)
            })
            this.$nextTick(()=>this.self_prevent_key_watcher = false)
          },
          immediate: true
        })
        
        // importante hacerlo "afterEach" tanto si es por .pushQuery() como por .go(-1)
        this.$router.afterEach(()=>{
          this.$nextTick(()=>{
            // console.log("this.self_prevent_route_watcher ",this.self_prevent_route_watcher," to false (afterEach)")
            this.self_prevent_route_watcher = false
          })
        })
        
        Object.keys(routerLinkedData).forEach((key)=>{

          this.$watch(key, ()=>{
            if(this.self_prevent_key_watcher) return
            let query = {}
            let value = routerLinkedData[key].getter?routerLinkedData[key].getter.call(this):defaultGetter.call(this,key);
            if(value) query[key] = value
            else query[key] = null
    
            queryQueue.push(query)
            
            setTimeout(()=>{
              if(queryQueue.length===0) return
              
              let bigQuery = queryQueue.reduce((bq,q)=>{return {...bq,...q}},{})
              queryQueue = []
              
              this.self_prevent_route_watcher = true
              if(forwardIsBack(bigQuery)){
                console.log("going back!")
                this.$router.go(-1)
              } else {
                this.$router.pushQuery(bigQuery).then(()=>{
                  // console.log("this.self_prevent_route_watcher ",this.self_prevent_route_watcher," to false (pushQuery.then)")
                  // No haria falta (este then) sino fuera por el "setTimout((),0)" que lo contiene....
                  this.self_prevent_route_watcher = false
                })
              }
              
            },0)
          });
          
        });
        
        // ESTE no tengo claro si/para qeu lo queriamos
        // No se puede empezar con un "routerLinkedData" con valor inicial?!?
        // Object.keys(routerLinkedData).forEach((key)=>{
        //   this._watchers.find((w)=>w.expression===key).cb.call(this)
        // })
        
        // Esto ya lo hacemos con el immediate de watch: $route
        // this._watchers.find((w)=>w.expression==='$route').cb.call(this,this.$router.history.current)
      }
    })
  }
}

export default RouterLinkedData
