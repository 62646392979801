import device from '@/utils/device'
export default {
  mounted(){
    this.setReponsiveRatio()
    window.addEventListener('resize',()=>{
      this.setReponsiveRatio()
    })
  },

  methods: {
    setReponsiveRatio () {
      // Desktop xd size:  1280 x 720
      // Mobile xd size: 375 x 667
      let ratioW = 1
      if(device==='mobile') ratioW = 1280 / 375
      
      let ratioH = 1
      // if(device==='mobile') ratioH = 720 / 667
      let measureEl = document.getElementById('measure')
      if(device==='mobile') ratioH = (720 / 667) * (measureEl.clientWidth / measureEl.clientHeight)
      
      // added for "extra large screens"
      if(measureEl.clientWidth>1440) {
        ratioH*=0.8
        ratioW*=0.8
      }
      
      document.documentElement.style.setProperty('--vw-rr', `${ratioW}`);
      document.documentElement.style.setProperty('--vh-rr', `${ratioH}`);
      
      
      if(device === 'mobile') document.documentElement.style.setProperty('--font-rr', `1.4`);
      else document.documentElement.style.setProperty('--font-rr', `1`);
    }
  },
  watch:{
    isMobile () {
      this.setReponsiveRatio()
    }
  }
}