<template>
  <div class="explore-by-ingredients">
    <div class="explore-header-container">
      <transition name="slide">
        <div class="selection-wrapper" ref="selection" v-if="selection && !hideSelection">
          <div class="selection">
            <div class="title">{{t('selected_ingredients')}}</div>
            <div class="container-list">
              <transition-group name="ing-trans" tag="div" class="list">
                <div
                  class="ingredient-wrapper"
                  v-for="ingredient in selection"
                  :key="ingredient.id"
                >
                  <IngredientItem :data="ingredient" showFamily="true" />
                  <div class="remove-button" @click="removeFromSelection(ingredient)"></div>
                </div>
              </transition-group>
            </div>
            <div class="clear-button" @click="clearSelection">{{t('clear_all')}}</div>
          </div>
        </div>
      </transition>
      <transition name="slide">
        <div class="explore-header" :class="selection?'small':''" v-if="!query">
          <BetterInput
            @change="(value)=>textFilter=value"
            :value="textFilter"
            class="search-input"
            :placeholder="t('search_ingredients')"
          />
          <div class="filters-and-toggle">
            <ListFilters
              v-if="ingredients"
              :listData="ingredients"
              :filterDefs="filterDefs"
              v-model="filterSelection"
              :labelAsSelection="isMobile"
            ></ListFilters>
            <MyToggleButton :class="this.selection?'hidden':'visible'" v-model="groupByPop">
              <template v-slot:default>{{t('toggle_popular_ing')}}</template>
            </MyToggleButton>
          </div>
        </div>
      </transition>
    </div>
    <div class="content-wrapper" ref="wrapper" @scroll="handleScroll">
      <div class="list-wrapper" v-if="!query">
        <LoadingOverlay v-if="loading" text="loading_related" />
        <div class="groups-list" v-if="groups">
          <div class="group" v-for="group in groups" :key="group.grouper.id">
            <intersect @enter="visibleGroups.push(group.grouper.id)">
              <div class="group-wrapper">
                <div class="group-title" :class="group.grouper.id==='best'?'best-matches':''">
                  <div class="name">{{group.grouper.name}}</div>
                  <div class="sup">{{group.ingredients.length}}</div>
                </div>
                <div class="ingredients-list" v-if="visibleGroups.indexOf(group.grouper.id)>=0">
                  <IngredientItem
                    v-for="ingredient in group.ingredients"
                    :key="ingredient.id"
                    :data="ingredient"
                    @click="addToSelection"
                    :showFamily="group.grouper.id==='best'"
                  ></IngredientItem>
                </div>
              </div>
            </intersect>
          </div>
        </div>
      </div>
      <div v-if="query" class="results-wrapper">
        <SimilarPerfumes
          type="by_ingredient"
          :reference="selection"
          @select="(p)=>selectedPerfume=p"
        ></SimilarPerfumes>
      </div>
      <transition name="fade">
        <div v-if="selectedPerfume" class="overlay">
          <PerfumeAndSimilars :data="selectedPerfume" @close="selectedPerfume=null"></PerfumeAndSimilars>
        </div>
      </transition>
      <div
        v-if="!query && selection"
        class="search-button btn btn--primary"
        @click="onClickShowMeResult"
      >{{t('show_me_results')}}</div>
    </div>
  </div>
</template>
<script>
import {getAllIngredients, getRestrictedIngredients} from '@/services/Api.js'
import {applyFilters, matchWords} from '@/utils'
import ListFilters from '@/components/ListFilters'
import MyToggleButton from '@/components/MyToggleButton'
import IngredientItem from '@/components/IngredientItem'
import SimilarPerfumes from '@/components/SimilarPerfumes'
import PerfumeAndSimilars from '@/components/PerfumeAndSimilars'
import BetterInput from '@/components/BetterInput'
import Intersect from 'vue-intersect'
import LoadingOverlay from '@/components/LoadingOverlay'
import { mapGetters } from 'vuex'
export default {
  name: 'ExploreByIngredients',
  metaInfo: {
    title: 'Search by perfume ingredient',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Select your favorite perfume ingredients and Wikiparfum will find the perfumes that best matches your selection.'
    },{
      vmid: 'og:title', name: 'og:title',
      content:"Wikiparfum - Search by perfume ingredient"

    },{
      vmid: 'og:description', name: 'og:description',
      content: 'Select your favorite perfume ingredients and Wikiparfum will find the perfumes that best matches your selection.'
    },{
      vmid: 'twitter:title', name: 'twitter:title',
      content:"Wikiparfum - Search by perfume ingredient"

    },{
      vmid: 'twitter:description', name: 'twitter:description',
      content: 'Select your favorite perfume ingredients and Wikiparfum will find the perfumes that best matches your selection.'
    }]
    },
  components: {
    ListFilters,
    MyToggleButton,
    IngredientItem,
    SimilarPerfumes,
    PerfumeAndSimilars,
    BetterInput,
    Intersect,
    LoadingOverlay
  },
  data(){
    return{
      allIngredients: null,
      groupByPop: false,
      selection: null,
      loading: false,
      selectedPerfume: null,
      hideSelection:false,
      filterDefs: [
        {
          id: 'family',
          filterLabel: 'filter_by_family',
          type: 'options',
          keyPath: 'family.id',
          labelPath: 'family.name',
          female:true
        }
      ],
      visibleGroups: [],
      filterSelection: null,
      query: null,
      textFilter: null
    }
  },
  lazyData: {
    selection: {type: 'ingredient'},
    selectedPerfume: {type: 'perfume'},
  },
  mounted(){
    this.eventTracker.emitEvent('VIEW_OPENED', { type: "EXPLORE_BY_INGREDIENTS" })
    this.lastScroll = 0
  },
  routerLinkedData: ['selection','selectedPerfume','query'],
  asyncComputed:{
    ingredients (){
      if(this.$store.state.i18n.lang) Function.prototype // noop
      this.loading = true
      if(!this.selection) return getAllIngredients().then((response)=>{
        this.loading = false
        response.sort((a, b) => a.name.localeCompare(b.name))
        return response
      })
      else return getRestrictedIngredients(this.selection).then((response)=>{
        this.loading = false
        response.sort((a, b) => a.name.localeCompare(b.name))
        return response
      })
    },
  },
  computed:{
    ...mapGetters(['lang']),
    filteredIngredients (){
      /** @type {any[]} */
      var filtered = this.ingredients && applyFilters(this.ingredients,this.filterSelection) || []
      /** issue #274: Filter ingredients if don't have imageUrl */
      if(this.textFilter){
        return filtered.filter((ing) => {
          return matchWords(ing.name,this.textFilter) || (ing.family && matchWords(ing.family.name,this.textFilter))
        })

      }
      return filtered.filter(e => (!e.useGenericImageUrl && e.imageUrl) || (!e.useGenericLightImageUrl && e.lightImageUrl)) //.slice(0,50)
    },
    groups(){
      if(!this.filteredIngredients) return null
      if(this.selection){
        return [{
          grouper: {id:'best',name:this.t("best_ing_matches")},
          ingredients: this.filteredIngredients
        }]
      } else if (!this.groupByPop) {
        let res = Object.values(this.filteredIngredients.reduce((dict,ing)=>{
          if(ing.family){
            dict[ing.family.id] = dict[ing.family.id] || {grouper: ing.family, ingredients:[]}
            dict[ing.family.id].ingredients.push(ing)
          }
          return dict
        },{}))
        return res//.sort((a, b) => a.grouper.name.localeCompare(b.grouper.name))
      } else  {
        return [{
          grouper: {id:0,name:this.t("popular_ingredients")},
          ingredients: this.filteredIngredients.slice(0,10)
        }]
      }
    },
  },
  methods: {
    handleScroll(){
     const last =this.lastScroll 
      this.lastScroll = this.$refs.wrapper.scrollTop
      if(this.lastScroll < last) 
        this.hideSelection = false
      // We add 30 (like a epsilon) in order to controll the margin and padding of the ingredients ref 
      else if (this.$refs.selection && this.$refs.wrapper.clientHeight + this.$refs.selection.clientHeight+ 30 <= this.$refs.wrapper.scrollHeight)
        this.hideSelection = true
      /*if(this.$refs.wrapper.scrollTop === 0)
        this.hideSelection = false
      //else if(!this.hideSelection && this.$refs.wrapper.scrollHeight - this.$refs.wrapper.scrollTop > this.$refs.selection.clientHeight){
      else if(!this.hideSelection && this.$refs.wrapper.scrollTop > 0  && this.$refs.wrapper.clientHeight + this.$refs.selection.clientHeight<= this.$refs.wrapper.scrollHeight){
        this.hideSelection = true
      }*/
    },
    addToSelection (ingredient) {
      this.$refs.wrapper.scrollTop = 0
      if(!this.selection) this.selection=[ingredient]
      else this.selection.push(ingredient)
    },
    removeFromSelection(ingredient){
      let selectionIds = this.selection.map(e => e.id)
      selectionIds.splice(selectionIds.indexOf(ingredient.id),1)
      if(selectionIds.length === 0){
        this.selection = null
        this.query = null
      }
      else 
        this.selection = selectionIds
      
    },
    clearSelection(){
      this.selection = null
      this.query = null
    },
    onClickShowMeResult(){
      this.query = true
    }
  },
  watch: {
    lang(){
      this.selection = this.selection.map(e => e.id)
    },
    query(){
      this.textFilter = null
      this.filterSelection = null
    },
    selection(){
      this.textFilter = null
      this.filterSelection = null
    }
  }
}
</script>
<style scoped lang="stylus">
$groups-list-margin = vh(40px)

.explore-by-ingredients
  display: flex
  flex-direction: column

  // overflow: hidden // POR LOS SCROLLS DE "results-wrapper"
  .explore-header-container
    align-items: center
    // background-color: $alabaster
    display: flex
    flex-direction: column
    width: 100%

    // NO APROTABA NADA ESTO y no quedaba bien del todo...
    .slide-enter-active,
    .slide-leave-active
      overflow: hidden
      transition: opacity 0.4s

    .slide-enter,
    .slide-leave-to
      height: 0
      opacity: 0

  .explore-header
    align-items: center
    background-color: #fff
    border-bottom: 1px solid rgba(#000, 0.15)
    display: flex
    justify-content: space-between
    padding: vh(10px) 5vw
    position: relative
    width: 100%

    &.small
      m-max-width-container(padding: 5%)

      .mobile &
        m-max-width-container(padding: 0)

    .search-input
      appearance: none
      background-image: url("~@/assets/icons/icon-search.svg")
      background-position: vw(10px) center
      background-repeat: no-repeat
      border: none
      border-radius: 0
      flex: 1 1 50%
      m-font("Lelo", "medium")
      m-font-size(14, 20)
      m-letter-spacing(15)
      outline: none
      padding: vh(5px) vh(10px) vh(5px) vh(40px)

    .filters-and-toggle
      align-items: center
      display: flex
      flex: 1 1 50%
      justify-content: flex-end

      .list-filters
        // Chapuzilla porque ahora mismo NO hay "tuned-toggle-btn"
        // margin-right: vw(20px)
        >>> .drop-down-container
          right: 0

      // LO escondemos de momento
      .tuned-toggle-btn
        display: none
        visibility: hidden

    .mobile &
      align-items: stretch
      flex-direction: column
      // flex-wrap wrap
      margin: 0
      padding: 0

      // un poco guarro, pero parece que es batante "especial" este caso...
      .search-input
        border-bottom: 1px solid rgba(#000, 0.15)
        flex: 1 1 100%
        padding: vw(10px) vw(10px) vw(10px) vw(40px)

      .filters-and-toggle
        align-items: center
        justify-content: space-between
        margin: 0 vw(10px)

        .list-filters
          flex: 0 0 40%
          max-width: 50%
          padding: vw(10px) 0

          >>> .filter
            flex: 1 1 auto
            overflow: hidden

            .dropdown-button
              width: 100%

          >>> .drop-down-container
            height: 45vh
            margin-left: vw(-10px)
            width: 100vw

        .tuned-toggle-btn
          flex: 1 1 0%
          justify-content: flex-end
          padding: vw(8px)

  .selection-wrapper
    background-color: $alabaster
    display: flex
    // flex: 0 0 15vw // VERY UGLY, pero es para poder hacer el "drawer"
    overflow: hidden
    // height: vw(210px) // 24vh // #212 en ipad se ve fatal
    padding-bottom: vh(10px)
    position: relative
    width: 100%

    .mobile &
      // flex: 0 0 50vw // VERY UGLY, pero es para poder hacer el "drawer"
      height: vh(280px) // 24vh

    &.slide-enter-active,
    &.slide-leave-active
      transition: flex 0.4s // , padding 0.4s

    &.slide-enter,
    &.slide-leave-to
      flex: 0 0 0%
      // .mobile &
      // padding 0 vw(10px)

    .selection
      align-items: flex-start
      // background-color: $alabaster
      display: flex
      // flex: 0 0 25vh // VERY UGLY, pero es para poder hacer el "drawer"
      flex-direction: column
      justify-content: center
      m-max-width-container(padding: 5%)
      // overflow: hidden
      position: relative
      width: 100%

      .mobile &
        m-max-width-container(padding: 0)
        padding: 0 vw(10px)

      .title
        m-font("Lelo", "light")
        m-font-size(15, 20)
        m-letter-spacing(80)
        margin-bottom: vh(10px)
        margin-top: vh(10px)
        text-transform: uppercase

        .mobile &
          margin-bottom: vh(20px)
          margin-top: vh(20px)
          width: 100%

      .container-list
        height: 100%
        width: 100%

      .list
        display: flex
        flex: 1 1 0%
        overflow-y: hidden
        width: 100%

        .desktop &
          margin-bottom: vh(10px)

        .ingredient-wrapper
          $margin = vw(20px)
          $percentage = percentage((1 / 7))
          display: flex
          flex-direction: column
          margin-right: vw(30px)
          padding-top: vh(20px)
          position: relative

          &.ing-trans-enter-active,
          &.ing-trans-leave-active
            transition: opacity 0.4s

          &.ing-trans-enter,
          &.ing-trans-leave-to
            opacity: 0

          .mobile &
            $margin = vw(20px)
            $percentage = percentage((1 / 4))
            $width = "calc(%s - %s)" % ($percentage $margin)
            display: flex
            margin-right: 0
            padding-top: 0

          .ingredient-item
            overflow: visible
            width: vw(130px)

            >>> .label
              m-font("Lelo", "light")
              m-font-size(14, 16)

            >>> .fam-label
              m-font("Lelo", "light")
              m-font-size(12, 14)

            .mobile &
              min-width: vw(110px)
              width: vw(130px)

              >>> .label
                m-font-size(14, 16)

              >>> .fam-label
                m-font-size(12, 14)

            >>> .image
              height: vw(63px)
              margin-left: auto
              margin-right: auto
              width: vw(63px)

              .mobile &
                height: vw(56px)
                width: vw(56px)

          .remove-button
            cursor: pointer
            m-icon("close", 12)
            position: absolute
            right: vw(5px)
            top: vh(10px)

            .mobile &
              right: 0
              top: vh(20px)

      .clear-button
        cursor: pointer
        m-font("Lelo", "light")
        m-font-size(13, 16)
        m-letter-spacing(80)
        position: absolute
        right: 10%
        text-transform: uppercase
        top: vh(10px)

        .mobile &
          m-font-size(0)
          m-icon("trash", 18)
          right: vw(14px)
          top: vh(18px)

  .content-wrapper
    display: flex
    flex: 1 1 0%
    flex-direction: column
    height: 100%
    overflow-y: auto

    .mobile &
      padding: 0 vw(10px)

    .list-wrapper
      position: relative

      .loading-overlay
        .mobile &
          margin-left: vw(-10px)
          margin-right: vw(-10px)
          width: "calc(100% + %s)" % vw(20px)

        >>> .content
          margin-top: vh(150px)

      .groups-list
        height: 100%
        m-max-width-container(padding: 5%)
        margin-top: $groups-list-margin

        .mobile &
          padding: 0
          m-max-width-container(padding: 2%)

        .group
          border-bottom: 1px solid rgba(#000, 0.15)
          margin-bottom: vh(50px)
          padding-bottom: vh(50px)

          .group-title
            display: flex
            margin-bottom: vh(20px)

            .mobile &
              margin-left: vw(10px)

            .name
              m-first-letter-case()
              m-font("SangBleu", "normal")
              m-font-size(22, 20)

            .sup
              m-font("Lelo", "light")
              m-font-size(16, 8)
              margin-left: vw(10px)
              vertical-align: super

            &.best-matches
              .name
                m-font("Lelo", "light")
                m-font-size(13, 16)
                m-letter-spacing(80)
                margin-bottom: vh(10px)
                margin-top: vh(10px)
                text-transform: uppercase
                width: 100%

              .sup
                display: none

              .mobile &
                text-align: center
                m-font-size(14, 16)

          .ingredients-list
            display: flex
            flex-wrap: wrap

            .ingredient-item
              $margin-x = vw(15px)
              $margin-y = vh(20px)
              $percentage = percentage((1 / 7))
              $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
              flex: 0 0 $width
              height: 100%
              margin: $margin-y $margin-x

              .mobile &
                $margin = 10px
                $margin-x = vw($margin)
                $margin-y = vh($margin)
                $percentage = percentage((1 / 3))
                $width = "calc(%s - (%s * 2))" % ($percentage $margin-x)
                flex: 0 0 $width
                margin: $margin-y $margin-x

    .results-wrapper
      display: flex
      flex: 1 1 0%
      overflow: visible

      .similar-perfumes
        display: flex
        flex-direction: column
        m-max-width-container(padding: 5%)
        width: 100%

        .mobile &
          m-max-width-container(padding: 0)

        >>> .similars-list
          margin-bottom: vw(20px)
          overflow-y: visible

    .search-button
      bottom: vh(20px)
      cursor: pointer
      left: 50%
      padding: 1.5em 2.5em
      position: absolute
      transform: translate(-50%, 0)
      width: 30%
      z-index: 10 // para que quede por encima del loading ings overlay

      .mobile &
        bottom: 0
        left: 0
        transform: none
        width: 100%

    .overlay
      display: flex
      height: 100%
      left: 0
      overflow: hidden
      position: absolute
      top: 0
      width: 100%
      z-index: 11

      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity 0.4s

      &.fade-enter,
      &.fade-leave-to
        opacity: 0
</style>