// import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import AsyncComputed from 'vue-async-computed'
import RouterLinkedData from './vue-plugins/RouterLinkedData'
import LazyData from './vue-plugins/LazyData'
import DeviceDetect from './vue-plugins/DeviceDetect'
import { getPerfume, getIngredient } from '@/services/Api'
import { EventTrackerPlugin } from './services/tracker'
import ResponsiveCss from './vue-plugins/ResponsiveCss'
import { getMetaIngredientById } from './services/Api'
import VueMeta from 'vue-meta'
import RouterPath from './vue-plugins/RouterPath'

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue() // Global event bus

Vue.mixin(i18n)
Vue.mixin(DeviceDetect)
Vue.mixin(RouterPath)

Vue.use(VueMeta)
Vue.use(RouterLinkedData)
Vue.use(EventTrackerPlugin)

Vue.use(LazyData, {
  types: {
    perfume: {
      lazyGetter: id =>
        getPerfume(id).then(response => {
          return response
        }),
      default: {
        brand: {},
        family: {},
        secondaryFamily: {},
        ingredientProportions: [],
        origin: []
      }
    },
    ingredient: {
      lazyGetter: id =>
        getIngredient(id).then(response => {
          return response
        }),
      default: {
        family: {},
        secondaryFamily: {}
      }
    },
    // FAKE, need real endpoint!!!
    family: {
      lazyGetter: id =>
        getIngredient(id).then(response => {
          return response
        }),
      default: {}
    },
    ingredientMeta: {
      lazyGetter: id => getMetaIngredientById(id).then(response => response),
      default: {
        family: {},
        secondaryFamily: {}
      }
    }
  }
})

Vue.use(AsyncComputed)

var SocialSharing = require('vue-social-sharing')
Vue.use(SocialSharing)

new Vue({
  router,
  store,
  render: h => h(App),
  mixins: [ResponsiveCss]
}).$mount('#app')
