export default {

//Header_and_login,
login: "Conectar",
logout: "Desconectar",
explore_by: "Buscar por",
explore_by_generic: "Seleccionar uno",
explore_by_name_long: "Marca o nombre",
explore_by_name: "Marca o nombre",
explore_by_ingredients: "Ingredientes",
explore_by_concepts: "Ideas",
explore_by_families: "Familias olfativas",
create_profile: "Crear un perfil",
view_profile: "Perfil",
about: "Acerca de nosotros",
logging_in: "Conectar",
create_your_profile: "Crear perfil olfativo",
oops_nothing_yet: "UPS! AQUÍ TODAVÍA NO HAY NADA …",
creating_account: "Creando cuenta",
cookies_and_policies: "Términos y condiciones",
how_it_works_header: "¿Cómo funciona?",
contact: "Contactar con nosotros",
notification_profile_update: "Tus favoritos han sido actualizados. Comprueba si hay nuevas recomendaciones en tu",
profile: "perfil",
user: "usuario",
language: "idioma",
legal: "legal",
accept_terms:"Al crear la cuenta, acepto los $terms_and_conds de uso.",
accept_policy:"Quiero recibir comunicaciones de WikiParfum y acepto la $privacy_policy",

//Home,
find_any_fragrance: "encontrar cualquier fragancia del mundo.",
title_by_name: "buscar por marca o nombre",
desc_by_name: "encontrar tu perfume a partir de fragancias similares",
title_by_ings: "buscar por materia prima",
desc_by_ings: "¿Canela o Almizcle? Encuentra los perfumes más parecidos a estos ingredientes",
title_by_concepts: "búsqueda por ideas",
desc_by_concepts: "encuentra tu perfume pensando en tu color o lugar preferido",
title_by_families: "búsqueda por famílias olfativas",
desc_by_families: "encuentra tu perfume pensando en tu...",

//Explore_by_name,
search_brand_or_name: "Buscar una marca o nombre",

//PerfumeFullView,
expert_reviewed: "revisado por expertos",
ingredients: "ingredientes",
description: "descripción",
perfumist: "perfumista",
concepts: "conceptos",
olfactive_family: "familia olfativa",
main_family: "familia",
sub_family: "sub-familia",
add_to_favs: "añadir al perfil",
remove_from_favs: "eliminar del perfil",
me_family: "Fragrances of the World ",

//PerfumeAndSimilars,
similars: "Perfumes con ingredientes similares",
filter_by_family: "familias",
filter_by_brand: "marcas",
filter_by_gender: "género",
for_him: "Para él",
for_her: "Para ella",
unisex: "Unisex",
all: "Todos",
all_female: "Todas",
similars_explanation: "Similitud determinada por los ingredientes principales y su familia olfativa",

//by_ingredient,
search_ingredients: "Buscar ingredientes",
show_similar: "encontrar perfumes con ingredientes similares",
selected_ingredients: "ingredientes seleccionados",
clear_all: "borrar todo",
show_me_results: "mostrar resultados",
toggle_popular_ing: "El más popular",
popular_ingredients: "Ingredientes populares",
best_ing_matches: "Mejores resultados para la selección",

//by_concepts,
toggle_popular_ideas: "El más popular",
related_concepts: "conceptos relacionados",
trash: "papelera?",
view_concepts: "ver los conceptos",
close_concepts: "conceptos parecidos",
you_are_looking_for: "estás buscando….",
concepts_list: "Lista de conceptos",
no_results_for_this_combination: "No hay perfumes para esta combinación",
select_at_least_one_concept: "Selecciona al menos un concepto",
view_perfumes: "ver perfumes",
back: "Volver",
selected_concepts: "Conceptos seleccionados:",
selected_families:"Familias seleccionadas:",
close:"Cerrar",
filters:"Filtros",
find_concept: "Buscar conceptos...",
smell_of: "Olor a",
touch_of: "Con un toque de",

//PREPROFILE,
add_or_wizard: "puedes añadir directamente o pasar el test si quieres",
add_to_profile: "añadir al perfil",
take_the_wizard: "hacer el test",
back_or_to_profile: "seguir buscando o ir a al perfil",
go_back: "seguir buscando",
enter_profile: "ver mi perfil",
login_title: "conectarse para acceder al perfil",
missing_fields: "¡faltan campos obligatorios!",
wrong_password_check: "¡Las contraseñas no coinciden!",
create_account: "crear una cuenta",
user_already_exists: "Ya existe una cuenta con este correo",
email_address: "Correo electrónico",
password: "Contraseña",
name: "Nombre",
create_password: "Crear una contraseña",
confirm_password: "Confirmar la contraseña",
sign_up: "Inscribirse",
dont_have_account: "¿Todavía no tienes una cuenta?",
signup_title: "crear una cuenta para guardar tu perfil",
already_have_account: "¿Ya tienes una cuenta?",
bad_login: "Correo electrónico y/o contraseña incorrectos",
remember_me: "Recuérdame",

//PROFILE,
wizard_myself: "algo para mi",
wizard_other: "un regalo para alguien",
wizard_what_are_you_looking: "¿qué estás buscando?",
wizard_something_gender: "estás buscando algo…",
wizard_masculine: "masculino",
wizard_femenine: "femenino",
wizard_unisex: "unisex",
your_profile: "tu perfil olfativo",
you_love_perfumes_of_the: "te gustan los perfumes de la familia",
you_love_perfumes_of_the_plural: "te gustan los perfumes de las familias ",
and: "y",
desc_family: "",
desc_families: "",
you_also_love_plural: "también te gustan las notas de la sub-familia",
you_also_love: "también te gustan las notas de las sub-familias",
desc_sub_family: "",
desc_sub_families: "",

//remove_confirmation,
are_you_sure_to_remove: "¿estás seguro de querer eliminarlo del perfil?",
this_will_remove: "si lo eliminas del perfil deberás añadirlo de nuevo",
save: "guardar",

//actions,
select: "seleccionar",
remove: "eliminar",
cancel: "cancelar",
get_recomendations: "recibir recomendaciones",
your_favorite_ings: "tus ingredientes favoritos",
click_to_view: "haga clic en la rueda para ver tus favoritos de cada familia",

//SHARE,
share_to: "compartir...",
facebook: "Facebook",
twitter: "Twitter",
whatsapp: "Whatsapp",
email: "eMail",
share_title: "¡Mira lo que he encontrado en WikiParfum!",
share_desc: "¡Mira lo que he encontrado en WikiParfum!",
share_quote: "¡Mira lo que he encontrado en WikiParfum!",

//LOADER,
loading_related: "cargando los ingredientes que combinan",
loading_perfumes: "cargando los perfumes",
loading_concepts: "cargando los conceptos",

//////////////HOW_IT_WORKS/////////////,
what_is_our_purpose: "¿Cuál es nuestro propósito?",
make_the_choosing: "HAGAMOS QUE LA ELECCIÓN DE UN PERFUME SEA UNA EXPERIENCIA DIVERTIDA Y PERSONAL.",
bring_the_craft: "TODO EL ARTE DE LA PERFUMERÍA, MÁS CERCA DE LA GENTE.",
the_starting_point_was: "El punto de partida fue la necesidad de conocimiento y asesoramiento experto en el mundo de la perfumería que muchas personas suelen expresar. \"Me encantan las fragancias, pero es complicado elegir y saber lo que me gusta \". <br> Creíamos que podíamos transmitir todo el conocimiento de los perfumistas, de una manera pedagógica y sencilla.",
how_does_it_work: "¿Cómo funciona?",
the_quadrification_shows: "La \"CUADRIFICACIÓN\" es la manera visual que tenemos para mostrar los ingredientes dominantes en un perfume, aquellos que definen más un perfume. Cuanto más grande es la imagen, más dominante es ese ingrediente en el perfume. En este ejemplo, \"Haba tonka\" y \"Cacao\" son las notas dominantes, seguidas de \"Jazmín\", \"Nardo\" y \"Almendra\".",
q_and_a: "Preguntas y Respuestas",
are_there_more_ingredients: "¿Pero hay más ingredientes en un perfume? Por supuesto, ¡muchos más! De todas formas, nos centramos en aquellos que definen el carácter principal de una fragancia, y aquellos que se puede aprender a percibir si se entrena el sentido del olfato.",
are_all_ingredients_the_same: "¿Todos los ingredientes son iguales? De ningún modo. Hay una gran diferencia en las calidades, especialmente en ingredientes naturales. Del mismo modo que un abrigo gris puede hacerse con lana básica o con las mejores lanas cashmere, nosotros no podemos reflejar en nuestras clasificaciones la diferente calidad que pueda haber entre ingredientes de distintas fragancias ni la concentración de estos.",
expret_reviewed_means: "“Revisado por expertos” significa que uno de nuestros perfumistas evaluadores ha revisado el perfil de la fragancia y su clasificación en una familia olfativa. Revisamos los perfumes sistemáticamente para asegurarnos que la clasificación y las descripciones sean precisas, basadas en un criterio objetivo y puramente perfumísitico.",
this_field_shows_the_scent: "Este campo muestra la clasificación de los perfumes según Fragrances of the World. Hay varias formas de clasificar las fragancias y no existe un acuerdo universal al respecto. Sin embargo, consideramos importante basarnos en Fragrances of the World, porque son una fuente de datos importante para WikiParfum y porque queremos reconocer el trabajo pionero que hicieron para desarrollar un lenguaje común en el mundo de la perfumería.",
an_independent_perfumistic: "Un punto<br>de vista perfumístico",
the_recomendations_are_100: "Las recomendaciones están basadas en un riguroso análisis del perfil olfativo de cada perfume, determinadas por un potente algoritmo.",
scents_are_recomended: "Las fragancias recomendadas se establecen según las coincidencias de estos perfumes con las preferencias, perfil olfativo o ingredientes que se han guardado.",
scents_are_recomended_second_part: "Los resultados de las recomendaciones no se tratan en ningún caso como equivalentes a las referencias de productos y criterios seleccionados.",
hundredpercent_perfumistic_point_of_view: "Punto de vista 100% perfumista",
perfumes_are_the_heart: "Los perfumistas son la esencia tanto de la lógica de la recomendación como de la visualización de cada uno de los perfumes. ¿Cómo lo hacemos? Cada perfume está ajustado al menos por un experto en perfumería. Además, revisamos rigurosamente los perfumes más populares para asegurar una recomendación precisa. En muchos casos utilizamos tecnología (como un cromatógrafo) para contrastar la opinión de los expertos.",
partnerships: "Asociaciones",
we_have_collaborated: "Hemos colaborado con Fragrances of the World, la mayor guía independiente para la clasificación de fragancias. Publicada por primera vez en 1984 por Michael Edwards, es considerada la más completa y cualitativa \"biblia de los perfumes\" que existe en la actualidad. Michael y su equipo evalúan y clasifican cada una de las nuevas referencias introducidas. Es por ello que también puede verse la clasificación de Fragrances of the World en todos aquellos productos en los que él proporciona información.",
is_the_visualization: "¿La visualización transmite de manera <br>realmente objetiva a que<br>huele?",
as_much_as_it_can_be: "Tanto como es posible, porque parte de una clasificación objetiva, basada en el perfil olfativo, los ingredientes y las familias olfativas. Representamos los ingredientes más dominantes en un aroma. Aquellos que un perfumista puede identificar \"fácilmente\" cuando huele un perfume (por supuesto, incluso si el producto contiene muchos más). Sin embargo, la perfumería no es una ciencia exacta. Los ingredientes pueden variar en calidad y concentración, y por tanto, en la forma en la que impactan a un perfume y su percepción, a pesar de que la visualización pueda ser similar. La calidad y la concentración de los ingredientes son dos aspectos que no podemos visualizar en WikiParfum.",

//POLICIES,
what_are_cookies: "¿Qué son las 'cookies'?",
types_of_cookies: "Tipos de 'cookies'?",
privacy_reference_center: "CENTRO DE REFERENCIA DE PRIVACIDAD",
accept_cookies: "Permitir 'cookies'",
cookies_policy: "Política de 'cookies'",
cookies_desc: "Utilizamos 'cookies' para mejorar tu experiencia en este sitio web. Al navegar por este sitio web, se entiende que aceptas el uso de estas 'cookies'.",
learn_more: "Más información",
cookies_accept: "Aceptar",
privacy_policy: "Política de privacidad",
terms_and_conds: "Términos y condiciones",

//OTHERS,
apply: "aplicar",
search: "buscar por",
contact_us_message_title: "mensaje",
contact_us: "contactar con nosotros",
contact_error: "Ha habido un error de conexión. Por favor, inténtalo más tarde",
contact_success: "¡Mensaje enviado! Gracias por contactar con nosotros.",
contact_send: "enviar",
how_it_works: "Cómo funciona",
no_results: "No hemos podido encontrar ningún perfume con los parámetros seleccionados.",
no_results_search: "Lo sentimos, no hemos podido encontrar lo que estás buscando.",

//FORGOT PASSWORD,
forgot_password: "¿Has olvidado la contraseña?",
reset_password: "Enviar",
forgotten_password: "¿Has olvidado la contraseña?",
forgotten_password_sub_title: "Enviaremos un link para restablecer la contraseña",
check_your_email: "Comprueba tu correo electrónico",
send_link: "Acabamos de enviarte un link para restablecer tu contraseña",
havent_receive: "No has recibido ningún mail?",
change_password: "Cambiar la contraseña",
show_password: "Mostrar la contraseña",
confirm_new_password: "Confirmar la nueva contraseña",
new_password: "Nueva contraseña",

//INGREDIENT CHAPTER,
ingredient_type: "Tipo",
ingredient_parts: "Partes usadas",
ingredient_extraction: "Método de extracción",
ingredient_first: "Perfume Icónico",
history: "Historia",
effect: "Producción y efectos",
more_info: "Más información",
same_ingredient: "Fragancias que contienen este ingrediente",
match_ingredient: "Mejores combinaciones con este ingrediente",
explore_by_ingredient: "Explorar según este ingrediente",
origin: "Origen",
iconic_fragrance: "Perfume icónico de este ingrediente",
production:"Producción",

//FAMILY CHAPTER,
iconic_fragrances: "Perfumes icónicos en esta familia",
Ingredient_family: "Ingrediente en esta familia",
show_more: "Mostrar más",

//SOCIAL LOGIN,
continue_with: "Continuar con",

//ALERTS
turn_your_device:"Por favor gire su dispositivo",
discontinued:"Descatalogado"
}