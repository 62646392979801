
export default {
  state: {
    lang: localStorage.getItem("lang") && ['en','fr','es'].indexOf(localStorage.getItem("lang"))>=0 ? localStorage.getItem("lang") : 'en'
  },
  mutations: {
    setLanguage(state,lang){
      state.lang=lang
    }
  },
  actions: {
    setLanguage(context,lang){
      localStorage.setItem("lang",lang)
      //context.dispatch('clearCache')
      context.commit('setLanguage',lang)
    }
  }
}