<template>
  <div class="concept-venn">
    <canvas ref="canvas" :width="size.width" :height="size.height"></canvas>
  </div>
</template>

<script>
import Size from "./mixins/Size";
import {
  generateVennCircles,
  getIntersectionRegions,
  isInside
} from "./utils/circleUtils";
export default {
  mixins: [Size],
  model: {
    prop: "selection",
    event: "change"
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    selection: {
      type: Object,
      required: true
    }
  },
  mounted() {
    let self = this
    this.$refs.canvas.addEventListener("click", e => {
      let intersections = self.$refs.canvas.intersections
      if (intersections) {
        let offset = self.$refs.canvas.getBoundingClientRect();
        let point = { x: e.clientX - offset.left, y: e.clientY - offset.top };

        let active = intersections.filter(i => {
          return isInside({ x: point.x, y: point.y }, i.groups, []);
        });
        active.sort((a, b) => b.groups.length - a.groups.length);
        let i = active[0];
        if (i) {
          let groupSelection = self.data.reduce(
            (dict, concept) => {
              dict[concept.id] = false;
              return dict;
            },
            {}
          );
          i.groups.forEach(g => {
            groupSelection[g.id] = true;
          });
          console.log("clicked region", groupSelection);
          this.$emit("change", groupSelection);
        }
      }
    });
  },
  watch: {
    size() {
      this.$nextTick(this.draw);
      // this.draw()
    },
    data() {
      this.draw();
    },
    selection:{
      handler(){
        this.draw();
      },
      deep: true
    }
  },
  methods: {
    draw() {
      let ctx = this.$refs.canvas.getContext("2d");
      ctx.clearRect(0, 0, this.size.width, this.size.height);
      let circles = generateVennCircles(
        this.data.length,
        this.size.width * 0.2,
        { x: this.size.width / 2, y: this.size.height / 2 }
      );
      circles.forEach((c, i) => {
        c.radius = c.r;
        c.id = this.data[i].id;
      });
      circles.forEach(c => {
        ctx.strokeStyle = "#000";
        ctx.lineWidth = 1;
        ctx.moveTo(c.x + c.r, c.y);
        ctx.beginPath();
        ctx.arc(c.x, c.y, c.r, 0, 2 * Math.PI);
        ctx.stroke();
        // ctx.fill()
      });

      let intersections = getIntersectionRegions(circles);
      this.$refs.canvas.intersections = intersections
      // let activeIntersections = intersections.filter(i => {
      //   return (
      //     i.groups.every(g => this.selection[g.id] == true) &&
      //     Object.keys(this.selection).every(
      //       k => !this.selection[k] || i.groups.find(g => g.id === k)
      //     )
      //   );
      // });

      let activeIntersections = intersections.filter(i => {
        return (
          i.groups.some(g => this.selection[g.id] == true) &&
          Object.keys(this.selection).every(
            k => !this.selection[k] || i.groups.find(g => g.id === k)
          )
        );
      });
      activeIntersections.forEach((int)=>{
        ctx.fillStyle = `rgba(0,0,0,0.1)`;
        ctx.fill(new Path2D(int.path));
      })
      
    }
  }
};
</script>

<style lang="stylus" scoped>
// .concept-venn
//   border: solid 2px
</style>