export function cleanConceptLabel(label) {
  return label
  // return label
  //   .split(',')[1]
  //   .replaceAll("'", '')
  //   .replaceAll(')', '')
}

export function getBBox(context, fontSize, text, node) {
  node.lastMeasured ||= {}
  if (node.lastMeasured.fontSize !== Math.round(fontSize)) {
    context.font = `${fontSize}px Arial`
    let metrics = context.measureText(text)
    // let fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
    let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
    if (node.selected) actualHeight = actualHeight * 1.5
    node.lastMeasured.fontSize = Math.round(fontSize)
    node.lastMeasured.bbox = { width: metrics.width, height: actualHeight }
  }
  return node.lastMeasured.bbox
}

export function isInsideRect(point, node) {
  let rect = { ...node.bbox, x: node.x - node.bbox.width / 2, y: node.y - node.bbox.height / 2 }
  return point.x > rect.x && point.x < rect.x + rect.width && point.y > rect.y && point.y < rect.y + rect.height
}


const RELATED_THRESHOLD = 1

export function setDists(concepts,selection){
  if(selection.length === 0){
    concepts.forEach((c)=>{
      c.dist = 0
    })
    return
  }
  let selected = concepts.filter(c =>
    selection.find(cc => cc.id === c.id)
  )
  let other = concepts.filter(c => !selection.find(cc => cc.id === c.id))

  selected.forEach(d => {
    d.dist = 0
    d.overThreshold = true
  })

  other.forEach(d => {
    let dists = selection.reduce((ds, sel) => {
      let candidate = sel.relations.find(rel => rel.id === d.id)
      if (candidate) ds.push(candidate.dist)
      return ds
    }, [])
    d.dist = dists.reduce((min, dist) => Math.min(min, dist), 999)
    d.overThreshold = false

  })
  let sortedNodes = [...other]
  sortedNodes.sort((a, b) => a.dist - b.dist)
  // sortedNodes.forEach(d => (d.visible = false))
  let nonZeroes = sortedNodes.filter(d => d.dist > 0)
  let visibleConcepts = nonZeroes.filter(d => d.dist < RELATED_THRESHOLD)
  if (visibleConcepts.length < 10) {
    visibleConcepts = nonZeroes.slice(0, 10)
  } else if (visibleConcepts.length > 100) {
    visibleConcepts = nonZeroes.slice(0, 100)
  }
  visibleConcepts.forEach(d => (d.overThreshold = true))

}