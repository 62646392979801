<template>
  <div class="explore-by-concepts">
    <template v-if="conceptNodes && perfumesNodes">
      <div class="concept-wrapper">
        <concept-sphere 
          :concepts="filteredConceptNodes" 
          :perfumes="perfumesNodes" 
          v-model="currentSelection" 
          @region="onRegionClick" 
          :highlightedRegion="highlightedRegion"
          @highlight="highlightedRegion=$event"
          @add-warn="doShowWarn"
        />
        <!-- <div class="concept-list-toggle" v-if="isMobile">
          <button class="toggle-btn" @click="openDialog=true">CONCEPTS LIST</button>
        </div> -->
        <concept-list-dialog v-if="isMobile" :concepts="conceptNodes" v-model="currentSelection"></concept-list-dialog>
    
        <template v-else>
          <concepts-filter :concepts="conceptNodes" v-model="selectedCategories"/>
          <concepts-search :concepts="conceptNodes" @select="tryAddConcept($event)"/>
        </template>
        <concept-tag-selector 
          v-if="currentSelection && currentSelection.length"
          v-model="currentSelection" 
          :title="t('selected_concepts')"
          :highlighted="highlightedRegion" 
          @highlight="highlightedRegion=$event"
          @show-recos="selectedRegion=$event"
        />
        <transition name="fade">
          <div class="max-warn" v-if="showWarn">3 concepts maximum. <br/> Remove one to add one.</div>
        </transition>
      </div>
      <transition name="slide">
        <div class="results-wrapper" v-if="selectedRegion">
          <result-explore-by-concepts 
            :perfumes="regionPerfumes" 
            :concepts="currentSelection" 
            v-model="selectedRegion" 
            @select="(perfume)=>selectedPerfume=perfume"
          />
        </div>
      </transition>
      <transition name="fade">
        <div class="selected-perfume-wrapper" v-if="selectedPerfume">
          <!-- SELECTED: {{selectedPerfume.name}} -->
          <perfume-full-view
            :data="selectedPerfume"
            @close="selectedPerfume=null"
            :canShowSimilar="false"
            :showClose="true"
            :tabletFormat="isTablet"
            :minified="false"
            :onlyquad="isMobile"
          />
        </div>
      </transition>
    </template>

    <LoadingOverlay v-else text="loading_concepts" />

  </div>
</template>

<script>
import ConceptSphere from '../components/Concepts/ConceptSphere.vue'
import ConceptListDialog from '../components/Concepts/ConceptListDialog.vue'
import {getConcepts,getConceptSimilarities,getConceptPerfumes} from '@/services/Api'
import {setDists} from '../components/Concepts/utils/conceptUtils'
import ResultExploreByConcepts from '../components/ResultExploreByConcepts.vue'
import PerfumeFullView from '@/components/PerfumeFullView'
import LoadingOverlay from '@/components/LoadingOverlay'
import { eventTracker } from '../services/tracker'
// import ConceptVenn from '../components/Concepts/ConceptVenn.vue'

// import ConceptTag from '@/components/ConceptTag'
import ConceptTagSelector from '@/components/Concepts/ConceptTagSelector'
import ConceptsFilter from '@/components/Concepts/ConceptsFilter'
import ConceptsSearch from '@/components/Concepts/ConceptsSearch'

export default {
  name: 'ExploreByIngredients',
  metaInfo: {
    title: 'Search by concepts',
    meta: [{
      vmid: 'description',
      name: 'description',
      content: ''
    },{
      vmid: 'og:title', name: 'og:title',
      content:"Wikiparfum - Search by concepts"

    },{
      vmid: 'og:description', name: 'og:description',
      content: ''
    },{
      vmid: 'twitter:title', name: 'twitter:title',
      content:"Wikiparfum - Search by concepts"

    },{
      vmid: 'twitter:description', name: 'twitter:description',
      content: ''
    }]
    },
  data(){
    return {
      currentSelection: [],
      selectedRegion:null,
      highlightedRegion: {},
      // openDialog:false,
      selectedPerfume: null,
      selectedCategories:[],
      showWarn: false
    }
  },
  routerLinkedData: ['currentSelection','selectedRegion','selectedPerfume'],
  lazyData: {selectedPerfume: {type: 'perfume'}},
  methods:{
    setFilter(field,value){
      this.filters = {...this.filters,[field]:value}
    },
    onRegionClick(region){
      // highlightedRegion===$event?selectedRegion=highlightedRegion:highlightedRegion=$event
      if(this.highlightedRegion && Object.values(this.highlightedRegion).some((v)=>v) && JSON.stringify(this.highlightedRegion)===JSON.stringify(region)){
        this.selectedRegion = this.highlightedRegion
      } else {
        this.highlightedRegion = region
      }
    },
    convertCurrentSelection(){
      if(this.currentSelection && this.currentSelection.length && this.conceptNodes && this.currentSelection.every((c)=>typeof c === 'string')){
        this.currentSelection = this.currentSelection.map((id)=>this.conceptNodes.find((cc)=>cc.id===id))
      }
    },
    tryAddConcept(c){
      if(!this.currentSelection) this.currentSelection = []
      if(this.currentSelection.length<3) {
        eventTracker.emitEvent('ACTION', { type: 'CONCEPT_SELECTED', data: { id: c } })
        this.currentSelection.push(c)
      }
      else this.doShowWarn()
    },
    doShowWarn(){
      this.showWarn = true
      setTimeout(()=>{
        this.showWarn = false
      },1000)
    }
  },
  components: {
    ConceptSphere,
    ConceptListDialog,
    ResultExploreByConcepts,
    ConceptTagSelector,
    PerfumeFullView,
    LoadingOverlay,
    ConceptsFilter,
    ConceptsSearch
  },
  mounted() {
    eventTracker.emitEvent('VIEW_OPENED', { type: 'EXPLORE_BY_CONCEPTS'})
  },
  asyncComputed: { 
    conceptData() {
      return getConcepts().then(resp => {
        return resp.reduce((dict, ob) => {
          if (ob.visible) {
            dict[ob.id] = ob
          }
          return dict
        }, {})
      })
    },
    
    concepts_x_concepts() {
      return getConceptSimilarities()
    },
    concepts_x_perfumes() {
      return getConceptPerfumes()
    },

  },
  watch:{
    currentSelection(newSel, oldSel){
      if (!newSel) newSel = []
      if (!oldSel) oldSel = []
      const addedConcepts = newSel.map(c => c.id).filter(c => !oldSel.map(c => c.id).includes(c))
      const removedConcepts = oldSel.map(c => c.id).filter(c => !newSel.map(c => c.id).includes(c))
      addedConcepts.forEach(c => {
        c && eventTracker.emitEvent('ACTION', { type: 'SELECT_CONCEPT', data: { id: parseInt(c) } })
      })
      removedConcepts.forEach(c => {
        c && eventTracker.emitEvent('ACTION', { type: 'UNSELECT_CONCEPT', data: { id: parseInt(c) } })
      })
      this.convertCurrentSelection()
      setDists(this.filteredConceptNodes || [],this.currentSelection || [])
      this.highlightedRegion = {}
    },
    selectedRegion(){
      if(this.selectedRegion) this.highlightedRegion = this.selectedRegion
    },
    filteredConceptNodes(){
      this.convertCurrentSelection()
      setDists(this.filteredConceptNodes || [],this.currentSelection || [])
    },
  },
  computed: {
    // selectedRegionIds(){
    //   return this.selectedRegion ? Object.keys(this.selectedRegion).filter(key =>this.selectedRegion[key] ): []
    // },
    perfumesDict(){
      let perfumesDict = {}
      let conceptsDict = this.concepts_x_perfumes
      Object.keys(conceptsDict).forEach(conceptKey => {
        let perfumes = conceptsDict[conceptKey]
        perfumes.forEach(perf => {
          perfumesDict[perf.id] ||= {
            ...perf,
            groups: [],
            scores:{},
            score: perf.recoValue, // Bad, it catch the first
            // label: 'whateves',
          }

          perfumesDict[perf.id].groups.push(conceptKey)
          perfumesDict[perf.id].scores[conceptKey]=perf.recoValue
        })
      })
      return perfumesDict
    },
    regionPerfumes(){
      let include = Object.keys(this.selectedRegion).filter((k)=>this.selectedRegion[k])
      // let exclude = Object.keys(this.selectedRegion).filter((k)=>!this.selectedRegion[k])
      // console.log(this.perfumesNodes)
      const regionPerfumes = this.perfumesNodes.filter(p => {
        return include.every((g)=>p.groups.includes(g)) // && exclude.every((g)=>!p.groups.includes(g))
      })
      // console.log(regionPerfumes)
      return regionPerfumes.sort((a,b)=>Math.sign(include.reduce((sum,k) => sum+b.scores[k],0)-include.reduce((sum,k) => sum+a.scores[k],0)))
    },
    selectedIds(){
      return this.currentSelection && this.currentSelection.map(concept => concept.id)
    },
    
    relatedConcepts(){
      if(this.currentSelection && this.currentSelection.length===0) return this.conceptNodes
      else return this.conceptNodes.filter(concept =>concept.overThreshold)
    },
    conceptNodes() {
      if (!this.conceptData || !this.concepts_x_concepts) return null
      let conceptDict = this.concepts_x_concepts
      
      let concepts = Object.keys(this.conceptData).map(key => {
        return {
          id: key,
          label: this.conceptData[key]?.name || `? (${key})`,
          relations: Object.keys(conceptDict[key] || {}).map(subKey => {
            return {
              id: subKey,
              label: this.conceptData[subKey]?.name || `? (${key})`,
              dist: conceptDict[key][subKey], // / 100, //NORMALIZAMOS, porque venian de 0 a 100
            }
          }),
          categories: this.conceptData[key]?.categories
        }
      })
      return concepts //.slice(0, 100)
    },
    filteredConceptNodes(){
      let ret = this.selectedCategories.length===0 
        ? this.conceptNodes 
        : this.conceptNodes.filter(concept =>
          (this.currentSelection && this.currentSelection.includes(concept)) ||
          this.selectedCategories.some(cat => concept.categories.map(c => c.name).includes(cat))
        )
      return ret
    },
    perfumesNodes() {
      if (!this.conceptData || !this.concepts_x_perfumes) return null
      let perfumesDict = {}
      let conceptsDict = this.concepts_x_perfumes
      console.log('conceptsDict',conceptsDict
)
      Object.keys(conceptsDict).forEach(conceptKey => {
        let perfumes = conceptsDict[conceptKey]
        perfumes.forEach(perf => {
          perfumesDict[perf.id] ||= {
            id: perf.id,
            groups: [],
            family: perf.familyId,
            secondaryFamily: perf.secondaryFamilyId,
            score: perf.recoValue, // Bad, it catch the first
            scores:{}
            // label: 'whateves',
          }
          perfumesDict[perf.id].groups.push(conceptKey)
          perfumesDict[perf.id].scores[conceptKey]=perf.recoValue
        })
      })
      
      return Object.values(perfumesDict)

    },
  },
  
}
</script>
<style scoped lang="stylus">
.explore-by-concepts
  display: flex
  flex-direction: column

  .concept-wrapper
    display: flex
    height: 100%
    width: 100%

    .concepts
      flex: 1 1 100%

    .concept-list-toggle
      position: absolute
      bottom: 0
      left: 50% 
      .toggle-btn
        background-color: #fff
        // border: 1px solid #000
        border-top-left-radius 5px
        border-top-right-radius 5px
        filter drop-shadow(0px 3px 5px rgba(0,0,0,0.1))
        border-bottom none
        color: #000
        transform: translate(-50%, 0)
    // .btn-filter
    //   background-color: #fff
    //   border: 1px solid #000
    //   border-radius: 100px
    //   color: #000
    //   padding: vw(10px) vw(25px)
    //   position: absolute
    //   right: vw(20px)
    //   top: vw(20px)

    //   .mobile &
    //     bottom: vw(20px)
    //     left: 50%
    //     right: auto
    //     top: auto
    //     transform: translate(-50%, 0)

    .concept-tag-selector
      left: 50%
      position: absolute
      top: 85%
      transform: translate(-50%, 0)

      .mobile &
        height: 100%
        left: 0
        top: 0
        transform: translate(0, 0)
        width: 100%

  .results-wrapper
    background: #fff
    display: flex
    height: 100%
    position: absolute
    width: 100%
    z-index: 10;
    // BAsurilla de transition, pero algo asi...
    &.slide-enter-active,
    &.slide-leave-active
      transition: transform 0.5s

    &.slide-enter,
    &.slide-leave-to
      // opacity: 0
      transform: translate(0, 100%)

  .selected-perfume-wrapper
    background: #fff
    display: flex
    height: 100%
    overflow-y: auto
    position: absolute
    width: 100%
    z-index: 11;
    // BAsurilla de transition, pero algo asi...
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.5s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0

  .concepts-loading
    height: 100%
    position: absolute
    width: 100%

    .text
      position: absolute
      m-font-size(24)
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
    
  
  .concepts-filter
    position: absolute
    left: vh(20px)
    top vh(20px)
    z-index: 0
  
  .concepts-search
    position: absolute
    right: vh(20px)
    top vh(20px)

  .max-warn
    position absolute
    top 50%
    left 50%
    color #e43
    m-font('Lelo','medium')
    text-align: center
    background #fff
    padding vw(10px)
    transform translate(-50%,-50%)
    m-font-size(20px)
    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.5s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0
</style>