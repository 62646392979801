<template>
  <div itemscope itemtype="http://schema.org/Product" class="perfume-list-item" @click="onSelected">
    <meta itemprop="image" :content="data.imageUrl"/>
    <meta itemprop="description" :content="data.description"/>
    <meta itemprop="url" :content="perfumePath(data.id)"/>
    <div class="image-wrapper">
      <div class="image" :style="{backgroundImage: `url(${data.imageUrl})`}"></div>
      
    </div>
    <div class="label-and-family">
      <div class="label">
        <div itemprop="name" class="name" :class="nameSizeClass(data.name)">{{data.name}}</div>
        <div class="brand-and-tag">
          <div itemprop="brand" class="brand" :class="{separator:data.subBrand}">{{data.brand.name}}</div>
              <div v-if="data.subBrand" class="sub-brand">{{data.subBrand.name}}</div>
        </div>
        
      </div>
      <div class="icon-discontinued">
        <FamiliesWithIcon itemprop="additionalProperty" v-if="showFamily" :family="data.family" :subfamily="data.secondaryFamily"></FamiliesWithIcon>
        <div v-if="data.discontinued" class="discontinued">{{t("discontinued")}}</div>
      </div>
    </div>
    <div class="action">
      <slot name="action"></slot>
    </div>
    <div class="remove" v-if="showRemove" @click="$emit('remove')"></div>
  </div>
</template>

<script>
import FamiliesWithIcon from '@/components/FamiliesWithIcon'
import NameSizeClasses from '@/vue-plugins/NameSizeClasses'
export default {
  name: 'PerfumesListItem',
  components:{
    FamiliesWithIcon
  },
  mixins: [NameSizeClasses],
  props: {
    data: Object,
    showRemove: {
      type: Boolean,
      default: false
    },
    showFamily: {
      type: Boolean,
      default: true
    }
  },
  methods:{
    onSelected(){
      this.$emit('click',this.data)
    }
  }
}
</script>

<style scoped lang="stylus">
.perfume-list-item
  align-items: center
  cursor: pointer
  display: flex
  padding: vh(10px) 0
  position: relative

  .mobile &
    padding: vh(30px) 0

  &:not(:last-child)
    border-bottom: 1px solid rgba(#000, 0.1)

    .mobile &
      border-bottom: 1px solid rgba(0, 0, 0, 0.05)

  .image-wrapper
    // padding: vw(10px)
    margin-right: vw(10px)
    margin-top vh(10px)
    margin-bottom vh(10px)

    .mobile &
      margin-left: vw(10px)

    .image
      background-position: center center
      background-repeat: no-repeat
      background-size: contain
      height: vw(65px)
      // margin: vw(10px) vw(20px)
      width: @height

      .mobile &
        height: vw(60px)
        // margin: vw(5px) vw(5px) vw(5px) vw(10px)
        width: @height

  .label-and-family
    display: flex
    flex: 1 1 0
    overflow: hidden
    align-items center
    .mobile &
      align-items: flex-start
      flex-direction: column
    .icon-discontinued
      display flex
      align-items center
      overflow hidden
      margin-left: vw(80px)
      margin-right: vw(40px)
      flex 1 1 0px
      .mobile &
        margin: 0
        margin-top: vw(10px)
        width: 100%
        flex 0 0
      .family-and-icon
        flex: 1 1 50%
        overflow: hidden
        >>> .label
          m-font-size(12, 16)
          font-weight: 300
      .discontinued
        m-font("Lelo", "light")
        m-font-size(12, 16)
        text-transform capitalize
        background-color #EEEEEE
        padding vh(2px) vw(7px) vh(2px) vw(7px)

    .label
      flex: 0 0 45%
      overflow: hidden

      .mobile &
        flex: 0 0 50%
        margin-bottom: vh(5px)
        width: 100%

      .name
        m-ellipsis(100%)
        m-font-size(20, 26)
        margin-bottom: vh(5px)

        // margin-bottom: vh(2px)

        // &.small-font
        // m-font-size(24, 28)

        // &.extra-small-font
        // m-font-size(20, 22)
        // .mobile &
          // m-font-size(20, 26)
          // &.small-font
          // m-font-size(20, 22)
      .brand-and-tag
        display flex
        align-items center
        .brand
          m-ellipsis(100%)
          m-font("Lelo", "light")
          m-font-size(13, 16)
          m-letter-spacing(16)
          text-transform: uppercase
        .separator
          position relative
          margin-right vw(10px)
          padding-right vw(10px)
          &:after
            background-color rgba(0,0,0,0.15)
            content ""
            height 100%
            position absolute
            right 0
            top 0
            width 1px
        .sub-brand
          m-ellipsis(100%)
          m-font("Lelo", "light")
          m-font-size(11, 16)
          text-transform uppercase
        
    

  .action
    .arrow
      m-icon("big-arrow", 30)
      margin-left: auto

      .mobile &
        m-icon("big-arrow", 20)
        margin-right: vw(10px)

      .mobile &
        m-icon("big-arrow", 25)
        margin-right: vw(10px)

  .remove
    cursor: pointer
    m-icon("close", 15)
    position: absolute
    right: vw(10px)
    top: vh(20px)
</style>