import { render, staticRenderFns } from "./ResultExploreByFamilies.vue?vue&type=template&id=8115c458&scoped=true&"
import script from "./ResultExploreByFamilies.vue?vue&type=script&lang=js&"
export * from "./ResultExploreByFamilies.vue?vue&type=script&lang=js&"
import style0 from "./ResultExploreByFamilies.vue?vue&type=style&index=0&id=8115c458&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8115c458",
  null
  
)

export default component.exports