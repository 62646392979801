<template>
  <div class="remove-confirmation">
    <div class="background" @click="$emit('close')"></div>
    <div class="content">
      <div class="title">{{t('are_you_sure_to_remove')}}</div>
      <div class="sub-title">{{t('this_will_remove')}}</div>
      <div class="actions">
        <SquareButton @click="$emit('close')">{{t('cancel')}}</SquareButton>
        <SquareButton @click="doRemove">{{t('remove')}}</SquareButton>
      </div>
      <div class="close-btn" @click="$emit('close')"></div>
    </div>
  </div>
</template>

<script>
import SquareButton from '@/components/SquareButton'

export default {
  name: 'RemoveConfirmation',
  components: {
    SquareButton
  },
  props: ['data'],
  computed:{
  },
  methods:{
    doRemove(){
    //  console.log("REmove me from store")
     this.$store.dispatch('toggleFav',  { ...this.data, eventTracker: this.eventTracker })
     this.$emit('close')
    }
  }
}
</script>
<style scoped lang="stylus">
.remove-confirmation
  height: 100%
  width: 100%

  .background
    background: #fff
    height: 100%
    position: absolute
    width: 100%
    z-index: 0

  .content
    align-items: center
    background: #fff
    display: flex
    flex-direction: column
    height: 100%
    justify-content: center
    left: 50%
    padding: vh(40px) vw(80px)
    position: absolute
    top: 0
    transform: translate(-50%, 0)
    width: 80vw

    .mobile &
      padding: vw(10px)
      width: 100%

    .title
      m-first-letter-case()
      m-font-size(30, 40)
      margin-bottom: vh(40px)
      text-align: center

    .sub-title
      m-first-letter-case()
      m-font-size(22, 30)
      margin-bottom: vh(40px)
      text-align: center

    .actions
      align-items: center
      display: flex
      .mobile &
        width: 100%
      
      .btn
        .mobile &
          flex: 1 1 0

    .close-btn
      cursor: pointer
      m-icon("close", 20)
      position: absolute
      right: vw(17px)
      top: vh(30px)
</style>