<template>
  <div class="reco-desc">
    <!-- <div class="intro" v-html="texts.intro"></div> -->
    <div class="content" v-html="texts.content"></div>
  </div>
</template>

<script>

function firstLetterCase (text) {
  // return text.replace(/^\w/, c => c.toUpperCase())
  return text.charAt(0).toUpperCase() + text.slice(1)
}
function concatWithAnd (array,andString) {
  if(array.length===1) return array[0]
  if(array.length===2) return array.join( ' ' + andString + ' ')
  return array.slice(0,-1).join(', ') + ' ' + andString + ' ' + array[array.length-1]
}
export default {
  name: 'RecoDesc',
  props: ['data'],
  mounted(){
    console.log('PROFILE FAMILIES: ',this.$store.getters.profileFamiliesWithSubs)
    console.log('USER INGREDIENTS: ',this.$store.getters.profileIngredients)
    console.log('COMMON FAMs',this.$store.getters.profileFamiliesWithSubs.filter((f)=>{
        return (this.data.family && f.family.id === this.data.family.id) || (this.data.secondaryFamily && f.family.id === this.data.secondaryFamily.id)
      }))
  },
  computed:{
    texts(){
      let descHero,descFamilies,descIng,descOther,fullDesc
      // let descs = []
      let famsAndSubs = this.$store.getters.profileFamiliesWithSubs // FALTAN LAS profile fams ? Utils.getProfileFamiliesAndSubs(this.props.profile)
      let userIngredients = this.$store.getters.profileMainIngredients // Faltan los profile ings
      let commonFams = famsAndSubs.filter((f)=>{
        return (this.data.family && f.family.id === this.data.family.id) || (this.data.secondaryFamily && f.family.id === this.data.secondaryFamily.id)
      })
      let commonIngs = this.data.ingredientProportions.slice(0,5).filter((p)=>userIngredients.find((i)=>i.id === p.ingredient.id))
      
      // CHAPUZILLA porque si hay mas de un hero, no viene como "heroIngreident", pero lo podemos detectar por "proportion=10"
      let commonHero
      let heroId = this.data.heroIngredient && this.data.heroIngredient.id
      if(heroId && commonIngs.find((i)=>i.ingredient.id===heroId)) commonHero = this.data.heroIngredient
      else {
        let otherHero = this.data.ingredientProportions.find((d)=>d.proportion>=10 && d.ingredient.id!==heroId)
        if(otherHero) commonHero = otherHero.ingredient
      }

      if(commonIngs.length > 0) {
        // if(this.data.type==='hero') descIng = this.t('recommended_by_ings_short').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.t('and')))
        // else descIng = this.t('recommended_by_ings').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.t('and')))
        if(this.data.recoType==='hero' && commonHero) commonIngs = commonIngs.filter((i)=>i.ingredient.id!==commonHero.id)
        if(commonIngs.length>0){
          descIng = this.t('recommended_by_ings').replace('%INGS%',concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.t('and')))
        }
        // descs.push(descIng)
      }
      if(this.data.recoType==='hero' && commonHero) {
        descHero = this.t('recommended_by_hero').replace('%HERO%',commonHero.name)
        fullDesc = descHero + (descIng?(`, ${this.t('and')} `+descIng+'.'):'.')
      }
      else if(this.data.recoType==='family'){
        descFamilies = this.t('recommended_by_fam').replace('%FAMILY%',concatWithAnd(commonFams.map((f)=>f.family.name),this.t("and")))
        fullDesc = descFamilies + (descIng?(`, ${this.t('and')} `+descIng+'.'):'.')
      } else {
        descOther = this.t('recommended_by_other')
        fullDesc = descOther + (descIng?(`, ${this.t('and')} `+descIng+'.'):'.')
      }
      fullDesc = firstLetterCase(fullDesc)

      return {content: fullDesc}
    },
    // texts_OLD(){
    //   let descHero,descFamilies,descIng
    //   let descs = []
      
    //   // if(this.props.isRecomended) descIntro = this.t('reco_desc_title')
    //   // else descIntro = this.t('possible_reco_title')
    //   // descIntro = this.t('reco_desc_title')
      
    //   let famsAndSubs = this.$store.getters.profileFamiliesWithSubs // FALTAN LAS profile fams ? Utils.getProfileFamiliesAndSubs(this.props.profile)
    //   let userIngredients = this.$store.getters.profileIngredients // Faltan los profile ings
    //   let commonFams = famsAndSubs.filter((f)=>{
    //     return (this.data.family && f.family.id === this.data.family.id) || (this.data.secondaryFamily && f.family.id === this.data.secondaryFamily.id)
    //   })
      
    //   if(commonFams.length>0) {
    //     descFamilies = this.t('recommended_by_fam').replace('%FAMILY%',concatWithAnd(commonFams.map((f)=>f.family.name),this.t("and")))
    //   } else {
    //     descFamilies = this.t('not_recommended_by_fam')
    //   }
      
    //   if(this.data.type==='hero') {
    //     descHero = this.t('recommended_by_hero').replace('%HERO%',this.data.heroIngredient.name)
    //     descs.push(descHero)
    //   }
      
      
    //   let commonIngs = this.data.ingredientProportions.slice(0,5).filter((p)=>userIngredients.find((i)=>i.id === p.ingredient.id))
    //   if(this.data.type==='hero') commonIngs = commonIngs.filter((i)=>i.ingredient.id !== this.data.heroIngredient.id)
    //   if(commonIngs.length > 0) {
    //     // if(this.data.type==='hero') descIng = this.t('recommended_by_ings_short').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.t('and')))
    //     // else descIng = this.t('recommended_by_ings').replace('%INGS%',Utils.concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.t('and')))
    //     descIng = this.t('recommended_by_ings').replace('%INGS%',concatWithAnd(commonIngs.map((i)=>i.ingredient.name),this.t('and')))
    //     descs.push(descIng)
    //   }
      
    //   // if(this.data.type==='expert') isExpert = true
      
    //   let fullDesc = 'nodesc'

    //   if(this.data.type==='hero') fullDesc = descHero
    //   if(this.data.type==='family') fullDesc = `${this.t('it')} `+descFamilies+(descIng?(`, ${this.t('and')} `+descIng):'')
    //   if(this.data.type==='ingredient' || this.data.type==='expert'){
    //     if (commonFams.length>0) {
    //       if(descIng) fullDesc =  `${this.t('it')} ` + descIng //+`, ${this.t('and')} ${this.t('it')} `+descFamilies
    //       else fullDesc =  `${this.t('it')} `+descFamilies
    //     } else {
    //       if(descIng) fullDesc = `${this.t('it')} `+ descIng //+`.<br/><br/>${descFamilies}`
    //       else fullDesc = descFamilies
    //     }
    //   }
    //   fullDesc = firstLetterCase(fullDesc)
    //   return {
    //     // intro: descIntro+':',
    //     content: fullDesc,
    //   }
    //   // return (
    //   //   <div className='reco-description'>
    //   //     {isExpert && <div className="expert-tag"><div className="expert-icon"/>{this.t('expert_reco')}</div>}
    //   //     <div className='intro' dangerouslySetInnerHTML={innerHTML(descIntro+'...')}></div>
    //   //     <div className='text' dangerouslySetInnerHTML={innerHTML(fullDesc)}></div>

    //   //   </div>
    //   // )
    // }
  }
}
</script>

<style>

</style>