<template>
  <div class="concepts-search" v-click-outside="()=>resultsDismissed=true">
    
    <div class="results" v-if="showResults">
      <template v-if="results && results.length">
        <div class="result" v-for="concept in results" :key="concept.id" @click="()=>onClick(concept)">
          {{concept.label}}
        </div>
      </template>
      <div v-else class="no-results">Nothing found</div>
    </div>
    <div class="input-box">
      <input class="search-input" v-model="search" :placeholder="t('find_concept')" @focus="resultsDismissed = false">
      <div class="icon" :class="search?'close':'search'" @click="search=null"></div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  props:{
    concepts:{type: Array,required: true}
  },
  directives: {
    ClickOutside
  },
  data(){
    return {
      search: null,
      resultsDismissed: false
    }
  },
  computed:{
    results(){
      return this.search ? this.concepts.filter(concept => concept.label.toLowerCase().indexOf(this.search.toLowerCase())>-1) : []
    },
    showResults(){
      return this.search && !this.resultsDismissed
    }
  },
  methods:{
    onClick(concept){
      this.$emit('select',concept)
      this.resultsDismissed=true
    }
  },
  watch:{
    search() {
      this.resultsDismissed = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.concepts-search
  .input-box
    display flex
    border solid 1px #ddd
    border-radius vw(20px)
    padding vw(8px) vw(16px)
    position relative
    background: #fff
    // background-image: url("~@/assets/icons/icon-search.svg")
    // background-position: vw(15px) center
    // background-repeat: no-repeat
    // background-size: vw(20px) vh(30px)
    .search-input
      border none
      outline: none;
    .icon
      cursor: pointer
      &.search
        m-icon('search',14)
      &.close
        m-icon('close',14)
  .results
    background #fff
    border solid 1px #ddd
    border-radius vw(20px)
    // margin-top vw(8px)
    padding vw(8px)
    width 100%
    padding-top: vw(35px);
    position absolute
    .result
      margin vw(4px)
      cursor: pointer
</style>