<template>
  <div class="world-map">
    <svg ref="svg">
    </svg>
  </div>
</template>

<script>
import d3 from '@/utils/d3Importer'
import world from '@/assets/world/topo-countries.json'
import countryMapping from '@/assets/world/country_mapping.json'
import * as topojson from 'topojson-client'
const color = ['rgba(0,0,0,0.18)','rgba(0,0,0,0.12)','rgba(0,0,0,0.06)']
export default {
  name: "WorldMap",
  props:{
    points:{type:Array,required:true}
  },
  mounted(){
    this.drawMap()
  },
  methods:{
    /**
     * @param {any} country 
     * @param {Number} index
     */
    setColorCountry(country,index){
      const point = this.points.map(e => ({...e,...countryMapping[e.code]})).find(countryMapped => {
        return countryMapped["country-code"]===country.id
        })
      
      if(point) return point.color
      return color[index%color.length]
    },
    drawMap(){
      let width = this.$refs.svg.clientWidth
      let height =  this.$refs.svg.clientHeight
      var projection = d3.geoMercator()
                      .scale(this.isMobile ? 70 : 200)
                      .translate( [width / 2, height / 1.5])
      var path = d3.geoPath().projection(projection);
      
      let svg = d3.select(this.$refs.svg)
      let map = svg.selectAll('.map').data([null]).enter().append('g').attr('class','map')
      let land = topojson.feature(world, world.objects.countries)
      
      map.selectAll('.path')
      .data(land.features)
      .enter()
      .append('path')
      .attr('class','path')
      .attr('d',e => path(e))
      .attr('fill',(e,i) =>this.setColorCountry(e,i)).attr('stroke-width',0.3)

      /**
       * APLICATED WHEN PUIG IMPLEMENT LAT AND LONG
      const circlesEnter = map.selectAll('.circle')
        .data(this.points.map(e => ({...e,coord:projection(e.coord)})))
        .enter()
       
      circlesEnter.append('circle').attr('class','circle')
        .attr('fill', e => e.color)
        .attr('r',this.isMobile ? '5':'7')
        .attr('cx',e => e.coord[0])
        .attr('cy',e => e.coord[1])
        .attr('opacity',0.7)
      */
    },
    refreshPoints(){
      let width = this.$refs.svg.clientWidth
      let height =  this.$refs.svg.clientHeight
      let svg = d3.select(this.$refs.svg)
      var projection = d3.geoMercator()
                      .scale(this.isMobile ? 70 : 200)
                      .translate( [width / 2, height / 1.5])
      let map = svg.selectAll('.map')//.data([null])
      
      
      let circles = map.selectAll('.circle')
        .data(this.points.map(e => ({...e,coord:projection(e.coord)})))
        
      circles.enter().append('circle')
      .attr('class','circle')
      .attr('fill', e => e.color)
      .attr('r',this.isMobile ? '5':'7')
      .attr('cx',e => e.coord[0])
      .attr('cy',e => e.coord[1])
      .attr('opacity',0.7)

      circles.exit()
        .attr('opacity',0)

      circles.attr('class','circle').attr('fill', e => e.color)
      .attr('r',this.isMobile ? '5':'7')
      .attr('cx',e => e.coord[0])
      .attr('cy',e => e.coord[1])
      .attr('opacity',0.7)
      
    }
  },
  watch:{
    points(){
      this.refreshPoints()
    }
  }
}
</script>

<style scoped lang="stylus">
.world-map
  display: flex;
  flex-direction: column;
  svg
    flex 1 1 auto
    width 100%
    height 100%
    >>> .map
      .path
        //fill none
        stroke #888
</style>