<template>
  <div class="concept-cloud">
    <canvas ref="circlesCanvas" :width="size.width" :height="size.height" />
    <canvas ref="regionsCanvas" :width="size.width" :height="size.height" />
    <canvas ref="perfumesCanvas" :width="size.width" :height="size.height" />
    <canvas ref="canvas" :width="size.width" :height="size.height" />
    <cloud-perfume-tooltip v-if="tooltipData" :meta-data="tooltipData" :style="{top: tooltipData.y+'px', left: tooltipData.x+'px'}" />
  </div>
</template>

<script>
import { forceSimulation as d3_forceSimulation } from 'd3-force'
import {
  forceManyBody as d3_forceManyBody,
  forceCollide as d3_forceCollide,
  forceX as d3_forceX,
  forceY as d3_forceY,
} from 'd3-force'
import { timer as d3_timer } from 'd3-timer'
import { select as d3_select } from 'd3-selection'
import { forceIncludeExclude, forceCollideOptional } from './utils/simulationUtils'
import { rectCollide } from './utils/simulationUtils'
import { dist, getIntersectionRegions, isInside, getCentroid, generateVennCircles } from './utils/circleUtils'
import { pointer as d3_pointer } from 'd3-selection'
import { quadtree as d3_quadtree } from 'd3-quadtree'

import { geoInertiaDrag as d3_geoInertiaDrag } from './utils/d3-inertia'
import { scalePow as d3_scalePow } from 'd3-scale'

import { geoOrthographic as d3_geoOrthographic } from 'd3-geo'
import { extent as d3_extent } from 'd3-array'
import { getBBox, isInsideRect } from './utils/conceptUtils'

import { fibonacci_sphere } from './utils/sphereUtils'
// eslint-disable-next-line
import { transition } from 'd3-transition'

import Size from './mixins/Size'
import CloudPerfumeTooltip from './CloudPerfumeTooltip.vue'
import {getFamilies} from '@/services/Api'
// import {superScriptNumber} from './utils/superscriptUtils'

// const RELATED_THRESHOLD = 0.8


const PERFUMES_AMOUNT_2 = [100, 65]
const PERFUMES_AMOUNT_3 = [100, 42, 22]


export default {
  components: { CloudPerfumeTooltip },
  mixins: [Size],
  model: {
    prop: 'selection',
    event: 'change',
  },
  props: ['concepts', 'selection', 'perfumes','highlightedRegion'],
  data() {
    return {
      tooltipData: null,
    }
  },
  asyncComputed: {
    FAMS () {
      return getFamilies()
    }
  },
  computed: {
    internalSelection: {
      get() {
        return this.selection || []
      },
      set(value) {
        this.$emit('change', value)
      },
    },
  },
  watch: {
    selection() {
      this.setMode()
    },
    concepts(){
      this.setMode()
    },
    size() {
      console.log('resized!', this.size.width, this.size.height)
      if (this.isMobile) this.size.perfumeRadius = Math.min(this.size.width, this.size.height) / 80
      else this.size.perfumeRadius = Math.min(this.size.width, this.size.height) / 120
      
      if(this.isMobile) this.size.circleRadius = Math.max(this.size.width, this.size.height) / 5
      else this.size.circleRadius = Math.min(this.size.width, this.size.height) / 4
      
      this.size.conceptRadius = Math.min(this.size.width, this.size.height) / 24
      this.size.largeFontSize = Math.min(this.size.width, this.size.height) / 24
      this.size.sphereFontSize = Math.min(this.size.width, this.size.height) / 28
      this.size.fontSize = Math.min(this.size.width, this.size.height) / 35
      this.$nextTick(() => {
        this.setup()
        this.setMode()
      })
    },
    highlightedRegion(){
      this.drawHighlighted()
    }
  },
  mounted() {
    this.$phantomRoot = d3_select(document.createElement('custom'))

    // this.setup()
    this.setupConceptClick()
  },
  methods: {
    setupConceptClick() {
      let self = this
      this.$refs.canvas.addEventListener('click', e => {
        // console.log('e', e)
        let coords = d3_pointer(e)
        // console.log(coords)
        let qt = d3_quadtree()
          .extent([
            [0, 0],
            [this.size.width, this.size.height],
          ])
          .x(d => d.x)
          .y(d => d.y)
        qt.addAll(this.$refs.canvas.simulation.nodes().filter((node)=>node.opacity !== 0))
        let node = qt.find(coords[0], coords[1], 150)
        if (!node || node.opacity === 0) return
        if (!isInsideRect({ x: coords[0], y: coords[1] }, node)) return

        let originalNode = self.concepts.find((c)=>c.id===node.id.split('_clone')[0])
        originalNode.x = node.x
        originalNode.y = node.y
        node = originalNode

        console.log('clicked node', node.id)
        e.stopImmediatePropagation()
        if (this.internalSelection.find(c => c.id === node.id)) {
          this.internalSelection = this.internalSelection.filter(c => c.id !== node.id)
        } else if (this.internalSelection.length < 3) {
          this.internalSelection = [...this.internalSelection,node]
        } else {
          this.$emit('add-warn')
        }
      })
      this.$refs.canvas.addEventListener('mousemove', e => {
        let coords = d3_pointer(e)
        // console.log(coords)
        let qt = d3_quadtree()
          .extent([
            [0, 0],
            [this.size.width, this.size.height],
          ])
          .x(d => d.x)
          .y(d => d.y)
        qt.addAll(this.$refs.canvas.simulation.nodes().filter((node)=>node.opacity !== 0))
        let node = qt.find(coords[0], coords[1], 150)

        if (!node || !isInsideRect({ x: coords[0], y: coords[1] }, node)) {
          this.$refs.canvas.style.cursor = 'default'
        } else {
          this.$refs.canvas.style.cursor = 'pointer'
        }
      })
      this.$refs.canvas.addEventListener('click', e => {
        let intersections = this.$refs.canvas.regions
        if (intersections) {
          let offset = this.$refs.canvas.getBoundingClientRect()
          let point = { x: e.clientX - offset.left, y: e.clientY - offset.top }

          let active = intersections.filter(i => {
            return isInside({ x: point.x, y: point.y }, i.groups, [])
          })
          active.sort((a, b) => b.groups.length - a.groups.length)
          let i = active[0] || {groups:[]}
          if (i) {
            let groupSelection = this.internalSelection.reduce((dict, concept) => {
              dict[concept.id] = false
              return dict
            }, {})
            i.groups.forEach(g => {
              groupSelection[g.id] = true
            })
            console.log('clicked region', groupSelection)
            this.$emit('region',groupSelection)
          }
          this.$nextTick(()=>this.drawHover(e))
        }
      })
      this.$refs.canvas.addEventListener('mousemove', e => {
        this.drawHover(e)
      })
      let timer
      if(!this.isMobile){
        this.$refs.canvas.addEventListener('mousemove', e => {
          // console.log('other mose move for tooltiping')
          let offset = this.$refs.canvas.getBoundingClientRect()
          let point = { x: e.clientX - offset.left, y: e.clientY - offset.top }
          let node = this.$refs.perfumesCanvas.simulation.find(point.x, point.y)
          clearTimeout(timer)
          if (node.visible && dist(point, node) < 10) {
            timer = setTimeout(() => {
              this.tooltipData = node
            }, 500)
          } else {
            this.tooltipData = null
          }
        })
      }
    },
    drawHover(e){
      let intersections = this.$refs.canvas.regions
      let context = this.$refs.regionsCanvas.getContext('2d')
      let offset = this.$refs.canvas.getBoundingClientRect()
      let point = { x: e.clientX - offset.left, y: e.clientY - offset.top }

      if (intersections) {
        // context.clearRect(0, 0, this.size.width, this.size.height)
        let active = intersections.filter(i => {
          return isInside({ x: point.x, y: point.y }, i.groups, [])
        })
        active.sort((a, b) => b.groups.length - a.groups.length)
        let i = active[0]

        this.drawHighlighted()

        if (i) {
          context.fillStyle = `rgba(248,248,248,0.7)`
          context.fill(new Path2D(i.path))
          // context.strokeStyle = `rgba(255,255,255,0.5)`
          // context.stroke(new Path2D(i.path))
        }
        
      }
    },
    drawHighlighted(){
      let context = this.$refs.regionsCanvas.getContext('2d')
      context.clearRect(0, 0, this.size.width, this.size.height)
      if(!this.$refs.canvas.regions) return
      let i = this.$refs.canvas.regions.find((r)=>{
        return r.groups.every((g)=>{
          return this.highlightedRegion[g.id]===true
        }) && Object.values(this.highlightedRegion).filter((v)=>v).length===r.groups.length
      })
      if (i) {
        // context.fillStyle = `rgba(200,200,200,0.5)`
        // context.fill(new Path2D(i.path))
        context.strokeStyle = `rgba(0,0,0,0.5)`
        context.stroke(new Path2D(i.path))
      }
    },
    setMode() {
      console.log('setMode')
      if (this.internalSelection.length === 0) {
        this.setupSphere() // EN REALIDAD SOLO "haira falta" el "setSpherePosition" + "readd el event .drag"...
        this.$refs.canvas.regions = null
        this.drawCircles([])
      } else {
        d3_select(this.$refs.canvas).on('.drag', null)
        this.setSelectionCircles()
      }
      this.$refs.regionsCanvas.getContext('2d').clearRect(0, 0, this.size.width, this.size.height)
      this.$refs.canvas.simulation.alpha(0.1).restart()
      this.$refs.perfumesCanvas.simulation.alpha(0.1).restart()
    },
    setup() {
      this.setupSimulation()
    },
    setupSphere() {
      console.log('setupSphere')
      let conceptCanvas = this.$refs.canvas
      let conceptNodes = this.concepts

      const sphere = { type: 'Sphere' }

      let projection
      if (conceptCanvas.projection) projection = conceptCanvas.projection
      else projection = d3_geoOrthographic()

      projection
        .fitExtent(
          [
            [-1, -1],
            [this.size.width + 1, this.size.height + 1],
          ],
          sphere
        )
        // .clipExtent([
        //   [-1, -1],
        //   [this.size.width + 1, this.size.height + 1],
        // ])
        .scale((0.8 * this.size.width) / 2)
      conceptCanvas.projection = projection

      let perfumesCanvas = this.$refs.perfumesCanvas
      let perfumeNodes = this.perfumes
      perfumesCanvas.projection = projection

      let setPerfumeSpherePositions = this.setupPerfumeSphere(perfumesCanvas, perfumeNodes)
      let setConceptSpherePositions = this.setupConceptSphere(conceptCanvas, conceptNodes)

      setConceptSpherePositions()
      setPerfumeSpherePositions()

      // eslint-disable-next-line
      var inertia = d3_geoInertiaDrag(
        conceptCanvas,
        function() {
          setConceptSpherePositions()
          setPerfumeSpherePositions()
        },
        projection,
        {
          time: 5000,
          start:()=>{
            console.log("inertia start")
          },
          finish:()=>{
            console.log("inertia finish")
          },
          end:()=>{
            console.log("inertia end")
          }
        }
      )
      // let dalpha = projection.rotate()
      let drift = ()=>{
        // dalpha[0]+=0.01*Math.PI
        // dalpha[1]+=0.001*Math.PI
        if(this.internalSelection.length === 0){

          let driftV = [0.01,0.001,0]
          projection.rotate(projection.rotate().map((x,i)=>x+driftV[i]))
          let prealpha = conceptCanvas.simulation.alpha()
          if(prealpha<0.01) prealpha = 0.01
          setConceptSpherePositions()
          setPerfumeSpherePositions()
          conceptCanvas.simulation.alpha(prealpha)
        }
        // console.log(inertia)
        // inertia.velocity = [10, 0];
        // inertia.render()
        // inertia.timer.start()
        requestAnimationFrame(drift)
      }
      drift()
    },
    setupConceptSphere(canvas, _nodes) {
      console.log('setupConceptSphere')
      let nodes = _nodes.slice(0, 180)
      // INERESETING pero hay "issues" con los ids...
      while(nodes.length<100){
        nodes = nodes.concat(_nodes.map((n)=>{
          let clone = JSON.parse(JSON.stringify(n))
          clone.id = n.id + '_clone'
          return clone
        })).slice(0, 100)
      }
      let coords = fibonacci_sphere(nodes.length)

      nodes.forEach((d, i) => {
        d.long = coords[i].long
        d.lat = coords[i].lat
        d.r = 30
        d.selected = false
        if (!d.visible) {
          let proj = canvas.projection([d.long, d.lat])
          d.x = this.size.width / 2 + (proj[0] - this.size.width / 2) * 3
          d.y = this.size.height / 2 + (proj[1] - this.size.height / 2) * 3
        }
      })

      function tester(projection) {
        let visible
        const stream = projection.stream({
          point() {
            visible = true
          },
        })
        return ({ lat, long }) => ((visible = false), stream.point(long, lat), visible)
      }

      // on the sphere
      let textScale = d3_scalePow()
        .exponent(1)
        .domain([0, 1])
        .range([this.size.sphereFontSize, this.size.sphereFontSize / 2.5])
        .clamp(true)

      let opacityScale = d3_scalePow()
        .exponent(1)
        .domain([0.9, 1])
        .range([1, 0])
        .clamp(true)

      function setSpherePositions() {
        let radius = canvas.projection.scale() //Math.max(canvas.width, canvas.height) / 2
        nodes.forEach(d => {
          let proj = canvas.projection([d.long, d.lat])
          proj = { x: proj[0], y: proj[1] }
          let r = dist(proj, { x: canvas.width / 2, y: canvas.height / 2 })
          let size = r / radius
          let fontSize = textScale(size)
          let opacity = opacityScale(size)
          d.sx = proj.x
          d.sy = proj.y
          d.visible = tester(canvas.projection)(d)
          d.opacity = d.visible ? opacity : 0
          d.fontSize = fontSize
          d.bbox = getBBox(canvas.getContext('2d'), d.fontSize, d.label, d)
        })
        canvas.simulation
          .force('x', d3_forceX(d => d.sx).strength(0.5))
          .force('y', d3_forceY(d => d.sy).strength(0.5))
          .force('charge', null)
          .force('inclexcl', null)
          .force('collision', null)
          .force('rectCollision', null)

        canvas.simulation.alpha(0.3).restart()
        // context.canvas.dispatchEvent(new CustomEvent('input'))
      }
      if(!this.isMobile) canvas.simulation.nodes(nodes)
      else canvas.simulation.nodes(nodes.filter((n)=>n.selected))
      return setSpherePositions
    },
    setupPerfumeSphere(canvas, nodes) {
      console.log('setupPerfumeSphere')
      nodes = nodes.slice(0, 200)
      let coords = fibonacci_sphere(nodes.length)

      nodes.forEach((d, i) => {
        d.long = coords[i].long
        d.lat = coords[i].lat
        if (!d.visible) {
          d.x = canvas.width / 2
          d.y = canvas.height / 2
        }
      })

      let context = canvas.getContext('2d')
      context.textBaseline = 'middle'
      context.textAlign = 'center'

      function tester(projection) {
        let visible
        const stream = projection.stream({
          point() {
            visible = true
          },
        })
        return ({ lat, long }) => ((visible = false), stream.point(long, lat), visible)
      }

      let opacityScale = d3_scalePow()
        .exponent(1)
        .domain([0.9, 1])
        .range([1, 0])
        .clamp(true)

      let self = this

      function setSpherePositions() {
        let radius = canvas.projection.scale() //Math.max(canvas.width, canvas.height) / 2
        nodes.forEach(d => {
          let proj = canvas.projection([d.long, d.lat])
          proj = { x: proj[0], y: proj[1] }
          let r = dist(proj, { x: canvas.width / 2, y: canvas.height / 2 })
          let size = r / radius
          let opacity = opacityScale(size)
          if(self.isMobile){
            d.sx = proj.x * 2 - canvas.width / 2
            d.sy = proj.y * 2 - canvas.height / 2
          } else {
            d.sx = -proj.x / 2 + canvas.width - canvas.width / 4
            d.sy = -proj.y / 2 + canvas.height - canvas.height / 4
          }
          
          d.visible = tester(canvas.projection)(d)
          d.opacity = d.visible ? opacity / 2 : 0
        })
        canvas.simulation
          .force('x', d3_forceX(d => d.sx).strength(0.5))
          .force('y', d3_forceY(d => d.sy).strength(0.5))
          .force('charge', null)
          .force('inclexcl', null)
          .force('collision', null)

        canvas.simulation.alpha(0.3).restart()
        // context.canvas.dispatchEvent(new CustomEvent('input'))
      }
      canvas.simulation.nodes(nodes)
      return setSpherePositions
    },
    setupSimulation() {
      console.log('setupSimulation')
      this.setupConceptSimulation()
      this.setupPerfumeSimulation()
    },
    setupConceptSimulation() {
      console.log('setupConceptSimulation')
      let canvas = this.$refs.canvas
      let context = canvas.getContext('2d')
      let nodes = this.concepts

      const simulation = canvas.simulation || d3_forceSimulation()
      let self = this
      function renderConcepts() {
        context.clearRect(0, 0, context.canvas.width, context.canvas.height)
        context.textBaseline = 'middle'
        context.textAlign = 'center'
        for (const node of simulation.nodes()) {
          // if (node.x < -0.5 * width) node.x = 0
          // if (node.x > 1.5 * width) node.x = width
          // if (node.y < -0.5 * height) node.y = 0
          // if (node.y > 1.5 * height) node.y = height

          if (!node.isPhantomBall && node.visible) {
            let fontSize = node.fontSize
            // let opacity = 1
            context.fillStyle = '#222'
            context.font = `${fontSize}px SangBleu`
            context.globalAlpha = node.opacity
            context.fillText(
              node.label, //+' '+ superScriptNumber(node.dist ? Math.round(node.dist*100)/100 : ''),
              node.x,
              node.y
            )
          

            //Draw BBOX (DEBUGGING)
            // context.globalAlpha = 1
            // context.strokeStyle = 'rgba(200,200,200,0.5)'
            // context.beginPath()
            // context.rect(
            //   node.x - node.bbox.width / 2,
            //   node.y - node.bbox.height / 2,
            //   node.bbox.width,
            //   node.bbox.height
            // )
            // context.stroke()

            if (node.selected) {
              context.strokeStyle = 'rgba(80,80,80,0.2)'
              context.lineWidth = 1
              context.beginPath()
              let iconPos = { x: node.x, y: node.y + self.size.largeFontSize * 0.7 }
              // let iconPos = {x: node.x + node.bbox.width / 2 + self.size.largeFontSize*0.5 ,y:node.y-self.size.largeFontSize*0.5}
              let r = 6
              context.arc(iconPos.x, iconPos.y, r, 0, 2 * Math.PI)
              context.stroke()
              context.moveTo(iconPos.x - r / 2, iconPos.y - r / 2)
              context.lineTo(iconPos.x + r / 2, iconPos.y + r / 2)
              context.stroke()
              context.moveTo(iconPos.x - r / 2, iconPos.y + r / 2)
              context.lineTo(iconPos.x + r / 2, iconPos.y - r / 2)
              context.stroke()
            }
          }
        }
        // console.log('cloud rendered')
      }
      simulation.nodes(nodes)
      canvas.simulation = simulation
      simulation.on('tick', renderConcepts)
      simulation.alpha(0.2).restart()
      // renderConcepts()
    },
    setupPerfumeSimulation() {
      console.log('setupPerfumeSimulation')
      let canvas = this.$refs.perfumesCanvas
      let context = canvas.getContext('2d')
      let nodes = this.perfumes
      let perfumeRadius = this.size.perfumeRadius
      nodes.forEach(d => {
        d.r = perfumeRadius + (Math.random() * perfumeRadius) / 2
      })
      const simulation = canvas.simulation || d3_forceSimulation()
      let self = this

      function renderPerfumes() {
        if(!self.FAMS) return
        context.clearRect(0, 0, context.canvas.width, context.canvas.height)

        for (const node of simulation.nodes()) {
          if (self.internalSelection.length > 0) context.globalAlpha = node.active ? 1 : 0
          else context.globalAlpha = node.opacity

          context.fillStyle = self.FAMS.find(f => f.id === node.family)?.color || '#fff'
          context.beginPath()
          context.moveTo(node.x + perfumeRadius, node.y)
          context.arc(node.x, node.y, perfumeRadius, 0, 2 * Math.PI)
          context.fill()

          context.fillStyle = self.FAMS.find(f => f.id === node.secondaryFamily)?.color || '#fff'
          context.strokeStyle = '#fff'
          context.beginPath()
          context.moveTo(node.x + perfumeRadius / 2, node.y)
          context.arc(node.x, node.y, perfumeRadius / 2, 0, 2 * Math.PI)
          context.fill()
          context.stroke()
        }
      }
      simulation.nodes(nodes)
      canvas.simulation = simulation
      simulation.on('tick', renderPerfumes)
      simulation.alpha(0.2).restart()
      // renderPerfumes()
    },
    setSelectionCircles() {
      console.log('setSelectionCircles')

      let conceptCanvas = this.$refs.canvas
      let conceptNodes = this.concepts

      let r = this.size.circleRadius
      let circles = generateVennCircles(this.internalSelection.length,r,{x:this.size.width/2,y:this.size.height/2},{transposed:this.isMobile})
      circles.forEach((c,i) =>{
        c.radius = c.r
        c.id = this.internalSelection[i].id
      })

      //VENNING
      let intersections = getIntersectionRegions(circles)
      conceptCanvas.regions = intersections

      this.drawCircles(circles)

      conceptNodes.forEach(d => {
        d.sx = null
        d.sy = null
        d.includeIn = []
        d.r = 10
      })

      let visibleConcepts = conceptNodes.filter((d)=>d.overThreshold)
      let selectedNodes = visibleConcepts.filter(c =>
        this.internalSelection.find(cc => cc.id === c.id)
      )
      let otherNodes = visibleConcepts.filter(c => !this.internalSelection.find(cc => cc.id === c.id))

      selectedNodes.forEach(d => {
        d.excludeFrom = []
        d.center = circles[this.internalSelection.findIndex(c => c.id === d.id)]
        d.fontSize = Math.min(this.size.largeFontSize, (1.5 * r) / d.label.length)
        // d.dist = 0
        d.selected = true
        d.opacity = 1
        d.bbox = getBBox(conceptCanvas.getContext('2d'), d.fontSize, d.label, d)
      })

      otherNodes.forEach(d => {
        d.excludeFrom = circles.map(c => ({ x: c.x, y: c.y, id: d.id, r: c.r + 40 }))
        d.center = null
        d.selected = false
        // let dists = this.internalSelection.reduce((ds, sel) => {
        //   let candidate = sel.relations.find(rel => rel.id === d.id)
        //   if (candidate) ds.push(candidate.dist)
        //   return ds
        // }, [])
        // d.dist = dists.reduce((min, dist) => Math.min(min, dist), 999)
      })

      let distExtent = d3_extent(otherNodes, d => d.dist)

      //When selected / circles
      let textScale = d3_scalePow()
        .exponent(1)
        .domain(distExtent)
        .range([this.size.fontSize, this.size.fontSize / 1.5])
        .clamp(true)

      let opacityScale = d3_scalePow()
        .exponent(1)
        .domain(distExtent)
        .range([1, 0.5])
        .clamp(true)

      otherNodes.forEach(d => {
        d.opacity = opacityScale(d.dist)
        d.fontSize = textScale(d.dist)
        d.bbox = getBBox(conceptCanvas.getContext('2d'), d.fontSize, d.label, d)
      })

      
      visibleConcepts.forEach(d => {
        if (!d.visible) {
          let theta = 2 * Math.PI * Math.random()
          d.x = this.size.width / 2 + (this.size.width / 2) * Math.sin(theta)
          d.y = this.size.height / 2 + (this.size.width / 2) * Math.cos(theta)
        }
      })

      conceptNodes.forEach(d => (d.visible = false))
      visibleConcepts.forEach(d => (d.visible = true))
      
      let ballNodes = this.internalSelection.map(d => {
        return { ...d, isPhantomBall: true }
      })
      if(!this.isMobile) conceptCanvas.simulation.nodes(visibleConcepts.concat(ballNodes))
      else conceptCanvas.simulation.nodes(visibleConcepts.filter((n)=>n.selected))
      
      // let context = conceptCanvas.getContext('2d')
      conceptCanvas.simulation
        .force(
          'x',
          d3_forceX(d => d.center?.x || this.size.width / 2).strength(d =>
            d.center ? 1 : this.internalSelection.length < 3 ? 0.2 : -0.03
          )
        )
        .force(
          'y',
          d3_forceY(d => d.center?.y || this.size.height / 2).strength(d =>
            d.center ? 1 : this.internalSelection.length < 3 ? 0.2 : -0.03
          )
        )
        // .force(
        //   'charge',
        //   d3_forceManyBody().strength(d => (d.center ? 0 : -40))
        // )
        // .force(
        //   'inclexcl',
        //   forceIncludeExclude()
        //     .includeStrength(0)
        //     .excludeStrength(1)
        // )
        .force(
          'rectCollision',
          rectCollide()
            .iterations(2)
            .size(function(d) {
              if (d.dist === 0) return [0, 0]
              else return [d.bbox.width*2, d.bbox.height*2] //[metrics.width, actualHeight]
            })
        )
        .force(
          'collision',
          forceCollideOptional(d =>
            d.isPhantomBall
              ? this.size.circleRadius * 1.1
              : this.size.conceptRadius * 1
          ).strength((a, b) => {
            return a.center && b.center ? 0 : 1
          })
        )

      let perfumeCanvas = this.$refs.perfumesCanvas
      let perfumeNodes = this.perfumes
      
      perfumeNodes.forEach(d => {
        // d.includeIn = [circles[0]]
        d.includeIn = circles.filter(c => d.groups.indexOf(c.id) >= 0)
        // d.includeIn = circles.filter(() => Math.random() > 0.8)
        // d.excludeFrom = [
        //   { x: circles[0].x, y: circles[0].y, r: circles[0].r / 2, id: circles[0].id },
        // ]
        d.excludeFrom = circles
          .filter(c => d.includeIn.indexOf(c) < 0)
          .concat(d.includeIn.map(cc => ({ x: cc.x, y: cc.y, r: cc.r * 0.45, id: cc.id })))
        d.center = d.includeIn.length ? getCentroid(d.includeIn) : null
        d.sx = null
        d.sy = null

        if (!d.visible) {
          let theta = 2 * Math.PI * Math.random()
          d.x = this.size.width / 2 + 50 * Math.sin(theta)
          d.y = this.size.height / 2 + 50 * Math.cos(theta)
        }

        if (d.includeIn.length > 0) d.active = true
        else d.active = false
        d.visible = d.active
      })

      let activePerfumes = perfumeNodes.filter(d => d.active)

      // "TRAMPA" para que no quede "demasiado petadas" las intersection regions
      let maxes = this.internalSelection.length < 3 ? PERFUMES_AMOUNT_2 : PERFUMES_AMOUNT_3
      let countDict = {}
      activePerfumes.forEach(d => {
        let key = d.includeIn.map(d => d.id).join('-')
        let max = maxes[d.includeIn.length - 1]
        countDict[key] ||= 0
        if (countDict[key] > max) d.active = false
        countDict[key] += 1
      })
      activePerfumes = perfumeNodes.filter(d => d.active)
      console.debug('Elements in each circle',countDict)

      perfumeCanvas.simulation.nodes(activePerfumes)
      perfumeCanvas.simulation
        .force(
          'x',
          d3_forceX(d => d.center?.x || this.size.width / 2).strength(d => (d.center ? 0.4 : 0.1))
        )
        .force(
          'y',
          d3_forceY(d => d.center?.y || this.size.height / 2).strength(d => (d.center ? 0.4 : 0.1))
        )
        .force('charge', d3_forceManyBody().strength((-80 * this.size.perfumeRadius) / 10))
        .force(
          'inclexcl',
          forceIncludeExclude()
            .includeStrength(0.3)
            .excludeStrength(0.7)
        )
        .force(
          'collision',
          d3_forceCollide(d => d.r)
        )
    },
    drawCircles(circlesData) {
      const DURATION = 400

      let circles = this.$phantomRoot.selectAll('.circle').data(circlesData, d => d.id)
      let circlesEnter = circles
        .enter()
        .append('custom.circle')
        .attr('class', 'circle')

      // circlesEnter.append('custom.text').attr('class', 'text')
      let circlesExit = circles.exit()

      circlesEnter
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('r', 0)

      circlesExit
        .transition()
        .duration(DURATION)
        .attr('r', 0)
        .attr('opacity', 0)
        .remove()

      circles = circles.merge(circlesEnter)

      circles
        .transition()
        .duration(DURATION)
        .attr('opacity', 1)
        .attr('cx', d => d.x)
        .attr('cy', d => d.y)
        .attr('r', d => d.r)

      let circlesContext = this.$refs.circlesCanvas.getContext('2d')

      function drawCircle(el) {
        let sel = d3_select(el)
        // circlesContext.strokeStyle = `rgba(44,44,44,${sel.attr('opacity')})` // COLORS[g.id]
        // circlesContext.lineWidth = 1
        circlesContext.fillStyle = `rgba(200,200,200,0.2)`
        circlesContext.moveTo(sel.attr('cx') + sel.attr('r'), sel.attr('cy'))
        circlesContext.beginPath()
        circlesContext.arc(sel.attr('cx'), sel.attr('cy'), sel.attr('r'), 0, 2 * Math.PI)
        // circlesContext.stroke()
        circlesContext.fill()
      }
      function drawCirclesOnCanvas() {
        circlesContext.clearRect(0, 0, circlesContext.canvas.width, circlesContext.canvas.height)
        circles.each(function(d) {
          drawCircle(this, d)
        })
        circlesExit.each(function(d) {
          drawCircle(this, d)
        })
      }
      const t = d3_timer(elapsed => {
        if (elapsed > DURATION * 2) t.stop()
        else drawCirclesOnCanvas()
      })

      /////////////////////////////////////////////
    },
  },
}
</script>

<style lang="stylus" scoped>
.concept-cloud
  width: 100%
  height: 100%
  background: #fff //#fafafa

  canvas
    position: absolute
</style>