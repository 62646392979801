<template>
  <div
    itemscope
    itemtype="http://schema.org/Product"
    class="perfume-card"
    @click="$emit('click',data)"
  >
    <FamiliesWithIcon
      itemprop="additionalProperty"
      :family="data.family"
      :subfamily="data.secondaryFamily"
    ></FamiliesWithIcon>
    <div class="image" :style="{backgroundImage: `url(${data.imageUrl})`}"></div>
    <div itemprop="name" class="name">{{data.name}}</div>
    <div itemprop="brand" class="brand">{{data.brand.name}}</div>
    <div class="remove-button" v-if="showRemove" @click.stop="$emit('remove',data)"></div>
  </div>
</template>

<script>
import FamiliesWithIcon from '@/components/FamiliesWithIcon'
export default {
  name: 'PerfumeCard',
  components:{
    FamiliesWithIcon
  },
  props: ['data','showRemove'],
  methods:{
  }
}
</script>

<style scoped lang="stylus">
.perfume-card
  align-items: center
  background-color: #fff
  border: 1px solid rgba(#000, 0.15)
  cursor: pointer
  display: flex
  flex-direction: column
  overflow: hidden
  padding: vw(10px)
  position: relative

  .image
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    flex: 1 1 0%
    margin: vh(10px) 0
    width: 100%

    .mobile &
      margin: vh(40px) 0

  .name
    m-ellipsis(100%)
    font-weight: 500
    m-ellipsis(100%)
    m-font-size(14, 20)

  .brand
    m-font("Lelo", "light")
    m-ellipsis(100%)
    m-font-size(11, 13)
    text-transform: uppercase

  .remove-button
    cursor: pointer
    m-icon("close", 15)
    position: absolute
    right: vw(4px)

  .family-and-icon
    width: 100%
</style>