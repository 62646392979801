<template>
  <div class="explore-by-families">
    <bubble-animation v-if="allNodes.length>0" :nodes="allNodes" :lines="lines" :selected="selected || []" @click="handleSelected" />
    <concept-tag-selector
          class="family-selector"
          :title="t('selected_families')"
          v-if="populatedSelected && populatedSelected.length"
          v-model="populatedSelected"
          :highlighted="highlightedRegion"
          @highlight="highlightedRegion=$event"
          @show-recos="searchPerfume=true"
        />
      <transition name="slide">
        <div class="results-wrapper" v-if="searchPerfume">
          <result-explore-by-families
            :perfumes="perfumesSelected"
            :concepts="concepts"
            @select="handlePerfumeSelected"
            @back="searchPerfume=false"
            @gender="e => genders=e"
            :genders="genders"
          />
        </div>
      </transition>
      <transition name="fade">
        <div class="selected-perfume-wrapper" v-if="selectedPerfume">
          <!-- SELECTED: {{selectedPerfume.name}} -->
          <perfume-full-view
            :data="selectedPerfume"
            @close="selectedPerfume=null"
            :canShowSimilar="false"
            :showClose="true"
            :tabletFormat="isTablet"
            :minified="false"
            :onlyquad="isMobile"
          />
        </div>
      </transition>
        <families-gender-filters v-model="genders" />
      <transition name="fade">
        <toggle v-if="selected && selected.length>0" @click="selected.pop()"> {{t("back")}}</toggle>
      </transition>
    </div>

</template>
<script>
import BubbleAnimation from "../components/Families/BubbleAnimation.vue";
import { getFamiliesById, getFamiliesTopList } from "../services/Api";
import ConceptTagSelector from "../components/Concepts/ConceptTagSelector.vue";
import Size from "../components/Concepts/mixins/Size";
import ResultExploreByFamilies from "../components/ResultExploreByFamilies.vue";
import PerfumeFullView from "@/components/PerfumeFullView";

import { eventTracker } from "../services/tracker";
import { pickTextColorBasedOnBgColorSimple } from "../utils/colorUtils";
import { mapGetters } from "vuex";
import FamiliesGenderFilters from "../components/Families/FamiliesGenderFilters.vue";
import Toggle from "../components/Families/Toggle.vue";
import d3Importer from "../utils/d3Importer";
const MAIN_TYPE = "main";
const SUB_TYPE = "sub";

export default {
  components: {
    BubbleAnimation,
    ConceptTagSelector,
    ResultExploreByFamilies,
    PerfumeFullView,
    FamiliesGenderFilters,
    Toggle,
  },
  mixins: [Size],
  metaInfo: {
    title: "Search by families",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "",
      },
      {
        vmid: "og:title",
        name: "og:title",
        content: "Wikiparfum - Search by families",
      },
      {
        vmid: "og:description",
        name: "og:description",
        content: "",
      },
      {
        vmid: "twitter:title",
        name: "twitter:title",
        content: "Wikiparfum - Search by families",
      },
      {
        vmid: "twitter:description",
        name: "twitter:description",
        content: "",
      },
    ],
  },
  routerLinkedData: ["selected", "selectedPerfume"],
  lazyData: { selectedPerfume: { type: "perfume" } },
  data() {
    return {
      selected: [],
      highlightedRegion: {},
      searchPerfume: false,
      selectedPerfume: null,
      genders: [],
    };
  },
  mounted() {
    eventTracker.emitEvent("VIEW_OPENED", { type: "EXPLORE_BY_FAMILIES" });
  },
  asyncComputed: {
    familyTopList() {
      return getFamiliesTopList({
        lang: this.lang,
        includeSubFamiliesTopPerfumes: true,
        genders: this.genders || [],
      });
    },
    perfumesSelected: {
      get() {
        if (
          !this.populatedSelected ||
          this.populatedSelected.length == 0 ||
          !this.searchPerfume
        )
          return [];
        const family = this.populatedSelected[0];
        const subfamily = this.populatedSelected[1];
        const isPrimary = family.type === MAIN_TYPE;
        if (!subfamily)
          return getFamiliesById(family._id, {
            isPrimary,
            includeFamilyTopPerfumes: true,
            genders: this.genders || [],
          }).then((data) => data.topPerfumes);
        return getFamiliesById(isPrimary ? family._id : subfamily._id, {
          isPrimary: true,
          includeSubFamiliesTopPerfumes: true,
          genders: this.genders || [],
        }).then((data) => {
          const subfam = isPrimary
            ? data.subfamilies?.find((sub) => sub.id === subfamily._id)
            : data.subfamilies?.find((sub) => sub.id === family._id);
          if (!subfam) {
            console.warn("ERROR: subfamily not found", subfamily);
            return [];
          }
          return subfam.topPerfumes;
        });
        // getFamiliesById(family._id,{isPrimary,includeSubFamiliesTopPerfumes:true}).then(data => {
        //   const subfam = data.subfamilies.find(sub => sub.id===subfamily._id)
        //   if(!subfam) {
        //     console.warn('ERROR: subfamily not found',subfamily)
        //     return []
        //   }
        //   subfam.topPerfumes
        // })
      },
      default: [],
    },
  },
  methods: {
    handleSelected(values) {
      this.selected = values && values.map((e) => e.id);
    },
    handlePerfumeSelected(perfume) {
      this.selectedPerfume = perfume;
    },

    /**
     * Algoritmo para calcular radios:
     * Partimos del un rádio calculado a partir del tamaño de la pantalla y la cantidad de perfumes (totales) de la familia.
     * Los radios de las subfamilias se calculan intentando que cubran el area de la familia multiplicado por un porcentaje (p)
     * Idem perfumes (p2)
     *
     */
    getNodes(nodes, mainType, invertColor) {
      if (!nodes) return [];
      const maxPerfumes = this.maxPerfumes
      const r = this.minRadius * 3;
      const scale = d3Importer.scaleLinear().range([this.minRadius*20,this.minRadius*100]).domain([0,maxPerfumes])
      /** número mínimo de perfumes que deben salir */
      const MIN_PERFUMES = 2;
      /** perfume máximo */
      const maxPerfumeRadius = 5
      /** el número es la cantidad de perfumes que entrarán como mínimo. Define el tamaño minimo de una subfamilia */
      const minSubFamPerfumeRadius = 1;

      const sort = (a, b) => (a.intensity < b.intensity ? 1 : -1);
      const getAngle = (i, total) => -Math.PI / 2 - (2 * Math.PI * i) / total;
      const getX = (r, i, total) => r * Math.cos(getAngle(i, total));
      const getY = (r, i, total) => r * Math.sin(getAngle(i, total));
      const getArea = (r) => 2 * Math.PI * r * r;
      const getRfromA = (a) => Math.sqrt(a / (2 * Math.PI));
      /** proporcion de radio para radio central */
      const proportionInternalRadio = 1 / 4;
      /** proprcion de area ocupada en las subfamilias */
      const p = 0.5;
      /** proprcion de area ocupada por los perfumes */
      const p2 = 0.75;

      const textSize = (deep) =>
        (r * 20 * (3 - deep)) / (this.isMobile ? 5 : 9);
      //const max = nodes.reduce((max,family)=>Math.max(max,family.subfamilies.reduce((max,subF) => Math.max(max,subF.totalPerfumesNum),0)),0)
      return nodes
        .sort(sort)
        .reduce(
          (
            array,
            /** @type {import('../types/TopFamilies').TopFamilies}*/ family
          ) => {
            const mainNode = {
              ...family,
              deep: 0,
              _id: family.id,
              id: mainType + "_" + family.id,
              name: family.name,
              parent: mainType,
              type: mainType,
              x: 0,
              y: this.lines[mainType].y,
              r:scale(family.totalPerfumesNum),
              colors: family.colors || [
                "white",
                "white",
                "white",
                family.color,
              ],
              fontColor: pickTextColorBasedOnBgColorSimple(family.color),
              colorOuter: family.color,
              colorBorder: family.color,
              intensity: family.intensity,
              image: new Image(),
              perfumes: family.totalPerfumesNum,
              totalPerfumesNum: family.totalPerfumesNum,
              textSize: textSize(0),
            };
            mainNode.image.src = family.imageUrl; // invertColor ? family.secondaryImageUrl ||  family.imageUrl: family.imageUrl;

            // calculate the max value in this family
            const max = family.subfamilies.reduce(
              (max, subF) => Math.max(max, subF.totalPerfumesNum),
              0
            );
            const maxInSubFam = 90; // family.subfamilies.reduce((max,subF) => Math.max(max,subF.topPerfumes?.length || 1),0)
            /** Get Proportional number of perfumes */
            const getPropNumPerfumes = (n) =>
              Math.min(n, Math.max(MIN_PERFUMES, (maxInSubFam * n) / max));
            // Número total de perfumes mostrados
            const totalPerfumesShowed = family.subfamilies.reduce(
              (sum, subF) => sum + getPropNumPerfumes(subF.totalPerfumesNum),
              0
            );
            /** Calcula el radio de la subfamilia (child) apartir del la familia (parent) */
            const getProportionalR = (parent, child) =>
              getRfromA(
                ((getArea(parent.r) * p -
                  getArea(parent.r * proportionInternalRadio)) *
                  Math.max(
                    minSubFamPerfumeRadius,
                    getPropNumPerfumes(child.totalPerfumesNum)
                  )) /
                  totalPerfumesShowed
              );
            /** Calcula el Radio que tienen que tener n bolas dentro de una bola de radio ra */
            const getR = (ra, n) =>
              getRfromA((getArea(ra * (1 - proportionInternalRadio)) * p2) / n);
            /** Calcula el mínimo de los radios de las subfamilias */
            const getProportionalRChild = (subFamalies) =>
              Math.min(maxPerfumeRadius,subFamalies.reduce(
                (min, subF) =>
                  Math.min(
                    min,
                    getR(subF.r, getPropNumPerfumes(subF.totalPerfumesNum))
                  ),
                Infinity
              )) * 0.7; //<---- BASTANTE ARBITRARIO, pero nos parecian "demasiado grandes los nodes perfume"

            const subFamiliesNodes = family.subfamilies
              .sort(sort)
              .map((subF, index) => ({
                ...subF,
                deep: 1,
                id: mainType + "_inner_" + subF.id + "_" + mainNode.id,
                _id: subF.id,
                name: subF.name,
                parent: mainNode.id,
                parentNode: mainNode,
                x: getX(mainNode.r / 2, index, family.subfamilies.length),
                y: getY(mainNode.r / 2, index, family.subfamilies.length),
                r: getProportionalR(mainNode, subF),
                type: mainType + "_inner_" + subF.id + "_" + family.id,
                perfumes: subF.totalPerfumesNum,
                intensity: subF.intensity,
                fontColor: "#000", //pickTextColorBasedOnBgColorSimple(subF.color),
                colorBorder: subF.color,
                colorOuter: invertColor ? subF.color : family.color,
                colorInner: invertColor ? family.color : subF.color,
                textSize: textSize(1),
              }))
              .filter((subF) => subF.perfumes > 0);

            const perfumeRadius = getProportionalRChild(subFamiliesNodes);
            const subNodes = subFamiliesNodes.reduce(
              (array, subFam) => {
                const numberPerfumes = getPropNumPerfumes(
                  subFam.totalPerfumesNum
                );
                const ghost = {
                  id: "ghost" + "sub" + subFam.id,
                  parent: subFam.id,
                  name: subFam.name,
                  perfumes: subFam.totalPerfumesNum,
                  textSize: textSize(1),
                  x: 0,
                  y: 0,
                  //fx:0,
                  //fy:0,
                  r: subFam.r * proportionInternalRadio,
                  ghost: true,
                  inner: true,
                  deep: 2,
                };

                return array.concat([ghost]).concat(
                  (subFam.topPerfumes || [])
                    .slice(0, numberPerfumes)
                    .map((perfume, i) => ({
                      ...perfume,
                      _id: perfume.id,
                      id: `${mainNode.id}_${subFam.id}_${perfume.id}`,
                      deep: 2,
                      type: `${mainType}_child_${subFam.id}-${i}`,
                      parent: subFam.id,
                      parentNode: subFam,
                      r: perfumeRadius,
                      colorBorder: subFam.colorOuter,
                      colorOuter: subFam.colorOuter,
                      colorInner: subFam.colorInner,
                      x: getX(subFam.r / 2, i, numberPerfumes),
                      y: getY(subFam.r / 2, i, numberPerfumes),
                    }))
                );
              },
              [
                {
                  id: "ghost" + mainNode.id,
                  parent: mainNode.id,
                  name: mainNode.name,
                  perfumes: mainNode.totalPerfumesNum,
                  textSize: textSize(0),
                  x: 0,
                  y: 0,
                  //fx:0,
                  //fy:0,
                  r: mainNode.r * proportionInternalRadio * 0.6,
                  ghost: true,
                  inner: true,
                  deep: 1,
                },
              ]
            );
            return array
              .concat([mainNode])
              .concat(subFamiliesNodes)
              .concat(subNodes);
          },
          []
        );
    },
  },
  computed: {
    ...mapGetters(["lang"]),
    maxPerfumes(){
      return this.familyTopList.concat(this.secondaryFamily).reduce((max,node)=>Math.max(max,node.totalPerfumesNum),0)
    },
    minRadius() {
      return this.isMobile
        ? Math.min(this.size.height, this.size.width) / 650
        : Math.min(this.size.height, this.size.width) / 720;
    },
    populatedSelected: {
      get() {
        if (!this.selected) return [];
        return this.allNodes
          .filter(
            (n) => n.deep < 2 && this.selected.some((select) => n.id == select)
          )
          .map((e) => {
            e.label = e.name;
            return e;
          });
      },
      set(values) {
        if (values.length === 1 && values[0].deep == 1) this.selected = [];
        else if (values) this.selected = values.map((e) => e.id);
        else this.selected = [];
      },
    },
    concepts() {
      if (!this.populatedSelected) return;
      const aux = this.populatedSelected;
      const isPrimary = aux[0].type === MAIN_TYPE;
      return isPrimary ? aux : aux.reverse();
    },
    lines() {
      return {
        [MAIN_TYPE]: {
          y: (-this.size.height * 1) / 4,
          title: this.t("smell_of"),
          position: (-this.size.height * 1) / 2,
        },
        [SUB_TYPE]: {
          y: (this.size.height * 1) / 4,
          title: this.t("touch_of"),
          position: 0,
        },
      };
    },
    allNodes() {
      const nodes = this.primaryNodes.concat(this.secondaryNodes);
      return nodes;
    },
    primaryNodes() {
      if (!this.familyTopList) return [];
      this.familyTopList.forEach((family) => {
        family.isSubfamily = false;
        family.subfamilies.forEach((subFam) => {
          subFam.isSubfamily = true;
        });
      });
      return this.getNodes(this.familyTopList, MAIN_TYPE, false);
    },
    secondaryFamily(){
      return Object.values(
        this.familyTopList.reduce(
          (
            obj,
            /** @type {import('../types/TopFamilies').TopFamilies}*/ family
          ) => {
            return family.subfamilies.reduce((aggr, subFam) => {
              aggr[subFam.id] = aggr[subFam.id] || {
                ...subFam,
                totalPerfumesNum: 0,
                id: subFam.id,
                _id: subFam.id,
                isSubfamily: true,
              };
              aggr[subFam.id].subfamilies = aggr[subFam.id].subfamilies || [];
              aggr[subFam.id].colors = [subFam.color, "white"];
              aggr[subFam.id].isSecondary = true;
              const copyFamily = { ...family, isSubfamily: false };
              copyFamily.totalPerfumesNum = subFam.totalPerfumesNum;
              copyFamily.topPerfumes = subFam.topPerfumes;
              aggr[subFam.id].subfamilies.push(copyFamily);
              aggr[subFam.id].totalPerfumesNum += subFam.totalPerfumesNum;
              return aggr;
            }, obj);
          },
          {}
        )
      );
    },
    secondaryNodes() {
      if (!this.familyTopList) return [];
      
      return this.getNodes(this.secondaryFamily, SUB_TYPE, true);
    },
  },
  watch: {
    genders() {
      console.log(this.genders);
    },
  },
};
</script>
<style lang="stylus" scoped>
.explore-by-families
  position: relative

  .gender-filter
    position: absolute
    right: vh(20px)
    top: 50%
    transform: translateY(-50%)
    z-index: 0

  .toggle
    left: vh(20px)
    position: absolute
    top: vh(20px)
    z-index: 0

  .family-selector
    bottom: vh(20)
    left: 50%
    position: absolute
    transform: translateX(-50%)

  .results-wrapper
    background: #fff
    display: flex
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%
    z-index: 10

    // BAsurilla de transition, pero algo asi...
    &.slide-enter-active,
    &.slide-leave-active
      transition: transform 0.5s

    &.slide-enter,
    &.slide-leave-to
      // opacity: 0
      transform: translate(0, 100%)

.selected-perfume-wrapper
  background: #fff
  display: flex
  height: 100%
  overflow-y: auto
  position: absolute
  top: 0
  width: 100%
  z-index: 11
  // BAsurilla de transition, pero algo asi...

.fade-enter-active,
.fade-leave-active
  pointer-events: none
  transition: opacity 0.5s

.fade-enter,
.fade-leave-to
  opacity: 0
</style>