<template>
  <div class="profile" >
    <!-- <PreProfile v-if="needsLogin" :prevLocation="prevLocation"></PreProfile> -->
    <!-- v-else -->
    <EmptyProfile v-if="!loading && favorites.length===0"></EmptyProfile>
    <template v-else>
      <div class="floating-buttons" v-if="needsLogin">
        <router-link to="/signup">
          <ButtonWithFeedback class="btn btn--primary btn--ghost action">{{t("save")}}</ButtonWithFeedback>
        </router-link>
      </div>
      <div class="top" ref="scroller">
        <div class="left">
          <div class="title">{{t('your_profile')}}</div>
          <div class="desc">
            <ProfileDesc v-model="selectedFamily"></ProfileDesc>
          </div>
          <SquareButton @click="showRecos=true" :ghost="true">{{t('get_recomendations')}}</SquareButton>
        </div>
        <div class="center">
          <div class="vertical-wrapper">
            <LoadingOverlay class="loading" v-if="loading" text=""/>
            <Donut
              v-else
              :data="profileFamiliesWithSubs"
              v-model="selectedFamily"
              :radiusPercent="1"
              ref="donut"
            ></Donut>
            <div :class="['family-pointer']">
              <div class="family-label">
                <transition name="shift">
                  <div
                    class="label"
                    :key="selectedFamily && selectedFamily.id"
                  >{{selectedFamily && selectedFamily.name}}</div>
                </transition>
              </div>

              <div class="arrow"></div>
              <div :class="['donut-explanation', {hidden:selectedFamily}]">{{t('click_to_view')}}</div>
            </div>
            <div v-if="isMobile" class="mobile-currents-wrapper">
              <transition name="swap" mode="out-in">
                <div class="currents-list" v-if="selectedFamily" :key="selectedFamily.id" >
                  <PerfumeCard
                    v-for="perfume in perfumesInSelection[0]"
                    :key="perfume.id"
                    :data="perfume"
                    :showRemove="true"
                    @remove="removing=perfume"
                    @click="selectedFav=perfume"
                  />
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">{{t('your_favorite_ings')}}</div>
          <div class="ingredients-list">
            <IngredientItem
              v-for="ing in ingsInSelection"
              :key="ing.id"
              :data="ing"

            ></IngredientItem>
          </div>
        </div>
      </div>
      <div v-if="!isMobile" :class="['bottom',{open: selectedFamily}]">
        <transition name="swap" mode="out-in">
          <div class="currents-list" v-if="selectedFamily" :key="selectedFamily.id">
            <div class="close-button" @click="selectedFamily=null"></div>
            <!-- slider if many chunks -->
            <slider
              v-if="perfumesInSelection.length>1"
              @tap="onListTap"
              @slide="onListSlide"
              :options="{
              pagination: perfumesInSelection.length>1
            }"
            >
              <slideritem v-for="(chunk,i) in perfumesInSelection" :key="i">
                <PerfumeCard
                  v-for="perfume in chunk"
                  :key="perfume.id"
                  :data="perfume"
                  :showRemove="true"
                  @remove="removing=perfume"
                  @click="onItemTap"
                />
              </slideritem>
            </slider>
            <!-- simple list otherwise -->
            <PerfumeCard
              v-else
              v-for="perfume in perfumesInSelection[0]"
              :key="perfume.id"
              :data="perfume"
              :showRemove="true"
              @remove="removing=perfume"
              @click="selectedFav=perfume.id"
            />
          </div>
        </transition>
      </div>
    </template>
    <transition name="fade">
      <template v-if="removing">
        <RemoveConfrimation :data="removing" @close="removing=null"></RemoveConfrimation>
      </template>
    </transition>
    <transition name="fade">
      <div v-if="showRecos" class="full-screen-recos">
        <Recommendations :family="selectedFamily ? selectedFamily.id: null" @close="showRecos=false"></Recommendations>
      </div>
    </transition>
    <transition name="fade">
      <template v-if="selectedFav">
        <PerfumeAndSimilars :data="selectedFav" @close="selectedFav=null"></PerfumeAndSimilars>
      </template>
    </transition>
    <!-- <transition name="fade">
      <div class="form-wrapper" v-if="showSaveForm">
        <div class="close-btn" @click="showSaveForm=false"></div>
        <Form @done="showSaveForm=false"></Form>
      </div>
    </transition>-->
  </div>
</template>

<script>
// @ is an alias to /src
import SquareButton from '@/components/SquareButton.vue'
import Donut from '@/components/Donut/Donut.vue'
import ProfileDesc from '@/components/Profile/ProfileDesc'
import PerfumeCard from '@/components/PerfumeCard'
import IngredientItem from '@/components/IngredientItem'
// import PreProfile from '@/components/Profile/PreProfile'
import Recommendations from '@/components/Profile/Recommendations'
import PerfumeAndSimilars from '@/components/PerfumeAndSimilars'
import RemoveConfrimation from '@/components/Profile/RemoveConfrimation'
// import Form from '@/components/Form'
import LoadingOverlay from '@/components/LoadingOverlay'
import EmptyProfile from '@/components/Profile/EmptyProfile'
import ButtonWithFeedback from '@/components/ButtonWithFeedback'
import { slider, slideritem } from 'vue-concise-slider'
import { fetchFavorites } from '@/services/Api'
var VueScrollTo = require('vue-scrollto');

export default {
  name: 'Profile',
  metaInfo: {title: 'My profile'},
  components: {
    // PreProfile,
    SquareButton,
    Donut,
    ProfileDesc,
    PerfumeCard,
    IngredientItem,
    Recommendations,
    PerfumeAndSimilars,
    slider,
    slideritem,
    RemoveConfrimation,
    // Form,
    ButtonWithFeedback,
    EmptyProfile,
    LoadingOverlay
  },
  data (){
    return {
      selectedFamily: null,
      mode: null,
      preStep: null,
      prevLocation: null,
      showRecos: null,
      selectedFav: null,
      removing: null,
      // showSaveForm: null
    }
  },
  routerLinkedData:['mode','preStep','showRecos','selectedFav'],
  lazyData:{
    selectedFav:{
      type:'perfume'
    }
  },
  asyncComputed:{
    favorites:{
      get(){
        return fetchFavorites()
      },
      default:[]
    }
  },
  computed: {
    loading(){
      return this.$asyncComputed.favorites.updating
    },
    profileFamiliesWithSubs () {
      return this.$store.getters.profileFamiliesWithSubs
    },
    perfumesInSelection () {
      if(!this.selectedFamily) return null
      let perfumes = this.favorites.filter((p)=>p.family.id === this.selectedFamily.id)
      let chunks = perfumes.reduce((chunks,p,i)=>{
        if(i%6===0) chunks.push([])
        chunks[chunks.length-1].push(p)
        return chunks
      },[])
      return chunks
    },
    ingsInSelection () {
      let all = this.$store.getters.profileMainIngredients
      if(!this.selectedFamily) return all
      let perfumes = this.favorites.filter((p)=>p.family.id === this.selectedFamily.id)
      let insel = all.filter((ing)=>perfumes.find((p)=>p.ingredientProportions.find((ip)=>ip.ingredient.id===ing.id)))
      return insel
    },
    needsLogin (){
      return this.preStep || !this.$store.state.session.meta
    }
  },
  beforeRouteEnter(to, from, next) {
    // this.prevLocation = from
    next((self)=>{
      self.prevLocation = from
    })
  },
  methods:{
    onListTap(){
      this.didSlide = false
    },
    onItemTap(data){
      if(!this.didSlide) this.selectedFav = data
    },
    onListSlide(e){
      if(e.direction!=='rebound') this.didSlide = true
    }
  },
  watch:{
    selectedFamily(){
      //HABRIA QUE NAIMARLO con algo como vue-scrollto
      if(this.isMobile){
        // let donutOffset = this.$refs.donut.$el.offsetTop + 80
        // this.$refs.scroller.scrollTop = donutOffset
        setTimeout(()=>{
          VueScrollTo.scrollTo(this.$refs.donut.$el, 600, {container: this.$refs.scroller, offset: 40})
        },300)
      }
    },
    profileFamiliesWithSubs() {
      if ( this.selectedFamily && this.profileFamiliesWithSubs.findIndex(el => { return el.family.id === this.selectedFamily.id}) < 0) {
        this.selectedFamily = null
      }
    },
    showRecos(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.eventTracker.emitEvent('VIEW_OPENED', { type: 'VIEW_RECOS' })
      }
    }
  }
}
</script>

<style scoped lang='stylus'>
.profile
  display: flex
  flex-direction: column

  .top
    display: flex
    flex: 1 1 0
    overflow: hidden

    .left
      align-items: center
      background-color: #fff
      display: flex
      flex: 0 0 30%
      flex-direction: column
      height: 60vh
      justify-content: flex-start
      margin: auto
      overflow: hidden
      padding: vh(40px) vw(40px)

      .mobile &
        height: auto

      .title
        m-font("Lelo", "medium")
        m-first-letter-case()
        m-font-size(12, 14)
        margin-bottom: vh(20px)

    .center
      align-items: center
      display: flex
      flex: 0 0 40%
      flex-direction: column
      overflow: hidden

      .vertical-wrapper
        align-items: center
        flex-direction: column
        margin: auto
        padding-top: vh(5px)
        width: 100%
        .loading
          >>>.content
            margin auto
        .donut
          height: 50vh
          width: 100%

          .mobile &
            margin-bottom vh(20px)

        .family-pointer
          align-items: center
          display: flex
          flex-direction: column
          margin-top: vh(15px)
          opacity: 1
          position: relative
          transition: opacity 0.4s

          .mobile &
            margin-bottom: vh(15px)

          &.hidden
            opacity: 0

          .donut-explanation
            m-first-letter-case()
            m-font("Lelo", "medium")
            m-font-size(12, 14)
            position: absolute
            top: vh(50px)
            transition: opacity 0.4s ease-in-out

            &.hidden
              opacity: 0

          .arrow
            height: vw(20px)
            width: @height

            &:after
              m-triangle("up", #000, 10)

          .family-label
            height: 3vh
            position: relative

            .label
              $shift-x = vw(200px)
              m-font("Lelo", "medium")
              position: absolute
              transform: translate(-50%, 0)
              white-space: nowrap

              &.shift-enter-active,
              &.shift-leave-active
                transition: transform 0.6s ease-in-out, opacity 0.4s

              &.shift-enter
                opacity: 0
                transform: translate($shift-x, 0)
                transition-delay: 0.6s

              &.shift-leave-to
                opacity: 0
                transform: translate(-$shift-x, 0)

        // EN MOBILE LA LSITA ES "OTRA", esta:
        .mobile-currents-wrapper
          width: 100%

          .currents-list
            border-top: 1px solid #F5F5F5
            display: flex
            display: flex
            flex-wrap: wrap
            justify-content: center
            overflow-x: auto
            position: relative
            transform: translate(0, 0)

            .perfume-card
              height: 30vh
              margin: vh(10px) vw(10px)
              width: "calc(50% - (%s * 2))" % vw(10px)

    .right
      align-items: center
      background-color: #fff
      display: flex
      flex: 0 0 30%
      flex-direction: column
      max-height: 65%
      justify-content: flex-start
      margin: auto
      overflow: hidden
      padding: vh(10px) vw(40px)

      .title
        m-font("Lelo", "medium")
        m-first-letter-case()
        m-font-size(12, 14)
        margin-bottom: vh(20px)

      .ingredients-list
        display: flex
        flex-wrap: wrap
        overflow: auto
        width: 100%

        .ingredient-item
          // he canviat de 20 a 10 els margins, quedaven petits sino...
          $margin-x = vw(10px)
          flex: 1 1 "calc(33.33% - 2 * %s)" % $margin-x
          margin: $margin-x

  .bottom
    // background: #fd9
    flex: 0 0 0
    overflow: hidden
    transition: flex 0.4s

    &.open
      flex: 0 0 33vh

    .currents-list
      border-top: 1px solid #F5F5F5
      display: flex
      // height: 100%;
      height: 33vh
      // align-items center
      justify-content: center
      overflow-x: auto
      position: relative
      transform: translate(0, 0)

      &.swap-enter-active,
      &.swap-leave-active
        transition: transform 0.4s ease-in-out

      &.swap-enter,
      &.swap-leave-to
        transform: translate(0, 150%)

      >>> .slider-pagination-bullets
        bottom: 0

        .slider-pagination-bullet
          border: solid 1px #ddd

      .slider-item
        align-items: initial
        color: #000

      .close-button
        cursor: pointer
        m-icon("close", 15)
        position: absolute
        right: vw(10px)
        top: vh(10px)

      .perfume-card
        $percentage = percentage((1 / 8))
        $margin-x = vw(10px)
        $margin-y = vh(20px)
        $width = "calc(%s - %s)" % ($percentage $margin-x)
        flex: 0 0 $width
        margin: $margin-y $margin-x

        // padding: calc($margin-y / 2) $margin-x
        >>> .family-and-icon
          align-self: flex-start
          m-ellipsis("calc(100% - %s)" % vw(15px)) // vw(15px) is the .remove-button width

        >>> .image
          margin: "calc(%s / 2) 0" % $margin-y

        >>> .name
          m-font-size(12, 14)
          font-weight: $fw-medium
          margin-bottom: "calc(%s / 4)" % $margin-y

        >>> .brand
          m-font("Lelo", "light")
          m-font-size(10, 12)

  .floating-buttons
    display: flex
    position: absolute
    right: vw(60px)
    top: vh(40px)

    .mobile &
      justify-content: flex-end
      padding: vw(10px)
      position: relative
      right: 0
      top: 0

    .action
      background: #fff
      margin: 0 vw(10px)
      padding: 1em 2em

      .mobile &
        margin: 0

  .full-screen-recos
    height: 100%
    position: absolute
    width: 100%
    z-index: 1

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.4s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0

  .perfume-and-similars
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.4s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0

  .remove-confirmation
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.4s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0

  .form-wrapper
    background: #fff
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%

    &.fade-enter-active,
    &.fade-leave-active
      transition: opacity 0.4s

    &.fade-enter,
    &.fade-leave-to
      opacity: 0

    .form
      left: 50%
      position: absolute
      top: 50%
      transform: translate(-50%, -50%)

      .mobile &
        // transform none
        width: 90%

  .close-btn
    cursor: pointer
    m-icon("close", 20)
    position: absolute
    right: 25%
    top: vh(40px)

    .mobile &
      right: vw(15px)
      top: vh(15px)

  .mobile &
    .top
      flex-direction: column
      overflow: auto
      width: 100%

      .center
        display: block
        overflow: visible

        .vertical-wrapper
          .donut
            height: 45vh

      .left
        display: block
        overflow: visible
        text-align: center

        .title
          m-font-size(16, 20)

        .desc
          margin: auto
          width: 90%

      .right
        display: block
        height: auto
        overflow: visible
        padding: vh(20px) vw(10px)

        .title
          border-top: 1px solid rgba(#000, 0.15)
          m-font-size(14, 16)
          margin-bottom: vh(30px)
          padding-top: vh(40px)
          text-align: center

    .bottom
      bottom: 0
      width: 100%

    .full-screen-recos
      >>> .recommendations
        .background
          display: none

        .content
          overflow: auto
          padding: vh(10px) vw(10px)
          width: 100%

          .similar-perfumes
            overflow: visible

        .selected-content
          width: 100%
</style>
