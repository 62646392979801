import { render, staticRenderFns } from "./ExploreByIngredients.vue?vue&type=template&id=f0dbe374&scoped=true&"
import script from "./ExploreByIngredients.vue?vue&type=script&lang=js&"
export * from "./ExploreByIngredients.vue?vue&type=script&lang=js&"
import style0 from "./ExploreByIngredients.vue?vue&type=style&index=0&id=f0dbe374&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0dbe374",
  null
  
)

export default component.exports