const SPLIT_KEY = '.'
/**
 * DONT USE WITH ARRAY
 * @param {T} ob object like {parent:{child:true}}
 * @param {string} path path of object: 'parent.child'
 * @param {string} split
 * @returns
 * @template T
 */
export function getNestedValue(ob, path, split = SPLIT_KEY) {
  let parts = path.split(split)
  return parts.reduce((child, part) => {
    return child && child[part]
  }, ob)
}

function getNestedValueRecursive(obj, parts = []) {
  if (!obj) {
    if (parts.length > 0) console.warn('uncontrolled object', obj, parts)
    return obj
  }
  if (parts.length === 0) return obj
  const [part, ...other] = parts
  const child = obj[part]
  if (Array.isArray(child)) return child.map(c => getNestedValueRecursive(c, other))
  if (typeof child === 'object') return getNestedValueRecursive(child, other)
  else return child
}
/**
 * Advanced nested values. you can find inside of array too
 * @param {*} ob 
 * @param {*} path 
 * @param {*} split 
 * @returns 
 */
export function getAdvanceNestedValue(ob, path, split = SPLIT_KEY) {
  return getNestedValueRecursive(ob, path.split(split))
}
/**
 * Return all field of the object. If there are recursive objects, then the fields are separated by splitKey
 * @param {any} obj 
 * @param {string} splitKey 
 * @param {string} recursivekey 
 * @returns {string[]}
 */
export function getAllKeys(obj, splitKey = SPLIT_KEY, recursivekey = '') {
  if (!obj) return [recursivekey]
  if (obj instanceof Date) return [recursivekey]
  switch (typeof obj) {
    case 'object':
      return Object.keys(obj).reduce((array, key) => {
        if (Array.isArray(obj[key]))
          return array.concat(
            getAllKeys(obj[key][0], splitKey, recursivekey ? recursivekey + splitKey + key : key)
          )
        return array.concat(
          getAllKeys(obj[key], splitKey, recursivekey ? recursivekey + splitKey + key : key)
        )
      }, [])
    default:
      return [recursivekey]
  }
}

/**
 * recursive function
 * @param {Object | undefined} obj
 * @param {Array} array
 * @return {Array}
 */
function getFieldFromArray(obj, array, initArray) {
  if (obj === undefined) {
    console.warn('obj is undefiend', initArray)
    if (array.length > 0) console.warn('WARN: fields', array, 'not found')
    return []
  }
  if (Array.isArray(obj)) {
    return obj.reduce(
      (arrayConcat, element) => arrayConcat.concat(getFieldFromArray(element, array, initArray)),
      []
    )
  }
  if (array.length === 0) return [obj]
  const newArray = [...array]
  let key = newArray.shift()
  return getFieldFromArray(obj[key], newArray, initArray)
}
/**
 *
 * @param {Object} obj
 * @param {String} string
 * @param {String} splitKey default SPLIT KEY
 * @return {any[]}
 */
export function getFieldFromString(obj, string, splitKey = SPLIT_KEY) {
  const array = string.split(splitKey)
  return getFieldFromArray(obj, array, array)
}
/**
 *
 * @param {Object[]} arrayObj
 * @param {String[]} arrayKeys
 */
export function mapArrayObjects(arrayObj, arrayKeys) {
  return arrayObj.reduce((array, obj) => {
    return array.concat(
      arrayKeys.reduce((array, key) => array.concat(getFieldFromString(obj, key)), [])
    )
  }, [])
}

/**
 *
 * @param {()=>Promise<T[]>} fetchFun
 * @param {String[]} arrayKeys
 * @template T
 */
export async function getMapedDataFromFetch(fetchFun, arrayKeys) {
  const data = await fetchFun()
  return mapArrayObjects(data, arrayKeys)
}

export function filterDifferent(array) {
  return array.reduce((newArray, element) => {
    if (newArray.indexOf(element) > -1) return newArray
    newArray.push(element)
    return newArray
  }, [])
}
/**
 * Get all possibles values from objects
 * @param {any[]} objects objects you want to get diferents values
 * @param {string} path path of field
 * @returns {any[]}
 */
export function getAllDifferentValuesFromObjects(objects,path){
  return filterDifferent(objects.map(obj => getAdvanceNestedValue(obj,path)))
}
