function xyzToLatLon(normalizedPosition) {
  let lat = 90 - Math.acos(normalizedPosition[1]) / Math.PI * 180
  let long = -Math.atan2(normalizedPosition[2], normalizedPosition[0]) / Math.PI * 180
  return { lat, long }
}
export function fibonacci_sphere(samples = 1) {

  let points = []
  let phi = Math.PI * (3 - Math.sqrt(5))  // golden angle in radians

  for (var i = 0; i < samples; i++) {
    let y = 1 - (i / (samples - 1)) * 2  // y goes from 1 to -1
    let radius = Math.sqrt(1 - y * y)  // radius at y

    let theta = phi * i  // golden angle increment

    let x = Math.cos(theta) * radius
    let z = Math.sin(theta) * radius

    points.push(xyzToLatLon([x, y, z]))
  }


  return points
}