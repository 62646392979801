export default {

//Header_and_login,
login: "Se connecter",
logout: "Se déconnecter",
explore_by: "Rechercher par",
explore_by_generic: "Selectionner",
explore_by_name_long: "Marque ou nom",
explore_by_name: "Marque ou nom",
explore_by_ingredients: "Matières premières",
explore_by_concepts: "Idées",
explore_by_families: "Familles olfactives",
create_profile: "Créer un profil",
view_profile: "Profil",
about: "A propos de nous",
logging_in: "Se connecter",
create_your_profile: "Créez votre profil olfactif",
oops_nothing_yet: "OUPS! IL N'Y A ENCORE RIEN ICI…",
creating_account: "création d'un compte",
cookies_and_policies: "Termes et conditions",
how_it_works_header: "Comment ça marche?",
contact: "Nous contacter",
notification_profile_update: "Vos favoris ont été mis à jour. Vérifiez les nouvelles recommandations sur votre",
profile: "profil",
user: "utilisateur",
language: "langue",
legal: "juridique/mentions légales",
accept_terms:"En créant le compte, j'accepte les $terms_and_conds d’utilisation.",
accept_policy:" Je souhaite recevoir les communications de la part de WikiParfum et j’accepte la $privacy_policy",
//Home,
find_any_fragrance: "trouver n'importe quel parfum dans le monde.",
title_by_name: "rechercher par marque ou par nom",
desc_by_name: "trouver votre parfum à partir de fragrances similaires",
title_by_ings: "rechercher par matières premières",
desc_by_ings: "Cannelle ou Musc? trouver le parfum le plus proche de ces ingrédients",
title_by_concepts: "rechercher par idées",
desc_by_concepts: "trouver votre parfum en pensant à votre couleur ou endroit préféré",
title_by_families: "rechercher par familles olfactives",
desc_by_families: "trouver votre parfum en pensant ...",


//Explore_by_name,
search_brand_or_name: "Rechercher une marque ou un nom",

//PerfumeFullView,
expert_reviewed: "vérifié par un expert",
ingredients: "ingrédients",
description: "description",
perfumist: "parfumeur",
concepts: "concepts",
olfactive_family: "famille olfactive",
main_family: "famille ",
sub_family: "sous-famille",
add_to_favs: "ajouter au profil",
remove_from_favs: "supprimer du profil",
me_family: "Fragrances of the World",

//PerfumeAndSimilars,
similars: "Parfums avec des ingrédients similaires",
filter_by_family: "familles",
filter_by_brand: "marques",
filter_by_gender: "genre",
for_him: "Pour lui",
for_her: "Pour elle",
unisex: "Unisex",
all: "Tous",
all_female: "Toutes",
similars_explanation: "Similitude déterminée par les ingrédients importants et leur famille olfactive",

//by_ingredient,
search_ingredients: "Rechercher des ingrédients",
show_similar: "trouver des parfums avec des ingrédients similaires",
selected_ingredients: "ingrédients sélectionnés",
clear_all: "tout effacer",
show_me_results: "montrez-moi les résultats",
toggle_popular_ing: "Le plus populaire",
popular_ingredients: "Ingrédients populaires",
best_ing_matches: "Meilleurs résultats pour la sélection",

//by_concepts,
toggle_popular_ideas: "Le plus populaire",
related_concepts: "concepts apparentés",
trash: "poubelle",
view_concepts: "voir les concepts",
close_concepts: "concepts proches",
you_are_looking_for: "vous cherchez ...",
concepts_list: "Liste des concepts",
no_results_for_this_combination: "Il n'y a pas de parfums pour cette combinaison",
select_at_least_one_concept: "Sélectionnez au moins un concept",
view_perfumes: "voir les parfums",
back:"Retourner",
selected_concepts: "Concepts sélectionnés:",
selected_families: "Familles sélectionnées:",
close:"Fermer",
filters:"Filtres",
find_concept: "Rechercher concepts...",
smell_of: "Odeur de",
touch_of: "Avec une touche de",

//PREPROFILE,
add_or_wizard: "vous pouvez ajouter directement ou passer le test si vous le souhaitez",
add_to_profile: "ajouter au profil",
take_the_wizard: "faites le test",
back_or_to_profile: "continuez vos recherches ou accédez à votre profil",
go_back: "continuer à explorer/ rechercher",
enter_profile: "voir mon profil",
login_title: "connectez-vous pour accéder à votre profil",
missing_fields: "les champs obligatoires sont manquants!",
wrong_password_check: "Les mots de passe ne correspondent pas!",
create_account: "créer un compte",
user_already_exists: "Il y a déjà un compte existant avec cet e-mail",
email_address: "Adresse électronique",
password: "Mot de passe",
name: "Nom",
create_password: "Créer un mot de passe",
confirm_password: "Confirmer le mot de passe",
sign_up: "S'inscrire",
dont_have_account: "Vous n'avez pas encore de compte?",
signup_title: "créer un compte pour enregistrer votre profil",
already_have_account: "Vous avez déjà un compte?",
bad_login: "E-mail ou mot de passe incorrect",
remember_me: "Se souvenir de moi",

//PROFILE,
wizard_myself: "quelque chose pour moi",
wizard_other: "un cadeau pour quelqu'un d'autre",
wizard_what_are_you_looking: "que cherchez-vous?",
wizard_something_gender: "vous cherchez quelque chose...",
wizard_masculine: "masculin",
wizard_femenine: "feminin",
wizard_unisex: "unisexe",
your_profile: "votre profil olfactif",
you_love_perfumes_of_the: "vous aimez les parfums de la famille",
you_love_perfumes_of_the_plural: "vous aimez les parfums des familles",
and: "et",
desc_family: "",
desc_families: "",
you_also_love_plural: "vous aimez aussi les nuances des sous-familles",
you_also_love: "vous aimez aussi les nuances de la sous-famille",
desc_sub_family: "",
desc_sub_families: "",

//remove_confirmation,
are_you_sure_to_remove: "êtes-vous sûr de vouloir le supprimer du profil?",
this_will_remove: "si vous le supprimez du profil, vous devrez l'ajouter à nouveau",
save: "enregistrer",

//actions,
select: "sélectionner",
remove: "retirer",
cancel: "annuler",
get_recomendations: "obtenir des recommandations",
your_favorite_ings: "vos ingrédients préférés",
click_to_view: "cliquez sur la roue pour voir vos favoris dans chaque famille",

//SHARE,
share_to: "partager...",
facebook: "Facebook",
twitter: "Twitter",
whatsapp: "Whatsapp",
email: "eMail",
share_title: "Regardez ce que j'ai trouvé sur WikiParfum!",
share_desc: "Regardez ce que j'ai trouvé sur WikiParfum!",
share_quote: "Regardez ce que j'ai trouvé sur WikiParfum!",

//LOADER,
loading_related: "chargement d'ingrédients qui se combinent",
loading_perfumes: "chargement de parfums",
loading_concepts: "chargement de concepts",

//////////////HOW_IT_WORKS/////////////,
what_is_our_purpose: "Quel est notre but?",
make_the_choosing: "NOUS FAISONS EN SORTE QUE LE CHOIX D'UN PARFUM DEVIENNE UNE EXPÉRIENCE LUDIQUE ET PERSONNELLE ",
bring_the_craft: "RÉVÉLER AU GRAND PUBLIC LE SAVOIR FAIRE DU PARFUMEUR",
the_starting_point_was: "Le point de départ a été la soif de connaissances et de conseils d'experts dans le monde de la parfumerie, que de nombreuses personnes ont exprimé. «J'adore les parfums mais c'est compliqué de choisir et de savoir ce que j'aime». <br> Nous pensions que nous pourrions faire vivre le savoir-faire des parfumeurs, d'une manière pédagogique et simple.",
how_does_it_work: "Comment ça marche",
the_quadrification_shows: "La «CUADRIFICATION» montre les ingrédients dominants, ceux qui définissent le mieux le parfum. Plus l'image est grande, plus l'ingrédient s'exprime dans ce parfum. Dans cet exemple, «fève tonka» et «cacao» sont les notes dominantes, suivies de «jasmin», «tubéreuse» et «amande».",
q_and_a: "Questions & Réponses",
are_there_more_ingredients: "Y a-t-il plus d'ingrédients dans un parfum? Oui, beaucoup plus! Cependant, nous nous concentrons sur ceux qui donnent le caractère principal à un parfum et ceux que, nous espérons, vous pourrez apprendre à détecter, si vous entraînez votre odorat.",
are_all_ingredients_the_same: "Tous les ingrédients sont-ils les mêmes? Pas du tout. Il y a une grande différence de qualités, spécialement dans les ingrédients naturels. Comme un manteau gris, il peut être réalisé avec de la laine basique ou du cachemire fin. Nous ne spécifions pas toujours la différence de qualité.",
expret_reviewed_means: "«Vérifié par un expert» signifie qu'un de nos spécialistes en parfums a examiné le profil du parfum et sa classification olfactive. Nous examinons systématiquement les parfums afin de nous assurer que la classification et la description sont exactes, d'un point de vue parfumistique.",
this_field_shows_the_scent: "Ce champ montre la classification des parfums selon Fragrances of the World. Il existe plusieurs façons de classer les parfums et il n'y a pas d'accord universel à ce sujet. Cependant, nous trouvons important d'accréditer Fragrances of the World, car ils sont une source de données pour WikiParfum et parce que nous voulons reconnaïtre leur travail de pionnier sur le développement d'un langage commun pour les parfums.",
an_independent_perfumistic: "Un point de vue <br> parfumistique",
the_recomendations_are_100: "Les recommandations sont basées sur une analyse rigoureuse du profil olfactif de chaque parfum, et déterminées par un puissant algorythme.",
scents_are_recomended: "Les parfums vous sont recommandés en fonction de l'adéquation de vos préférences olfactives, votre profil, ou les ingrédients sélectionnés.",
scents_are_recomended_second_part: "Les recommandations ne sont en aucun cas des équivalences aux produits soumis comme critères de préférences.",
hundredpercent_perfumistic_point_of_view: "Point de vue 100% Parfumistique",
perfumes_are_the_heart: "Les parfumeurs sont au cœur de la logique de recommandation et de la visualisation de chaque parfum. Comment faisons-nous ça? Chaque parfum est calibré par, au moins, un expert en parfumerie. De plus, nous examinons rigoureusement les parfums les plus populaires pour vérifier l'exactitude des recommandations. Dans de nombreux cas, nous utilisons la technologie (comme un chromatographe) pour confirmer l'opinion des experts.",
partnerships: "Partenariats",
we_have_collaborated: "Nous avons collaboré avec Fragrances of the World, le plus grand guide indépendant de classification des parfums. Publié pour la première fois en 1984 par Michael Edwards, c'est la «Bible des parfums» la plus complète et la plus qualitative qui existe aujourd'hui. Michael et son équipe évaluent chaque entrée et la classent. C'est pourquoi vous voyez la classiﬁcation de Fragrances of the World, dans tous les produits dont il fournit l'information.",
is_the_visualization: "La visualisation <br> est-elle vraiment objective par rapport à son odeur?",
as_much_as_it_can_be: "Autant que possible, car on part d'une classification objective, basée sur le profil olfactif, la famille et les ingrédients. Nous représentons les ingrédients les plus dominants dans un parfum. Ceux qu'un parfumeur peut identifier «facilement» en sentant un parfum (même si le produit en contient beaucoup plus bien sûr). Cependant, la parfumerie n'est pas une science exacte. Les ingrédients varient en qualité et en concentration, et donc dans la manière dont ils impactent le parfum et sa perception, même si la visualisation peut paraître similaire. La qualité et la quantité des ingrédients sont deux aspects qui ne sont pas visualisés dans WikiParfum.",

//POLICIES,
what_are_cookies: "Que sont les cookies",
types_of_cookies: "Types de cookies",
privacy_reference_center: "CENTRE DE RÉFÉRENCE DE CONFIDENTIALITÉ",
accept_cookies: "Autoriser les cookies",
cookies_policy: "Politique de cookies",
cookies_desc: "Nous utilisons des cookies pour améliorer votre expérience sur ce site Web. En naviguant sur le site, vous devez accepter l'utilisation de ces cookies",
learn_more: "Plus d'informations",
cookies_accept: "Accepter",
privacy_policy: "Politique de confidentialité",
terms_and_conds: "Termes et conditions",

//OTHERS,
apply: "appliquer",
search: "rechercher",
contact_us_message_title: "message",
contact_us: "nous contacter",
contact_error: "Une erreur de connexion s'est produite. Veuillez réessayer plus tard.",
contact_success: "Message envoyé! Merci de nous avoir contacté.",
contact_send: "envoyer",
how_it_works: "Comment ça marche",
no_results: "Nous n'avons trouvé aucun parfum avec les paramètres sélectionnés.",
no_results_search: "Désolés, nous n'avons pas trouvé ce que vous cherchiez.",

//FORGOT PASSWORD,
forgot_password: "Mot de passe oublié?",
reset_password: "Soumettre",
forgotten_password: "Mot de passe oublié?",
forgotten_password_sub_title: "Vous allez recevoir un lien pour réinitialiser votre mot de passe",
check_your_email: "Vérifiez votre e-mail",
send_link: "Nous venons de vous envoyer un lien pour réinitialiser votre mot de passe",
havent_receive: "Vous n’avez pas reçu d'e-mail?",
change_password: "Changer votre mot de passe",
show_password: "Visualiser votre mot de passe",
confirm_new_password: "Confirmer votre mot de passe",
new_password: "Nouveau mot de passe",

//INGREDIENT CHAPTER,
ingredient_type: "Type",
ingredient_parts: "Parties utilisées",
ingredient_extraction: "Méthode d'extraction",
ingredient_first: "Parfum iconique",
history: "Histoire",
effect: "Production et effet",
more_info: "Plus d'informations",
same_ingredient: "Fragrances qui contiennent cet ingrédient",
match_ingredient: "Les meilleures combinaisons avec cet ingrédient",
explore_by_ingredient: "Rechercher pour cet ingrédient",
origin: "Origine",
iconic_fragrance: "Parfum iconique de cet ingrédient",
production: "Production",

//FAMILY CHAPTER,
iconic_fragrances: "Parfums iconiques de cette famille",
Ingredient_family: "Ingrédients de cette famille",
show_more: "Montrer plus",

//SOCIAL LOGIN,
continue_with: "Continuer avec",

//ALERTS
turn_your_device:"Veuillez tourner votre dispositif",
discontinued:"Discontinué"
}