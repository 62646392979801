<template>
  <div class="concept-tag-selector">
    <div class="tags-container">
      <div class="title" v-if="concepts && concepts.length">{{title || 'Selected concepts:'}}</div> 
      <div class="tags-wrapper">
        <concept-tag v-for="c in concepts"  
          :color="(highlighted && highlighted[c.id]) ? 'black':'white'" 
          :key="c.id"
          :data="c" 
          @click="()=>toggleConcept(c)"
          @remove="()=>removeConcept(c)
        "/>
        <div class="remove-btn" v-if="concepts.length>0" @click="$emit('change',[])"><div class="icon"/></div>
      </div>
      </div>
    <div class="reco-btn" @click="onShowPerfumes"><div class="text">{{t("view_perfumes")}}</div></div>
  </div>
</template>

<script>
import ConceptTag from '@/components/ConceptTag'
export default {
  components:{ConceptTag},
  props:{
    concepts: {
      type: Array
    },
    highlighted:{
      type: Object,
      default: ()=>{}
    },
    title:{
      type: String
    }
  },
  methods:{
    removeConcept(c){
      this.$emit('change',this.concepts.filter((cc)=>cc.id!==c.id))
    },
    toggleConcept(c){
      let newHighlight = {...this.highlighted}
      newHighlight[c.id] = !newHighlight[c.id]
      this.$emit('highlight',newHighlight)
    },
    onShowPerfumes(){
      let some = Object.values(this.highlighted).some((v)=>v)>0
      let all = this.concepts.reduce((dict,c)=>{
        dict[c.id] = true
        return dict
      },{})
      if(some) this.$emit('show-recos',this.highlighted)
      else this.$emit('show-recos',all)
    }
  },
  model:{
    prop: 'concepts',
    event: 'change'
  }
}
</script>

<style lang="stylus" scoped>
.concept-tag-selector
  display: flex
  pointer-events: none

  .tags-container
    align-items: center
    background-color: #fff
    border: solid 1px #ddd
    border-radius: 100px
    display: flex
    margin: vw(10px)
    padding: vw(5px)
    pointer-events: all

    .mobile &
      background: none
      border: none
      margin: none
      padding: none

    .title
      margin: vw(10px)
      white-space: nowrap

      .mobile &
        display: none

    .tags-wrapper
      align-items: center
      display: flex

      .mobile &
        flex-wrap: wrap
        justify-content: center
        width: 80vw

      .concept-tag
        cursor: pointer
        margin: vw(5px)
        white-space: nowrap

      .remove-btn
        align-items: center
        background-color: #fff
        border: 1px solid #333
        border-radius: vw(40px)
        color: #333
        cursor: pointer
        display: flex
        height: vw(30px)
        margin: vw(5px)
        width: vw(30px)

        .icon
          m-icon("tag-close", 16)
          flex: 1 1 0%

  .reco-btn
    m-font("Lelo", "light")
    align-items: center
    background-color: #000
    border-radius: 100px
    color: #fff
    cursor: pointer
    display: flex
    margin: vw(10px)
    padding: vw(5px) vw(25px)
    pointer-events: all
    text-transform: uppercase
    white-space: nowrap

  .mobile &
    .tags-container
      left: 50%
      position: absolute
      top: vh(0)
      transform: translate(-50%, 0)

    .reco-btn
      m-font-size(13, 22)
      bottom: vh(150px)
      left: 50%
      margin: 0
      position: absolute
      transform: translate(-50%, 0)
</style>