<template>
  <div :class="['header',{transparent: transparent, minimal: minimal}]">
    <div v-if="!isMobile" class="header-content">
      <router-link to="/home" event="" @click.native="goToHome">

        <img v-if="transparent" src="~@/assets/images/logo-white.svg" alt="Wikiparfum"/>
        <img v-else src="~@/assets/images/logo-black.svg" alt="Wikiparfum"/>

      </router-link>
      <div class="header-menu">
        <template v-if="!minimal">
          <div class="explore-group-wrapper">
            <div
              :class="['link', 'explore-group', (currentExplore?'active':'')]"
              @click="popupVisible=true"
            >
              <div class="pre-explore-text">{{t("explore_by")}}</div>
              <div class="current" :class="currentExplore?'enabled':'disabled'">
                <span class="text">{{currentExploreName}}</span>
                <span class="icon"></span>
              </div>
              <transition name="drop-down">
                <div v-if="popupVisible" class="options">
                  <router-link class="link option" to="/explore/by-name">
                    <span class="text">{{t('explore_by_name_long')}}</span>
                  </router-link>
                  <router-link class="link option" to="/explore/by-ingredients">
                    <span class="text">{{t('explore_by_ingredients')}}</span>
                  </router-link>
                  <router-link
                    v-if="byConceptsAvailable"
                    class="link option"
                    to="/explore/by-concepts"
                  >
                    <span class="text">{{t('explore_by_concepts')}}</span>
                  </router-link>
                  <router-link
                    class="link option"
                    to="/explore/by-families"
                  >
                    <span class="text">{{t('explore_by_families')}}</span>
                  </router-link>
                </div>
              </transition>
            </div>
          </div>
          <router-link class="link" to="/profile">
            <!-- <span class="text" >{{this.$store.state.session.meta?t('view_profile'):t('create_profile')}}</span> -->
            <span class="text">{{t('view_profile')}}</span>
          </router-link>
          <a class="instagram-link" :href="instagramURL" target="_blank"><div class="instagram"/></a>

          <!-- <router-link class="link" to="/about">
            <span class="text">{{t('about')}}</span>
          </router-link>-->
            <div class="burger" v-if="!minimal" @click.self="burgerPopupVisible=!burgerPopupVisible">
            <div class="burger-dropdown" v-if="burgerPopupVisible">
              <div class="burger-group">
                <div class="burger-title">{{t("user")}}</div>
                <div class="burger-content">
                  <div class="login-button">
                    <router-link
                      class="lala"
                      to="/login"
                      v-if="!this.$store.state.session.meta"
                      @click.native="burgerPopupVisible = false"
                    >
                      <span class="text">{{t('login')}}</span>
                    </router-link>
                    <ButtonWithFeedback
                      v-else
                      @click="$store.dispatch('resetSession'); burgerPopupVisible = false"
                    >{{t('logout')}}</ButtonWithFeedback>
                  </div>
                </div>
              </div>
              <div class="burger-group">
                <div class="burger-title">{{t("language")}}</div>
                <div class="burger-content">
                  <LangToggle @change="burgerPopupVisible = false"></LangToggle>
                </div>
              </div>
              <div class="burger-group">
                <div class="burger-title">{{t("legal")}}</div>
                <div class="burger-content">
                  <ButtonWithFeedback
                    class="lala"
                    @click="showLegal=true"
                  >{{t("cookies_and_policies")}}</ButtonWithFeedback>
                  <router-link class="lala" to="/howto">{{t("how_it_works_header")}}</router-link>
                  <router-link class="lala" to="/contact">{{t("contact")}}</router-link>
                </div>
              </div>
            </div>
          </div>

        </template>
        <template v-else>
           <div class="explore-group-wrapper"/>
          <router-link
            v-if="minimal && !this.$store.state.session.meta"
            class="link"
            to="/login"
            @click.native="burgerPopupVisible = false"
          >
            <span class="text">{{t('login')}}</span>
          </router-link>
        </template>
      </div>
    </div>
    <div v-else class="header-content-mobile">
      <div class="static-header">
        <router-link :class="openMenu?'home-maxi-button':'home-mini-button'" to="/home"></router-link>
        <div
          class="mobile-current explore-group"
          @click="()=>{if(currentExplore) {popupVisible=!popupVisible} else {openMenu=!openMenu}}"
          v-if="!openMenu"
        >
          <template v-if="!currentExplore">{{$router.currentRoute.name}}</template>
          <template v-else>
            {{t("explore_by")}}
            <span class="bold">{{currentExploreName}}</span>
          </template>
          <transition name="drop-down">
            <div v-if="popupVisible" class="options">
              <router-link class="link option" to="/explore/by-name">
                <span class="text">{{t('explore_by_name_long')}}</span>
              </router-link>
              <router-link class="link option" to="/explore/by-ingredients">
                <span class="text">{{t('explore_by_ingredients')}}</span>
              </router-link>
              <router-link v-if="byConceptsAvailable" class="link option" to="/explore/by-concepts">
                <span class="text">{{t('explore_by_concepts')}}</span>
              </router-link>
              <router-link class="link option" to="/explore/by-families">
                <span class="text">{{t('explore_by_families')}}</span>
              </router-link>
            </div>
          </transition>
        </div>
        <a class="instagram-link" :href="instagramURL" target="_blank"><div class="instagram"/></a>
        <div class="toggle-container">
          <ButtonWithFeedback
            class="toggle anim-burger"
            :class="openMenu?'open':''"
            @click="openMenu=!openMenu"
          >
            <span></span>
            <span></span>
            <span></span>
          </ButtonWithFeedback>
        </div>
      </div>
      <transition name="slide">
        <div v-if="openMenu" class="header-menu">
          <div class="burger-group">
            <div class="burger-title">{{t("explore_by")}}</div>
            <div class="burger-content">
              <router-link class="link option" to="/explore/by-name" @click.native="openMenu=false">
                <span class="text">{{t('explore_by_name_long')}}</span>
              </router-link>
              <router-link
                class="link option"
                to="/explore/by-ingredients"
                @click.native="openMenu=false"
              >
                <span class="text">{{t('explore_by_ingredients')}}</span>
              </router-link>
              <router-link
                v-if="byConceptsAvailable"
                class="link option"
                to="/explore/by-concepts"
                @click.native="openMenu=false"
              >
                <span class="text">{{t('explore_by_concepts')}}</span>
              </router-link>
              <router-link
                class="link option"
                to="/explore/by-families"
                @click.native="openMenu=false"
              >
                <span class="text">{{t('explore_by_families')}}</span>
              </router-link>
            </div>
          </div>
          <div class="burger-group">
            <div class="burger-title">User</div>
            <div class="burger-content">
              <div class="login-button">
                <router-link class="link" to="/profile">{{t('profile')}}</router-link>

                <router-link
                  class="lala"
                  to="/login"
                  v-if="!this.$store.state.session.meta"
                  @click.native="openMenu=false"
                >
                  <span class="text">{{t('login')}}</span>
                </router-link>
                <ButtonWithFeedback
                  v-else
                  @click="$store.dispatch('resetSession'); openMenu = false"
                >{{t('logout')}}</ButtonWithFeedback>
              </div>
            </div>
          </div>
          <div class="burger-group">
            <div class="burger-title">Language</div>
            <div class="burger-content">
              <LangToggle @change="openMenu = false"></LangToggle>
            </div>
          </div>
          <div class="burger-group">
            <div class="burger-title">Legal</div>
            <div class="burger-content">
              <ButtonWithFeedback class="lala" @click="showLegal=true">{{t("cookies_and_policies")}}</ButtonWithFeedback>
              <router-link class="lala" to="/howto">{{t("how_it_works_header")}}</router-link>
              <router-link class="lala" to="/contact">{{t("contact")}}</router-link>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="slide">
      <div class="notification" v-if="showNotification">
        <div class="content">
          {{t('notification_profile_update')}}
          <router-link class="link" to="/profile">{{t('profile')}}</router-link>
        </div>
        <ButtonWithFeedback class="close-button" @click="dismissNotification"></ButtonWithFeedback>
      </div>
    </transition>
  </div>
</template>

<script>
import ButtonWithFeedback from "@/components/ButtonWithFeedback";
import LangToggle from "@/components/LangToggle";
export default {
  name: "Header",
  props: ["transparent", "minimal"],
  // mounted () {
  //   console.log(this.atelier)
  // },
  components: {
    ButtonWithFeedback,
    LangToggle,
  },
  data() {
    return {
      popupVisible: false,
      currentExplore: this.getCurrentExplore(),
      openMenu: false,
      burgerPopupVisible: false,
      showLegal: null,
    };
  },
  routerLinkedData: ["openMenu", "showLegal"],
  mounted() {
    window.addEventListener("click", this.onWindowClick);
  },
  destroy() {
    window.removeEventListener("click", this.onWindowClick);
  },
  computed: {
    instagramURL() {
      return process.env.VUE_APP_INSTAGRAM;
    },
    showNotification() {
      return (
        this.$store.state.appState.pendingView &&
        this.$router.currentRoute.name !== "profile"
      );
    },
    byConceptsAvailable() {
      return true;
      // return this.$store.state.i18n.lang === 'en'
    },
    currentExploreName() {
      if (this.currentExplore) {
        return this.t(this.currentExplore.name.split("-").join("_"));
      } else {
        return this.t("explore_by_generic");
      }
    },
  },
  methods: {
    getCurrentExplore() {
      if (
        this.$router.currentRoute.name &&
        this.$router.currentRoute.name.indexOf("explore") >= 0
      ) {
        return this.$router.currentRoute;
      } else {
        return null;
      }
    },
    dismissNotification() {
      this.$store.dispatch("setViewed", true);
    },
    goToHome() {
      this.eventTracker.emitEvent('NEW_SESSION')
      this.$router.push("/home")
    },
    onWindowClick(e) {
      if (!e.target.closest(".explore-group")) this.popupVisible = false;
      if (!e.target.closest(".burger")) this.burgerPopupVisible = false;
    },
  },
  watch: {
    $route() {
      if (this.$router.currentRoute.name === "profile")
        this.dismissNotification();
      this.currentExplore = this.getCurrentExplore();
      this.popupVisible = false;
      this.burgerPopupVisible = false;
    },
  },
};
</script>

<style scoped lang="stylus">
$logo-width = vw(170px)

.header
  align-items: center
  border-bottom: 1px solid rgba(#000, 0.15)
  display: flex
  height: $header-height
  position: relative

  .instagram-link
    text-decoration: none

    .instagram
      m-icon("instagram", 20)
      margin-left: vw(20px)

      .mobile &
        m-icon("instagram", 23)

  .mobile &
    flex: 0 0 $header-height-mobile
    height: $header-height-mobile

  &.transparent,
  &.minimal
    >>> + .section
      position: absolute

  &.transparent
    color: #000

    .header-content
      background: transparent

  &.minimal
    border-bottom-color: transparent
    color: #fff
    width: 100%
    z-index: 1

    .header-content
      .home-button
        background-image: url("~@/assets/images/logo-white.svg")

      .favs-button
        opacity: 0
        pointer-events: none

    .header-menu
      justify-content: flex-end

  .header-content
    align-items: center
    background: #fff
    display: flex
    height: 100%
    justify-content: space-between
    padding-left: 5vw
    padding-right: 5vw
    transition: background 0.2s, color 0.2s
    width: 100%
    z-index: 2

    .header-menu
      m-font("Lelo", "light")
      align-items: center
      display: flex
      height: $header-height
      justify-content: space-between
      width: 75%

      > .link,
      .explore-group-wrapper > .link
        &[class*="active"]
          > .text
            // cursor: default
            position: relative

            &:after
              background-color: #000
              bottom: 1px
              content: ""
              height: 2px
              left: 0
              position: absolute
              width: 100%

      > .link,
      .explore-group-wrapper > .link
        // flex: 0 0 auto
        // margin: 0 vw(100px)
        align-items: center
        cursor: pointer
        display: flex
        height: 100%
        justify-content: center
        m-font-size(12, 14)
        m-letter-spacing(50)
        text-transform: uppercase

        .text
          align-items: center
          display: flex
          height: 100%
          justify-content: center

    .explore-group-wrapper
      display: flex
      flex: 1 1 0%
      height: 100%
      justify-content: space-around

      .explore-group
        align-items: center
        cursor: pointer
        display: flex
        // flex: 0 0 auto
        height: 100%
        margin-left: "calc(%s * -1)" % $logo-width
        position: relative

        .pre-explore-text
          margin-right: vw(5px)

        .current
          border-bottom: 2px solid

          &.disabled
            color: #aaa

          > .text
            position: relative

            &:after
              content: ""
              cursor: pointer
              m-icon("caret-down", 12)
              margin-top: 0.1em
              position: absolute
              right: vw(-20px)
              top: 50%
              transform: translateY(-50%)

        .options
          background-color: #fff
          border: 1px solid rgba(#000, 0.15)
          display: flex
          flex-direction: column
          position: absolute
          right: vw(-30px)
          // top: "calc(100% - %s)" % vh(2px)
          top: 80%
          width: "calc(100% + %s)" % vh(40px)
          z-index: 2

          &.drop-down-enter-active,
          &.drop-down-leave-active
            transition: opacity 0.4s

          &.drop-down-enter,
          &.drop-down-leave-to /* .drop-down-leave-active below version 2.1.8 */
            opacity: 0

          &:before
            right: vw(7px)
            m-triangle("up", rgba(#000, 0.15), 10 8)
            top: vh(-10px)

          &:after
            right: vw(7px)
            m-triangle("up", #fff, 10 8)
            top: vh(-9px)

          .option
            padding: vh(10px) vw(10px)

            &:not(:last-child)
              border-bottom: 1px solid rgba(#000, 0.15)

            .text
              font-weight: $fw-medium

    .close-button
      cursor: pointer
      m-icon("close", 15)
      position: absolute
      right: vw(4px)

  .burger
    // background: #fa0
    // height: vw(20px)
    position: relative
    // width: vw(20px)
    m-icon("menu", 30)
    margin-left: vw(30px)

    .burger-dropdown
      background-color: #fff
      border: 1px solid rgba(#000, 0.15)
      display: flex
      flex-direction: column
      padding: vw(20px)
      position: absolute
      right: 0
      top: calc(100% + 10px)
      z-index: 2

      &:before
        m-triangle("up", rgba(#000, 0.15), 10 8)
        right: vw(2px)
        top: vh(-10px)

      &:after
        m-triangle("up", #fff, 10 8)
        right: vw(2px)
        top: vh(-9px)

  // poso el css fora del "burger-dropdown" xk serveixi pel mobile tb
  // tot i que shauran de posar ".mobile &" o similars
  .burger-group
    m-font("Lelo", "light")

    &:not(:last-child)
      border-bottom: 1px solid rgba(#000, 0.15)
      margin-bottom: vw(20px)
      padding-bottom: vw(20px)

    .burger-title
      color: rgba(#000, 0.15)
      m-font("Lelo", "light")
      m-font-size(10, 14)
      m-letter-spacing(15)
      margin-bottom: vh(5vh)
      text-transform: uppercase

      .mobile &
        m-font-size(14, 16)
        margin-bottom: vh(20px)

    .burger-content
      margin-left: vw(10px)
      m-font-size(14, 20)
      m-letter-spacing(15)
      text-transform: uppercase

      .mobile &
        m-font-size(20, 30)
        margin: 0 vw(20px)

      .lala
        cursor: pointer
        white-space: nowrap

        &:hover
          text-decoration: underline

        &:not(:last-child)
          color: #000
          display: block
          margin-bottom: vh(5px)

      >>> .link
        .mobile &
          justify-content: flex-start

  .notification
    $notification-height = vh(40px)
    $notification-height-out = vh(-40px)
    align-items: center
    background-color: #000
    color: #fff
    display: flex
    height: $notification-height
    justify-content: center
    m-font-size(14, 22)
    position: absolute
    text-align: center
    top: 100%
    width: 100%
    z-index: 1

    .mobile &
      $notification-height = vh(80px)
      $notification-height-out = vh(-80px)
      height: $notification-height

      &.slide-enter,
      &.slide-leave-to /* .drop-down-leave-active below version 2.1.8 */
        transform: translate(0, $notification-height-out)

    &.slide-enter-active,
    &.slide-leave-active
      transition: transform 0.2s

    &.slide-enter,
    &.slide-leave-to /* .drop-down-leave-active below version 2.1.8 */
      transform: translate(0, $notification-height-out)

    .content
      flex: 1 1 0%
      m-font("Lelo", "light")
      m-font-size(14, 16)
      m-letter-spacing(50)
      padding: vh(5px) vw(5px)

      .mobile &
        m-font-size(12, 14)

      .link
        text-decoration: underline

    .close-button
      margin: 0 vw(10px)
      m-icon("close-white", 15)

  .home-button
    background-image: url("~@/assets/images/logo-black.svg")
    background-position: center center
    background-repeat: no-repeat
    background-size: contain
    height: vw(20px)
    width: $logo-width

  .header-content-mobile
    height: 100%
    transition: background 0.2s, color 0.2s
    width: 100%
    z-index: 2

    .static-header
      align-items: center
      background-color: #fff
      border-bottom: 1px solid rgba(#000, 0.15)
      // display: flex
      display: flex
      height: $header-height-mobile
      // overflow: hidden //DANGEROUS!
      // justify-content: space-between
      // padding: 0 3vw
      position: relative
      width: 100%
      z-index: 4

      .home-mini-button
        background-image: url("~@/assets/images/mini-logo-black.svg")
        background-position: center
        background-repeat: no-repeat
        background-size: 50%
        flex: 0 0 $header-height-mobile
        height: 100%

      .home-maxi-button
        background-image: url("~@/assets/images/logo-black.svg")
        background-position: center
        background-position: 7% center
        background-repeat: no-repeat
        background-size: 44%
        flex: 1 1 0%
        height: 100%

      .mobile-current
        // align-items: center
        // display: flex
        // justify-content: center
        flex: 1 1 0%
        m-ellipsis(100%)
        m-font("Lelo", "light")
        m-font-size(14, 16)
        m-letter-spacing(50)
        text-align: center
        text-transform: uppercase

        > .bold
          m-font("Lelo", "medium")
          position: relative

          &:after
            content: ""
            cursor: pointer
            m-icon("caret-down", 12)
            margin-top: -0.05em
            position: absolute
            right: vw(-20px)
            top: 50%
            transform: translateY(-50%)

        .options
          background-color: #fff
          border-bottom: 1px solid rgba(#000, 0.15)
          border-top: 1px solid rgba(#000, 0.15)
          display: flex
          flex-direction: column
          left: 0
          padding: vh(50px) vw(20px)
          position: absolute
          top: 100%
          width: 100%

          &.drop-down-enter-active,
          &.drop-down-leave-active
            transition: opacity 0.4s

          &.drop-down-enter,
          &.drop-down-leave-to /* .drop-down-leave-active below version 2.1.8 */
            opacity: 0

          .option
            m-font-size(20, 24)
            opacity: 0.5

            &:not(:last-child)
              margin-bottom: vh(30px)

            &.router-link-active
              opacity: 1

      .toggle-container
        align-items: center
        display: flex
        flex: 0 0 $header-height-mobile
        height: 100%
        justify-content: center

      .toggle
        cursor: pointer
        height: vw(18px)
        margin: 0 vw(10px)
        // m-icon("menu", 30)
        width: vw(18px)

    .header-menu
      background-color: #fff
      display: flex
      flex-direction: column
      height: "calc(100% - %s)" % $header-height-mobile
      justify-content: flex-start
      left: 0
      overflow-y: auto
      padding: vw(20px)
      position: fixed
      top: $header-height-mobile
      width: 100%
      z-index: 2

      &.slide-enter-active,
      &.slide-leave-active
        transition: transform 0.4s ease-in-out

      &.slide-enter,
      &.slide-leave-to /* .drop-down-leave-active below version 2.1.8 */
        transform: translate(0, -100%)

      .link
        align-items: center
        color: #000
        cursor: pointer
        display: flex
        flex: 1 1 0%
        height: 100%
        justify-content: center

        > .text
          m-font("Lelo", "light")
          m-font-size(24, 30)
          m-letter-spacing(50)
          text-transform: uppercase

          .mobile &
            m-font-size(20, 30)

      .footer
        align-items: center
        display: flex
        justify-content: space-between
        m-font("Lelo", "light")
        m-font-size(16, 20)
        m-letter-spacing(50)
        // sino no salia en anroid... ???
        // flex: 1 1 0
        padding: vh(40px) vw(20px)
        text-transform: uppercase
        width: 100%

      .lang-toggle
        m-font("Lelo", "light")
        m-font-size(16, 20)
        m-letter-spacing(50)
        margin: 0

        .mobile &
          m-font-size(20, 30)

      .login-button
        > a
          color: #000
</style>