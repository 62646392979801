<template>
  <div class="similar-perfumes">
    <LoadingOverlay v-if="loading" text="loading_perfumes" />
    <template v-if="results">
      <ListFilters
        v-if="filterDefs && filterDefs.length!==0"
        :listData="results"
        :filterDefs="filterDefs"
        v-model="filterSelection"
        :labelAsSelection="isMobile"
        applyText="search"
      ></ListFilters>
      <div class="explanation">
        {{t("similars_explanation")}}
        <router-link to="/howto">
          <b class="how-link">{{t("how_it_works")}}</b>
        </router-link>
      </div>
      <div class="similars-list" v-if="filteredResults">
        <div class="scroll-wrapper">
          <PerfumeCard
            v-for="perfume in filteredResults"
            :key="perfume.id"
            :data="perfume"
            @click="$emit('select',perfume)"
          ></PerfumeCard>
        </div>
        <div class="no-results" v-if="filteredResults.length===0 && !loading">
          <div class="text">{{t("no_results")}}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ListFilters from '@/components/ListFilters'
import PerfumeCard from '@/components/PerfumeCard'
import {getSimilarPerfumes, getSimilarByIngredients, getSimilarByText, getAllBrands} from '@/services/Api'
import LoadingOverlay from '@/components/LoadingOverlay'
// import {applyFilters} from '@/utils'
import { GENDER_ENUM } from '../utils/enums'
import { filtersToFiltersEventObject } from '../utils'
export default {
  name: 'SimilarPerfumes',
  components:{
    PerfumeCard,
    ListFilters,
    LoadingOverlay
  },
  props: {
    reference: [Object,Array,String],
    type: String,
    selectId:[String,Number]
    
  },

  data (){
    return {
      results: null,
      filterSelection: null,
      loading: false
    }
  },
  computed:{
    filteredResults (){
      // return this.results && applyFilters(this.results,this.filterSelection) || []
      // return applyFilters(this.results,this.filterSelection) || []
      return this.results
    }
  },
  asyncComputed:{
    filterDefs(){
      return getAllBrands().then((brands)=>{
        return [
          // {
          //   id: 'family',
          //   filterLabel: 'filter_by_family',
          //   type: 'options',
          //   keyPath: 'family.id',
          //   labelPath: 'family.name'
          // },
          {
            id: 'gender',
            filterLabel: 'filter_by_gender',
            type: 'options',
            fixedOptions:[{value:GENDER_ENUM.FEMALE,customLabel:'for_her'},{value:GENDER_ENUM.MALE,customLabel:'for_him'},{value:GENDER_ENUM.UNISEX,customLabel:'unisex'}],
            keyPath: 'gender',
            validation:(value,object) => value.indexOf(object)>=0
          },
          {
            id: 'brand',
            filterLabel: 'filter_by_brand',
            type: 'options',
            fixedOptions: brands.map((b)=>({value:b.id,label:b.name})),
            keyPath: 'brand.id',
            female:true,
          }
        ] 
      })

    },
    results () {
      if(!this.reference) return null
      this.loading = true
      if(this.type==="by_perfume" || this.type==="by_profile"){
        
        return getSimilarPerfumes(this.reference,this.filterSelection).then((response)=>{
        // return getSimilarPerfumes(this.reference).then((response)=>{
          this.loading = false
          // Chapuza para solucionar si viene el ID en la URL y no podemos cogerlo de Redux por el "type"
          if(this.selectId) this.$emit("select",response.find(e => e.id==this.selectId))
          return response //.map((p)=>p.perfume)
        })
      } else if (this.type==="by_ingredient"){
        return getSimilarByIngredients(this.reference,this.filterSelection).then((response)=>{
        // return getSimilarByIngredients(this.reference).then((response)=>{
          this.loading = false
          // Chapuza para solucionar si viene el ID en la URL y no podemos cogerlo de Redux por el "type"
          if(this.selectId) this.$emit("select",response.find(e => e.perfume.id==this.selectId))
          return response.map((p)=>p.perfume)
        })
      } else if (this.type==="by_text"){
        return getSimilarByText(this.reference,this.filterSelection).then((response)=>{
        // return getSimilarByText(this.reference).then((response)=>{
          this.loading = false
          // Chapuza para solucionar si viene el ID en la URL y no podemos cogerlo de Redux por el "type"
          if(this.selectId) this.$emit("select",response.find(e => e.perfume.id==this.selectId))
          return response.map((p)=>p.perfume)
        })
      }
      
    }
  },
  methods:{
    getById (id) {
      if(this.filteredResults) return this.filteredResults.find((p)=>p.id == id)
      else return null
    }
  },
  watch:{
    filterSelection(){
      console.log({ filtersSelection: this.filterSelection})
    },
    results(newResults, oldResults){
      if (!newResults || this.results.length === 0) return
      if (oldResults !== null) {
        if (oldResults.map(({id}) => id).join(',') === newResults.map(({id}) => id).join(',')) {
          return
        }
      }
      switch(this.type) {
        case 'by_profile':
          this.eventTracker.emitEvent('RECOMMENDATION_FETCH', {
              filter: this.filterSelection ? filtersToFiltersEventObject(this.filterSelection) : {},
              resultingProfile: {
                perfumes: this.reference.map(({ id }) =>  id)
              },
              topResults: newResults.map(p => p.id)
            })
          break;
        case 'by_perfume':
          this.eventTracker.emitEvent('SEARCH_PERFUMES', {
            type: 'PERFUMES',
            data: {
              perfumes: Array.isArray(this.reference) ? this.reference.map(e => e.id) : [this.reference.id],
            },
            filter: this.filterSelection ? filtersToFiltersEventObject(this.filterSelection) : {},
            topResults: newResults.map(p => p.id)
          })
          break;
        case 'by_ingredient':
          this.eventTracker.emitEvent('SEARCH_PERFUMES', {
            type: 'INGREDIENT',
            data: {
              ingredients: this.reference.map(e => e.id),
            },
            filter: this.filterSelection ? filtersToFiltersEventObject(this.filterSelection) : {},
            topResults: newResults.map((p)=>p.id)
          })
          break;
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.similar-perfumes
  // min-height: 100% //WORNG!! no se porque, pero hacia aparecer un scroll de mas.... :(
  position: relative

  // display: flex
  // flex-direction: column
  .list-filters
    background: #fff
    border-bottom: 1px solid rgba(#000, 0.15)
    flex-wrap: wrap
    padding: vw(10px) 0
    z-index: 1

    .mobile &
      border-bottom: 1px solid rgba(#000, 0.15)
      padding: vw(10px) 0
      width: 100%

      >>> .filter
        flex: 0 0 50%
        overflow: hidden
        padding: 0 vw(10px)

        &:first-child
          padding-right: vw(20px)

        .dropdown-button
          width: 100%

  .explanation
    // m-first-letter-case()
    m-font("Lelo", "light")
    m-font-size(12, 14)
    margin: vh(10px) 0 vh(20px)

    .mobile &
      m-font-size(12, 14)
      margin: vh(15px) 0 vh(25px)

    .how-link
      display: block
      margin-top: vh(5px)
      text-decoration: underline

      .mobile &
        margin-top: vh(10px)

  .similars-list
    .mobile &
      margin-bottom: vh(10px)

    .scroll-wrapper
      display: flex
      flex-wrap: wrap
      height: 100%
      margin: vw(-10px)

      .mobile &
        margin: vw(-5px)

      .perfume-card
        $margin = vw(10px)
        $percentage = percentage((1 / 4))
        $width = "calc(%s - (%s * 2))" % ($percentage $margin)
        flex: 0 0 $width
        height: vw(200px)
        margin: $margin
        overflow: hidden

        .mobile &
          $margin = vw(5px)
          $percentage = percentage((1 / 2))
          $width = "calc(%s - (%s * 2))" % ($percentage $margin)
          flex: 0 0 $width !important
          margin: $margin !important

  .loading-overlay
    .explore-by-concepts &
      position: fixed

      >>> .content
        justify-content: center
        margin-top: 0

    >>> .content
      margin-top: 30vh

  .no-results
    display: flex

    .text
      margin: vw(10px) auto
</style>